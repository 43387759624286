export const findKeysDifferences = (previousKeys: string[], currentKeys: string[]) => {
  const previousSet = new Set(previousKeys);
  const currentSet = new Set(currentKeys);

  const addedKeys = currentKeys.filter(key => !previousSet.has(key));
  const removedKeys = previousKeys.filter(key => !currentSet.has(key));

  return {
    added: addedKeys,
    removed: removedKeys,
    synced: currentKeys,
  };
};

export const syncVisibleKeys = (
  visibleKeys: string[],
  changes: { added: string[], removed: string[] },
) => {
  const { added, removed } = changes;

  let updatedVisibleKeys = visibleKeys.filter(key => !removed.includes(key));

  updatedVisibleKeys = [...updatedVisibleKeys, ...added];

  return updatedVisibleKeys;
};

export const syncOrderedKeys = (
  orderedKeys: string[],
  changes: { added: string[], removed: string[], synced: string[] },
) => {
  const { added, removed, synced } = changes;
  const updatedOrderedKeys = orderedKeys.filter(key => !removed.includes(key));

  added.forEach(key => {
    const index = synced.indexOf(key);
    updatedOrderedKeys.splice(index, 0, key);
  });

  return updatedOrderedKeys;
};

const syncColumns = (
  previousColumns: string[],
  currentColumns: string[],
  visibleKeys: string[],
  orderedKeys: string[],
) => {
  const changes = findKeysDifferences(previousColumns, currentColumns);
  const updatedVisibleKeys = syncVisibleKeys(visibleKeys, changes);
  const updatedOrderedKeys = syncOrderedKeys(orderedKeys, changes);

  return {
    visibleKeys: updatedVisibleKeys,
    orderedKeys: updatedOrderedKeys,
  };
};

export default syncColumns;
