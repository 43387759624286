import React, { useCallback, useMemo, useState } from 'react';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { Dropdown, MenuProps, notification } from 'antd';
import useUpdateUnitClients from 'api/unit/useUpdateUnitClients';
import { NestedUnitType } from 'interfaces';
import { UnknownType } from 'types/Unknown';
import { useTranslate } from 'hooks';
import { StageEnum } from 'hooks/use2FA';
import { useModal } from 'components/Modal/hooks/useModal';
import { OTP_ERROR } from 'constants/index';
import { useAuthorisationContext } from 'contexts';
import AssignClientForm from '../AssignClientForm';
import { Button, CreateClientButton, Modal, With2FA } from 'components';
import { AssignableUserValues } from '../../../../components';
import { UserAddOutlined } from '@ant-design/icons';
import styles from './AddClientDropdown.module.scss';

export type AddClientDropdownProps = {
  unit: NestedUnitType;
  disabled?: boolean;
};

const AddClientDropdown = ({ unit, disabled }: AddClientDropdownProps) => {
  const [savedData, setSavedData] = useState<AssignableUserValues>({
    added: [], removed: [],
  });

  const { t } = useTranslate();
  const {
    twoFA: {
      onSuccess,
      onError,
      setStage,
      stage,
    },
  } = useAuthorisationContext();

  const { isOpen, onModalOpen, onModalClose } = useModal('unit-tab-clients-choose');

  const onRefresh = async () => {
    await cleverRefetchQueries('clients');
  };

  const { mutate, isPending } = useUpdateUnitClients({
    onSuccess: async (res) => {
      onSuccess(res, async () => {
        notification.success({ message: t('users.units.clientsUpdated') });

        await onRefresh();
        onModalClose();
      });
    },
    onError: (error: UnknownType) => {
      onError(error, () => {
        if (stage !== StageEnum.DEFAULT && error.response?.data?.messagemessage.includes(OTP_ERROR)) {
          setStage(StageEnum.DEFAULT);
        }

        notification.error({
          description: error?.response?.data?.message,
          message: error?.response?.status,
        });
      });
    },
  });

  const handleSubmit = useCallback((data: AssignableUserValues, otpCode?: string) => {
    if (!otpCode) {
      setSavedData(data);
    }
    mutate({ id: unit.id, body: { ...data, otpCode } });
  }, [mutate, unit]);

  const handleSubmitOtp = useCallback((otp: string) => {
    return handleSubmit(savedData, otp);
  }, [handleSubmit, savedData]);

  const items: MenuProps['items'] = useMemo(() => ([
    {
      key: 'createNew',
      label: (
        <CreateClientButton
          unitId={unit?.id}
          disabled={disabled}
          rootUnitId={unit?.rootUnitId as number}
          className={styles.createClientButton}
          onRefresh={onRefresh}
        />
      ),
    },
    {
      key: 'selectExisting',
      label: t('client.selectExisting'),
      disabled,
      onClick: onModalOpen,
    },
  ]), [unit, disabled, t, onModalOpen]);

  return (
    <>
      <Dropdown
        menu={{ items }}
        placement="bottomRight"
        trigger={['click']}
        disabled={disabled}
        className={styles.dropdown}
        overlayClassName={styles.overlay}
        // getPopupContainer={() => document.getElementById(containerId) as HTMLElement}
      >
        <Button
          type="default"
          textSize="14px"
          disabled={disabled}
          suffixIcon={<UserAddOutlined style={{ fontSize: 16 }} />}
          className={styles.button}
        >
          {t('users.units.assignClients')}
        </Button>
      </Dropdown>

      <Modal
        width={450}
        destroyOnClose
        isOpen={isOpen}
        title={t('users.units.assignClients')}
        titleIcon={<UserAddOutlined style={{ fontSize: 20 }} />}
        onClose={onModalClose}
      >
        <With2FA onSubmit={handleSubmitOtp}>
          <AssignClientForm
            onSubmit={handleSubmit}
            onModalClose={onModalClose}
            loading={isPending}
            initialValues={{ unitId: unit.id, rootUnitId: unit.rootUnitId }}
          />
        </With2FA>
      </Modal>
    </>
  );
};

export default AddClientDropdown;
