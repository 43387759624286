import { ReactElement } from 'react';
import { Permission } from '@fanckler/processing-auth';
import { Card, Tabs } from 'antd';
import { useTranslate } from 'hooks';
import { useAuthorisationContext } from 'contexts';
import { ActiveSessionsTable, ActivityLogTable } from './components';
import styles from './AccountActivity.module.scss';

type Props = {
  userId: number;
};

export const AccountActivity = ({ userId }: Props): ReactElement => {
  const { t } = useTranslate();
  const { checkPermissions, user } = useAuthorisationContext();
  const canRenderSessions = checkPermissions([Permission.ADMIN_IAM_ROOT_UNIT_ADMIN]) || (user && user?.id === userId);

  return (
    <Card title={t('myAccount.accountActivity')} className={styles.card}>
      <Tabs
        defaultActiveKey={canRenderSessions ? 'activeUsersSessions' : 'activityLog'}
        type="card"
        tabBarStyle={{ paddingBottom: '16px' }}
        className={styles.tabs}
        items={[
          {
            disabled: !canRenderSessions,
            key: 'activeUsersSessions',
            label: t('myAccount.activeUsersSessions'),
            children: <ActiveSessionsTable userId={userId} />,
          },
          {
            key: 'activityLog',
            label: t('myAccount.activityLog'),
            children: <ActivityLogTable userId={userId} />,
          },
        ]}
      />
    </Card>
  );
};
