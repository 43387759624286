import React from 'react';
import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd';
import styles from './CategorizeTransactionSelect.module.scss';

export type CategorizeTransactionSelectProps = AntdSelectProps;

const CategorizeTransactionSelect = ({ ...props }: CategorizeTransactionSelectProps) => {
  return (
    <AntdSelect {...props}
      className={styles.select}
      popupClassName={styles.popup}
      getPopupContainer={trigger => trigger.parentNode}
    />
  );
};

export default CategorizeTransactionSelect;
