import { useMemo } from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { IClientTransaction } from 'interfaces/IClientTransaction';
import getCryptoCurrencyIcon from './utils/getCryptoCurrencyIcon';
import getRows from './utils/getRows';
import PDFTable from './PDFTable';
import styles from './styles';
import logo from 'assets/images/Logo0.png';

const PDFTransactionReceipt = ({
  data,
}: {
  data: IClientTransaction,
}) => {
  const { date, time } = useMemo(() => {
    const dateObject = new Date(data.createdAt);
    const formattedDate = format(dateObject, 'dd.MM.yyyy');
    const formattedTime = format(dateObject, 'HH:mm:ss');

    return {
      date: formattedDate,
      time: formattedTime,
    };
  }, [data.createdAt]);

  return (
    <Document creator={process.env.REACT_APP_TITLE}>
      <Page size={{ width: 595, height: 'auto' }} style={styles.page}>
        <View style={styles.header}>
          <View style={styles.titleWithLogo}>
            <Text style={styles.title}>Transaction receipt</Text>
            <Image src={logo} style={styles.logo} />
          </View>

          <View style={styles.dateWrapper}>
            <Text style={styles.dateTitle}>Receipt date</Text>

            <View style={styles.dateValueWrapper}>
              <Text style={styles.date}>{date}</Text>
              <Text style={styles.time}>{time}</Text>
            </View>
          </View>
        </View>

        <View style={styles.body}>
          <View style={styles.amountWrapper}>
            <Text style={styles.amountTitle}>Amount</Text>

            <View style={styles.amountValueWrapper}>
              <Text style={styles.amountValue}>{Math.abs(+data.amount)}</Text>
              <Image
                src={getCryptoCurrencyIcon(`${data.currency.toLowerCase()}_${data.network.toLowerCase()}`)}
                style={styles.currencyImage}
              />
              <Text style={styles.currencyTicker}>{data.currency}</Text>
            </View>
          </View>

          <PDFTable data={data} rows={getRows()} />
        </View>
      </Page>
    </Document>
  );
};

export default PDFTransactionReceipt;
