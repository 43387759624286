import { Link, Text, View } from '@react-pdf/renderer';
import { IClientTransaction } from 'interfaces/IClientTransaction';
import { UnknownType } from 'types/Unknown';
import { generateBlockchainScanLink } from 'utils/generateBlockchainScanLink';
import styles from '../styles';

export type RowType<TData> = {
  key: string;
  title: string;
  dataIndex: keyof TData;
  render?: (value: UnknownType, record: IClientTransaction) => React.ReactNode;
};

const getRows = () => {
  const rows: RowType<IClientTransaction>[] = [
    {
      key: 'txId',
      title: 'TxID',
      dataIndex: 'txId',
      render: (txId: IClientTransaction['txId'], { network }) => (
        <Link href={generateBlockchainScanLink(network, txId)}>{txId}</Link>
      ),
    },
    {
      key: 'network',
      title: 'Network',
      dataIndex: 'network',
      render: (network: IClientTransaction['network']) => network?.replace(/([a-zA-Z])([0-9])/g, '$1-$2'),
    },
    {
      key: 'fee',
      title: 'Fee',
      dataIndex: 'fee',
      render: (fee: IClientTransaction['fee']) => fee || '-',
    },
    {
      key: 'serviceFee',
      title: 'Service fee',
      dataIndex: 'serviceFee',
      render: (serviceFee: IClientTransaction['serviceFee']) => serviceFee || '-',
    },
    {
      key: 'availableAmount',
      title: 'Available amount',
      dataIndex: 'availableAmount',
      render: (availableAmount: IClientTransaction['availableAmount'], { currency }) => {
        if (!availableAmount) return '-';

        return (
          <View style={styles.availableAmountWrapper}>
            <Text style={styles.availableAmountValue}>{availableAmount} </Text>
            <Text style={styles.availableAmountValue}>{currency}</Text>
            {/* <Image
              src={getCryptoCurrencyIcon(`${currency.toLowerCase()}_${network.toLowerCase()}`)}
              style={styles.availableAmountCurrencyIcon}
            /> */}
          </View>
        );
      },
    },
    {
      key: 'direction',
      title: 'Direction',
      dataIndex: 'direction',
      render: (direction: IClientTransaction['direction']) => direction?.toUpperCase(),
    },
  ];

  return rows;
};

export default getRows;
