import React from 'react';
import { Switch } from 'antd';
import styles from './SwitchItem.module.scss';

export type SwitchItemProps = {
  label: string;
  value?: boolean;
  onChange?: (value?: boolean) => void;
  checkedLabel?: string;
  uncheckedLabel?: string;
};

const SwitchItem = ({
  label,
  value,
  onChange,
  checkedLabel,
  uncheckedLabel,
}: SwitchItemProps) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>{label}</span>

      <div className={styles.switchContainer}>
        <span className={styles.stateLabel}>
          {value ? checkedLabel : uncheckedLabel}
        </span>

        <Switch checked={value} onChange={onChange} />
      </div>
    </div>
  );
};

export default SwitchItem;
