import _ from 'lodash';
import { IWallet } from 'interfaces';
import { UnknownType } from 'types/Unknown';
import { IUnitTree } from 'components/TreeUnitsSelect/types';

const currency = 'USDT'; // Currency in which the balance is displayed

export type TreeOrgStructureDatum = {
  id?: number;
  uuid?: string;
  name: string;
  isActive?: boolean;
  balance?: number;
  currency?: string;
  children?: TreeOrgStructureDatum[];
};

const getTreeStructure = (
  dataArray: IUnitTree[],
  extractBalanceFn: (wallets: IWallet[], currency: string) => number,
) => {
  const convertNode = (node: IUnitTree): TreeOrgStructureDatum => {
    const children = _.map(node.children, convertNode);
    const balance = extractBalanceFn(node.wallets as UnknownType, currency);

    return { ...node, balance, currency, children };
  };

  return _.map(dataArray, convertNode);
};

export default getTreeStructure;
