import axios, { SERVICE } from 'libs/axios';
import { UseMutateFunction, useMutation } from 'libs/reactQuery';

type CreateLogBody = { action: string, payload: string };

type LogMutationReturned = {
  createLog: UseMutateFunction<void, unknown, CreateLogBody, unknown>,
  isCreateLogFetching: boolean;
};

export const useLogMutation = (): LogMutationReturned => {
  const createActivityLog = async (body: CreateLogBody) => {
    const { data } = await axios.post(`${SERVICE.ADMIN}/logs`, body);
    return data;
  };

  const { mutate: createLog, isPending: isCreateLogFetching } = useMutation({ mutationFn: createActivityLog });

  return {
    createLog,
    isCreateLogFetching,
  };
};
