import React, { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Button, Form, Image, Input, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { UnknownType } from 'types/Unknown';
import { WalletCurrencyEnum } from 'types/WalletCurrencyEnum';
import { WalletNetworkEnum } from 'types/WalletNetworkEnum';
import { ExpiryTimeType, InvoiceFormDataType } from 'components/CreateInvoice/types';
import { useTranslate } from 'hooks';
import { EMAIL_SCHEMA } from 'constants/validation';
import { CurrencyIcon } from 'utils/currencyIcon';
import { amountValidator } from './utils';
import { InvoiceView } from '../InvoiceView';
import { SelectWithCheckbox } from './components';
import { CustomExpiryTime } from './components/CustomExpiryTime';
import styles from './CreateInvoiceForm.module.scss';

export enum CreateInvoiceStep {
  CREATE = 'create',
  CONFIRM = 'confirm',
}

export type CreateInvoiceFormProps = {
  isLoading: boolean;
  isAnyAmount?: boolean;
  setAnyAmount: Dispatch<SetStateAction<boolean>>;
  onSubmit: (formData: InvoiceFormDataType) => void;
};

type Option<T extends string> = { value: T, label: T };

const currencyOpts: Option<WalletCurrencyEnum>[] = [
  { value: WalletCurrencyEnum.BTC, label: WalletCurrencyEnum.BTC },
  { value: WalletCurrencyEnum.ETH, label: WalletCurrencyEnum.ETH },
  { value: WalletCurrencyEnum.TRX, label: WalletCurrencyEnum.TRX },
  { value: WalletCurrencyEnum.BNB, label: WalletCurrencyEnum.BNB },
  { value: WalletCurrencyEnum.DAI, label: WalletCurrencyEnum.DAI },
  { value: WalletCurrencyEnum.USDT, label: WalletCurrencyEnum.USDT },
  { value: WalletCurrencyEnum.USDC, label: WalletCurrencyEnum.USDC },
];

const networkOpts: Option<WalletNetworkEnum>[] = [
  { value: WalletNetworkEnum.BITCOIN, label: WalletNetworkEnum.BITCOIN },
  { value: WalletNetworkEnum.ERC20, label: WalletNetworkEnum.ERC20 },
  { value: WalletNetworkEnum.TRC20, label: WalletNetworkEnum.TRC20 },
  { value: WalletNetworkEnum.BEP20, label: WalletNetworkEnum.BEP20 },
];

const networkCurrencyMapping = new Map([
  [WalletNetworkEnum.BITCOIN, [WalletCurrencyEnum.BTC]],
  [WalletNetworkEnum.ERC20, [
    WalletCurrencyEnum.USDT,
    WalletCurrencyEnum.USDC,
    WalletCurrencyEnum.ETH,
  ]],
  [WalletNetworkEnum.TRC20, [WalletCurrencyEnum.TRX, WalletCurrencyEnum.USDT]],
  [WalletNetworkEnum.BEP20, [
    WalletCurrencyEnum.USDT,
    WalletCurrencyEnum.USDC,
    WalletCurrencyEnum.BNB,
    WalletCurrencyEnum.DAI,
  ]],
]);

// const DISABLE_FEE_MOCK = true;
export const MAX_INVOICE_DAYS = 14;

export const CreateInvoiceForm = ({
  isLoading,
  onSubmit,
  isAnyAmount,
  setAnyAmount,
}: CreateInvoiceFormProps): ReactElement => {
  const [selectedNetwork, setSelectedNetwork] = useState<WalletNetworkEnum | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<WalletCurrencyEnum | null>(null);
  const [currencies, setCurrencies] = useState(currencyOpts);

  const [step, setStep] = useState<CreateInvoiceStep>(CreateInvoiceStep.CREATE);
  const [invoice, setInvoice] = useState<InvoiceFormDataType | null>(null);
  const [customTime, setCustomTime] = useState<string>('');
  const { t } = useTranslate();
  const [form] = useForm();

  const onChangeCustomTime = (value: string) => {
    if (value.includes('custom')) {
      setCustomTime(value.split('|')[1]);
    }
  };

  const formatExpiryTime = (expiryTime: string) => {
    if (expiryTime.includes('custom')) {
      const time: ExpiryTimeType = JSON.parse(expiryTime.split('|')[1]);

      if (!isEmpty(time)) {
        return moment().add(time.days, 'd').add(time.hours, 'h').toISOString();
      } else {
        return moment().add(3, 'd').add(0, 'h').toISOString();
      }
    } else {
      const time: ExpiryTimeType = JSON.parse(expiryTime);
      return moment().add(time.days, 'd').add(time.hours, 'h').toISOString();
    }
  };

  useEffect(() => {
    if (isAnyAmount) {
      form.setFieldsValue({
        // fee: '100/0',
        expirationAt: JSON.stringify({ days: MAX_INVOICE_DAYS, hours: 0 }),
      });
    } else {
      form.resetFields(['expirationAt']);
    }
  }, [isAnyAmount, form]);

  // useEffect(() => {
  //   if (DISABLE_FEE_MOCK) {
  //     form.setFieldsValue({ fee: '100/0' });
  //   }
  // }, [form, isAnyAmount]);

  const onFinishForm = (formData: InvoiceFormDataType) => {
    setInvoice(formData);
    setStep(CreateInvoiceStep.CONFIRM);
  };

  const onCofirmSubmit = (formData: InvoiceFormDataType) => {
    onSubmit({
      ...formData,
      expirationAt: formatExpiryTime(formData.expirationAt),
    });
  };

  // const onExchange = (e: any) => {
  //   setExchange(e.target.checked);
  //   form.resetFields(['exchangeCurrency']);
  // };

  // useEffect(() => {
  //   if (isExchange) form.setFieldValue('fee', '100/0');
  // }, [form, isExchange]);

  const handleSelectNetwork = useCallback((newSelectedNetwork: WalletNetworkEnum) => {
    setSelectedNetwork(newSelectedNetwork);

    const currenciesByNetwork = networkCurrencyMapping.get(newSelectedNetwork) || [];
    const filteredCurrencies = currencyOpts.filter(currency => currenciesByNetwork.includes(currency.value));
    setCurrencies(filteredCurrencies);

    form.setFieldValue('currency', filteredCurrencies[0].value);
    form.validateFields(['currency']);

    setSelectedCurrency(filteredCurrencies[0].value);
  }, [form]);

  // const exchangeCurrencyOptions = useMemo(
  //   () => getExchangeCurrencyOptions(currency, network),
  //   [currency, network],
  // );

  const expiryTimeValidator = (_: UnknownType, value: string) => {
    if (!value) return Promise.reject(t('invoice.validation.pleaseSelectExpiryTime'));

    if (!value.match(/^(?!custom\|$).*/)) {
      return Promise.reject(t('invoice.validation.pleaseSelectExpiryTime'));
    } else {
      if (value.split('|')[1]) {
        const time = JSON.parse(value.split('|')[1]);

        if (!time.days && !time.hours) {
          return Promise.reject(t('invoice.validation.minValue'));
        } else {
          return Promise.resolve();
        }
      } else {
        return Promise.resolve();
      }
    }
  };

  // const exchangeValidator = useCallback((_: any, value: string) => {
  //   if (isExchange && !value) {
  //     return Promise.reject(t('invoice.validation.exchangeCurrencyRequired'));
  //   } else {
  //     return Promise.resolve();
  //   }
  // }, [isExchange, t]);

  return (
    (<div className={styles.formWrapper}>
      {step === CreateInvoiceStep.CREATE && (
        <Switch
          checkedChildren="Open amount"
          unCheckedChildren="Open amount"
          className={styles.switch}
          onChange={() => {
            setAnyAmount((prev) => !prev);
          }}
        />
      )}
      {step === CreateInvoiceStep.CREATE && (
        <Form
          form={form}
          onFinish={onFinishForm}
          className={styles.form}
        >
          {!isAnyAmount && (
            <Form.Item
              name="title"
              label={t('invoice.fields.title')}
              className={styles.formItem}
              rules={[
                { required: true, message: t('invoice.validation.pleaseInputTitle') },
                { pattern: /^(?=.*[^ ]).+$/gm, message: t('invalidCharacter') },
              ]}
            >
              <Input className={styles.input} placeholder={t('invoice.fields.title')} />
            </Form.Item>
          )}

          <div className={styles.alignItems}>
            <Form.Item
              name="network"
              className={styles.formItem}
              label={t('processing.payway')}
              rules={[{ required: true, message: t('processing.pleaseSelectPayway') }]}
            >
              <Select
                placeholder="ERC20"
                value={selectedNetwork}
                onSelect={handleSelectNetwork}
                popupClassName={styles.selectCurrencyPopup}
                className={styles.selectCurrency}
              >
                {networkOpts.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    <div className={styles.option}>
                      <Image
                        preview={false}
                        src={CurrencyIcon[option.value]}
                        key={`option-icon-${option.value}`}
                        className={styles.icon}
                      />

                      <span className={styles.label}>{option.label}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="currency"
              className={styles.formItem}
              label={t('processing.currency')}
              rules={[{ required: true, message: t('processing.pleaseSelectCurrency') }]}
            >
              <Select
                placeholder="Ethereum"
                value={selectedCurrency}
                onSelect={setSelectedCurrency}
                popupClassName={styles.selectCurrencyPopup}
                className={styles.selectCurrency}
              >
                {currencies.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    <div className={styles.option}>
                      <Image
                        preview={false}
                        src={CurrencyIcon[option.value]}
                        key={`option-icon-${option.value}`}
                        className={styles.icon}
                      />

                      <span className={styles.label}>{option.label}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          {!isAnyAmount && (
            <Form.Item
              required
              name="amount"
              label={t('processing.paymentOrders.amount')}
              className={styles.formItem}
              normalize={(value: string) => value.replace(',', '.')}
              rules={isAnyAmount ? [] : [{ validator: (_, value) => amountValidator(value, selectedCurrency, t) }]}
            >
              <Input
                disabled={isAnyAmount}
                className={styles.input}
                placeholder={t('processing.paymentOrders.amount')}
              />
            </Form.Item>
          )}

          {/* <Form.Item
            name="fee"
            className={styles.formItem}
            label={t('processing.paymentOrders.fee')}
            rules={[{ required: true, message: t('invoice.validation.pleaseSelectFee') }]}
          >
            <SelectWithCheckbox
              placeholder={t('processing.paymentOrders.fee')}
              className={styles.select}
              value={(isAnyAmount || DISABLE_FEE_MOCK) ? '100/0' : undefined}
              disabled={isAnyAmount || DISABLE_FEE_MOCK}
              options={[
                { label: t('invoice.select.businessPays'), value: '100/0' },
                { label: t('invoice.select.clientPays'), value: '0/100' },
                { label: t('invoice.select.25business75client'), value: '25/75' },
                { label: t('invoice.select.50business50client'), value: '50/50' },
                { label: t('invoice.select.75business25client'), value: '75/25' },
              ]}
            />
          </Form.Item> */}

          <Form.Item
            name="customerEmail"
            initialValue=""
            label={t('invoice.fields.emailCustomer')}
            className={styles.formItem}
            rules={[{ pattern: EMAIL_SCHEMA, message: t('fields.email.validation.rules') }]}
          >
            <Input className={styles.input} placeholder={t('invoice.fields.emailCustomer')} />
          </Form.Item>

          <Form.Item
            required
            name="expirationAt"
            className={styles.formItem}
            label={t('invoice.fields.expiryTime')}
            rules={[
              // { required: true, message: t('invoice.validation.pleaseSelectExpiryTime') },
              // { pattern: /^(?!custom\|$).*/, message: t('invoice.validation.pleaseSelectExpiryTime') },
              { validator: expiryTimeValidator },
            ]}
          >
            <SelectWithCheckbox
              placeholder={t('invoice.fields.expiryTime')}
              expandedContent={CustomExpiryTime}
              wrapperId="expiryTimeSelect"
              className={styles.select}
              onChange={onChangeCustomTime}
              value={customTime}
              disabled={isAnyAmount}
              options={[
                { label: '3 hours', value: JSON.stringify({ days: 0, hours: 3 }) },
                { label: '1 day', value: JSON.stringify({ days: 1, hours: 0 }) },
                { label: '3 days', value: JSON.stringify({ days: 3, hours: 0 }) },
                { label: '7 days', value: JSON.stringify({ days: 7, hours: 0 }) },
                { label: t('invoice.select.customValue'), value: `custom|${customTime}` },
                ...(isAnyAmount ? [{
                  label: `${MAX_INVOICE_DAYS} days`,
                  value: JSON.stringify({ days: MAX_INVOICE_DAYS, hours: 0 }),
                }] : []),
              ]}
            />
          </Form.Item>

          <Form.Item
            name="description"
            initialValue=""
            label={t('administration.financialManagement.fields.description')}
            className={styles.formItem}
          >
            <Input
              className={styles.input}
              placeholder={t('administration.financialManagement.fields.enterDescription')}
            />
          </Form.Item>

          {/* <Space.Compact className={styles.exchangeFields}>
            <Checkbox
              name='isExchange'
              checked={isExchange}
              onChange={onExchange}
              className={styles.checkbox}
            >
              {t('invoice.fields.exchangeFundsFor')}
            </Checkbox>

            <Form.Item
              name='exchangeCurrency'
              className={styles.formItem}
              rules={[{ required: isExchange, message: t('processing.pleaseSelectCurrency') }]}
            >
              <Select
                className={styles.select}
                disabled={!isExchange}
                placeholder={t('processing.currency')}
                options={exchangeCurrencyOptions}
              />
            </Form.Item>
          </Space.Compact> */}

          <Button
            htmlType="submit"
            className={clsx(styles.button, styles.submit)}
          >
            {t('continue')}
          </Button>
        </Form>
      )}
      {(!isEmpty(invoice) && step === CreateInvoiceStep.CONFIRM) && (
        <>
          <InvoiceView invoice={invoice} isAnyAmount={isAnyAmount} />

          <div className={styles.buttons}>
            <Button
              danger
              type="link"
              onClick={() => setStep(CreateInvoiceStep.CREATE)}
              className={clsx(styles.button, styles.back)}
            >
              {t('back')}
            </Button>

            <Button
              loading={isLoading}
              onClick={() => onCofirmSubmit(invoice)}
              className={clsx(styles.button, styles.submit)}
            >
              {t('send')}
            </Button>
          </div>
        </>
      )}
    </div>)
  );
};
