import { IReplenishment } from 'interfaces/IReplenishment';

export const moveItemsToFront = (data: IReplenishment[], uuid: string | undefined) => {
  if (data.length === 0) return;

  return [...data].sort((a, b) => {
    if (a.unit && a.unit.uuid === uuid && b.unit && b.unit.uuid !== uuid) return -1;
    if (a.unit && a.unit.uuid !== uuid && b.unit && b.unit.uuid === uuid) return 1;
    if (a.createdAt < b.createdAt) return 1;
    if (a.createdAt > b.createdAt) return -1;
    return 0;
  });
};
