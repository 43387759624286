import React, { memo, useMemo } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import _ from 'lodash';
import { UnknownType } from 'types/Unknown';
import { DraggableHeaderCell } from './DraggableHeaderCell';
import Table, { TableProps } from 'components/Table';

export type DraggableTableProps<RecordType extends Record<string, UnknownType>> = TableProps<RecordType> & {
  setOrder: React.Dispatch<React.SetStateAction<string[]>>;
};

const components: TableProps<UnknownType>['components'] = {
  header: {
    cell: (props: UnknownType) => props.id
      ? <DraggableHeaderCell {...props} />
      : <th {...props}>{props.children}</th>,
  },
  // body: {
  //   cell: DraggableCell, // Problematic component, if removed it will improve performance
  // },
};

const DraggableTable = <RecordType extends Record<string, UnknownType>>({
  setOrder,
  columns,
  ...props
}: DraggableTableProps<RecordType>) => {
  const enhancedColumns = useMemo(() => (
    columns.map(column => ({
      ...column,
      ...((column.key && !column.fixed) && {
        onHeaderCell: () => ({ id: column.key }),
        onCell: () => ({ id: column.key }),
      }) as UnknownType,
    }))
  ), [columns]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        tolerance: 5,
        delay: 200,
      },
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setOrder(prevOrder => {
        const oldIndex = prevOrder.findIndex(key => key === active.id);
        const newIndex = prevOrder.findIndex(key => key === over?.id);
        const correctedNewIndex = (newIndex === -1 || newIndex === prevOrder.length - 1) ? oldIndex : newIndex;
        return arrayMove(prevOrder, oldIndex, correctedNewIndex);
      });
    }
  };

  const items = useMemo(() => _.map(enhancedColumns, 'key'), [enhancedColumns]) as UniqueIdentifier[];

  return (
    <DndContext
      modifiers={[restrictToHorizontalAxis]}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      sensors={sensors}
    >
      <SortableContext items={items} strategy={horizontalListSortingStrategy}>
        <Table {...props} columns={enhancedColumns} components={components} />
      </SortableContext>

      <DragOverlay dropAnimation={null} />
    </DndContext>
  );
};

export default memo(DraggableTable) as typeof DraggableTable;
