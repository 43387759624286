import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { Filter } from 'types/Filter';
import { UnknownType } from 'types/Unknown';
import { TransactionsSorting } from '../types';

type TransactionsPayload = {
  filter: Filter<Record<string, unknown>>;
  paging: { limit: number; offset: number };
  sorting: TransactionsSorting[];
  page?: 'unit' | 'client' | 'transaction';
};

export const useTransactions = (payload?: TransactionsPayload): UnknownType => {
  const getManagerTransactions = async (context: UnknownType) => {
    const { data } = await axios.get(`${SERVICE.CLIENT}/transactions/manager`, {
      params: context.queryKey[1] || payload,
    });
    return data;
  };

  const refreshWithNewParams = (newParams?: TransactionsPayload) => {
    return getManagerTransactions({
      queryKey: ['transactions-manager', { ...newParams }],
    });
  };

  const { data, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['transactions-manager'],
    queryFn: getManagerTransactions,
    refetchInterval: 30000,
  });

  return {
    data,
    refetch,
    isLoading,
    isRefetching,
    refreshWithNewParams,
  };
};
