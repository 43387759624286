import React, { useState } from 'react';
import { cleverRefetchQueries } from 'libs/reactQuery';
import useCreateInvoice from 'api/invoice/useCreateInvoice';
import { InvoiceFormDataType } from 'components/CreateInvoice/types';
import { useTranslate } from 'hooks';
import { useColdStorageHardwareId } from 'components/ColdStorage/hooks';
import { useModal } from 'components/Modal/hooks/useModal';
import { Button, Modal } from 'components';
import { CreateInvoiceForm } from 'components/CreateInvoice/components';
import { ExceptionOutlined } from '@ant-design/icons';
import styles from './CreateInvoiceButton.module.scss';

export type CreateInvoiceButtonProps = {
  unitId: number;
  disabled?: boolean;
};

const CreateInvoiceButton = ({ unitId, disabled }: CreateInvoiceButtonProps) => {
  const [isAnyAmount, setAnyAmount] = useState(false);
  const hardwareId = useColdStorageHardwareId();
  const { t } = useTranslate();

  const { isOpen, onModalOpen, onModalClose } = useModal('unit-tab-invoices');

  const { mutate, isPending } = useCreateInvoice({
    onSuccess: async () => {
      await cleverRefetchQueries('invoices');
      onModalClose();
    },
  });

  const onSubmit = (formData: InvoiceFormDataType) => {
    mutate({
      unitId,
      isAnyAmount,
      amount: isAnyAmount ? '0' : String(formData.amount),
      network: formData.network,
      currency: formData.currency,
      title: formData.title?.trim() || undefined,
      expirationAt: formData.expirationAt,
      description: formData.description.trim() || undefined,
      customerEmail: formData.customerEmail.trim() || undefined,
      hardwareId: hardwareId || undefined,
    });
  };

  return (
    <>
      <Button
        type="default"
        textSize="14px"
        disabled={disabled}
        onClick={onModalOpen}
        suffixIcon={<ExceptionOutlined style={{ fontSize: 16 }} />}
        className={styles.button}
      >
        {t('invoice.createInvoice')}
      </Button>

      <Modal
        isOpen={isOpen}
        width={450}
        destroyOnClose
        onClose={onModalClose}
        title={t('invoice.createInvoice')}
        titleIcon={<ExceptionOutlined style={{ fontSize: 20 }} />}
      >
        <CreateInvoiceForm
          setAnyAmount={setAnyAmount}
          isAnyAmount={isAnyAmount}
          onSubmit={onSubmit}
          isLoading={isPending}
        />
      </Modal>
    </>
  );
};

export default CreateInvoiceButton;
