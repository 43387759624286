import React, { useCallback, useState } from 'react';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { notification } from 'antd';
import useUpdateUnitManagers from 'api/unit/useUpdateUnitManagers';
import { useTranslate } from 'hooks';
import { StageEnum } from 'hooks/use2FA';
import { useModal } from 'components/Modal/hooks/useModal';
import { OTP_ERROR } from 'constants/index';
import { useAuthorisationContext } from 'contexts';
import AssignManagerForm from '../AssignManagerForm';
import { Button, Modal, With2FA } from 'components';
import { AssignableUserValues } from '../../../../components';
import { TeamOutlined, UserAddOutlined } from '@ant-design/icons';
import styles from './AssignManagerButton.module.scss';

export type AssignManagerButtonProps = {
  unitId: number;
  rootUnitId: number;
  managerIds: number[];
  disabled?: boolean;
};

const AssignManagerButton = ({
  unitId,
  rootUnitId,
  managerIds,
  disabled,
}: AssignManagerButtonProps) => {
  const [savedData, setSavedData] = useState<AssignableUserValues>({
    added: [], removed: [],
  });

  const { t } = useTranslate();
  const {
    twoFA: {
      onSuccess,
      onError,
      stage,
      setStage,
    },
  } = useAuthorisationContext();

  const { isOpen, onModalOpen, onModalClose } = useModal('unit-tab-managers-choose');

  const { mutate, isPending } = useUpdateUnitManagers({
    onSuccess: async (res) => {
      onSuccess(res, async () => {
        notification.success({ message: t('users.units.managersUpdated') });

        await cleverRefetchQueries('unit');
        onModalClose();
      });
    },
    onError: (error) => {
      onError(error, () => {
        if (stage !== StageEnum.DEFAULT && error.response?.data?.message.includes(OTP_ERROR)) {
          setStage(StageEnum.DEFAULT);
        }

        notification.error({
          description: error?.response?.data?.message,
          message: error?.response?.status,
        });
      });
    },
  });

  const handleSubmit = useCallback((data: AssignableUserValues, otpCode?: string) => {
    if (!otpCode) {
      setSavedData(data);
    }
    mutate({ id: unitId, body: { ...data, otpCode } });
  }, [mutate, unitId]);

  const handleSubmitOtp = useCallback((otp: string) => {
    handleSubmit(savedData, otp);
  }, [handleSubmit, savedData]);

  return (
    <>
      <Button
        type="default"
        textSize="14px"
        disabled={disabled}
        onClick={onModalOpen}
        suffixIcon={<UserAddOutlined style={{ fontSize: 16 }} />}
        className={styles.button}
      >
        {t('users.units.assignManagers')}
      </Button>
      <Modal
        width={450}
        destroyOnClose
        isOpen={isOpen}
        title={t('users.units.assignManagers')}
        titleIcon={<TeamOutlined style={{ fontSize: 20 }} />}
        onClose={onModalClose}
      >
        <With2FA onSubmit={handleSubmitOtp}>
          <AssignManagerForm
            loading={isPending}
            onSubmit={handleSubmit}
            onModalClose={onModalClose}
            initialValues={{ managerIds, rootUnitId }}
          />
        </With2FA>
      </Modal>
    </>
  );
};

export default AssignManagerButton;
