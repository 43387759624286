import { ReactNode } from 'react';
import { NestedUnitType } from 'interfaces/IUnit';
import { NestedUnitContext } from './NestedUnitContext';

type NestedUnitProviderProps = {
  children: ReactNode;
  refetch: () => void;
  unit: NestedUnitType | undefined;
};

const NestedUnitProvider = ({ children, unit, refetch }: NestedUnitProviderProps) => {
  return (
    <NestedUnitContext.Provider value={{ unit, refetch }}>
      {children}
    </NestedUnitContext.Provider>
  );
};

export default NestedUnitProvider;
