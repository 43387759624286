import axios, { AxiosError, SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IExternalUser } from 'interfaces/IExternalUser';
import { Filter } from 'types/Filter';
import { UnknownType } from 'types/Unknown';

type ClientsSelectParams = {
  filter?: Filter<Record<string, unknown>> | UnknownType;
  paging?: { limit: number; offset?: number };
  sorting?: UnknownType;
  unit?: boolean;
};

type OptionType = {
  onSuccess?: () => Promise<void>;
  onError?: (e: AxiosError<{ message: string; }>) => void;
};

export const useClients = (params: ClientsSelectParams, options?: OptionType, enabled = true) => {
  const getClients = async () => {
    const { data } = await axios.get(SERVICE.CLIENT + '/clients', { params });
    return data;
  };

  return useQuery<{ nodes: IExternalUser[], totalCount: number }>({
    queryKey: ['clients', params.filter, params.sorting, params.unit],
    queryFn: getClients,
    enabled,
    refetchOnMount: true,
    ...options,
  });
};
