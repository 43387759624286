import axios, { SERVICE } from 'libs/axios';

type TransactionGroupCreateDto = {
  name: string;
  color?: string;
  description?: string;
  rootUnitId?: number;
};

export type TransactionGroupCreateVariables = TransactionGroupCreateDto;

export const transactionGroupCreate = async (body: TransactionGroupCreateVariables) => {
  const { data } = await axios.post(
    `${SERVICE.CLIENT}/transaction-categories/group`, body,
  );
  return data;
};
