import React from 'react';
import { noop } from 'lodash';
import { Currencies, StatusVariant } from 'components/ColdStorage/types';
import { SelectWalletCheckbox } from '../SelectWalletCheckbox';
import styles from './SelectWalletCurrencies.module.scss';

type SelectWalletCurrenciesProps = {
  networks: Currencies[];
  checkedNetworks: Currencies[];
  connectionStatus: Record<string, StatusVariant>;
  onRetry?: (network: Currencies) => void;
  changeCheckedNetworks: (currency: Currencies) => void;
};

const disabledStatuses = [StatusVariant.Connecting, StatusVariant.Loading];

const SelectWalletCurrencies = ({
  networks,
  checkedNetworks,
  connectionStatus,
  onRetry = noop,
  changeCheckedNetworks,
}: SelectWalletCurrenciesProps) => {
  return (
    <div className={styles.list}>
      {networks.map((network) => (
        <SelectWalletCheckbox
          key={network}
          checked={checkedNetworks.includes(network)}
          onClick={() => changeCheckedNetworks(network)}
          currency={network}
          disabled={disabledStatuses.includes(connectionStatus[network])}
          status={connectionStatus[network]}
          onRetry={() => onRetry(network)}
        />
      ))}
    </div>
  );
};

export default SelectWalletCurrencies;
