import React, { useCallback, useMemo } from 'react';
import { UnknownType } from 'types/Unknown';
import { useTranslate } from 'hooks';
import Breadcrumb from './components/Breadcrumb';
import StepperBody from './components/StepperBody';

export type Step<T, StepProps> = {
  id: T,
  label?: React.ReactNode,
  excludeFromBreadcrumb?: boolean,
  component: React.ComponentType<StepProps>,
  footer?: React.ComponentType<StepProps>,
  onBack: ((stepProps?: StepProps) => void) | null | undefined,
  noScroll?: boolean,
};

type Props<T, StepProps> = {
  title?: React.ReactNode,
  stepId: T,
  onStepChange: (step: Step<T, StepProps>) => void,
  steps: Step<T, StepProps>[],
  hideBreadcrumb?: boolean,
  onClose: () => void,
  disabledSteps?: number[],
  errorSteps?: number[],
  error?: Error,
  signed?: boolean,
  children?: React.ReactNode,
  params?: UnknownType,
  hideCloseButton?: boolean,
};

const Stepper = <T, StepProps>({
  stepId,
  steps,
  onStepChange,
  hideBreadcrumb,
  title,
  onClose,
  disabledSteps,
  errorSteps,
  children,
  hideCloseButton,
  ...props
}: Props<T, StepProps>) => {
  const { t } = useTranslate();

  const transitionTo = useCallback(
    (toStepId: string) => {
      const stepIndex = steps.findIndex(s => s.id === toStepId);
      const step = steps[stepIndex];
      onStepChange(step);
    },
    [onStepChange, steps],
  );

  const { step, visibleSteps, indexVisible } = useMemo(() => {
    const stepIndex = steps.findIndex(s => s.id === stepId);
    const stepReturned = steps[stepIndex];

    const visibleStepsReturned = steps.filter(s => !s.excludeFromBreadcrumb);
    const indexVisibleReturned = Math.min(
      steps.slice(0, stepIndex).filter(s => !s.excludeFromBreadcrumb).length,
      visibleStepsReturned.length - 1,
    );

    return {
      step: stepReturned,
      visibleSteps: visibleStepsReturned,
      indexVisible: indexVisibleReturned,
    };
  }, [stepId, steps]);

  const {
    component: StepComponent,
    footer: StepFooter,
    noScroll,
    onBack,
  } = step;

  const stepProps: UnknownType = {
    ...props,
    onClose,
    t,
    transitionTo,
  };

  return (
    <StepperBody
      refocusWhenChange={stepId}
      // onClose={hideCloseButton || deviceBlocked ? undefined : onClose}
      onBack={onBack ? () => onBack(stepProps) : undefined}
      title={title}
      noScroll={noScroll}
      onClose={onClose}
      render={() => (
        <>
          {!hideBreadcrumb && (
            <Breadcrumb
              // mb={props.error && props.signed ? 4 : 6}
              currentStep={indexVisible}
              items={visibleSteps}
              stepsDisabled={disabledSteps}
              stepsErrors={errorSteps}
            />
          )}
          <StepComponent {...stepProps} />
          {children}
        </>
      )}
      renderFooter={StepFooter ? () => <StepFooter {...stepProps} /> : undefined}
    />
  );
};

export default Stepper;
