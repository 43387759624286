import React, { useCallback } from 'react';
import { Checkbox, Form, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useForm } from 'antd/lib/form/Form';
import { UnitFormData } from '../../types';
import { useTranslate } from 'hooks';
import { LocalStorage, LocalStorageKey } from 'utils';
import { Button } from 'components';
import styles from './FormFreezeUnit.module.scss';

export interface ModalUnitFormProps {
  loading?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  initialValues: UnitFormData | null;
}

export const FormFreezeUnit = ({
  loading,
  onClose,
  onSubmit,
  initialValues,
}: ModalUnitFormProps) => {
  const { t } = useTranslate();
  const [form] = useForm();

  const translationVariable = {
    variables: { unitName: `${initialValues?.name}` },
  };

  const titleJSX = initialValues?.isActive
    ? t('users.units.areYouSureDeactivate', translationVariable)
    : t('users.units.areYouSureActivate', translationVariable);

  const descriptionJSX = initialValues?.isActive
    ? t('users.units.areYouSureDeactivateDescription', translationVariable)
    : t('users.units.areYouSureActivateDescription', translationVariable);

  const handleCheckboxChange = useCallback((event: CheckboxChangeEvent) => {
    const checked = event.target.checked;
    LocalStorage.set(LocalStorageKey.DEACTIVATE_UNIT_DONT_SHOW_CONFIRMATION, checked ? 'true' : 'false');
  }, []);

  return (
    <Form form={form} onFinish={() => onSubmit()} className={styles.form}>
      <Typography className={styles.title}>{titleJSX}</Typography>
      <Typography className={styles.description}>{descriptionJSX}</Typography>

      <Checkbox
        onChange={handleCheckboxChange}
        className={styles.checkbox}
      >
        {t('users.units.dontShowAgain')}
      </Checkbox>

      <div className={styles.buttons}>
        <Button
          block
          color={initialValues?.isActive ? 'error' : 'primary'}
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          {initialValues?.isActive ? t('deactivate') : t('activate')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          disabled={loading}
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
      </div>
    </Form>
  );
};
