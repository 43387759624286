import { OptionsKeys } from 'pages/Administration/Units/components/NestedUnit/components/WalletsAndBalance/types';
import { CurrencyRate, Wallet } from 'hooks/useCurrencyRate';

type CalculateBalanceParams = {
  group: Record<string, number>;
  unit: Wallet[];
  rates: CurrencyRate[];
  viewType: OptionsKeys;
  viewCurrency: string
};

const prepareUnitBalances = (wallets: Wallet[]) => {
  const res = {} as Record<string, number>;

  wallets.forEach((wallet) => {
    if (!wallet.balance || !wallet.currency) return;

    if (res[wallet.currency]) {
      return res[wallet.currency] += Number(wallet.balance);
    }
    res[wallet.currency] = Number(wallet.balance);
  });
  return res;
};

const removeZeroBalances = (wallets: Record<string, number | string>) => {
  return Object.entries(wallets).reduce((acc: Record<string, number | string>, [key, value]) => {
    if (Number(value) === 0) return acc;
    return { ...acc, [key]: Number(value) };
  }, {} as Record<string, number>) as Record<string, number>;
};

const getTargetBalances = ({ group, unit, viewType }: CalculateBalanceParams) => {
  if (viewType === OptionsKeys.Group) {
    return group;
  }

  if (viewType === OptionsKeys.Unit) {
    return prepareUnitBalances(unit);
  }

  return {};
};

const calculateBalance = (params: CalculateBalanceParams) => {
  const { rates, viewCurrency } = params;

  const targetBalances = removeZeroBalances(getTargetBalances(params));
  return Object.entries(targetBalances).reduce((acc, [currency, balance]) => {
    const rate: CurrencyRate | undefined = rates.find((currentRate) => {
      return currentRate.input === currency.toLowerCase() && currentRate.output === viewCurrency.toLowerCase();
    });
    const formattedRate = (rate?.rate && rate.rate[1]) || 1;

    return {
      ...acc,
      [currency]: {
        original: balance,
        converted: balance * formattedRate,
      },
    };
  }, {} as Record<string, { original: number, converted: number }>);
};

export default calculateBalance;
