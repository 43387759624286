import { useMemo } from 'react';
import { IBusiness } from 'interfaces/IBusiness';
import { IChildrenUnit, IUnit } from 'interfaces/IUnit';
import { useCurrencyRate } from 'hooks/useCurrencyRate';
import { useUnitsTree } from 'components/TreeUnitsSelect/hooks';
import { getTreeStructure } from '../utils';

const useTreeOrgStructure = <ParentType extends IBusiness | IUnit | IChildrenUnit>(
  parent: ParentType,
) => {
  const { walletBalance } = useCurrencyRate();

  const resolvedRootUnitId = 'rootUnitId' in parent && parent.rootUnitId ? parent.rootUnitId : parent.id;
  const currentUnitId = 'rootUnitId' in parent && parent.rootUnitId ? parent.id : undefined;

  const { data = [], isLoading } = useUnitsTree({
    rootUnitId: resolvedRootUnitId,
    unitId: currentUnitId,
    balances: true,
    nested: true,
  });

  const tree = useMemo(() => getTreeStructure(data, walletBalance), [data, walletBalance]);

  // This case only for root organizational unit (business)
  const treeWithParent = {
    id: parent.id,
    name: parent.name,
    logo: parent.logo,
    isActive: parent.isActive,
    children: tree,
  };

  return {
    tree: currentUnitId ? tree : treeWithParent,
    isLoading,
  };
};

export default useTreeOrgStructure;
