import storage from 'redux-persist/lib/storage';
import { ColdStorage, ColdStorageActionType } from 'components/ColdStorage/types';

const initialState: ColdStorage = {
  isColdStorageMode: false,
  connectedDeviceId: null,
  lastConnectedDeviceId: null,
  storages: {},
};

const STORAGE_VERSION = 1;

export const coldStoragePersistConfig = {
  key: `coldStorage_v${STORAGE_VERSION}`,
  storage,
  whitelist: ['isColdStorageMode', 'storages', 'lastConnectedDeviceId'],
  blacklist: ['connectedDeviceId'],
};

export const coldStorageReducer = (
  state = initialState,
  { payload, type }: ColdStorageActionType,
): ColdStorage => {
  if (!type) {
    return state;
  }

  switch (type) {
    case 'SET_COLD_STORAGE_MODE':
      return {
        ...state,
        isColdStorageMode: payload,
      };
    case 'SET_COLD_STORAGE_DEVICE_ID':
      return {
        ...state,
        connectedDeviceId: payload,
      };
    case 'SET_COLD_STORAGE_LAST_CONNECTED_DEVICE_ID':
      return {
        ...state,
        lastConnectedDeviceId: payload,
      };
    case 'SET_COLD_STORAGE_WALLETS':
      return {
        ...state,
        storages: {
          ...state.storages,
          [payload.id]: {
            ...state.storages[payload.id],
            wallets: {
              ...state.storages[payload.id].wallets,
              ...payload.wallets,
            },
          },
        },
      };
    case 'REMOVE_COLD_STORAGE_WALLET': {
      const newWallets = { ...state.storages[payload.id].wallets };
      delete newWallets[payload.currency];
      return {
        ...state,
        storages: {
          ...state.storages,
          [payload.id]: {
            ...state.storages[payload.id],
            wallets: newWallets,
          },
        },
      };
    }
    case 'SET_COLD_STORAGE_DEVICE': {
      if (!state.storages[payload.id]) {
        return {
          ...state,
          storages: {
            ...state.storages,
            [payload.id]: {
              id: payload.id,
              type: payload.type,
              name: payload.name,
              lastConnected: new Date().toISOString(),
              wallets: {},
            },
          },
          lastConnectedDeviceId: payload.id,
        };
      } else {
        return {
          ...state,
          storages: {
            ...state.storages,
            [payload.id]: {
              ...state.storages[payload.id],
              name: payload.name,
              lastConnected: new Date().toISOString(),
            },
          },
          lastConnectedDeviceId: payload.id,
        };
      }
    }
    default:
      return state;
  }
};
