import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { IChildrenUnit, IUnit } from 'interfaces/IUnit';
import { Controls } from 'pages/Administration/Businesses/components';
import { CheckPermissionsFunction } from 'contexts/AuthorisationContext/AuthorisationContext';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import getMenuItems from './getMenuItems';
import { BalanceCell, GroupBalanceCell, OrganizationalLogo, TruncatedTooltip } from 'components';
import styles from '../Units.module.scss';

export interface GetColumnsParams {
  t: TranslateFunc;
  checkPermissions: CheckPermissionsFunction;
  expandedRowKeys: React.Key[];
  setExpandedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
  openEditModal: (unit: IUnit) => void;
  openToggleModal: (unit: IUnit) => void;
}

const getColumns = ({
  t,
  openEditModal,
  openToggleModal,
  checkPermissions,
  expandedRowKeys,
  setExpandedRowKeys,
}: GetColumnsParams) => {
  const columns: ColumnsType<IUnit | IChildrenUnit> = [
    {
      key: 'name',
      title: t('users.units.unitName'),
      dataIndex: 'name',
      width: 250,
      className: styles.columnName,
      render: (value, record) => (
        <div className={styles.columnLogoWithName}>
          <OrganizationalLogo name={record.name} logoPath={record.logo} />
          <span className={styles.columnValue}>
            <TruncatedTooltip text={value} max={32} />
          </span>
        </div>
      ),
    },
    {
      key: 'unitsCount',
      title: t('unitsCount'),
      dataIndex: 'unitsCount',
      render: value => <span className={styles.columnValue}>{value}</span>,

      // dataIndex: 'unitCount',
      // sorter: !withoutSort,
      // sortOrder: sort && sort.field === 'unitCount' ? sort.order : undefined,
      // render: value => <span className={styles.columnValue}>{value}</span>,
    },
    {
      key: 'adminIds',
      title: t('administration.managers.title'),
      dataIndex: 'adminIds',
      render: (admins: IUnit['adminIds']) => (
        <span className={styles.columnValue}>{admins.length}</span>
      ),
    },
    {
      key: 'clientsCount',
      title: t('clients'),
      dataIndex: 'clientsCount',
      render: value => <span className={styles.columnValue}>{value}</span>,
    },
    {
      key: 'transactionsCount',
      title: t('transactions'),
      dataIndex: 'transactionsCount',
      render: value => <span className={styles.columnValue}>{value}</span>,
    },
    {
      key: 'balance',
      title: t('balance'),
      dataIndex: 'wallets',
      width: 250,
      render: value => <BalanceCell wallets={value} />,
    },
    {
      key: 'groupBalance',
      title: t('users.units.groupBalance'),
      dataIndex: 'totalBalances',
      width: 250,
      render: value => <GroupBalanceCell balances={value} />,
    },
  ];

  columns.push({
    key: '__extra__',
    fixed: 'right',
    width: 130,
    render: (record: IUnit) => (
      <Controls
        record={record}
        expandedRowKeys={expandedRowKeys}
        setExpandedRowKeys={setExpandedRowKeys}
        menuItems={getMenuItems({
          record,
          checkPermissions, t,
          openEditModal,
          openToggleModal,
        })}
      />
    ),
  });

  return columns;
};

export default getColumns;
