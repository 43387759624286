import { Locale } from 'contexts/TranslateContext/interfaces/Locale';

export enum LocalStorageKey {
  LOCALE = 'LOCALE',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  LAST_ACTIVITY = 'LAST_ACTIVITY',
  BUSINESS_LOGO = 'BUSINESS_LOGO',

  FINANCIAL_STATEMENT_COLUMNS = 'FINANCIAL_STATEMENT_COLUMNS',
  BREADCRUMBS_FROM_UNIT = 'BREADCRUMBS_FROM_UNIT',

  UNIT_PENDING_APPROVAL_COLUMNS = 'UNIT_PENDING_APPROVAL_COLUMNS',
  UNIT_SUBACCOUNTS_COLUMNS = 'UNIT_SUBACCOUNTS_COLUMNS',
  UNIT_WHITELIST_COLUMNS = 'UNIT_WHITELIST_COLUMNS',
  UNIT_MANAGERS_COLUMNS = 'UNIT_MANAGERS_COLUMNS',
  UNIT_CLIENTS_COLUMNS = 'UNIT_CLIENTS_COLUMNS',

  ADMINISTRATION_ROLES_COLUMNS = 'ADMINISTRATION_ROLES_COLUMNS_v2',
  ADMINISTRATION_MANAGERS_COLUMNS = 'ADMINISTRATION_MANAGERS_COLUMNS_v2',
  ADMINISTRATION_REPLENISHMENT_REQUESTS_COLUMNS = 'ADMINISTRATION_REPLENISHMENT_REQUESTS_COLUMNS_v2',

  USERS_CLIENTS_COLUMNS = 'USERS_CLIENTS_COLUMNS_v2',
  USERS_CLIENTS_TRANSACTIONS_COLUMNS = 'USERS_CLIENTS_TRANSACTIONS_COLUMNS_v2',
  USERS_CLIENTS_SUBACCOUNTS_COLUMNS = 'USERS_CLIENTS_SUBACCOUNTS_COLUMNS_v2',
  USERS_TRANSACTIONS_COLUMNS = 'USERS_TRANSACTIONS_COLUMNS_v2',
  USERS_INVOICES_COLUMNS = 'USERS_INVOICES_COLUMNS_v2',

  PROCESSING_PAYMENT_ORDERS_COLUMNS = 'PROCESSING_PAYMENT_ORDERS_COLUMNS_v2',
  PROCESSING_TRANSACTIONS_COLUMNS = 'PROCESSING_TRANSACTIONS_COLUMNS_v2',
  PROCESSING_TRANSACTIONS_FIAT_COLUMNS = 'PROCESSING_TRANSACTIONS_FIAT_COLUMNS_v2',

  SYSTEM_TASKS_COLUMNS = 'SYSTEM_TASKS_COLUMNS_v2',
  SYSTEM_FIREWALL_COLUMNS = 'SYSTEM_FIREWALL_COLUMNS_v2',
  SYSTEM_ACTIVITY_LOG_COLUMNS = 'SYSTEM_ACTIVITY_LOG_COLUMNS_v2',

  WALLETS_FAVORITES = 'WALLETS_FAVORITES',
  WALLETS_SELECTED = 'WALLETS_SELECTED',

  EXCHANGE_MODULE_CONFIRM_RATE = 'EXCHANGE_MODULE_CONFIRM_RATE',

  DEACTIVATE_UNIT_DONT_SHOW_CONFIRMATION = 'DEACTIVATE_UNIT_DONT_SHOW_CONFIRMATION',
}

interface KeyValueMap {
  readonly [LocalStorageKey.SYSTEM_TASKS_COLUMNS]: string[];
  readonly [LocalStorageKey.SYSTEM_FIREWALL_COLUMNS]: string[];
  readonly [LocalStorageKey.SYSTEM_ACTIVITY_LOG_COLUMNS]: string[];
  readonly [LocalStorageKey.FINANCIAL_STATEMENT_COLUMNS]: string[];
  readonly [LocalStorageKey.UNIT_PENDING_APPROVAL_COLUMNS]: string;
  readonly [LocalStorageKey.UNIT_SUBACCOUNTS_COLUMNS]: string;
  readonly [LocalStorageKey.UNIT_WHITELIST_COLUMNS]: string;
  readonly [LocalStorageKey.UNIT_MANAGERS_COLUMNS]: string;
  readonly [LocalStorageKey.UNIT_CLIENTS_COLUMNS]: string;
  readonly [LocalStorageKey.ADMINISTRATION_ROLES_COLUMNS]: string;
  readonly [LocalStorageKey.ADMINISTRATION_ROLES_COLUMNS]: string;
  readonly [LocalStorageKey.ADMINISTRATION_MANAGERS_COLUMNS]: string;
  readonly [LocalStorageKey.ADMINISTRATION_REPLENISHMENT_REQUESTS_COLUMNS]: string;
  readonly [LocalStorageKey.PROCESSING_PAYMENT_ORDERS_COLUMNS]: string;
  readonly [LocalStorageKey.PROCESSING_TRANSACTIONS_COLUMNS]: string;
  readonly [LocalStorageKey.PROCESSING_TRANSACTIONS_FIAT_COLUMNS]: string[];
  readonly [LocalStorageKey.USERS_CLIENTS_COLUMNS]: string;
  readonly [LocalStorageKey.USERS_CLIENTS_TRANSACTIONS_COLUMNS]: string;
  readonly [LocalStorageKey.USERS_CLIENTS_SUBACCOUNTS_COLUMNS]: string;
  readonly [LocalStorageKey.USERS_TRANSACTIONS_COLUMNS]: string;
  readonly [LocalStorageKey.USERS_INVOICES_COLUMNS]: string;
  readonly [LocalStorageKey.BREADCRUMBS_FROM_UNIT]: string[];
  readonly [LocalStorageKey.LAST_ACTIVITY]: string;
  readonly [LocalStorageKey.ACCESS_TOKEN]: string;
  readonly [LocalStorageKey.REFRESH_TOKEN]: string;
  readonly [LocalStorageKey.LOCALE]: Locale;
  readonly [LocalStorageKey.BUSINESS_LOGO]: string;
  readonly [LocalStorageKey.WALLETS_FAVORITES]: string[];
  readonly [LocalStorageKey.WALLETS_SELECTED]: string[];
  readonly [LocalStorageKey.EXCHANGE_MODULE_CONFIRM_RATE]: string;
  readonly [LocalStorageKey.DEACTIVATE_UNIT_DONT_SHOW_CONFIRMATION]: string;
}

const parsableKeys = [
  // LocalStorageKey.USERS_CLIENTS_COLUMNS,
  // LocalStorageKey.SYSTEM_TASKS_COLUMNS,
  // LocalStorageKey.SYSTEM_ACTIVITY_LOG_COLUMNS,
  // LocalStorageKey.PROCESSING_PAYMENT_ORDERS_COLUMNS,
  // LocalStorageKey.PROCESSING_TRANSACTIONS_COLUMNS,
  // LocalStorageKey.PROCESSING_TRANSACTIONS_FIAT_COLUMNS,
  // LocalStorageKey.USERS_TRANSACTIONS_COLUMNS,
  // LocalStorageKey.USERS_INVOICES_COLUMNS,
  LocalStorageKey.BREADCRUMBS_FROM_UNIT,
  LocalStorageKey.LAST_ACTIVITY,
  LocalStorageKey.WALLETS_FAVORITES,
  LocalStorageKey.WALLETS_SELECTED,
];

class LocalStorage {
  static get<T extends LocalStorageKey, R extends KeyValueMap[T]>(key: T): R | null {
    const value = localStorage.getItem(key);

    if (value === null) {
      return null;
    }

    return LocalStorage.parseValue(key, value) as R;
  }

  static set<T extends LocalStorageKey, R extends KeyValueMap[T]>(key: T, value: R): void {
    localStorage.setItem(key, LocalStorage.stringifyValue(key, value));
  }

  static remove<T extends LocalStorageKey>(key: T): void {
    localStorage.removeItem(key);
  }

  private static stringifyValue<
    T extends LocalStorageKey,
    R extends KeyValueMap[T],
  >(key: T, value: R): string {
    if (parsableKeys.includes(key)) {
      return JSON.stringify(value);
    }
    return value.toString();
  }

  private static parseValue<
    T extends LocalStorageKey,
    R extends KeyValueMap[T],
  >(key: LocalStorageKey, value: string): R | string {
    if (parsableKeys.includes(key)) {
      return LocalStorage.parseJSON(key, value) as R;
    }
    return value;
  }

  private static parseJSON<T extends LocalStorageKey, R = KeyValueMap[T]>(
    key: T,
    value: string,
  ): R | null {
    try {
      return JSON.parse(value);
    } catch {
      localStorage.removeItem(key);

      return null;
    }
  }
}

export default LocalStorage;
