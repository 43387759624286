import React, { Fragment, useEffect, useRef, useState } from 'react';

type DebouncedButtonProps = {
  onClick?: () => void;
  children: (props: { isDisabled: boolean; onClick: () => void }) => React.ReactNode;
};

const DebouncedButton = ({ onClick, children }: DebouncedButtonProps) => {
  const [internalDisable, setInternalDisable] = useState(false);

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const debounceEnableTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleClick = () => {
    if (!onClick) return;

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    if (debounceEnableTimeout.current) {
      clearTimeout(debounceEnableTimeout.current);
    }
    setInternalDisable(true);

    debounceTimeout.current = setTimeout(() => {
      onClick();
    }, 500);
    debounceTimeout.current = setTimeout(() => {
      setInternalDisable(false);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      if (debounceEnableTimeout.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(debounceEnableTimeout.current);
      }
    };
  }, []);

  return (
    <Fragment>
      {children({ isDisabled: internalDisable, onClick: handleClick })}
    </Fragment>
  );
};

export default DebouncedButton;
