import React from 'react';
import clsx from 'clsx';
import styles from './Chip.module.scss';

type ChipProps = {
  children: React.ReactNode;
  onClick?: () => void;
  isActive?: boolean;
};

const Chip = ({ children, onClick, isActive }: ChipProps) => {
  return (
    <span
      className={clsx(styles.chip, isActive && styles.active)}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default Chip;
