import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { UseQueryHandlers } from 'types/UseQueryHandlers';

export type UpdateTransactionCategoryParams = {
  transactionId: number;
  categoryId: number;
};

const useUpdateTransacitonCategory = (options?: UseQueryHandlers) => {
  const updateTransaction = async (params: UpdateTransactionCategoryParams) => {
    const { data } = await axios.patch(`${SERVICE.CLIENT}/transactions/category`, null, { params });
    return data;
  };

  return useMutation({ ...options, mutationFn: updateTransaction });
};

export default useUpdateTransacitonCategory;
