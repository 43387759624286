export enum LogActionEnum {
  // PROCESSING PO
  CREATE_PO_LVL_2 = 'createPaymentOrderLvl2',
  APPROVE_PO_LVL_2 = 'approvePaymentOrderLvl2',
  DECLINE_PO_LVL_2 = 'declinePaymentOrderLvl2',

  // USER PO
  CREATE_PAYMENT_ORDER = 'createPaymentOrder',
  MASS_PAYOUT_INITIALIZATION = 'massPayoutInitialization',
  REQUEST_REPLENISHMENT = 'requestReplenishment',

  DEPOSIT_CHECKOUT = 'depositCheckout',
  RESEND_TRANSACTION = 'resendTransaction',

  // CLIENT
  CREATE_CLIENT = 'createClient',
  ADD_CLIENT_WALLET = 'addClientWallet',

  // ROOT UNIT
  CREATE_ROOT_UNIT = 'createRootUnit',
  UPDATE_ROOT_UNIT = 'updateRootUnit',

  // UNIT
  CREATE_UNIT = 'createUnit',
  UPDATE_UNIT = 'updateUnit',
  ACTIVE_UNIT = 'activeUnit',
  INACTIVE_UNIT = 'inactiveUnit',
  ADD_WALLET_TO_UNIT = 'addWalletToUnit',
  UPDATE_UNIT_CLIENTS = 'updateUnitClients',
  UPDATE_UNIT_MANAGERS = 'updateUnitManagers',

  // INVOICE
  CREATE_INVOICE = 'createInvoice',

  // EXCHANGE
  CREATE_EXCHANGE = 'createExchange',

  // WHITELIST
  ADD_WHITELIST_WALLET = 'addWhitelistWallet',

  // SUBACCOUNT
  ADD_SUB_WALLET_TO_UNIT = 'addSubWalletToUnit',

  // INTERNAL USER
  CREATE_USER = 'createUser',
  UPDATE_USER = 'updateUser',
  UPDATE_MANY_USERS = 'updateManyUsers',

  // GROUP/ROLES
  CREATE_GROUP = 'createGroup',
  UPDATE_GROUP = 'updateGroup',

  // TRANSACTION CATEGORIZATION
  CREATE_TRANSACTION_GROUP = 'createTransactionGroup',
  UPDATE_TRANSACTION_GROUP = 'updateTransactionGroup',
  DELETE_TRANSACTION_GROUP = 'deleteTransactionGroup',
  CREATE_TRANSACTION_CATEGORY = 'createTransactionCategory',
  UPDATE_TRANSACTION_CATEGORY = 'updateTransactionCategory',
  DELETE_TRANSACTION_CATEGORY = 'deleteTransactionCategory',

  // FIREWALL
  ADD_IP = 'addIP',
  ADD_BOT_IP = 'addBotIP',
  UPDATE_IP = 'updateIP',
  DELETE_IP = 'deleteIP',

  // MY ACCOUNT
  UPDATE_PASSWORD = 'updatePassword',
  INVALID_PASSWORD = 'invalidPassword',
  SET_SMTP_OPTIONS = 'setSMTPOptions',

  // AUTH
  USER_LOGIN_SSO = 'userLoginSSO',
  USER_LOGIN_PASSWORD_LESS = 'userLoginPasswordLess',
  USER_SEND_PASSWORD_LESS = 'userSendPasswordLess',
  USER_LOGIN = 'userLogin',
  USER_LOGOUT = 'userLogout',
  USER_DELETE_SESSION = 'userDeleteSession',
}
