import React from 'react';
import clsx from 'clsx';
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import styles from './Alert.module.scss';

export type AlertType = 'warn' | 'err' | 'info';

const Alert = ({
  type = 'info',
  children,
}: {
  type?: AlertType;
  children: React.ReactNode;
}) => {
  return (
    <div className={clsx(styles.wrapper, {
      [styles.warn]: type === 'warn',
      [styles.err]: type === 'err',
    })}>
      <div className={styles.content}>
        {type === 'warn'
          ? <WarningOutlined className={styles.icon} />
          : type === 'err'
            ? <CloseCircleOutlined className={styles.icon} />
            : <ExclamationCircleOutlined className={styles.icon} />}

        {children}
      </div>
    </div>
  );
};

export default Alert;
