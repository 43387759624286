import { UseMutateFunction } from 'libs/reactQuery';
import { UnknownType } from 'types/Unknown';

export enum MutateActionEnum {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export type MutationFunctionMap<Variables extends Record<MutateActionEnum, UnknownType>> = {
  [K in MutateActionEnum]: UseMutateFunction<UnknownType, Error, Variables[K]>;
};

// Responses types
export interface IUnit {
  id: number,
  createdAt: string,
  updatedAt: string,
  uuid: string,
  name: string,
  rootUnitId: number,
  parentId: number | null,
  adminIds: number[],
  description: string;
  isActive: boolean;
}

export interface ITransactionCategory {
  id: number;
  name: string;
  units: IUnit[];
  color: string | null;
  description: string | null;
  updatedAt: string;
  createdAt: string;
}

export interface ITransactionGroup {
  id: number;
  name: string;
  color: string | null;
  description: string | null;
  rootUnitId: number | null;
  createdAt: string;
  updatedAt: string;
  categories: ITransactionCategory[];
}
