import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IChildrenUnit } from 'interfaces';

export type UseUnitChildrenParams = {
  uuid?: string;
  paging?: { limit: number; offset: number };
};

const useUnitChildren = ({ uuid, ...params }: UseUnitChildrenParams) => {
  const getUnitChildren = async () => {
    const { data } = await axios.get(`${SERVICE.CLIENT}/units/${uuid}/children`, { params });
    return data as IChildrenUnit[];
  };

  return useQuery({
    queryFn: getUnitChildren,
    queryKey: ['unit-children', uuid],
    enabled: Boolean(uuid),
  });
};

export default useUnitChildren;
