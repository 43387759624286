import React from 'react';
import { useActivityLogsTable } from './hooks';
import Table from 'components/Table/Table';

type Props = {
  userId: number;
};

export const ActivityLogTable = ({ userId }: Props) => {
  const { isLoading, data, columns } = useActivityLogsTable({ userId });

  return (
    <Table
      loading={isLoading}
      dataSource={data?.nodes}
      columns={columns}
      scroll={{ x: 'max-content' }}
      pagination={false}
      rowKey="id"
    />
  );
};
