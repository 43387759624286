import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { batch } from 'react-redux';
import useWithdrawSubaccount from 'api/client/useWithdrawSubaccount';
import { IWallet } from 'interfaces';
import { Path } from 'routes/interfaces/Path';
import { UnknownType } from 'types/Unknown';
import { useClients, useCurrencyRate, useTablePagination, useTranslate } from 'hooks';
import useTableColumns from 'hooks/useTableColumns';
import { useModal } from 'components/Modal/hooks/useModal';
import { AuthorisationContext } from 'contexts';
import { unifyWalletsStructure } from 'utils';
import { LocalStorageKey } from 'utils/localStorage';
import getColumns from './utils/getColumns';
import { Modal, Pagination, TransferFundsForm, TransferFundsFormDataReturned } from 'components';
import DraggableTable from 'components/DraggableTable';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import styles from './ClientsTab.module.scss';

export type ClientsTabProps = {
  unitId: number;
};

const ClientsTab = ({ unitId }: ClientsTabProps) => {
  const { checkPermissions } = useContext(AuthorisationContext);
  const { walletBalance } = useCurrencyRate();
  const navigate = useNavigate();
  const { t } = useTranslate();

  const {
    isOpen,
    payload,
    onModalOpen,
    onModalClose,
    onSetModalPayload,
  } = useModal('unit-tab-clients-withdraw');

  const { page, perPage, setPage, setPerPage } = useTablePagination({
    withoutURL: true,
  });

  const { data, isLoading, refetch, isRefetching } = useClients({
    paging: { limit: perPage, offset: (page - 1) * perPage },
    filter: { and: [{ unit: { id: { eq: unitId } } }] },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
  });

  useEffect(() => {
    refetch();
  }, [page, perPage, refetch]);

  const { mutate, isPending } = useWithdrawSubaccount({
    onSuccess: () => {
      onModalClose();
      refetch();
    },
  });

  const clients = data?.nodes || [];
  const totalCount = data?.totalCount || 0;

  const handleOpenWithdrawModal = useCallback((wallets: IWallet[]) => {
    batch(() => {
      onModalOpen();
      onSetModalPayload({ wallets });
    });
  }, [onModalOpen, onSetModalPayload]);

  const handleWithdrawSubmit = useCallback((formData: TransferFundsFormDataReturned) => {
    mutate(formData);
  }, [mutate]);

  const checkTotalWalletsBalance = useCallback((wallets: IWallet[]) => Number(
    walletBalance(unifyWalletsStructure(wallets), 'USDT'),
  ), [walletBalance]);

  const initialColumns = useMemo(() =>
    getColumns({
      t,
      checkPermissions,
      checkTotalWalletsBalance,
      onWithdrawModalOpen: handleOpenWithdrawModal,
    }),
  [
    t,
    checkPermissions,
    checkTotalWalletsBalance,
    handleOpenWithdrawModal,
  ]);

  const { columns, setOrder } = useTableColumns({
    storageKey: LocalStorageKey.UNIT_CLIENTS_COLUMNS,
    initialColumns,
  });

  return (
    <div className={styles.wrapper}>
      <DraggableTable
        setOrder={setOrder}
        onRefresh={refetch}
        dataSource={clients}
        rowClassName={styles.rowClassName}
        rootClassName={styles.tableWrapper}
        loading={isLoading || isRefetching}
        columns={columns}
        scroll={{ x: 'max-content' }}
        onRow={record => ({
          onClick: () => navigate(`${Path.USERS_CLIENTS}/${record.uuid}`),
        })}
        pagination={false}
        rowKey="id"
      />
      <Pagination
        totalCount={totalCount}
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
        disablePageChange={isLoading || isRefetching || !totalCount}
        disablePerPageHandler={isLoading || isRefetching || !totalCount}
        elementsSize="xs"
      />
      <Modal
        width={450}
        destroyOnClose
        isOpen={isOpen}
        onClose={onModalClose}
        titleIcon={<VerticalAlignTopOutlined style={{ fontSize: 20 }} />}
        title={t('client.withdraw')}
      >
        <TransferFundsForm
          loading={isPending}
          onSubmit={handleWithdrawSubmit}
          onModalClose={onModalClose}
          wallets={payload.wallets as UnknownType}
        />
      </Modal>
    </div>
  );
};

export default ClientsTab;
