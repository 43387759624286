import _ from 'lodash';

const appendIdToDeviceName = ({
  deviceName,
  deviceId,
  maxLen,
}: {
  deviceName: string;
  deviceId: string;
  maxLen: number;
}) => {
  const baseNameLength = maxLen - deviceId.length;

  const truncatedBaseName = _.truncate(deviceName, {
    length: baseNameLength,
    omission: '',
  });

  return truncatedBaseName + ' ' + deviceId;
};

export default appendIdToDeviceName;
