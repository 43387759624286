import axios, { AxiosError, SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { UnknownType } from 'types/Unknown';

type Options = {
  onSuccess?: (data: UnknownType) => void;
  onError?: (e: AxiosError<{ message: string }>) => void;
};

const useNotificationArchive = (options?: Options) => {
  const archive = async (body: { ids: number[] }) => {
    const { data } = await axios.post(
      `${SERVICE.NOTIFICATION}/notifications/archive`, body,
    );

    return data;
  };

  return useMutation({ mutationFn: archive, ...options });
};

export default useNotificationArchive;
