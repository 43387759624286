export enum ClientTransactionStatusEnum {
  CREATED = 'created',
  SUBMITED = 'submitted',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  REJECTED = 'rejected',
  IN_FINALIZATION = 'in_finalization',

  UNDER_REVIEW = 'under_review',
  PENDING_APPROVAL = 'pending_approval',
  APPROVED = 'approved',
  SUSPENDED = 'suspended',
  PENDING = 'pending',
  IN_PROCESS = 'in_process',
}
