import React from 'react';
import { UnknownType } from 'types/Unknown';
import StepperContent from '../StepperContent';
import StepperFooter from '../StepperFooter';
import StepperHeader from '../StepperHeader';

type RenderProps = {
  onClose?: () => void,
  data: UnknownType,
};

type StepperBodyProps = {
  title?: React.ReactNode,
  subTitle?: React.ReactNode,
  headerStyle?: UnknownType,
  onBack?: () => void,
  onClose?: () => void,
  render?: (arg?: RenderProps) => UnknownType,
  renderFooter?: (arg?: RenderProps) => UnknownType,
  modalFooterStyle?: UnknownType,
  renderProps?: RenderProps,
  noScroll?: boolean,
  refocusWhenChange?: UnknownType,
};

const StepperBody = ({
  onBack,
  onClose,
  title,
  subTitle,
  render,
  renderFooter,
  renderProps,
  noScroll,
  modalFooterStyle,
  refocusWhenChange,
}: StepperBodyProps) => {
  const renderedFooter = renderFooter && renderFooter(renderProps);

  return (
    <>
      <StepperHeader
        title={title}
        subTitle={subTitle}
        onBack={onBack}
        onClose={onClose}
      />

      <StepperContent noScroll={noScroll}>
        {render && render(renderProps)}
      </StepperContent>

      {renderedFooter && <StepperFooter>{renderedFooter}</StepperFooter>}
    </>
  );
};

export default StepperBody;
