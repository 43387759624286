import { UnknownType } from 'types/Unknown';
import { CryptoCurrency, TokenCurrency } from 'components/ColdStorage/assets/crypto/types';
import * as icons from 'components/ColdStorage/assets/icons';

export const getIconId = ({ ticker }: CryptoCurrency | TokenCurrency) => {
  let id = ticker.toLowerCase();
  if (!isNaN(parseInt(id.charAt(0), 10))) id = `_${id}`; // fix variable name leading with a numerical value

  return id;
};

const getCryptoCurrencyIcon = (
  currency: CryptoCurrency,
): string => {
  return (icons as UnknownType)[getIconId(currency)];
};

export default getCryptoCurrencyIcon;
