import React, { useCallback, useState } from 'react';
import { batch } from 'react-redux';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { notification } from 'antd';
import useCreateUnit from 'api/unit/useCreateUnit';
import { UnknownType } from 'types/Unknown';
import { FormUnit } from 'pages/Administration/Units/components';
import { useTranslate } from 'hooks';
import { StageEnum } from 'hooks/use2FA';
import { useModal } from 'components/Modal/hooks/useModal';
import { OTP_ERROR } from 'constants/index';
import { useAuthorisationContext } from 'contexts';
import { Button, Modal, With2FA } from 'components';
import { ApartmentOutlined } from '@ant-design/icons';
import styles from './CreateUnitButton.module.scss';

export type CreateUnitButtonProps = {
  rootUnitId: number;
  unitId: number;
  disabled?: boolean;
};

const CreateUnitButton = ({ rootUnitId, unitId, disabled }: CreateUnitButtonProps) => {
  const [savedData, setSavedData] = useState<FormData>({} as FormData);
  const { t } = useTranslate();
  const {
    twoFA: {
      onSuccess: onSuccess2fa,
      onError: onError2fa,
      setStage,
      stage,
    },
  } = useAuthorisationContext();

  const {
    isOpen,
    payload,
    onModalOpen,
    onModalClose,
    onSetModalPayload,
  } = useModal('unit-tab-units-create-child');

  const openCreateModal = useCallback(() => {
    batch(() => {
      onSetModalPayload({ rootUnitId, unitId });
      onModalOpen();
    });
  }, [onModalOpen, onSetModalPayload, rootUnitId, unitId]);

  const onSuccess = (res: UnknownType) => {
    onSuccess2fa(res, async () => {
      notification.success({ message: t('users.units.created') });
      await cleverRefetchQueries('unit-children');
      onModalClose();
    });
  };

  const onError = useCallback((error: UnknownType) => {
    onError2fa(error, () => {
      if (stage !== StageEnum.DEFAULT && error.response?.data?.message.includes(OTP_ERROR)) {
        setStage(StageEnum.DEFAULT);
      }

      if (error?.response?.status === 413) {
        notification.error({ message: t('business.invalidFileSize') });
      } else {
        notification.error({
          description: error?.response?.data?.message,
          message: error?.response?.status,
        });
      }
    });
  }, [onError2fa, setStage, stage, t]);

  const { mutate: createUnit, isPending: isCreating } = useCreateUnit({ onSuccess, onError });

  const onSubmit = useCallback((formData: FormData, otpCode?: string) => {
    if (!otpCode) {
      setSavedData(formData);
    } else {
      formData.set('otpCode', otpCode);
    }

    createUnit(formData);
  }, [createUnit]);

  const handleSubmitOtp = useCallback((otp: string) => {
    onSubmit(savedData, otp);
  }, [onSubmit, savedData]);

  return (
    <>
      <Button
        type="default"
        textSize="14px"
        disabled={disabled}
        onClick={openCreateModal}
        suffixIcon={<ApartmentOutlined style={{ fontSize: 16 }} />}
        className={styles.button}
      >
        {t('users.units.createChild')}
      </Button>
      <Modal
        width={520}
        destroyOnClose
        isOpen={isOpen}
        title={t('users.units.createUnit')}
        titleIcon={<ApartmentOutlined style={{ fontSize: 20 }} />}
        onClose={onModalClose}
      >
        <With2FA onSubmit={handleSubmitOtp}>
          <FormUnit
            loading={isCreating}
            onSubmit={onSubmit}
            onClose={onModalClose}
            initialValues={payload as UnknownType}
          />
        </With2FA>
      </Modal>
    </>
  );
};

export default CreateUnitButton;
