import { notification } from 'antd';
import { ResponseError } from 'types/ResponseError';
import { useActiveSessions, useRemoveActiveSession } from '../../../../../hooks';
import { useAuthorisationContext } from 'contexts';
import { useGetColumns } from './useGetColumns';

type Props = {
  userId: number;
};

export const useActiveSessionsTable = ({ userId }: Props) => {
  const { user } = useAuthorisationContext();
  const { data, refetch, isLoading } = useActiveSessions({ userId: userId === user?.id ? undefined : userId });
  const { removeSession } = useRemoveActiveSession({
    onSuccess: () => {
      refetch();
    },
    onError: (e: ResponseError) => {
      notification.error({
        description: e?.response?.data.message,
        message: e?.response?.statusText,
      });
    },
  });

  const columns = useGetColumns({
    onRemoveSession: removeSession,
    currentSessionId: user?.sessionId as string,
  });

  return {
    columns,
    isLoading,
    data,
  };
};
