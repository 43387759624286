import { useNavigate } from 'react-router-dom';
import { Path } from 'routes/interfaces/Path';

const useNavigateToUnit = () => {
  const navigate = useNavigate();

  const navigateToUnit = (rootUnitId: number, unitUuid?: string) => {
    const BASE_PATH = Path.ADMINISTRATION_BUSINESSES;
    const unitsPath = '/units';
    const unitPath = unitUuid ? `/${unitUuid}` : '';

    navigate(`${BASE_PATH}/${rootUnitId}${unitsPath}${unitPath}`);
  };

  return navigateToUnit;
};

export default useNavigateToUnit;
