import { useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import { UnknownType } from 'types/Unknown';
import LocalStorage, { LocalStorageKey } from 'utils/localStorage';
import getColumnsByKeys from './utils/getColumnsByKeys';
import getStoredColumnsState, { StoredColumnsState } from './utils/getStoredColumnsState';

export type UseTableColumnsProps<RecordType extends Record<string, UnknownType>> = {
  storageKey: LocalStorageKey;
  initialColumns: ColumnsType<RecordType>;
  initiallyHiddenColumns?: string[];
};

const useTableColumns = <RecordType extends Record<string, UnknownType>>({
  storageKey,
  initialColumns,
  initiallyHiddenColumns = [],
}: UseTableColumnsProps<RecordType>) => {
  const { visibleKeys, orderedKeys } = getStoredColumnsState({
    initialColumns: _.map(initialColumns, 'key') as string[],
    initiallyHiddenColumns,
    storageKey,
  });

  const [displayed, setDisplayed] = useState<string[]>(visibleKeys);
  const [order, setOrder] = useState<string[]>(orderedKeys);

  useEffect(() => {
    const storedData: StoredColumnsState = {
      orderedKeys: order,
      visibleKeys: displayed,
    };

    LocalStorage.set(storageKey, JSON.stringify(storedData));
  }, [storageKey, order, displayed]);

  const columns = useMemo(() => getColumnsByKeys(order, initialColumns), [order, initialColumns]);

  return {
    columns,
    setOrder,
    displayed,
    setDisplayed,
  };
};

export default useTableColumns;
