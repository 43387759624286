import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { ChangePasswordBodyType, ChangePasswordOptionsType } from './types';

export const useChangePassword = (options: ChangePasswordOptionsType) => {
  const changePasswordMutate = async (body: ChangePasswordBodyType) => {
    const { data } = await axios.patch(`${SERVICE.ADMIN}/users/update-password`, body);
    return data;
  };

  const {
    mutate: changePassword,
    isPending: isChangePasswordLoading,
  } = useMutation({ mutationFn: changePasswordMutate, ...options });

  return {
    changePassword,
    isChangePasswordLoading,
  };
};
