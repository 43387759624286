import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { Filter } from 'types/Filter';
import { ITransactionGroup } from '../types';

type GetTransactionGroupsParams = {
  unitId?: string,
  filter?: Filter<Record<string, unknown>>;
  paging?: { limit: number; offset?: number };
  sorting?: { field: string, direction: string }[];
};

const useGetTransactionGroups = (params?: GetTransactionGroupsParams) => {
  const getGroups = async () => {
    const { data } = await axios.get(`${SERVICE.CLIENT}/transaction-categories/group`, { params });
    return data;
  };

  return useQuery<ITransactionGroup[]>({
    queryFn: getGroups,
    queryKey: ['transaction-groups', params],
  });
};

export default useGetTransactionGroups;
