import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IInvoice } from 'interfaces';
import { Filter } from 'types/Filter';
import { InvoiceSorting } from 'pages/Users/Invoices/types';

type InvoiceResultType = {
  data: { nodes: IInvoice[], totalCount: number };
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
};

type UseInvoiceParamsType = {
  filter?: Filter<Record<string, unknown>>;
  paging: { limit: number; offset: number };
  sorting: InvoiceSorting[];
};

const useInvoicesByUnit = (
  uuid: string | undefined,
  params?: UseInvoiceParamsType,
): InvoiceResultType => {
  const getInvoicesByUnit = async () => {
    const { data } = await axios.get(
      `${SERVICE.CLIENT}/invoices/unit/${uuid}`, { params },
    );
    return data;
  };

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['invoices', [uuid, params]],
    queryFn: getInvoicesByUnit,
    enabled: Boolean(uuid),
  });

  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
};

export default useInvoicesByUnit;
