import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Dropdown } from 'antd';
import { IUser } from 'interfaces/IUser';
import useUpdateStatus, { StatusStateEnum, StatusTypeEnum } from 'hooks/useUpdateStatus';
import { AuthorisationContext } from 'contexts';
import { StatusOption } from './components';
import { RightOutlined } from '@ant-design/icons';
import styles from './ActivityStatusDropdown.module.scss';

const ActivityStatus = () => {
  const { user } = useContext(AuthorisationContext);
  const { mutate: updateStatus } = useUpdateStatus();
  const [visible, setVisible] = useState(false);

  const handleChangeStatus = useCallback((newStatus: StatusStateEnum) => {
    updateStatus({
      status: newStatus,
      type: StatusTypeEnum.MANUAL,
    });
  }, [updateStatus]);

  const items = useMemo(() => ([
    StatusStateEnum.ONLINE,
    StatusStateEnum.OFFLINE,
    StatusStateEnum.AWAY,
    StatusStateEnum.DO_NOT_DISTURB,
  ].map(item => ({
    key: item,
    label: <StatusOption status={item} checked={item === user?.status} />,
    className: styles.option,
    onClick: () => handleChangeStatus(item),
  }))), [handleChangeStatus, user?.status]);

  return (
    <Dropdown
      open={visible}
      onOpenChange={setVisible}
      menu={{ items, className: styles.menu }}
      overlayClassName={styles.overlay}
      align={{
        points: ['tr', 'tl'],
        offset: [-15, 0],
      }}
    >
      <div className={styles.wrapper}>
        <StatusOption status={(user as IUser).status} />
        <RightOutlined className={styles.arrow} />
      </div>
    </Dropdown>
  );
};

export default ActivityStatus;
