import { UnknownType } from 'types/Unknown';
import 'App.scss';

const renderEmptyCell = (value: UnknownType) => {
  const isEmpryString = value === '';
  const isNull = value === null;

  return (
    <span className={isNull || isEmpryString ? 'emptyCell' : ''}>
      { isNull || isEmpryString ? String(null) : String(value)}
    </span>
  );
};

export default renderEmptyCell;
