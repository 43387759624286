import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { NestedUnitType } from 'interfaces/IUnit';

type UnitParams = {
  uuid: string | undefined;
  hardwareId: string | null;
  isColdStorageMode: boolean;
};

const getUnit = (params: UnitParams) => async () => {
  const { data } = await axios.get(`${SERVICE.CLIENT}/units/${params.uuid}`, {
    params: {
      hardwareId: params.hardwareId,
    },
  });
  return data as NestedUnitType;
};

const useUnit = (params: UnitParams) => {
  return useQuery({
    queryFn: getUnit(params),
    queryKey: ['unit', params],
    refetchOnMount: true,
    enabled: !!params.uuid,
  });
};

export default useUnit;
