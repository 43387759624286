import React from 'react';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import { Button } from 'components';
import { PlusOutlined } from '@ant-design/icons';
import styles from './AddWallet.module.scss';

type AddWalletButtonProps = {
  isLoading?: boolean;
  buttonType?: 'link' | 'primary' | 'default';
  iconType?: 'suffix' | 'prefix';
  id?: string;
  onClick?: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
};

const AddWalletButton = ({ isLoading, buttonType, id, iconType, onClick, disabled, style, className }: AddWalletButtonProps) => {
  const { t } = useTranslate();
  return (
    <Button
      loading={isLoading}
      withoutBgShadow={Boolean(buttonType === 'link') as never}
      withoutWaveEffect={Boolean(buttonType === 'link')}
      id={id}
      type={buttonType}
      suffixIcon={iconType === 'suffix' ? <PlusOutlined /> : undefined}
      prefixIcon={iconType === 'prefix' ? <PlusOutlined /> : undefined}
      className={clsx(styles.button, className)}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {t('coldStorage.addWallet')}
    </Button>
  );
};

export default AddWalletButton;
