import React from 'react';
import Lottie from 'react-lottie';

const Animation = ({
  animation,
  width = '100%',
  height = '100%',
  loop = true,
  autoplay = true,
  rendererSettings = { preserveAspectRatio: 'xMidYMin' },
  isStopped = false,
}: {
  animation: object,
  width?: string,
  height?: string,
  loop?: boolean,
  autoplay?: boolean,
  rendererSettings?: object,
  isStopped?: boolean,
}) => (
  <Lottie
    isClickToPauseDisabled
    ariaRole="animation"
    height={height}
    width={width}
    // isPaused={!!process.env.PLAYWRIGHT_RUN}
    isStopped={isStopped}
    options={{
      loop,
      autoplay,
      rendererSettings,
      animationData: animation,
    }}
  />
);

export default Animation;
