import React, { ReactElement } from 'react';
import { Form } from 'antd';
import { UnknownType } from 'types/Unknown';
import { FormDataResult } from 'pages/Administration/FinancialManagement/components/FormCreateUpdate';
import { useTranslate } from 'hooks';
import { Button } from 'components';
import styles from './FormApproveAction.module.scss';

export type FormApproveActionPropsType = {
  loading: boolean;
  actionText: string;
  cancelButtonText?: string;
  approveButtonText?: string;
  initialData: UnknownType;
  onCancel: () => void;
  onApprove: (formData: FormDataResult) => void;
};

export const FormApproveAction = ({
  loading, onCancel, onApprove,
  initialData, actionText,
  cancelButtonText,
  approveButtonText,
}: FormApproveActionPropsType): ReactElement => {
  const { t } = useTranslate();

  const onFinish = (data: UnknownType) => {
    onApprove({ ...initialData, ...data });
  };

  return (
    <Form className={styles.form} onFinish={onFinish}>
      <span className={styles.title}>{actionText}</span>

      <div className={styles.buttons}>
        <Button
          block
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          {approveButtonText || t('save')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          disabled={loading}
          onClick={onCancel}
        >
          {cancelButtonText || t('cancel')}
        </Button>
      </div>
    </Form>
  );
};
