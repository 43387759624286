import React, { useCallback, useState } from 'react';
import { Permission } from '@fanckler/processing-auth';
import { Card, Divider, Switch } from 'antd';
import clsx from 'clsx';
import { IUser } from 'interfaces/IUser';
import { UnknownType } from 'types/Unknown';
import { useTranslate } from 'hooks';
import { useAuthorisationContext } from 'contexts';
import { formatDate } from 'utils';
import { AssignedUnitsList, useAssignedUnitClick } from 'components/AssignedUnitsList';
import { Button } from 'components/Button';
import { CheckCircleOutlined, CloseCircleOutlined, KeyOutlined, LoginOutlined, ReloadOutlined, StopOutlined } from '@ant-design/icons';
import styles from './AccountInformation.module.scss';

type AccountInformationProps = {
  user: IUser;
  onRefresh?: () => Promise<void>;
  onChangeBlocked?: (data: Pick<IUser, 'isActive'>) => Promise<void>;
};

const AccountInformation = ({ user, onRefresh, onChangeBlocked }: AccountInformationProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslate();
  const { checkPermissions } = useAuthorisationContext();
  const canUpdate = checkPermissions([Permission.ADMIN_IAM_USER_UPDATE]) && checkPermissions([Permission.ADMIN_IAM_ROOT_UNIT_ADMIN]);
  const handleChipClick = useAssignedUnitClick(user.rootUnitId);

  const handleChangeBlocked = useCallback(async (status: boolean) => {
    if (!onChangeBlocked) return;
    try {
      setIsLoading(true);
      await onChangeBlocked({ isActive: status });
    } catch (e) {
      //   do nothing
    } finally {
      setIsLoading(false);
    }
  }, [onChangeBlocked]);

  const handleRefresh = useCallback(async () => {
    if (!onRefresh) return;
    try {
      setIsLoading(true);
      await onRefresh();
    } catch (e) {
      //   do nothing
    } finally {
      setIsLoading(false);
    }
  }, [onRefresh]);

  return (
    <Card title={t('myAccount.accountInformation')} className={styles.card} extra={(
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {canUpdate && (
          <>
            <Button
              type="link"
              style={{ padding: 0 }}
              size="small"
              suffixIcon={<ReloadOutlined />}
              onClick={handleRefresh}
              loading={isLoading}
              disabled={isLoading}
            >
              {t('myAccount.resetFailedCounters')}
            </Button>
            <Divider type="vertical" style={{ margin: '0 24px' }} />
            <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
              {t('myAccount.activationsStatus')}:
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <Switch
                  loading={isLoading}
                  disabled={isLoading}
                  checked={Boolean(user.isActive && !user.blockTime)}
                  onChange={handleChangeBlocked}
                />
                {user.isActive && !user.blockTime ? (
                  <span>{t('active')}</span>
                ) : (
                  <span>{t('blocked')}</span>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    )}>
      <div className={styles.blockCard}>
        <div className={styles.header}>
          <span>{t('myAccount.failedAuthentications')}</span>
          <LoginOutlined />
        </div>
        <span className={styles.counter}>
          {user.failedLogins}
        </span>
      </div>
      <div className={styles.blockCard}>
        <div className={styles.header}>
          <span>{t('myAccount.failedOTPEntered')}</span>
          <KeyOutlined />
        </div>
        <span className={styles.counter}>
          {user.failedOtp}
        </span>
      </div>
      <div className={styles.blockCard}>
        <div className={styles.header}>
          <span>{t('myAccount.blockStatus')}</span>
          <StopOutlined />
        </div>
        <div className={styles.statusWrapper}>
          {user.blockTime || !user.isActive ? (
            <>
              <span className={clsx(styles.status, styles.blocked)}>{t('blocked')} <CloseCircleOutlined /></span>
              {user.blockTime && (
                <span className={styles.time}>
                  {t('myAccount.until')} {formatDate(Number(user.blockTime) as UnknownType)}
                </span>
              )}
            </>
          ) : (
            <span className={styles.status}>{t('myAccount.notBlocked')}<CheckCircleOutlined /></span>
          )}
        </div>
      </div>
      {user.units.length > 0 && (
        <div className={styles.assignedUnitsWrapper}>
          <p className={styles.assignedUnitsTitle}>
            {t('myAccount.assignedUnits')}
          </p>
          <AssignedUnitsList
            units={user.units}
            showFullList={true}
            onClick={handleChipClick}
          />
        </div>
      )}
    </Card>
  );
};

export default AccountInformation;
