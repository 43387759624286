import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { UseQueryHandlers } from 'types/UseQueryHandlers';

export type CreateWalletDTO = {
  currency: string;
  network: string;
  name?: string;
  isSubWallet?: boolean;
  hardwareId?: string;
  otpCode?: string;
};

export const createWallet = (unitId: number | undefined) => async (body: CreateWalletDTO) => {
  const { data } = await axios.post(`${SERVICE.CLIENT}/units/add-wallet/${unitId}`, body);
  return data;
};

const createWalletsMany = (unitId: number | undefined) => async (body: CreateWalletDTO[]) => {
  return Promise.all(
    body.map(async (item) => {
      await createWallet(unitId)(item);
    }),
  ).catch(() => {
    // do nothing
  });
};

const useCreateWallet = (unitId: number | undefined, options?: UseQueryHandlers) => {
  const mutation = useMutation({
    mutationFn: createWallet(unitId),
    mutationKey: ['addWallet'],
    ...options,
  });

  const mutationMany = useMutation({
    mutationFn: createWalletsMany(unitId),
    mutationKey: ['addWallet'],
    ...options,
  });

  return {
    isLoading: mutation.isPending,
    createWallet: mutation.mutate,
    isLoadingMany: mutationMany.isPending,
    createWalletsManyAsync: mutationMany.mutateAsync,
  };
};

export default useCreateWallet;
