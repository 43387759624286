import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { notification } from 'antd';
import { useSelector } from 'init/rootReducer';
import { StepProps } from '../../types';
import { Currencies, StorageType } from 'components/ColdStorage/types';
import { useColdStorageDevice } from 'components/ColdStorage/hooks';
import { useConnect } from 'components/ColdStorage/trezor/hooks';
import { ContentWithButton } from '../ContentWithButton';
import { ConnectionConditions } from 'components/ColdStorage/components';
import { Loading } from 'components/ColdStorage/components/Loading';
import { connectWallet } from 'components/ColdStorage/trezor/api/trezorAPI';

const CONDITIONS = [
  'coldStorage.trezorConditions.deviceIsNotConnected',
  'coldStorage.trezorConditions.deviceIsLocked',
  'coldStorage.trezorConditions.deviceIsAlreadyUsed',
  'coldStorage.trezorConditions.applicationIsClosed',
];

const CheckTrezorConnection = ({ transitionTo }: StepProps) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const { connect } = useConnect();
  const connectedDeviceId = useSelector((state) => state.coldStorageReducer.connectedDeviceId);
  const { coldStorageDevice } = useColdStorageDevice();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const isTrezorConnected = useMemo(
    () => connectedDeviceId && coldStorageDevice?.type === StorageType.Trezor,
    [coldStorageDevice?.type, connectedDeviceId],
  );

  const clearConnectionTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  const goNextStep = useCallback(() => {
    transitionTo('chooseCurrency');
  }, [transitionTo]);

  const tryConnectTrezor = useCallback(() => {
    const connectTrezor = async () => {
      setIsConnecting(true);
      notification.close('coldStorage');
      await connect();
      timeoutRef.current = setTimeout(async () => {
        if (!isTrezorConnected) {
          try {
            await connectWallet(Currencies.BITCOIN);
          } catch (error) {
            return setIsConnecting(false);
          }
        }
      }, 5000);
    };

    if (!isTrezorConnected) {
      connectTrezor();
    } else {
      goNextStep();
    }

    if (isTrezorConnected && timeoutRef.current) {
      clearConnectionTimeout();
      goNextStep();
      setIsConnecting(false);
    }
  }, [clearConnectionTimeout, connect, goNextStep, isTrezorConnected]);

  useEffect(() => {
    tryConnectTrezor();
    return () => {
      clearConnectionTimeout();
    };
  }, [clearConnectionTimeout, tryConnectTrezor]);

  return (
    <ContentWithButton
      onButtonClick={() => isConnecting ? goNextStep() : tryConnectTrezor()}
      isDisabled={isConnecting}
      isLoading={isConnecting}
    >
      {isConnecting ? (
        <Loading />
      ) : (
        <ConnectionConditions conditions={CONDITIONS} />
      )}
    </ContentWithButton>
  );
};

export default CheckTrezorConnection;
