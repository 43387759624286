import React, { useCallback, useMemo } from 'react';
import { batch } from 'react-redux';
import { IInvoice } from 'interfaces/IInvoice';
import { Path } from 'routes/interfaces/Path';
import { UnknownType } from 'types/Unknown';
import { InvoiceFormDataType } from 'components/CreateInvoice/types';
import { useTablePagination, useTranslate } from 'hooks';
import { useConfirmRootAdmin } from 'hooks/useConfirmRootAdmin';
import { useManualTransfer } from 'hooks/useManualTransfer';
import useTableColumns, { filterByDisplayed } from 'hooks/useTableColumns';
import { useInvoicesByUnit } from './hooks';
import { useColdStorageHardwareId } from 'components/ColdStorage/hooks';
import { useModal } from 'components/Modal/hooks/useModal';
import { LocalStorageKey } from 'utils/localStorage';
import { formatExpiryTime, getColumns } from './utils';
import { Modal, Pagination } from 'components';
import { LinkForPayment } from 'components/CreateInvoice/components';
import { InvoiceView } from 'components/CreateInvoice/components/InvoiceView';
import DraggableTable from 'components/DraggableTable';
import styles from './InvoicesTab.module.scss';

export type InvoicesTabProps = {
  uuid: string | undefined;
};

const InvoicesTab = ({ uuid }: InvoicesTabProps) => {
  const hardwareId = useColdStorageHardwareId();
  const { isRoot } = useConfirmRootAdmin();
  const { t } = useTranslate();

  const { page, perPage, setPage, setPerPage } = useTablePagination({
    withoutURL: true,
  });

  const { data, isLoading, refetch } = useInvoicesByUnit(uuid, {
    paging: { limit: perPage, offset: (page - 1) * perPage },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
    filter: { and: [{ hardwareId: { [hardwareId === null ? 'is' : 'eq']: `${hardwareId}` } }] },
  });

  const { mutate: onManuallyTransfer } = useManualTransfer();

  const {
    isOpen,
    payload,
    isCreate,
    onModalOpen,
    onModalClose,
    onSetModalPayload,
  } = useModal('invoice');

  const onViewInvoice = useCallback(
    (invoice: IInvoice) => {
      batch(() => {
        onModalOpen();
        onSetModalPayload({ ...invoice });
      });
    },
    [onModalOpen, onSetModalPayload],
  );

  const invoices = data?.nodes || [];
  const totalCount = data?.totalCount || 0;
  const wallets: UnknownType = payload.wallets;

  const initialColumns = useMemo(() =>
    getColumns({
      t,
      isRoot,
      onViewInvoice,
      onManuallyTransfer,
    }),
  [t, isRoot, onViewInvoice, onManuallyTransfer]);

  const { columns, setOrder, displayed } = useTableColumns({
    storageKey: LocalStorageKey.USERS_INVOICES_COLUMNS,
    initialColumns,
  });

  return (
    <div className={styles.wrapper}>
      <DraggableTable
        setOrder={setOrder}
        onRefresh={refetch}
        dataSource={invoices}
        rootClassName={styles.tableWrapper}
        loading={isLoading}
        columns={filterByDisplayed(columns, displayed)}
        scroll={{ x: 'max-content' }}
        pagination={false}
        rowKey="id"
      />
      <Pagination
        totalCount={totalCount}
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
        disablePageChange={isLoading || !totalCount}
        disablePerPageHandler={isLoading || !totalCount}
        elementsSize="xs"
      />
      <Modal
        width={450}
        destroyOnClose
        isOpen={isOpen && !isCreate}
        onClose={onModalClose}
        title={t('invoice.information')}
      >
        <div className={styles.infoForm}>
          {isRoot && wallets?.length > 0 && (
            <LinkForPayment
              path={Path.INVOICES}
              uuid={payload.uuid as string}
              wallet={wallets?.length && wallets[0]?.walletAddress as string}
            />
          )}

          <InvoiceView
            invoice={{
              ...payload,
              expirationAt: formatExpiryTime(
                payload.createdAt as string,
                payload.expirationAt as string,
              ),
            } as InvoiceFormDataType}
          />
        </div>
      </Modal>
    </div>
  );
};

export default InvoicesTab;
