import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { UnknownType } from 'types/Unknown';
import { ColdStorageData, Currencies } from 'components/ColdStorage/types';
import { useColdStorageDevice } from 'components/ColdStorage/hooks/index';

const apiUrlMap = new Map([
  [Currencies.BITCOIN, SERVICE.BTC],
  [Currencies.ETHEREUM, SERVICE.ETH],
  [Currencies.TRON, SERVICE.TRON],
]);

const getBalance = async (currency: Currencies, wallet: string): Promise<Record<string, string | number>> => {
  try {
    if (!apiUrlMap.has(currency)) return await Promise.resolve({});

    const url = `${apiUrlMap.get(currency)}/wallets/getWalletBalances/${wallet}`;
    const response: UnknownType = await axios.get(url);
    return response.data;
  } catch (error) {
    return Promise.resolve({});
  }
};

const getBalances = (coldWallets: ColdStorageData['wallets']) => async () => {
  const response: Record<string, Record<string, string | number>> = {};
  for (const [currency, wallet] of Object.entries(coldWallets)) {
    response[currency] = await getBalance(currency as Currencies, wallet.address);
  }
  return response;
};

const useColdStorageBalance = () => {
  const { coldStorageDevice } = useColdStorageDevice();

  const {
    data,
    refetch,
    isLoading,
    isRefetching,
  } = useQuery({
    queryFn: getBalances(coldStorageDevice?.wallets || {}),
    queryKey: ['coldStorageBalance'],
    refetchOnMount: true,
  });

  return {
    data,
    refetch,
    isLoading: isLoading || isRefetching,
  };
};

export default useColdStorageBalance;
