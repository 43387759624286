import {
  ModalActionType,
  RESET_MODALS,
  SET_MODAL_PAYLOAD,
  SET_MODAL_TYPE,
  SET_MODAL_VISIBLE,
} from './types/Actions';
import { ActionModalType } from './types/Modal';
import { ModalType } from './types/Reducer';

const initialState: ModalType = {
  myAccount: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  businesses: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  replenishmentRequests: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  clients: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  internalUser: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  externalUser: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  units: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  unitWallets: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  externalUserMass: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  group: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  financialManagement: {
    isOpen: false,
    type: ActionModalType.EDIT,
    payload: {},
  },
  systemChanges: {
    isOpen: false,
    type: ActionModalType.EDIT,
    payload: {},
  },
  firewall: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  paymentOrders: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  transactions: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  approveAction: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  createClient: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  invoice: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  showQR: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  coldStorage: {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  'unit-toggle-status': {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  'unit-tab-transactions': {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  'unit-tab-invoices': {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  'unit-tab-subaccounts': {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  'unit-tab-subaccounts-withdraw': {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  'unit-tab-whitelist': {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  'unit-tab-managers-choose': {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  'unit-tab-clients-choose': {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  'unit-tab-clients-withdraw': {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  'unit-tab-units-create-child': {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  'unit-tab-units-update-child': {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  'client-tab-transactions': {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
  'client-tab-subaccounts': {
    isOpen: false,
    type: ActionModalType.DEFAULT,
    payload: {},
  },
};

export const modalReducer = <P>(
  state = initialState,
  action: ModalActionType<P>,
): ModalType => {
  if (!('type' in action)) {
    return state;
  }

  switch (action.type) {
    case RESET_MODALS:
      return {
        ...state,
        ...initialState,
      };
    case SET_MODAL_VISIBLE:
      return {
        ...state,
        [action.payload.modal]: {
          ...state[action.payload.modal],
          isOpen: action.payload.value,
        },
      };
    case SET_MODAL_TYPE:
      return {
        ...state,
        [action.payload.modal]: {
          ...state[action.payload.modal],
          type: action.payload.value,
        },
      };
    case SET_MODAL_PAYLOAD:
      return {
        ...state,
        [action.payload.modal]: {
          ...state[action.payload.modal],
          payload: action.payload.payload,
        },
      };
    default:
      return state;
  }
};
