import React from 'react';
import { Form, notification } from 'antd';
import { isEmpty } from 'lodash';
import { UnknownType } from 'types/Unknown';
import { useTranslate } from 'hooks';
import { useActiveSessions, useChangePassword } from '../../hooks';
import { useModal } from 'components/Modal/hooks/useModal';
import { useAuthorisationContext } from 'contexts';
import { encryptPassword, LocalStorage, LocalStorageKey } from 'utils';
import { FormChangePassword } from '../FormChangePassword';
import { SmallButton } from '../SmallButton';
import { Modal } from 'components/Modal';
import { With2FA } from 'components/TwoFA';
import { KeyOutlined, ToolOutlined } from '@ant-design/icons';

const ButtonChangePassword = () => {
  const [credentials, setCredentials] = React.useState<UnknownType | null>(null);
  const { t } = useTranslate();

  const {
    twoFA: {
      onError,
      onSuccess,
    },
  } = useAuthorisationContext();
  const [passForm] = Form.useForm();

  const { isOpen, onModalOpen, onModalClose } = useModal('myAccount');

  const onCloseModal = () => {
    onModalClose();
    passForm.resetFields();
  };

  const { isLoading } = useActiveSessions({});

  const { changePassword, isChangePasswordLoading } = useChangePassword({
    onSuccess: (response) => {
      onSuccess(response as UnknownType, () => {
        LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, response.accessToken);

        onCloseModal();
        notification.success({
          message: t('myAccount.passwordChangedSuccessfully'),
        });
      });
    },
    onError: (error) => {
      onError(error as UnknownType, () => {
        const oldPassNotCorrectError = 'The old password is not correct';
        const oldAndNewPassSameError =
          'The new password must not be the same as the old one';

        if (error.response?.data.message === oldPassNotCorrectError) {
          passForm.setFields([
            {
              name: 'oldPassword',
              errors: [t('myAccount.validation.oldPasswordNotCorrect')],
            },
          ]);
        } else if (error.response?.data.message === oldAndNewPassSameError) {
          passForm.setFields([
            {
              name: 'newPassword',
              errors: [t('myAccount.validation.newPasswordRules')],
            },
          ]);
        } else {
          notification.error({
            message: error.response?.statusText,
            description: error.response?.data.message,
          });
        }
      });
    },
  });

  const onSubmitCreds = async ({ password, oldPassword, otpCode }: UnknownType) => {
    setCredentials({ oldPassword, password });
    changePassword({
      oldPassword: await encryptPassword(oldPassword),
      password: await encryptPassword(password),
      otpCode,
    });
  };

  const onSubmitCode = (code: string) => {
    if (!isEmpty(credentials)) {
      onSubmitCreds({ ...credentials, otpCode: code });
    }
  };

  return (
    <>
      <SmallButton
        onClick={onModalOpen}
        suffixIcon={<KeyOutlined style={{ fontSize: 16 }} />}
      >
        {t('myAccount.changePassword')}
      </SmallButton>

      <Modal
        destroyOnClose
        isOpen={isOpen}
        width={450}
        title={t('myAccount.changePassword')}
        onClose={onCloseModal}
        titleIcon={<ToolOutlined style={{ fontSize: 20 }} />}
      >
        <With2FA onSubmit={onSubmitCode} isLoading={isLoading}>
          <FormChangePassword
            loading={isChangePasswordLoading}
            onSubmit={onSubmitCreds}
            onClose={onCloseModal}
            form={passForm}
          />
        </With2FA>
      </Modal>
    </>
  );
};

export default ButtonChangePassword;
