import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Image, Select } from 'antd';
import { RuleObject } from 'antd/lib/form';
import useGetSubaccountsByUuid from 'api/client/useGetSubaccountsByUuid';
import { sortBy } from 'lodash';
import { IWallet } from 'interfaces';
import { WalletCurrencyEnum } from 'types/WalletCurrencyEnum';
import { WalletNetworkEnum } from 'types/WalletNetworkEnum';
import { useTranslate } from 'hooks';
import { CurrencyIcon } from 'utils';
import WalletSelect from './WalletSelect';
import { Button } from 'components/Button';
import styles from './TransferFundsForm.module.scss';

type TransferFundsFormData = {
  network: string;
  currency: string;
  addressFrom: string;
};

export type TransferFundsFormDataReturned = TransferFundsFormData;

export type TransferFundsFormProps = {
  uuid?: string;
  wallets?: IWallet[];
  initialWallet?: IWallet;
  loading?: boolean;
  onSubmit: (formData: TransferFundsFormDataReturned) => void;
  onModalClose?: () => void;
};

type Option<T extends string> = { value: T, label: T };

const currencyOpts: Option<WalletCurrencyEnum>[] = [
  { value: WalletCurrencyEnum.BTC, label: WalletCurrencyEnum.BTC },
  { value: WalletCurrencyEnum.ETH, label: WalletCurrencyEnum.ETH },
  { value: WalletCurrencyEnum.TRX, label: WalletCurrencyEnum.TRX },
  { value: WalletCurrencyEnum.BNB, label: WalletCurrencyEnum.BNB },
  { value: WalletCurrencyEnum.DAI, label: WalletCurrencyEnum.DAI },
  { value: WalletCurrencyEnum.USDT, label: WalletCurrencyEnum.USDT },
  { value: WalletCurrencyEnum.USDC, label: WalletCurrencyEnum.USDC },
];

const networkOpts: Option<WalletNetworkEnum>[] = [
  { value: WalletNetworkEnum.BITCOIN, label: WalletNetworkEnum.BITCOIN },
  { value: WalletNetworkEnum.ERC20, label: WalletNetworkEnum.ERC20 },
  { value: WalletNetworkEnum.TRC20, label: WalletNetworkEnum.TRC20 },
  { value: WalletNetworkEnum.BEP20, label: WalletNetworkEnum.BEP20 },
];

const networkCurrencyMapping = new Map([
  [WalletNetworkEnum.BITCOIN, [WalletCurrencyEnum.BTC]],
  [WalletNetworkEnum.ERC20, [
    WalletCurrencyEnum.USDT,
    WalletCurrencyEnum.USDC,
    WalletCurrencyEnum.ETH,
  ]],
  [WalletNetworkEnum.TRC20, [WalletCurrencyEnum.TRX, WalletCurrencyEnum.USDT]],
  [WalletNetworkEnum.BEP20, [
    WalletCurrencyEnum.USDT,
    WalletCurrencyEnum.USDC,
    WalletCurrencyEnum.BNB,
    WalletCurrencyEnum.DAI,
  ]],
]);

const TransferFundsForm = ({
  uuid,
  initialWallet,
  wallets,
  loading,
  onSubmit,
  onModalClose,
}: TransferFundsFormProps) => {
  const [selectedNetwork, setSelectedNetwork] = useState<WalletNetworkEnum | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<WalletCurrencyEnum | null>(null);
  const [currencies, setCurrencies] = useState(currencyOpts);
  const [form] = Form.useForm();
  const { t } = useTranslate();

  const { data: subaccounts, isLoading } = useGetSubaccountsByUuid({
    uuid: uuid || '',
    filter: {
      and: [
        selectedCurrency ? { currency: { eq: selectedCurrency } } : {},
        selectedNetwork ? { network: { eq: selectedNetwork } } : {},
      ],
    },
  }, {
    enabled: Boolean(selectedCurrency && selectedNetwork && uuid),
  });

  useEffect(() => {
    form.setFieldValue('addressFrom', initialWallet?.walletAddress);
  }, [form, selectedNetwork, selectedCurrency, initialWallet?.walletAddress]);

  const handleSelectNetwork = useCallback((newSelectedNetwork: WalletNetworkEnum) => {
    setSelectedNetwork(newSelectedNetwork);

    const currenciesByNetwork = networkCurrencyMapping.get(newSelectedNetwork) || [];
    const filteredCurrencies = currencyOpts.filter(currency => currenciesByNetwork.includes(currency.value));
    setCurrencies(filteredCurrencies);

    form.setFieldValue('currency', filteredCurrencies[0].value);
    form.validateFields(['currency']);

    setSelectedCurrency(filteredCurrencies[0].value);
  }, [form]);

  const filteredAndSortedWallets = useMemo(() => {
    const relevantWallets = [...(wallets || []), ...(subaccounts?.nodes || [])]?.filter(wallet => (
      wallet.network === selectedNetwork && wallet.currency === selectedCurrency
    )) || (initialWallet ? [initialWallet] : []);

    return sortBy(relevantWallets, [
      wallet => wallet.subId !== null,
    ]);
  }, [initialWallet, selectedCurrency, selectedNetwork, subaccounts?.nodes, wallets]);

  const addressFromValidator = useCallback((
    _rule: RuleObject,
    addressFrom: string,
  ) => {
    if (!addressFrom) return Promise.reject(t('selectWallet'));

    const selectedWallet = filteredAndSortedWallets.find(wallet => wallet.walletAddress === addressFrom);

    if (selectedWallet?.balance === '0') {
      return Promise.reject(t('noFundsInTheWallet'));
    }

    return Promise.resolve();
  }, [filteredAndSortedWallets, t]);

  const handleFinish = ({ addressFrom, ...restFormData }: TransferFundsFormData) => {
    // const targetWallet = filteredAndSortedWallets.find(wallet => wallet.walletAddress === addressFrom);
    // const amount = targetWallet?.balance?.toString() || '0';

    onSubmit({ ...restFormData, addressFrom });
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      className={styles.form}
    >
      <div className={styles.alignItems}>
        <Form.Item
          name="network"
          label={t('subaccount.form.fields.network')}
          initialValue={initialWallet?.network}
          className={styles.formItem}
          rules={[{ required: true, message: t('subaccount.form.validation.selectNetwork') }]}
        >
          <Select
            placeholder="ERC20"
            disabled={!!initialWallet?.network}
            value={selectedNetwork}
            onSelect={handleSelectNetwork}
            className={styles.select}
            popupClassName={styles.selectPopup}
          >
            {networkOpts.map(option => (
              <Select.Option key={option.value} value={option.value}>
                <div className={styles.option}>
                  <Image
                    preview={false}
                    src={CurrencyIcon[option.value]}
                    key={`option-icon-${option.value}`}
                    className={styles.icon}
                  />

                  <span className={styles.label}>{option.label}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="currency"
          label={t('subaccount.form.fields.currency')}
          initialValue={initialWallet?.currency}
          className={styles.formItem}
          rules={[{ required: true, message: t('subaccount.form.validation.selectCurrency') }]}
        >
          <Select
            placeholder="Ethereum"
            disabled={!!initialWallet?.currency}
            value={selectedCurrency}
            onSelect={setSelectedCurrency}
            popupClassName={styles.selectPopup}
            className={styles.select}
          >
            {currencies.map(option => (
              <Select.Option key={option.value} value={option.value}>
                <div className={styles.option}>
                  <Image
                    preview={false}
                    src={CurrencyIcon[option.value]}
                    key={`option-icon-${option.value}`}
                    className={styles.icon}
                  />

                  <span className={styles.label}>{option.label}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Form.Item
        name="addressFrom"
        rules={[{ validator: addressFromValidator }]}
        className={styles.formItem}
      >
        <WalletSelect
          loading={loading || isLoading}
          disabled={!!initialWallet?.walletAddress}
          wallets={filteredAndSortedWallets}
          className={styles.select}
        />
      </Form.Item>

      <div className={styles.buttonWrapper}>
        <Button
          block
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          {t('withdraw')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          onClick={onModalClose}
        >
          {t('cancel')}
        </Button>
      </div>
    </Form>
  );
};

export default TransferFundsForm;
