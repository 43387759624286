import React from 'react';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { notification } from 'antd';
import useTranslate from 'hooks/useTranslate';
import { useAddWhitelistWallet } from '../../hooks';
import { useModal } from 'components/Modal/hooks/useModal';
import AddWhitelistWalletForm from '../AddWhitelistWalletForm';
import { Button, Modal } from 'components';
import { WalletOutlined } from '@ant-design/icons';
import styles from './AddWhitelistWalletButton.module.scss';

export type AddWhitelistWalletButtonProps = {
  uuid: string,
  disabled?: boolean;
};

const AddWhitelistWalletButton = ({ uuid, disabled }: AddWhitelistWalletButtonProps) => {
  const { t } = useTranslate();
  const {
    isOpen,
    isCreate,
    onModalOpen,
    onModalClose,
  } = useModal('unit-tab-whitelist');

  const { mutate, isPending } = useAddWhitelistWallet(uuid, {
    onSuccess: async () => {
      await cleverRefetchQueries('whitelist');
      onModalClose();

      notification.success({
        message: t('coldStorage.successfullyAdded'),
        key: 'wallet-created',
      });
    },
    onError: () => {
      notification.error({
        message: t('coldStorage.cannotAdded'),
        key: 'wallet-created',
      });
    },
  });

  return (
    <>
      <Button
        type="default"
        textSize="14px"
        disabled={disabled}
        onClick={onModalOpen}
        suffixIcon={<WalletOutlined style={{ fontSize: 16 }} />}
        className={styles.button}
      >
        {t('whitelist.addButton')}
      </Button>

      <Modal
        width={450}
        destroyOnClose
        isOpen={isOpen && !isCreate}
        onClose={onModalClose}
        titleIcon={<WalletOutlined style={{ fontSize: 20 }} />}
        title={t('whitelist.form.title')}
      >
        <AddWhitelistWalletForm
          onSubmit={mutate}
          onClose={onModalClose}
          loading={isPending}
        />
      </Modal>
    </>
  );
};

export default AddWhitelistWalletButton;
