import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { init as hjInit } from 'libs/hotjar';
import initSentry from './setup/sentry';
import { persistor, store } from 'init/store';
import { BRAND_COLOR, initTheme } from 'init/theme';
import { TranslateProvider } from './contexts';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from './components/ErrorBoundary';
import './index.scss';

initSentry();
hjInit();
initTheme(BRAND_COLOR);

const root = createRoot(document.getElementById('root') as Element);
root.render(
  <BrowserRouter>
    <TranslateProvider>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <App />
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </TranslateProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
