import { ColumnType } from 'antd/lib/table';
import { IWhitelistWallet } from 'interfaces';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import { WalletAddress } from 'components';
import CurrencyCell from 'components/CurrencyCell';
import DeleteWhitelistWalletButton from '../components/DeleteWhitelistWalletButton';

const getColumns = ({
  t,
  uuid,
  isActive,
}: {
  t: TranslateFunc;
  isActive: boolean;
  uuid: string | undefined;
}) => {
  const columns: ColumnType<IWhitelistWallet>[] = [
    {
      key: 'name',
      title: t('whitelist.columns.name'),
      dataIndex: 'name',
      width: 160,
      render: (name: IWhitelistWallet['name']) => <span style={{ fontWeight: 500 }}>{name}</span>,
    },
    {
      key: 'walletAddress',
      title: t('whitelist.columns.address'),
      dataIndex: 'walletAddress',
      width: 160,
      render: (walletAddress: IWhitelistWallet['walletAddress']) => (
        <WalletAddress walletAddress={walletAddress} startLength={6} endLength={4} />
      ),
    },
    {
      key: 'currency',
      title: t('whitelist.columns.currency'),
      dataIndex: 'currency',
      width: 160,
      render: (currency: IWhitelistWallet['currency']) => <CurrencyCell currency={currency} />,
    },
    {
      key: 'network',
      title: t('whitelist.columns.network'),
      dataIndex: 'network',
      width: 160,
      render: (network: IWhitelistWallet['network']) => <CurrencyCell network={network} />,
    },
    {
      key: '__extra__',
      dataIndex: '',
      width: 66,
      fixed: 'right',
      align: 'center',
      render: (record: IWhitelistWallet) => <DeleteWhitelistWalletButton data={{ ...record, uuid }} disabled={!isActive} />,
    },
  ];

  return columns;
};

export default getColumns;
