import React from 'react';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { notification, Tooltip } from 'antd';
import { IWhitelistWallet } from 'interfaces';
import useTranslate from 'hooks/useTranslate';
import useDeleteWhitelistWallet from '../../hooks/useDeleteWhitelistWallet';
import { Button } from 'components';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

const DeleteWhitelistWalletButton = ({
  data,
  disabled,
}: {
  data: IWhitelistWallet & { uuid: string | undefined };
  disabled: boolean;
}) => {
  const { t } = useTranslate();

  const { mutate, isPending } = useDeleteWhitelistWallet({
    onSuccess: async () => {
      await cleverRefetchQueries('whitelist');

      notification.success({
        message: t('whitelist.success.delete'),
      });
    },
    onError: (error) => {
      notification.error({
        message: error.response?.data.message,
      });
    },
  });

  const handleClick = () => mutate({ id: data.id, uuid: data.uuid as string });

  const prefixIconJSX = isPending
    ? <LoadingOutlined style={{ fontSize: 20 }} />
    : <DeleteOutlined style={{ fontSize: 20 }} />;

  return (
    <Tooltip title={t('whitelist.tooltip.delete')} placement="left">
      <Button
        type="link"
        color="error"
        withoutBgShadow
        withoutWaveEffect
        disabled={isPending || disabled}
        onClick={handleClick}
        prefixIcon={prefixIconJSX}
        style={{ padding: 0, margin: '0 auto' }}
      />
    </Tooltip>
  );
};

export default DeleteWhitelistWalletButton;
