import React from 'react';
import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IWallet } from 'interfaces';
import { IInvoice } from 'interfaces/IInvoice';
import { InvoiceSortField } from 'pages/Users/Invoices/types';
import { Sort } from 'hooks';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount';
import renderEmptyCell from 'utils/renderEmptyCell';
import renderActionButtons from './renderActionButtons';
import { CopyToClipboard, CurrencyNetworkTd, DateTime, Txns } from 'components';
import { StatusBadge } from 'components/StatusBadge';
import styles from '../InvoicesTab.module.scss';

const renderAmount = (invoice: IInvoice) => {
  const { amount, currency } = invoice;
  const { Text } = Typography;

  return (
    <Text className={styles.amount}>
      {formatCurrencyAmount(amount, currency)}
    </Text>
  );
};
export type ColumnsArgsType = {
  t: TranslateFunc;
  isRoot: boolean;
  withSort?: boolean;
  sort?: Sort<InvoiceSortField> | null;
  onViewInvoice: (invoice: IInvoice) => void;
  onManuallyTransfer: (uuid: string) => void;
};

const getColumns = ({
  t,
  sort,
  isRoot,
  withSort,
  onViewInvoice,
  onManuallyTransfer,
}: ColumnsArgsType): ColumnsType<IInvoice> => {
  const columns: ColumnsType<IInvoice> = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      width: 80,
      sorter: withSort,
      sortOrder: sort && sort.field === 'id' ? sort.order : undefined,
    },
    {
      key: 'customerEmail',
      title: t('invoice.fields.emailCustomer'),
      dataIndex: 'customerEmail',
      width: 190,
      sorter: withSort,
      sortOrder: sort && sort.field === 'customerEmail' ? sort.order : undefined,
      render: renderEmptyCell,
    },
    /*
     * Removed fields: destinationCurrency, destinationNetwork, clientFee;
     */
    // {
    //   key: 'invoiceAmount',
    //   title: t('invoicePage.invoiceAmount'),
    //   dataIndex: 'invoiceAmount',
    //   sorter: withSort,
    //   sortOrder: sort && sort.field === 'amount' ? sort.order : undefined,
    //   render: (_, invoice) => calcInvoiceAmount(invoice),
    // },
    {
      key: 'amount',
      title: t('processing.amount'),
      dataIndex: 'amount',
      width: 160,
      sorter: withSort,
      sortOrder: sort && sort.field === 'amount' ? sort.order : undefined,
      render: (_, invoice) => renderAmount(invoice),
    },
    {
      key: 'currency',
      title: t('currency'),
      dataIndex: 'currency',
      width: 160,
      sorter: withSort,
      sortOrder: sort && sort.field === 'currency' ? sort.order : undefined,
      render: (_, invoice: IInvoice) => (
        <CurrencyNetworkTd
          currency={invoice.currency}
          network={invoice.network}
        />
      ),
    },
  ];

  if (withSort) {
    columns.push({
      key: 'network',
      title: t('processing.payway'),
      dataIndex: 'network',
      width: 160,
      sorter: withSort,
      sortOrder: sort && sort.field === 'network' ? sort.order : undefined,
    });
  }

  columns.push(
    // {
    //   key: 'swapTo',
    //   title: t('invoice.columns.swapTo'),
    //   dataIndex: 'swapTo',
    //   sorter: withSort,
    //   sortOrder: sort && sort.field === 'destinationNetwork' ? sort.order : undefined,
    //   render: (_, invoice: IInvoice) => invoice.destinationCurrency && invoice.destinationNetwork
    //     ? <CurrencyNetworkTd currency={invoice.destinationCurrency} network={invoice.destinationNetwork} />
    //     : showEmptyValue(null),
    // },
    {
      key: 'status',
      title: t('status'),
      dataIndex: 'status',
      width: 160,
      sorter: withSort,
      sortOrder: sort && sort.field === 'status' ? sort.order : undefined,
      render: (status) => (
        <StatusBadge status={status} textTransform="capitalize" />
      ),
    },
    // {
    //   key: 'fee',
    //   title: t('invoice.columns.fee'),
    //   dataIndex: 'fee',
    //   sorter: withSort,
    //   sortOrder: sort && sort.field === 'merchantFeePercent' ? sort.order : undefined,
    //   render: (_, invoice: IInvoice) => t('invoice.select.fee', {
    //     variables: {
    //       businessFee: invoice.merchantFeePercent,
    //       clientFee: invoice.clientFeePercent,
    //     },
    //   }),
    // },
    // {
    //   key: 'merchantFee',
    //   title: t('invoicePage.businessFee'),
    //   dataIndex: 'merchantFee',
    //   sorter: withSort,
    //   sortOrder: sort && sort.field === 'merchantFee' ? sort.order : undefined,
    // },
    // {
    //   key: 'clientFee',
    //   title: t('invoicePage.clientFee'),
    //   dataIndex: 'clientFee',
    //   sorter: withSort,
    //   sortOrder: sort && sort.field === 'clientFee' ? sort.order : undefined,
    // },
    // {
    //   key: 'feeCurrency',
    //   title: t('invoice.columns.feeCurrency'),
    //   dataIndex: 'feeCurrency',
    //   sorter: withSort,
    //   sortOrder: sort && sort.field === 'feeCurrency' ? sort.order : undefined,
    //   render: (feeCurrency) => feeCurrency ? <CurrencyBadge currency={feeCurrency} /> : showEmptyValue(feeCurrency),
    // },
    {
      key: 'totalFee',
      title: t('invoice.columns.totalFee'),
      dataIndex: 'totalFee',
      width: 160,
      sorter: withSort,
      sortOrder: sort && sort.field === 'totalFee' ? sort.order : undefined,
    },
    {
      key: 'total_fee_paid',
      title: t('invoice.columns.totalFeePaid'),
      dataIndex: 'total_fee_paid',
      width: 220,
      sorter: withSort,
      sortOrder: sort && sort.field === 'total_fee_paid' ? sort.order : undefined,
    },
    {
      key: 'received_amount',
      title: t('transaction.receivedAmount'),
      dataIndex: 'received_amount',
      width: 160,
      sorter: withSort,
      sortOrder: sort && sort.field === 'received_amount' ? sort.order : undefined,
    },
    {
      key: 'total_received_amount',
      title: t('invoice.columns.totalReceivedAmount'),
      dataIndex: 'total_received_amount',
      width: 220,
      sorter: withSort,
      sortOrder: sort && sort.field === 'total_received_amount' ? sort.order : undefined,
    },
    {
      key: 'availableAmount',
      title: t('transaction.availableAmount'),
      dataIndex: 'availableAmount',
      width: 180,
      sorter: withSort,
      sortOrder: sort && sort.field === 'availableAmount' ? sort.order : undefined,
    },
    {
      key: 'total_available_amount',
      title: t('invoice.columns.totalAvailableAmount'),
      dataIndex: 'total_available_amount',
      width: 250,
      sorter: withSort,
      sortOrder: sort && sort.field === 'total_available_amount'
        ? sort.order
        : undefined,
    },
    {
      key: 'wallets',
      title: t('wallet'),
      dataIndex: 'wallets',
      width: 160,
      // sorter: withSort,
      // sortOrder: sort && sort.field === 'wallets.address.walletAddress' ? sort.order : undefined,
      render: (wallets: IWallet[], invoice) => {
        const targetWallet = wallets.find(
          (wallet) => wallet.currency === invoice.currency,
        );
        return !targetWallet?.walletAddress ? (
          renderEmptyCell(null)
        ) : (
          <CopyToClipboard
            walletAddress={targetWallet.walletAddress}
            iconColor="var(--primary-color)"
            type="center"
            revers
          />
        );
      },
    },
    {
      key: 'txId',
      title: 'txId',
      dataIndex: 'txId',
      // sorter: withSort,
      // sortOrder: sort && sort.field === 'txId' ? sort.order : undefined,
      render: (txId, item) => <Txns transactions={item.transactions} />,
    },
    {
      key: 'createdAt',
      title: t('created'),
      dataIndex: 'createdAt',
      width: 160,
      sorter: withSort,
      sortOrder: sort && sort.field === 'createdAt' ? sort.order : undefined,
      render: (createdAt) => <DateTime value={createdAt} />,
    },
    {
      key: 'expirationAt',
      title: t('invoice.fields.expiryTime'),
      dataIndex: 'expirationAt',
      width: 160,
      sorter: withSort,
      sortOrder: sort && sort.field === 'expirationAt' ? sort.order : undefined,
      render: (expirationAt) => <DateTime value={expirationAt} />,
    },
  );

  columns.push({
    key: '__extra__',
    dataIndex: '',
    width: 66,
    fixed: 'right',
    align: 'center',
    className: styles.userLink,
    render: (invoice) => renderActionButtons({
      onView: onViewInvoice,
      onManuallyTransfer,
      isRoot,
      invoice,
    }),
  });

  return columns;
};

export default getColumns;
