import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { isEmpty } from 'lodash';
import { AppState } from 'init/rootReducer';
import { IReplenishment } from 'interfaces/IReplenishment';
import { NestedUnitType } from 'interfaces/IUnit';
import { UnknownType } from 'types/Unknown';
import { ActionModalType } from 'components/Modal/types/Modal';
import { FormData, FormVerifyRequest } from 'pages/Administration/ReplenishmentRequests/components';
import useApproveReplenishmentRequest from 'pages/Administration/ReplenishmentRequests/hooks/useApproveReplenishmentRequest';
import useRejectReplenishmentRequest from 'pages/Administration/ReplenishmentRequests/hooks/useRejectReplenishmentRequest';
import { setReplenishmentRequest } from 'pages/Administration/Units/actions';
import { useTablePagination, useTranslate } from 'hooks';
import useTableColumns from 'hooks/useTableColumns';
import { useReplenishmentsById } from './hooks';
import { useModal } from 'components/Modal/hooks/useModal';
import { useAuthorisationContext } from 'contexts';
import { LocalStorageKey } from 'utils/localStorage';
import getColumns from './utils/getColumns';
import { moveItemsToFront } from './utils/moveItemsToFront';
import { Modal, Pagination, With2FA } from 'components';
import DraggableTable from 'components/DraggableTable';
import { AuditOutlined } from '@ant-design/icons';
import styles from './PendingApprovalsTab.module.scss';

export type PendingApprovalsTabProps = {
  unit: NestedUnitType | undefined;
};

const PendingApprovalsTab = ({ unit }: PendingApprovalsTabProps) => {
  const { isRequestCreated } = useSelector((state: AppState) => state.unitReducer);
  const [savedData, setSavedData] = useState<FormData>();
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const { page, perPage, setPage, setPerPage } = useTablePagination({ withoutURL: true });

  const { twoFA: {
    isDisabled,
    setDisabled,
    onError,
    onSuccess,
  } } = useAuthorisationContext();

  const {
    isOpen,
    payload,
    onModalOpen,
    onModalClose,
    onSetModalType,
    onSetModalPayload,
  } = useModal('replenishmentRequests');

  const { data, isLoading, refetch } = useReplenishmentsById(unit?.uuid, {
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
    paging: { limit: perPage, offset: (page - 1) * perPage },
  });

  // useEffect(() => {
  //   if (isRequestCreated && isTabActive) {
  //     refetch();
  //     dispatch(setReplenishmentRequest(false));
  //   }
  // }, [dispatch, refetch, isTabActive, isRequestCreated]);

  const { mutate: approveRequest, isPending: isApprovePending } = useApproveReplenishmentRequest({
    onSuccess: (_, variables) => {
      onSuccess(_, () => {
        onModalClose();

        notification.success({
          message: `${t('users.replenishmentRequests.title')} "${variables?.id}"`,
          description: t('users.replenishmentRequests.approved', { variables: { id: `${variables?.id}` } }),
        });

        refetch();
      });
    },
    onError: (error) => {
      onError(error, () => {
        notification.error({
          description: error.response?.data.message,
          message: error.response?.statusText,
        });
      });
    },
  });

  const { mutate: rejectRequest, isPending: isRejectPending } = useRejectReplenishmentRequest({
    onSuccess: (_, variables) => {
      onSuccess(_, () => {
        onModalClose();
        notification.success({
          message: `${t('users.replenishmentRequests.title')} "${variables?.id}"`,
          description: t('users.replenishmentRequests.declined', { variables: { id: `${variables?.id}` } }),
        });

        refetch();
      });
    },
    onError: (error) => {
      onError(error, () => {
        notification.error({
          description: error.response?.data.message,
          message: error.response?.statusText,
        });
      });
    },
  });

  const openVerifyModal = useCallback(
    (request: IReplenishment) => {
      batch(() => {
        onModalOpen();
        onSetModalPayload({ ...request });
        onSetModalType(ActionModalType.EDIT);
      });
    },
    [onModalOpen, onSetModalPayload, onSetModalType],
  );

  const sortedReplenishmmentRequests = moveItemsToFront(data?.nodes || [], unit?.uuid);
  const totalCount = data?.totalCount || 0;
  const wallets = unit?.wallets;

  const getRowClassName = (record: IReplenishment) => {
    if (record.unit.uuid === unit?.uuid) {
      return `${styles.row} ${styles.highlighted}`;
    }
    return styles.row;
  };

  const handleSubmit = (callback: (formData: FormData) => void) => (
    formData: FormData,
  ) => {
    setSavedData(formData);
    callback(formData);
  };

  const handleSubmitWithCode = (otpCode: string) => {
    if (!isEmpty(savedData)) {
      setDisabled(true);

      if ((savedData as UnknownType).unitId) { // If approve action
        approveRequest({ ...savedData, otpCode } as UnknownType);
      } else {
        rejectRequest({ ...savedData, otpCode });
      }
    }
  };

  const initialColumns = useMemo(() =>
    getColumns({
      t,
      wallets: wallets || [],
      currentUnit: unit,
      uuid: unit?.uuid,
      openVerifyModal,
      onRefresh: refetch,
      onDecline: rejectRequest,
    }),
  [t, wallets, unit, openVerifyModal, refetch, rejectRequest]);

  const { columns, setOrder } = useTableColumns({
    storageKey: LocalStorageKey.UNIT_PENDING_APPROVAL_COLUMNS,
    initialColumns,
  });

  return (
    <div className={styles.wrapper}>
      <DraggableTable
        setOrder={setOrder}
        onRefresh={refetch}
        dataSource={sortedReplenishmmentRequests}
        rowClassName={getRowClassName}
        rootClassName={styles.tableWrapper}
        loading={isLoading}
        columns={columns}
        scroll={{ x: 'max-content' }}
        pagination={false}
        rowKey="id"
      />
      <Pagination
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        totalCount={totalCount}
        onPerPageChange={setPerPage}
        disablePageChange={isLoading || !totalCount}
        disablePerPageHandler={isLoading || !totalCount}
        elementsSize="xs"
      />
      <Modal
        width={450}
        isOpen={isOpen}
        onClose={onModalClose}
        title={t('users.replenishmentRequests.approveOrDecline')}
        titleIcon={<AuditOutlined />}
        destroyOnClose
      >
        <With2FA onSubmit={handleSubmitWithCode} isLoading={isLoading || isDisabled}>
          <FormVerifyRequest
            initialValues={payload as unknown as IReplenishment}
            loading={isApprovePending || isRejectPending}
            onApprove={handleSubmit(approveRequest)}
            onReject={handleSubmit(rejectRequest)}
          />
        </With2FA>
      </Modal>
    </div>
  );
};

export default PendingApprovalsTab;
