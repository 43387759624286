import { Text, View } from '@react-pdf/renderer';
import { IClientTransaction } from 'interfaces/IClientTransaction';
import { UnknownType } from 'types/Unknown';
import { RowType } from './utils/getRows';
import styles from './styles';

const PDFTable = ({
  data,
  rows,
}: {
  data: IClientTransaction;
  rows: RowType<IClientTransaction>[],
}) => {
  return (
    <View style={styles.table}>
      {rows.map((row, index) => (
        <View key={row.key} style={[styles.row, rows.length - 1 === index ? styles.lastRow : {}]}>
          <View style={styles.idWithLabelCol}>
            <Text style={styles.idCol}>{index + 1}</Text>
            <Text style={styles.labelCol}>{row.title}</Text>
          </View>
          <Text style={styles.valueCol}>
            {row.render
              ? row.render(data[row.dataIndex], data) as UnknownType
              : data[row.dataIndex]}
          </Text>
        </View>
      ))}
    </View>
  );
};

export default PDFTable;
