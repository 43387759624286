import axios, { SERVICE } from 'libs/axios';

type TransactionCategoryCreateDto = {
  name: string;
  groupId: number;
  color?: string;
  description?: string;
  unitIds?: number[];
  rootUnitId?: number;
};

export type TransactionCategoryCreateVariables = TransactionCategoryCreateDto;

export const transactionCategoryCreate = async (body: TransactionCategoryCreateVariables) => {
  const { data } = await axios.post(
    `${SERVICE.CLIENT}/transaction-categories`, body,
  );
  return data;
};
