import React, { useContext, useMemo } from 'react';
import { Table } from 'antd';
import { ITransactionCategory, ITransactionGroup } from '../../api/types';
import { useTranslate } from 'hooks';
import { AuthorisationContext } from 'contexts';
import { getCategoryColumns } from '../../utils';
import styles from './CategoriesTable.module.scss';

export type CategoryColHandlersType = {
  onEditCategory: (category: ITransactionCategory, rootUnitId: ITransactionGroup['rootUnitId']) => void;
};

export interface ICategoryTableProps {
  loading: boolean;
  rootUnitId: number | null,
  categories: ITransactionCategory[];
  categoryColHandlers: CategoryColHandlersType,
}

export const CategoriesTable = ({
  loading,
  categories,
  rootUnitId,
  categoryColHandlers: { onEditCategory },
}: ICategoryTableProps) => {
  const { checkPermissions } = useContext(AuthorisationContext);
  const { t } = useTranslate();

  const categoryColumns = useMemo(() =>
    getCategoryColumns({
      checkPermissions,
      onEditCategory,
      rootUnitId,
      t,
    }),
  [checkPermissions, onEditCategory, rootUnitId, t]);

  return (
    <div className={styles.wrapper}>
      <Table
        rowKey="id"
        pagination={false}
        loading={loading}
        dataSource={categories}
        columns={categoryColumns}
        rowClassName={styles.row}
        className={styles.table}
      />
    </div>
  );
};
