import React, { useMemo } from 'react';
import { Select } from 'antd';
import { useTranslate } from 'hooks';
import { PerPageNumber } from 'components/Pagination';
import styles from './PerPageSelect.module.scss';

export type PerPageSelectProps = {
  items: PerPageNumber[];
  selected: PerPageNumber;
  disabled: boolean;
  onSelect: (perPageCount: PerPageNumber) => () => void;
};

const PerPageSelect = ({ items, selected, disabled, onSelect }: PerPageSelectProps) => {
  const { t } = useTranslate();

  const options = useMemo(() => items.map(item => ({ value: item, label: item })), [items]);

  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>{t('rowsPerPage')}</span>

      <Select
        value={selected}
        onSelect={value => onSelect(value)()}
        disabled={disabled}
        options={options}
        className={styles.select}
        popupClassName={styles.dropdown}
        getPopupContainer={trigger => trigger.parentNode}
      />
    </div>
  );
};

export default PerPageSelect;
