import { ReactNode } from 'react';
import { map } from 'lodash';
import { ITransactionGroup } from 'pages/Administration/FinancialManagement/api/types';
import renderOption from './renderOption';

const getGroupOptions = (
  items: ITransactionGroup[],
): { value: number; label: ReactNode }[] => {
  return map(items, item => ({
    value: item.id,
    label: renderOption(item),
  }));
};

export default getGroupOptions;
