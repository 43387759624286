import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { UseQueryHandlers } from 'types/UseQueryHandlers';

export type AddWhitelistWalletDto = {
  name: string;
  network: string;
  currency: string;
  walletAddress: string;
};

const useAddWhitelistWallet = (uuid: string | undefined, options?: UseQueryHandlers) => {
  return useMutation({
    ...options,
    mutationFn: async (body: AddWhitelistWalletDto) => {
      const response = await axios.post(
        `${SERVICE.CLIENT}/whitelist-wallets/${uuid}`, body,
      );

      return response.data;
    },
  });
};

export default useAddWhitelistWallet;
