import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { StatusStateEnum } from 'hooks/useUpdateStatus';
import styles from './StatusBadge.module.scss';

export type StatusBadgeProps = PropsWithChildren<{
  status: StatusStateEnum;
  size?: number;
  absolute?: boolean;
}>;

const StatusBadge = ({ status, size = 13, absolute, children }: StatusBadgeProps) => {
  return (
    <div className={styles.wrapper}>
      <div
        style={{
          width: size,
          height: size,
          position: absolute ? 'absolute' : 'static',
        }}
        className={clsx(styles.ellipse, {
          [styles.online]: status === StatusStateEnum.ONLINE,
          [styles.offline]: status === StatusStateEnum.OFFLINE,
          [styles.away]: status === StatusStateEnum.AWAY,
          [styles.doNotDisturb]: status === StatusStateEnum.DO_NOT_DISTURB,
        })}
      />

      {children}
    </div>
  );
};

export default StatusBadge;
