import React, { ReactElement } from 'react';
import { Layout, Typography } from 'antd';
import clsx from 'clsx';
import { useCollapsed } from './hooks/useCollapsed';
import { useTranslate } from '../../hooks';
import { Logo } from '../Logo/Logo';
import { SidebarMenu } from './components/menu';
import arrow from './icons/arrow.png';
import styles from './Sidebar.module.scss';

export const Sidebar = (): ReactElement => {
  const { t } = useTranslate();
  const { animateCollapsed, collapsed, onCollapse } = useCollapsed();

  return (
    <Layout.Sider
      width={290}
      collapsed={collapsed}
      className={clsx(styles.sidebar, { [styles.sidebarCollapsed]: animateCollapsed })}
    >
      <div className={clsx(styles.head, { [styles.headCollapsed]: animateCollapsed })}>
        <Logo collapsed={animateCollapsed} className={styles.logo} smallIcon />
      </div>
      <div
        className={
          clsx(styles.collapseContainer, { [styles.collapseContainerCollapsed]: animateCollapsed })
        }
      >
        {!animateCollapsed && <div className={styles.shortLine} />}
        <button type="button" className={styles.button} onClick={onCollapse}>
          {animateCollapsed
            ? <img src={arrow} alt="arrow" className={styles.icon} />
            : <img src={arrow} alt="arrow" className={clsx(styles.icon, styles.iconCollapsed)} />}
        </button>
        {!animateCollapsed && <Typography.Text className={styles.text} type="secondary">{t('collapseMenu')}</Typography.Text>}
        {!animateCollapsed && <div className={styles.line} />}
      </div>
      <SidebarMenu collapsed={collapsed} />
    </Layout.Sider>
  );
};
