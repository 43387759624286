import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { DefaultError, UseQueryHandlers } from 'types/UseQueryHandlers';

type RejectReplenishmentRequestDto = { otpCode?: string };

export type RejectReplenishmentRequestVariables = RejectReplenishmentRequestDto & { id: number };

type Options = UseQueryHandlers<unknown, DefaultError, RejectReplenishmentRequestVariables>;

const useRejectReplenishmentRequest = (options: Options) => {
  const mutationFn = async ({ id, ...body }: RejectReplenishmentRequestVariables) => {
    const { data } = await axios.post(
      `${SERVICE.CLIENT}/replenishment-requests/reject/${id}`, body,
    );
    return data;
  };

  return useMutation({ mutationFn, ...options });
};

export default useRejectReplenishmentRequest;
