import React, { Dispatch } from 'react';
import { Permission } from '@fanckler/processing-auth';
import { Avatar, Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import clsx from 'clsx';
import { truncate } from 'lodash';
import { ITransactionGroup } from '../api/types';
import { CheckPermissionsFunction } from 'contexts/AuthorisationContext/AuthorisationContext';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import { EditButton } from 'components';
import styles from '../components/GroupsTable/GroupsTable.module.scss';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as ArrowUp } from 'assets/icons/ArrowUp.svg';

export interface GetColumnsParams {
  t: TranslateFunc;
  checkPermissions: CheckPermissionsFunction;
  expandedRowKeys: number[];
  setExpandedRowKeys: Dispatch<React.SetStateAction<number[]>>;
  onCreateCategory: (group: ITransactionGroup) => void;
  onEditGroup: (group: ITransactionGroup) => void;
}

export const getGroupColumns = ({
  t, onEditGroup,
  onCreateCategory,
  checkPermissions,
  expandedRowKeys,
  setExpandedRowKeys,
}: GetColumnsParams): ColumnsType<ITransactionGroup> => {
  const avatarRender = (group: ITransactionGroup) => {
    const truncatedName = truncate(group.name, {
      length: 50, omission: '...',
    });

    return (
      <div className={styles.avatar}>
        {/* <Badge
          count={group.categories.length}
          {...(group.id !== 1 && { style: { display: 'none' } })}
        > */}

        <div className={styles.avatarWrapper}>
          <Avatar
            size="large"
            className={styles.avatarItem}
            style={{ backgroundColor: group.color as string }}
          >
            <span className={styles.letterName} /* style={{ color: group.color }} */>
              {group.name.slice(0, 1)}
            </span>
          </Avatar>
        </div>

        {/* </Badge> */}
        <span className={styles.name}>
          <Tooltip title={group.name}>{truncatedName}</Tooltip>
        </span>
      </div>
    );
  };

  const createCategoryButton = (group: ITransactionGroup) => (
    <Button
      type="link"
      disabled={!checkPermissions([Permission.CLIENT_TRANSACTION_CATEGORY_CREATE]) || group.id === 1}
      onClick={() => onCreateCategory(group)}
      className={clsx(styles.button, styles.createCategory)}
    >
      {t('administration.financialManagement.createCategory')}
      <PlusOutlined className={styles.plusIcon} />
    </Button>
  );

  const expandableButton = (group: ITransactionGroup) => {
    const expand = expandedRowKeys.includes(group.id);

    return (
      <Button
        type="link"
        disabled={!group.categories.length}
        onClick={() => {
          setExpandedRowKeys(prev => {
            if (prev.includes(group.id)) {
              return prev.filter(id => id !== group.id);
            } else {
              return [...prev, group.id];
            }
          });
        }}
        className={clsx(styles.button, styles.toggleExpand)}
      >
        {t(`administration.financialManagement.${expand ? 'hide' : 'show'}Categories`)}
        <ArrowUp className={clsx(styles.arrowIcon, { [styles.mirror]: expand })} />
      </Button>
    );
  };

  const columns: ColumnsType<ITransactionGroup> = [
    {
      key: 'avatarWithName',
      dataIndex: '',
      render: avatarRender,
    },
    {
      key: 'categories',
      dataIndex: 'categories',
      render: (categories) => (
        <span className={styles.categoriesTd}>
          {t('administration.financialManagement.categories')}
          <span className={styles.value}>{categories.length}</span>
        </span>
      ),
    },
    {
      key: 'createCategory',
      dataIndex: '',
      render: createCategoryButton,
    },
    {
      key: 'toggleViewCategories',
      dataIndex: '',
      render: expandableButton,
    },
    {
      width: 66,
      dataIndex: '',
      fixed: 'right',
      align: 'center',
      render: (group) => (
        <EditButton
          disabled={!checkPermissions([Permission.CLIENT_TRANSACTION_GROUP_UPDATE]) || group.id === 1}
          onClick={() => onEditGroup(group)}
        />
      ),
    },
  ];

  return columns;
};
