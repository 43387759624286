import React, { PropsWithChildren, useCallback, useState } from 'react';
import { batch } from 'react-redux';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { Spin, Tooltip } from 'antd';
import { NestedUnitType } from 'interfaces';
import { UnknownType } from 'types/Unknown';
import { UnitFormData } from '../../types';
import { ActionModalType } from 'components/Modal/types/Modal';
import { useTranslate } from 'hooks';
import { useModal } from 'components/Modal/hooks/useModal';
import { ModalFreezeUnit } from '../ModalFreezeUnit';
import { Button, DebouncedButton } from 'components';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import styles from './FreezeButton.module.scss';

export type FreezeButtonProps = PropsWithChildren<{
  unit: NestedUnitType | undefined;
  isLoading?: boolean;
  fromDropdown?: boolean;
  onRefresh?: () => Promise<void>;
}>;

export const FreezeButton = ({ unit, isLoading, onRefresh, ...props }: FreezeButtonProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const children: UnknownType = props.children;
  const { t } = useTranslate();

  const {
    isOpen,
    payload,
    onModalOpen,
    onModalClose,
    onSetModalType,
    onSetModalPayload,
  } = useModal('unit-toggle-status');

  const freezeUnit = useCallback(async () => {
    if (!unit) return;

    batch(() => {
      onSetModalPayload({
        id: unit.id,
        name: unit.name,
        isActive: unit.isActive,
      });
      onModalOpen();
      onSetModalType(ActionModalType.UPLOAD);
    });
  }, [onModalOpen, onSetModalPayload, onSetModalType, unit]);

  const handleFreezeUnit = useCallback(async () => {
    setIsFetching(true);
    try {
      await cleverRefetchQueries('unit');
      if (onRefresh) {
        await onRefresh();
      }
    } catch (error) {
      //   do nothing
    } finally {
      setIsFetching(false);
    }
  }, [onRefresh]);

  const modalTitle = unit?.isActive ? t('users.units.deactivateUnit') : t('users.units.activateUnit');

  return (
    <>
      <div className={styles.wrapper}>
        <DebouncedButton onClick={freezeUnit}>
          {({ isDisabled, onClick }) => (
            <span style={{ display: 'flex' }}>
              {(isLoading || isDisabled || isFetching) ? (
                <Spin className={styles.loader} size="small" />
              ) : children ? (
                <span onClick={onClick}>
                  {children}
                </span>
              ) : (
                <Tooltip title={modalTitle} placement="bottomLeft">
                  <Button
                    type="link"
                    withoutBgShadow
                    withoutWaveEffect
                    onClick={onClick}
                    prefixIcon={unit?.isActive
                      ? <CloseCircleOutlined style={{ fontSize: 28, color: 'var(--red-status-color)' }} />
                      : <CheckCircleOutlined style={{ fontSize: 28, color: 'var(--green-status-color)' }} />}
                    className={styles.button}
                  />
                </Tooltip>
              )}
            </span>
          )}
        </DebouncedButton>
      </div>
      <ModalFreezeUnit
        isOpen={isOpen && unit?.id === payload?.id}
        onModalClose={onModalClose}
        onComplete={handleFreezeUnit}
        payload={payload as UnitFormData}
      />
    </>
  );
};
