import { useEffect, useState } from 'react';
import { UserRefusedOnDevice } from '@ledgerhq/errors';
import Transport from '@ledgerhq/hw-transport';
import TransportWebHID from '@ledgerhq/hw-transport-webhid';
import { AxiosError } from 'libs/axios';
import { UnknownType } from 'types/Unknown';
import { DeviceModelId } from '../types';
import { CryptoCurrency } from 'components/ColdStorage/assets/crypto/types';
import { ColdStorageWallet } from 'components/ColdStorage/types';
import getAppAndVersion from '../utils/getAppAndVersion';
import isDashboardName from '../utils/isDashboardName';
import openApp from '../utils/openApp';
import quitApp from '../utils/quitApp';
import getAddress from 'components/ColdStorage/ledger/utils/getAddress';
import { transport } from '../transport';
import getDeriviationById from 'components/ColdStorage/assets/crypto/deriviation';

export enum ConnectionRequest {
  OPEN_APP,
  VERIFY,
}

export enum ConnectionStatus {
  PENDING_CONFIRMATION,
  LOADING,
  ERROR,
  IDLE,
}

type ConnectionErrorState = { status: ConnectionStatus.ERROR, reason: Error };
type PendingConfirmationState = { status: ConnectionStatus.PENDING_CONFIRMATION, reason: ConnectionRequest };
type GeneralConnectionState = { status: Exclude<ConnectionStatus, ConnectionStatus.ERROR | ConnectionStatus.PENDING_CONFIRMATION> };

type ConnectionState = ConnectionErrorState | PendingConfirmationState | GeneralConnectionState;

const delay = async (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const useConnect = (currency: CryptoCurrency, {
  onFinish,
  setError,
}: {
  onFinish: (result: ColdStorageWallet) => Promise<void>;
  setError: (error: Error | undefined, stepIndex: number) => void;
}) => {
  const [device, setDevice] = useState<DeviceModelId | null>(null);
  const [state, setState] = useState<ConnectionState>({
    status: ConnectionStatus.IDLE,
  });

  const connectApp = async (withoutDelay?: boolean) => {
    setState({ status: ConnectionStatus.LOADING });

    if (!withoutDelay) {
      await delay(1000);
    }

    return TransportWebHID.create()
      .then((currentTransport) => {
        transport.value = currentTransport;
        setDevice(currentTransport.deviceModel?.id as UnknownType);

        return getAppAndVersion(currentTransport)
          .then(appAndVersion => {
            if (isDashboardName(appAndVersion.name)) {
              setState({
                status: ConnectionStatus.PENDING_CONFIRMATION,
                reason: ConnectionRequest.OPEN_APP,
              });

              return openApp(currentTransport, currency.managerAppName)
                .then(() => {
                  connectApp();
                });
            }

            if (appAndVersion.name !== currency.managerAppName) {
              return quitApp(currentTransport).then(() => {
                connectApp();
              });
            }

            setState({
              status: ConnectionStatus.PENDING_CONFIRMATION,
              reason: ConnectionRequest.VERIFY,
            });

            return getAddress(currentTransport as Transport, {
              currency,
              path: getDeriviationById(currency.id),
              forceFormat: 'bech32', // for Bitcoin
              askChainCode: true,
              verify: true,
            })
              .then(async (result) => {
                setState({ status: ConnectionStatus.LOADING });

                return onFinish({
                  address: result.address,
                  publicKey: result.publicKey,
                  chainCode: result.chainCode,
                })
                  .catch((error: AxiosError<{ message: string }>) => {
                    throw ({
                      name: 'WalletCreateError',
                      message: error.response?.data.message,
                    } as UnknownType);
                  });
              });
          });
      })
      .catch(error => {
        if (error.name === 'InvalidStateError' && transport) {
          return transport.value?.close().then(() => {
            connectApp();
          });
        }

        if (error.name === 'TransportStatusError' && error.statusCode === 0x5501) {
          throw new UserRefusedOnDevice();
        }

        throw error;
      })
      .then(() => setError(undefined, 2)) // reset error
      .catch(error => {
      // console.error(error);
      // console.dir(error);

        setError(error, 2); // 2 is index of current step "connect"
        setState({
          status: ConnectionStatus.ERROR,
          reason: error,
        });
      });
  };

  useEffect(() => {
    connectApp();

    return () => {
      setError(undefined, 2); // reset error

      if (transport.value) {
        transport.value.close();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    state,
    device,
    onRetry: () => connectApp(),
  };
};

export default useConnect;
