import { useEffect } from 'react';
import { QueryClient, useMutation as useMutationOriginal, useQuery as useQueryOriginal, UseQueryOptions } from '@tanstack/react-query';
import { UnknownType } from 'types/Unknown';

export type {
  UseMutateFunction,
  UseQueryResult,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
export { QueryClientProvider, useQueries, keepPreviousData } from '@tanstack/react-query';

export const useMutation = useMutationOriginal;

export type UseQueryProps<T> = UseQueryOptions<T> & {
  onSuccess?: (data: UnknownType) => void;
  onError?: (e: UnknownType) => void;
};

export const useQuery = <T>(options: UseQueryProps<T>) => {
  const {
    onSuccess,
    onError,
    ...queryOptions
  } = options;

  const query = useQueryOriginal(queryOptions);

  useEffect(() => {
    if (query.isSuccess && onSuccess) {
      onSuccess(query.data);
    }
  }, [query.isSuccess, query.data, onSuccess]);

  useEffect(() => {
    if (query.isError && onError) {
      onError(query.error || {});
    }
  }, [query.isError, query.error, onError]);

  return query;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retryOnMount: false,
      retry: false,
    },
  },
});

export const cleverRefetchQueries = async (key: string) => {
  const allCache = queryClient.getQueryCache().getAll();
  const keys = allCache.filter((query) => query.queryKey[0] === key);

  return Promise.all(
    keys.map((queryKey) => queryClient.invalidateQueries({ queryKey: queryKey.queryKey })),
  );
};
