import React from 'react';
import { IClientTransactionCategory } from 'interfaces/IClientTransaction';
import styles from '../CategorizeTransactionSelect/CategorizeTransactionSelect.module.scss';

const renderOption = (option: IClientTransactionCategory | IClientTransactionCategory['group']) => {

  return (
    <div className={styles.option}>
      <div className={styles.circle} style={{ backgroundColor: option.color || '#CCCCCC' }} />
      <span className={styles.label}>{option.name}</span>
    </div>
  );
};

export default renderOption;
