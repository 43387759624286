import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CopyToClipboard as CopyAntd } from 'react-copy-to-clipboard';
import { Button, Tooltip } from 'antd';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import { truncateString } from 'utils';
import { CopyOutlined } from '@ant-design/icons';
import styles from './WalletAddress.module.scss';

type WalletAddressType = {
  walletAddress: string;
  className?: string;
  endLength?: number;
  startLength?: number;
  textPlacement?: 'left' | 'right';
};

const WalletAddress = ({ walletAddress, className, endLength = 8, startLength = 8, textPlacement = 'right' }: WalletAddressType): ReactElement => {
  const { t } = useTranslate();
  const [isCopied, setIsCopied] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const AddressComponent = useMemo(() => {
    const truncatedAddress = walletAddress && truncateString(walletAddress, { type: 'center', endLength, startLength });

    return (
      <Tooltip title={walletAddress}>
        <span>{truncatedAddress}</span>
      </Tooltip>
    );
  }, [endLength, startLength, walletAddress]);

  const handleCopy = useCallback(() => {
    setIsCopied(true);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  if (!walletAddress) {
    return (
      <span className={styles.null}>
        {String(null)}
      </span>
    );
  }

  return (
    <div className={clsx(styles.address, className)}>

      {textPlacement === 'left' && (AddressComponent)}

      <CopyAntd text={walletAddress} onCopy={handleCopy}>
        <Tooltip title={isCopied ? t('copied') : t('clickToCopy')}>
          <Button
            type="text"
            size="middle"
            icon={<CopyOutlined className={styles.copyIcon} />}
            className={styles.copyBtn}
          />
        </Tooltip>
      </CopyAntd>

      {textPlacement === 'right' && (AddressComponent)}
    </div>
  );
};

export default WalletAddress;
