import { useMemo } from 'react';
import { connectTrezor, disconnectTrezor } from 'components/ColdStorage/trezor/api/trezorAPI';

const useConnect = () => {
  return useMemo(() => ({
    connect: connectTrezor,
    disconnect: disconnectTrezor,
  }), []);
};

export default useConnect;
