import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { notification } from 'antd';
import { MutationOptions, ToggleUnitVariables } from './types';
import { useTranslate } from 'hooks';

const toggleUnit = async ({ id, body }: ToggleUnitVariables) => {
  const { data } = await axios.put(`${SERVICE.CLIENT}/units/${id}/status`, body);
  return data;
};

const useToggleUnit = (mutationOptions?: MutationOptions) => {
  const { t } = useTranslate();

  return useMutation({
    ...mutationOptions,
    mutationFn: toggleUnit,
    onSuccess(data, variables, context) {
      notification.success({
        message: variables.body.isActive ? t('users.units.activated') : t('users.units.deactivated'),
      });

      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      notification.error({
        message: error.message,
        description: error.response?.data.message || t('somethingWrong'),
      });

      if (mutationOptions?.onError) {
        mutationOptions.onError(error, variables, context);
      }
    },
  });
};

export default useToggleUnit;
