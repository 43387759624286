import React, { PropsWithChildren } from 'react';
import { useTranslate } from 'hooks';
import { Button } from 'components';
import styles from '../../Trezor.module.scss';

type ContentWithButtonProps = PropsWithChildren<{
  isDisabled?: boolean;
  isLoading?: boolean;
  onButtonClick?: () => void;
}>;

const ContentWithButton = ({ children, onButtonClick, isDisabled, isLoading }: ContentWithButtonProps) => {
  const { t } = useTranslate();

  return (
    <div className={styles.wrapper}>
      {children}

      <div className={styles.wrapper}>
        <Button
          className={styles.button}
          onClick={onButtonClick}
          disabled={isDisabled}
          loading={isLoading}
        >
          {t('continue')}
        </Button>
      </div>
    </div>
  );
};

export default ContentWithButton;
