import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RiLogoutBoxLine, RiQuestionMark, RiSettings3Line } from '@remixicon/react';
import { Dropdown, MenuProps } from 'antd';
import clsx from 'clsx';
import { IUser } from 'interfaces/IUser';
import { Path } from 'routes/interfaces/Path';
import { useTranslate } from 'hooks';
import { AuthorisationContext } from 'contexts';
import getRobohash from 'utils/getRobohash';
import packageJson from '../../../package.json';
import { UserAvatar } from 'components';
import ActivityStatusDropdown from 'components/ActivityStatusDropdown';
import LanguageDropdown from './components/LanguageDropdown/LanguageDropdown';
import styles from './UserMenuDropdown.module.scss';

const UserMenuDropdown = () => {
  const { logout, user } = useContext(AuthorisationContext);
  const navigate = useNavigate();
  const { t } = useTranslate();

  const [visible, setVisible] = useState(false);

  const items: MenuProps['items'] = useMemo(() => [
    {
      key: 'avatar',
      className: clsx(styles.option, styles.avatar),
      label: (
        <UserAvatar
          name={(user as IUser).name}
          role={(user as IUser).group.name}
          src={(user as IUser).avatar}
          altSrc={getRobohash((user as IUser).uuid)}
        />
      ),
    },
    {
      key: 'status',
      className: clsx(styles.option, styles.status),
      label: <ActivityStatusDropdown />,
    },
    { type: 'divider', className: styles.divider },
    {
      key: 'settings',
      label: t('settings'),
      itemIcon: <RiSettings3Line />,
      className: clsx(styles.option, styles.button),
      onClick: () => { setVisible(false); navigate(Path.MY_ACCOUNT); },
    },
    { type: 'divider', className: styles.divider },
    {
      key: 'helpCenter',
      label: t('help'),
      itemIcon: <RiQuestionMark />,
      className: clsx(styles.option, styles.button),
      onClick: () => { setVisible(false); navigate(Path.HELP_CENTER); },
    },
    { type: 'divider', className: styles.divider },
    {
      key: 'logout',
      label: t('logout'),
      itemIcon: <RiLogoutBoxLine />,
      className: clsx(styles.option, styles.button, styles.logout),
      onClick: () => { setVisible(false); logout(); },
    },
    { type: 'divider', className: styles.divider },
    {
      key: 'language',
      className: clsx(styles.option),
      label: (
        <div className={styles.language}>
          <span>{t('language')}</span>
          <LanguageDropdown />
        </div>
      ),
    },
    {
      key: 'meta',
      className: clsx(styles.option),
      label: (
        <div className={styles.meta}>
          <span>&copy; {new Date().getFullYear()}</span>
          <span>v{packageJson.version}</span>
        </div>
      ),
    },
  ], [user, logout, navigate, t]);

  return (
    <Dropdown
      open={visible}
      onOpenChange={setVisible}
      menu={{ items, className: styles.menu }}
      trigger={['click']}
      placement="bottomRight"
      overlayClassName={styles.overlay}
    >
      <div style={{ cursor: 'pointer' }} onClick={e => e.preventDefault()}>
        <UserAvatar
          name={(user as IUser).name}
          status={(user as IUser).status}
          role={(user as IUser).group.name}
          src={(user as IUser).avatar}
          altSrc={getRobohash((user as IUser).uuid)}
        />
      </div>
    </Dropdown>
  );
};

export default UserMenuDropdown;
