import React, { PropsWithChildren } from 'react';
import { Empty } from 'antd';
import { useTranslate } from 'hooks';
import styles from './EmptyWallets.module.scss';

const EmptyWallets = ({ children }: PropsWithChildren) => {
  const { t } = useTranslate();

  return (
    <div className={styles.wrapper}>
      {Empty.PRESENTED_IMAGE_SIMPLE}
      <div className={styles.textContainer}>
        <span className={styles.textMain}>
          {t('coldStorage.noWalletYet')}
        </span>
        <span className={styles.textSecondary}>
          {t('coldStorage.addWalletToStart')}
        </span>
      </div>

      {children}
    </div>
  );
};

export default EmptyWallets;
