import React, { ReactElement, useContext } from 'react';
import { batch } from 'react-redux';
import { Permission } from '@fanckler/processing-auth';
import clsx from 'clsx';
import _ from 'lodash';
import { UnknownType } from 'types/Unknown';
import { ITransactionCategory, ITransactionGroup, MutateActionEnum } from './api/types';
import { ActionModalType } from 'components/Modal/types/Modal';
import { useTranslate } from 'hooks';
import { useModal } from 'components/Modal/hooks/useModal';
import { AuthorisationContext } from 'contexts';
import useTransactionCategoryMutate from './api/category/useTransactionCategoryMutate';
import useGetTransactionGroups from './api/group/useGetTransactionGroups';
import useTransactionGroupMutate from './api/group/useTransactionGroupMutate';
import { ApproveActionModal, Button, Filters, Modal } from 'components';
import { FormCreateUpdate, GroupsTable } from './components';
import { FormDataResult, InitialValues } from './components/FormCreateUpdate';
import { PlusOutlined, ToolOutlined } from '@ant-design/icons';
import styles from './FinancialManagement.module.scss';

export enum ModalType {
  GROUP = 'group',
  CATEGORY = 'category',
}

const FinancialManagement = (): ReactElement => {
  const { user, checkPermissions } = useContext(AuthorisationContext);
  const { t } = useTranslate();

  const {
    isOpen,
    isEdit,
    payload,
    onModalOpen,
    onModalClose,
    onSetModalType,
    onSetModalPayload,
  } = useModal('financialManagement');

  const {
    isOpen: isOpenApproveModal,
    payload: modalApprovePayload,
    onModalOpen: onModalApproveOpen,
    onModalClose: onModalApproveClose,
    onSetModalPayload: onSetModalApprovePayload,
  } = useModal('approveAction');

  const { data, refetch, isLoading } = useGetTransactionGroups({
    sorting: [{ field: 'createdAt', direction: 'ASC' }],
  });

  const { mutate: mutateGroup, isPending: isPendingGroup } = useTransactionGroupMutate({
    onSuccess: () => {
      refetch();
      onModalClose();
      onModalApproveClose();
    },
  });

  const { mutate: mutateCategory, isPending: isPendingCategory } = useTransactionCategoryMutate({
    onSuccess: () => {
      refetch();
      onModalClose();
      onModalApproveClose();
    },
  });

  const onCreateGroup = () => {
    batch(() => {
      onModalOpen();
      onSetModalType(ActionModalType.CREATE);
      onSetModalPayload({ modalType: ModalType.GROUP });
    });
  };
  const onEditGroup = (group: ITransactionGroup) => {
    batch(() => {
      onModalOpen();
      onSetModalType(ActionModalType.EDIT);
      onSetModalPayload({ ...group, modalType: ModalType.GROUP });
    });
  };
  const onDeleteGroup = (group: ITransactionGroup) => {
    batch(() => {
      onModalApproveOpen();
      onSetModalApprovePayload({ ...group, modalType: ModalType.GROUP });
    });
  };

  const onCreateCategory = (group: ITransactionGroup) => {
    batch(() => {
      onModalOpen();
      onSetModalType(ActionModalType.CREATE);
      onSetModalPayload({
        groupId: group.id,
        rootUnitId: group.rootUnitId,
        modalType: ModalType.CATEGORY,
      });
    });
  };
  const onEditCategory = (
    category: ITransactionCategory,
    rootUnitId: ITransactionGroup['rootUnitId'],
  ) => {
    batch(() => {
      onModalOpen();
      onSetModalType(ActionModalType.EDIT);
      onSetModalPayload({
        ...category,
        rootUnitId,
        units: _.map(category.units, 'id'),
        modalType: ModalType.CATEGORY,
      });
    });
  };
  const onDeleteCategory = (category: ITransactionCategory) => {
    batch(() => {
      onModalApproveOpen();
      onSetModalApprovePayload({ ...category, modalType: ModalType.CATEGORY });
    });
  };

  const onSubmit = (formData: FormDataResult) => {
    if (isEdit) {
      if (payload.modalType === ModalType.GROUP) {
        mutateGroup(MutateActionEnum.UPDATE)({
          id: formData.id,
          rootUnitId: formData.rootUnitId,
          name: formData.name?.trim() || payload.name as string,
          // ...(formData.name !== payload.name && { name: formData.name?.trim() }),
          ...(formData.color !== payload.color && { color: formData.color }),
          ...(formData.description !== payload.description && { description: formData.description }),
        });
      } else {
        mutateCategory(MutateActionEnum.UPDATE)({
          id: formData.id,
          unitIds: formData.unitIds,
          rootUnitId: formData.rootUnitId,
          name: formData.name?.trim() || payload.name as string,
          ...(formData.color !== payload.color && { color: formData.color }),
          // ...(formData.name !== payload.name && { name: formData.name?.trim() }),
        });
      }
    } else {
      if (payload.modalType === ModalType.GROUP) {
        mutateGroup(MutateActionEnum.CREATE)({
          rootUnitId: formData.rootUnitId,
          name: formData.name?.trim() as string,
          color: formData.color as string,
          description: formData.description || '',
        });
      } else {
        mutateCategory(MutateActionEnum.CREATE)({
          unitIds: formData.unitIds,
          rootUnitId: formData.rootUnitId,
          groupId: formData.groupId as number,
          name: formData.name?.trim() as string,
          color: formData.color,
        });
      }
    }
  };

  const onDelete = (formData: FormDataResult) => {
    if (modalApprovePayload.modalType === ModalType.GROUP) {
      mutateGroup(MutateActionEnum.DELETE)({
        id: formData.id as number,
        name: formData.name as string,
      });
    } else {
      mutateCategory(MutateActionEnum.DELETE)({
        id: formData.id as number,
        name: formData.name as string,
      });
    }
  };

  const isGroup = payload.modalType === ModalType.GROUP;
  const modalTitleJSX = isEdit
    ? t(`administration.financialManagement.edit${isGroup ? 'Group' : 'Category'}`)
    : t(`administration.financialManagement.create${isGroup ? 'Group' : 'Category'}`);
  const modalActionTitleJSX =
    t(`administration.financialManagement.youSureWantDelete${isGroup ? 'Group' : 'Category'}`,
      { variables: { name: payload.name as string } });

  const createGroupButton = (
    <Button
      onClick={onCreateGroup}
      prefixIcon={<PlusOutlined />}
      disabled={!checkPermissions([Permission.CLIENT_TRANSACTION_GROUP_CREATE])}
    >
      {t('administration.financialManagement.createGroup')}
    </Button>
  );

  return (
    <div>
      <Filters
        onRefresh={refetch as UnknownType}
        prefixControls={createGroupButton}
        displayOf={{
          count: data?.length || 0,
          totalCount: data?.length || 0,
        }}
      />
      <div className={clsx(styles.layout, { [styles.loading]: isLoading })}>
        <GroupsTable
          groups={data || []}
          loading={isLoading}
          groupColHandlers={{
            onEditGroup,
            onCreateCategory,
          }}
          categoryColHandlers={{
            onEditCategory,
          }}
        />
      </div>
      <Modal
        width={450}
        destroyOnClose
        isOpen={isOpen}
        title={modalTitleJSX}
        onClose={() => onModalClose(false)}
        afterClose={() => onSetModalPayload({})}
        titleIcon={isEdit ? <ToolOutlined style={{ fontSize: 20 }} /> : <PlusOutlined style={{ fontSize: 18 }} />}
      >
        <FormCreateUpdate
          isEdit={isEdit}
          onSubmit={onSubmit}
          initialValues={payload as unknown as InitialValues}
          onDeleteGroup={onDeleteGroup}
          onDeleteCategory={onDeleteCategory}
          checkPermissions={checkPermissions}
          onClose={() => onModalClose(false)}
          loading={isPendingCategory || isPendingGroup}
          businessId={payload?.rootUnitId as number || user?.rootUnitId}
        />
      </Modal>
      <ApproveActionModal
        modalProps={{
          width: 480,
          destroyOnClose: true,
          isOpen: isOpenApproveModal,
          onClose: () => onModalApproveClose(false),
          afterClose: () => onModalApproveClose({}),
        }}
        formProps={{
          onApprove: onDelete,
          initialData: payload,
          cancelButtonText: t('no'),
          approveButtonText: t('yes'),
          actionText: modalActionTitleJSX,
          loading: isPendingCategory || isPendingGroup,
          onCancel: () => onModalApproveClose(false),
        }}
      />
    </div>
  );
};

export default FinancialManagement;
