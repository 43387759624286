import React, { PropsWithChildren, useMemo } from 'react';
import { StorageType } from 'components/ColdStorage/types';
import { useTranslate } from 'hooks';
import { Button } from 'components';
import { SlidersOutlined } from '@ant-design/icons';
import styles from './CardWithTable.module.scss';

type CardWithTableProps = PropsWithChildren & {
  titleKey: string;
};

const titleMap = new Map([
  [StorageType.Ledger, 'Ledger'],
  [StorageType.Trezor, 'Trezor'],
]);

export const CardWithTable = ({
  children,
  titleKey,
}: CardWithTableProps) => {
  const { t } = useTranslate();
  // const { activeTab } = useColdStorageContext();
  const activeTab = StorageType.Trezor;

  const title = useMemo(() => {
    if (!activeTab || !titleMap.has(activeTab)) {
      return null;
    }
    return titleMap.get(activeTab);
  }, [activeTab]);

  if (!activeTab) {
    return null;
  }

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        {title && (
          <span className={styles.cardTitle}>{t(titleKey, { variables: { wallet: title } })}</span>
        )}
        <div style={{ display: 'flex' }}>
          <Button className={styles.addFilterButton} prefixIcon={<SlidersOutlined />} type="link">
            {t('coldStorage.addWallet')}
          </Button>
          <Button className={styles.addFilterButton} prefixIcon={<SlidersOutlined />} type="link">
            {t('addFilter')}
          </Button>
        </div>
      </div>
      <div className={styles.cardBody}>{children}</div>
    </div>
  );
};
