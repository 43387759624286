import React, { useCallback, useState } from 'react';
import { Divider, Form, Input, Tooltip } from 'antd';
import clsx from 'clsx';
import { IReplenishment } from 'interfaces/IReplenishment';
import { IUser } from 'interfaces/IUser';
import { UnknownType } from 'types/Unknown';
import { IUnitTree } from 'components/TreeUnitsSelect/types';
import { BusinessSelect } from 'pages/Administration/FinancialManagement/components';
import WithCustomWrapper from './hocs/WithCustomWrapper';
import { useCurrencyRate, useTranslate } from 'hooks';
import { useConfirmRootAdmin } from 'hooks/useConfirmRootAdmin';
import { ApproveReplenishmentRequestVariables } from '../../hooks/useApproveReplenishmentRequest';
import { RejectReplenishmentRequestVariables } from '../../hooks/useRejectReplenishmentRequest';
import { useUnitsTree } from 'components/TreeUnitsSelect/hooks';
import { useAuthorisationContext } from 'contexts';
import findElementById from 'components/TreeUnitsSelect/utils/findElementById';
import { Button, CountUp, TreeUnitsSelect } from 'components';
import CurrencyCell from 'components/CurrencyCell';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styles from './FormVerifyRequest.module.scss';

export type FormData = UnknownType;

const FormVerifyRequest = ({
  initialValues,
  onApprove,
  onReject,
  loading,
}: {
  loading: boolean;
  initialValues: IReplenishment;
  onApprove: (payload: ApproveReplenishmentRequestVariables) => void;
  onReject: (payload: RejectReplenishmentRequestVariables) => void;
}) => {
  const { user } = useAuthorisationContext();
  const { isRoot } = useConfirmRootAdmin();
  const [form] = Form.useForm();
  const { t } = useTranslate();

  const [businessId, setBusinessId] = useState<number | undefined>((user as IUser).rootUnitId || undefined);
  const [selectedUnit, setSelectedUnit] = useState<IUnitTree | undefined>(undefined);

  const { walletBalance, isLoading: rateLoading } = useCurrencyRate();

  const { data, isLoading } = useUnitsTree({
    rootUnitId: businessId,
    balances: true,
    nested: true,
  });

  const handleFinish = (formData: UnknownType) => {
    const selectedUnitBalance = (selectedUnit?.wallets || []).filter(wallet => (
      wallet.network === initialValues.network && wallet.currency === initialValues.currency
    ))[0]?.balance;

    if (selectedUnitBalance < initialValues.amount) {
      form.setFields([{ name: 'newUnitId', errors: [t('users.replenishmentRequests.notEnoughBalanceOnUnit')] }]);
    } else {
      form.setFields([{ name: 'newUnitId', errors: [] }]);

      onApprove({
        id: initialValues.id,
        unitId: formData.newUnitId,
      });
    }
  };

  const handleReject = () => onReject({ id: initialValues.id });

  const handleSelectUnit = useCallback((id: (string | number | undefined)[]) => {
    if (!data) return;
    const targetId = id[0];
    form.setFieldValue('newUnitId', targetId);
    setSelectedUnit(targetId ? findElementById(data, targetId) : undefined);
  }, [data, form]);

  return (
    <Form
      form={form}
      name="verify-replenishment-request"
      initialValues={initialValues}
      onFinish={handleFinish}
      className={styles.form}
    >
      <div className={styles.alignItems}>
        <Form.Item
          name="network"
          label={t('users.replenishmentRequests.currency')}
          className={styles.formItem}
        >
          <WithCustomWrapper>
            {({ value }) => <CurrencyCell network={value} currency={initialValues.currency} />}
          </WithCustomWrapper>
        </Form.Item>

        <Form.Item
          name="amount"
          help={!rateLoading ? (
            <CountUp
              prefix="≈ "
              isRoundDown
              suffix=" USDT"
              currency="USDT"
              end={walletBalance([{ currency: initialValues.currency, balance: initialValues.amount }], 'USDT')}
            />
          ) : '≈ 00.00 USDT'}
          label={t('users.replenishmentRequests.amount')}
          className={styles.formItem}
        >
          <WithCustomWrapper>
            {({ value }) => <CountUp end={Number(value)} currency={initialValues.currency} decimal="." />}
          </WithCustomWrapper>
        </Form.Item>
      </div>

      <Form.Item
        name="walletAddress"
        label={t('users.replenishmentRequests.walletAddress')}
        className={styles.formItem}
      >
        <Input.TextArea
          readOnly
          wrap="soft"
          placeholder={t('users.replenishmentRequests.walletAddress')}
          autoSize={{ minRows: 1, maxRows: 2 }}
          className={clsx(styles.input, styles.textArea)}
        />
      </Form.Item>

      {initialValues.description && (
        <Form.Item
          name="description"
          label={t('administration.financialManagement.fields.description')}
          className={styles.formItem}
        >
          <Input
            readOnly
            placeholder={t('administration.financialManagement.fields.description')}
            className={styles.input}
          />
        </Form.Item>
      )}

      <Divider className={styles.divider} style={{ marginBottom: 10 }} />

      {isRoot && (
        <Form.Item
          name="businessId"
          label={t('business.title')}
          rules={[{ required: true, message: t('selectBusiness') }]}
          className={styles.formItem}
        >
          <BusinessSelect
            onChange={setBusinessId}
            inputPlaceholder={t('selectBusiness')}
            className={styles.businessSelect}
          />
        </Form.Item>
      )}

      <div className={styles.alignItems}>
        <Form.Item
          name="newUnitId"
          label={t('users.replenishmentRequests.payerUnit')}
          rules={[{ required: true, message: t('administration.financialManagement.fields.selectUnits') }]}
          className={styles.formItem}
        >
          <WithCustomWrapper>
            {() => (
              <TreeUnitsSelect
                data={data || []}
                isLoading={isLoading}
                onSelect={handleSelectUnit}
                itemIndicatorType="radio"
                isMultiply={false}
                withSelectAllButton={false}
                disableDeactivatedUnits
                dropdownAlign={[-15, 15]}
                unitId={String(initialValues.unitId)}
                selection={false}
              >
                {({ isOpen, setIsOpen }) => (
                  <div className={styles.unitSelect} onClick={()=>setIsOpen(!isOpen)}>
                    <span className={selectedUnit?.name ? styles.value : styles.placeholder}>
                      {selectedUnit?.name || t('administration.financialManagement.fields.selectUnits')}
                    </span>
                  </div>
                )}
              </TreeUnitsSelect>
            )}
          </WithCustomWrapper>
        </Form.Item>

        <Form.Item
          name="unit"
          label={t('users.replenishmentRequests.requesterUnit')}
          className={styles.formItem}
        >
          <WithCustomWrapper>{({ value }) => value.name}</WithCustomWrapper>
        </Form.Item>
      </div>

      <Divider className={styles.divider} style={{ marginTop: 10, marginBottom: 20 }} />

      <div className={styles.buttonWrapper}>
        <Tooltip title={t('users.replenishmentRequests.approve')} placement="bottom">
          <Button
            block
            htmlType="submit"
            loading={loading}
            prefixIcon={<CheckOutlined />}
          >
            {t('processing.paymentOrders.approveBtn')}
          </Button>
        </Tooltip>

        <Tooltip title={t('users.replenishmentRequests.decline')} placement="bottom">
          <Button
            block
            color="error"
            loading={loading}
            onClick={handleReject}
            prefixIcon={<CloseOutlined />}
          >
            {t('processing.paymentOrders.declineBtn')}
          </Button>
        </Tooltip>
      </div>
    </Form>
  );
};

export default FormVerifyRequest;
