import { darken, lighten, parseToHsl, toColorString } from 'polished';

export const BRAND_COLOR = process.env.REACT_APP_BRAND_COLOR || '#1DA1F2';

export const initTheme = (brandColor: string) => {
  const root = document.documentElement;

  root.style.setProperty('--brand-color', brandColor);
  root.style.setProperty('--brand-active-color', darken(0.1, brandColor));
  root.style.setProperty('--brand-hover-color', lighten(0.1, brandColor));
  root.style.setProperty('--brand-light-color', lighten(0.3, brandColor));
  root.style.setProperty('--brand-ultra-light-color', toColorString({ ...parseToHsl(brandColor), alpha: 0.1 }));
};
