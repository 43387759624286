import React, { useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Permission } from '@fanckler/processing-auth';
import { AxiosError } from 'libs/axios';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { notification } from 'antd';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { NestedUnitType } from 'interfaces/IUnit';
import { UnknownType } from 'types/Unknown';
import { ActionModalType } from 'components/Modal/types/Modal';
import { MassWithdrawBodyType } from 'components/UploadPOForm/hooks/types';
import { setReplenishmentRequest } from 'pages/Administration/Units/actions';
import { FormWithdraw } from 'pages/Administration/Units/components/FormWithdraw';
import { WithdrawFormDataReturned } from 'pages/Administration/Units/components/FormWithdraw/FormWithdraw';
import { useCreatePayment } from 'pages/Administration/Units/hooks/useCreatePayment';
import { useRequestReplen } from 'pages/Administration/Units/hooks/useRequestReplen';
import { useTranslate } from 'hooks';
import { StageEnum } from 'hooks/use2FA';
import { useColdStorageHardwareId } from 'components/ColdStorage/hooks';
import { useModal } from 'components/Modal/hooks/useModal';
import { useMassWithdraw } from 'components/UploadPOForm/hooks';
import { useAuthorisationContext } from 'contexts';
import { Button, Modal, UploadPOForm, With2FA } from 'components';
import { ActionDropdown } from './components/ActionDropdown';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as PaymentsIcon } from 'assets/icons/Payments.svg';
import styles from './Actions.module.scss';

type ActionsProps = {
  disabled?: boolean;
  onRefresh: () => Promise<void>;
  unit: NestedUnitType | undefined
  isLoading?: boolean;
};

const Actions = ({
  unit,
  disabled,
  onRefresh,
  isLoading,
}: ActionsProps) => {
  const [formData, setFormData] = useState<WithdrawFormDataReturned | MassWithdrawBodyType | null>(null);
  const hardwareId = useColdStorageHardwareId();
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const {
    isOpen,
    isEdit,
    isCreate,
    isUpload,
    onModalOpen,
    onModalClose,
    onSetModalType,
    onSetModalPayload,
  } = useModal('unitWallets');

  const {
    refreshUser,
    checkPermissions,
    twoFA: {
      onError,
      onSuccess,
      stage,
      setStage,
      isDisabled,
      setDisabled,
      setPrevStage,
    },
  } = useAuthorisationContext();

  const createPayment = () => {
    if (isLoading) return;
    batch(() => {
      onModalOpen();
      onSetModalType(ActionModalType.CREATE);
    });
  };

  const uploadPayments = () => {
    batch(() => {
      onModalOpen();
      onSetModalType(ActionModalType.UPLOAD);
    });
  };

  const requestReplen = () => {
    batch(() => {
      onModalOpen();
      onSetModalType(ActionModalType.EDIT);
    });
  };

  const {
    massWithdrawMutate,
    isMassWithdrawLoading,
  } = useMassWithdraw({
    onSuccess: async (response, variables) => {
      setFormData(variables.body);

      onSuccess(response, () => {
        onModalClose();
        if (isUpload) {
          onSetModalType(ActionModalType.UPLOAD);
        }
        refreshUser();
        onRefresh();

        if (response.status === 'ERROR') {
          response.data.forEach(item => {
            notification.error({
              message: item.error,
              description: (
                <div className={styles.notifyDesc}>
                  <span className={styles.address}>{item.walletAddress}</span>

                  <div className={styles.amountPayway}>
                    <span className={styles.amount}>{item.amount}</span>
                    <span className={styles.payway}>{item.payway}</span>
                  </div>
                </div>
              ),
              duration: 10,
            });
          });
        } else {
          notification.success({
            message: t('processing.paymentOrders.allPaymentsCreated'),
          });
        }
      });
    },
    onError: async (error, variables) => {
      setFormData(variables.body);

      onError(error, () => {
        notification.error({
          description: error?.response?.data?.message,
          message: error?.response?.statusText,
        });
      });
    },
  });

  const { mutateCreatePayment, isCreating } = useCreatePayment({
    onSuccess: async (data) => {
      await cleverRefetchQueries('transactions');
      cleverRefetchQueries('unit-total-balances');
      setPrevStage(StageEnum.DEFAULT);

      onSuccess(data, () => {
        onModalClose();
        refreshUser();
        onRefresh();

        notification.success({
          message: t('processing.paymentOrders.paymentCreated'),
        });
      });
    },
    onError: async (data) => {
      onError(data, () => {
        notification.error({
          description: data?.response?.data?.message,
          message: data?.response?.statusText,
        });
      });
    },
  });

  const { mutateRequestReplen, isRequesting } = useRequestReplen({
    onSuccess: async () => {
      onModalClose();
      dispatch(setReplenishmentRequest(true));

      notification.success({
        message: t('replanishment.requestSent'),
      });
    },
    onError: (e: AxiosError) => {
      const { message, error } = e.response?.data as { message: string; error: string };
      notification.error({ message: error, description: message });
    },
  });

  const wallets = useMemo(() => unit?.wallets || [], [unit]);

  const handleSubmit = (data: WithdrawFormDataReturned & MassWithdrawBodyType) => {
    if (!unit) return;

    if (isEdit) {
      mutateRequestReplen({
        body: {
          unitId: unit.id,
          group: data.group,
          network: data.network,
          currency: data.currency,
          category: data.category,
          amount: Number(data.amount),
          description: data.description,
          walletAddress: data.walletAddress,
        },
      });
    } else if (isCreate) {
      setFormData(data);

      mutateCreatePayment({
        uuid: unit.uuid,
        body: {
          hardwareId: hardwareId || undefined,
          group: data.group,
          feeValue: data.feeValue,
          totalFee: data.totalFee,
          gasPrice: data.gasPrice,
          currency: data.currency,
          amount: data.amount,
          otpCode: data.otpCode,
          category: data.category,
          annotation: data.annotation,
          walletAddress: data.walletAddress,
          payway: data.network === 'Bitcoin' ? 'BTC' : data.network,
        },
      });
    } else {
      massWithdrawMutate({
        uuid: unit.uuid,
        body: {
          data: data.data,
          otpCode: data.otpCode,
        },
      });
    }
  };

  const handleSubmitCode = (code: string) => {
    if (!isEmpty(formData)) {
      setDisabled(true);
      handleSubmit({ ...formData as UnknownType, otpCode: code });
    }
  };

  const modalTitle = isEdit
    ? t('replanishment.requestReplanishment')
    : t('processing.paymentOrders.createPayment');

  return (
    <>
      {checkPermissions([Permission.CLIENT_PAYMENT_ORDER_CREATE]) && (
        <Button
          type="default"
          withoutWaveEffect
          disabled={disabled}
          onClick={createPayment}
          suffixIcon={<PlusOutlined />}
          className={clsx(styles.headerButton, { [styles.disabled]: isLoading })}
        >
          {t('processing.paymentOrders.createPayment')}
        </Button>
      )}
      <ActionDropdown
        isLoading={isLoading}
        wallets={wallets}
        onRequestReplanishment={requestReplen}
        onMassPayout={uploadPayments}
        disabled={disabled}
      />
      <Modal
        width={isUpload && stage === StageEnum.DEFAULT ? 1200 : 450}
        destroyOnClose
        isOpen={isOpen}
        title={isUpload ? null : modalTitle}
        titleIcon={isUpload ? null : <PaymentsIcon width={26} />}
        onClose={() => {
          onModalClose(false);
          setStage(StageEnum.DEFAULT);
          setPrevStage(StageEnum.DEFAULT);

          if (isUpload) {
            onSetModalType(ActionModalType.UPLOAD);
          }
        }}
        afterClose={() => {
          onSetModalType(ActionModalType.DEFAULT);
          onSetModalPayload({});
          setFormData(null);
        }}
        className={isUpload ? styles.modal : undefined}
      >
        <With2FA onSubmit={handleSubmitCode} isLoading={isCreating || isDisabled}>
          {stage === StageEnum.DEFAULT && (
            isUpload ? (
              <UploadPOForm
                onRefresh={onRefresh}
                onClose={() => {
                  onModalClose(false);
                  if (isUpload) {
                    onSetModalType(ActionModalType.UPLOAD);
                  }
                }}
                onSubmit={handleSubmit as UnknownType}
                isLoading={isMassWithdrawLoading}
              />
            ) : (
              <FormWithdraw
                {...formData && { initialValues: formData } as UnknownType}
                unitId={unit?.uuid}
                isWithdraw={isCreate}
                onSubmit={handleSubmit}
                isLoading={isCreating || isRequesting}
                onClose={onModalClose}
                wallets={wallets}
              />
            )
          )}
        </With2FA>
      </Modal>
    </>
  );
};

export default Actions;
