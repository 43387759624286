import React, { PropsWithChildren } from 'react';
import { Button } from 'components/Button';
import styles from './SmallButton.module.scss';

type SmallButtonProps = PropsWithChildren & {
  disabled?: boolean
  loading?: boolean
  onClick?: () => void;
  suffixIcon?: React.ReactNode;
};

const SmallButton = ({ disabled, loading, onClick, children, suffixIcon }: SmallButtonProps) => {
  return (
    <Button
      type="default"
      textSize="14px"
      disabled={disabled}
      onClick={onClick}
      suffixIcon={suffixIcon}
      className={styles.button}
      loading={loading}
    >
      {children}
    </Button>
  );
};

export default SmallButton;
