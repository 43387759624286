import React, { lazy } from 'react';
import { Permission } from '@fanckler/processing-auth';
import lazyRetry from 'setup/lazyRetry';
import { IRoute } from './interfaces/IRoute';
import { Path } from './interfaces/Path';

const Units = lazy(() => lazyRetry(() => import('pages/Administration/Units/Units')));
const Managers = lazy(() => lazyRetry(() => import('pages/Administration/Managers/Managers')));
const Manager = lazy(() => lazyRetry(() => import('pages/Administration/Manager/Manager')));
const AdministrationGroups = lazy(() => lazyRetry(() => import('pages/Administration/AdministrationGroups/AdministrationGroups')));
const ReplenishmentRequests = lazy(() => lazyRetry(() => import('pages/Administration/ReplenishmentRequests/ReplenishmentRequests')));
const FinancialManagement = lazy(() => lazyRetry(() => import('../pages/Administration/FinancialManagement/FinancialManagement')));
const Businesses = lazy(() => lazyRetry(() => import('pages/Administration/Businesses/Businesses')));
const Statistics = lazy(() => lazyRetry(() => import('pages/Administration/Statistics/Statistics')));
const ExchangeModule = lazy(() => lazyRetry(() => import('pages/Administration/ExchangeModule/ExchangeModule')));
const NestedUnit = lazy(() => lazyRetry(() => import('pages/Administration/Units/components/NestedUnit/NestedUnit')));

const administrationRoutes: IRoute[] = [
  {
    path: `${Path.ADMINISTRATION_MANAGERS}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.ADMIN_IAM_USER_READ],
    component: () => <Managers />,
  },
  {
    path: `${Path.ADMINISTRATION_MANAGERS_UUID}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.ADMIN_IAM_USER_READ],
    component: () => <Manager />,
  },
  {
    path: `${Path.ADMINISTRATION_FIN_MANAGEMENT}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [
      Permission.CLIENT_TRANSACTION_GROUP_READ,
      Permission.CLIENT_TRANSACTION_CATEGORY_READ,
    ],
    component: () => <FinancialManagement />,
  },
  {
    path: `${Path.ADMINISTRATION_ROLES}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.ADMIN_IAM_GROUP_READ],
    component: () => <AdministrationGroups />,
  },
  {
    path: `${Path.ADMINISTRATION_BUSINESSES}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.ADMIN_IAM_ROOT_UNIT_READ],
    component: () => <Businesses />,
  },
  {
    path: Path.ADMINISTRATION_REPLENISHMENT_REQUESTS,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_UNIT_READ],
    component: () => <ReplenishmentRequests />,
  },
  {
    path: Path.ADMINISTRATION_BUSINESSES_UNITS,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_UNIT_READ],
    component: () => <Units />,
  },
  {
    path: Path.ADMINISTRATION_BUSINESSES_UNITS_UNIT_IDS,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_UNIT_READ],
    component: () => <NestedUnit />,
  },
  {
    path: Path.ADMINISTRATION_STATISTICS_ROOT_UNIT_ID,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_UNIT_READ],
    component: () => <Statistics />,
  },
  {
    path: Path.ADMINISTRATION_EXCHANGE_MODULE,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_EXCHANGE_READ],
    component: () => <ExchangeModule />,
  },
];

export default administrationRoutes;
