import {
  RemoveColdStorageWallet,
  SetColdStorageConnectedDeviceId,
  SetColdStorageDevice,
  SetColdStorageLastConnectedDeviceId,
  SetColdStorageMode,
  SetColdStorageWallets } from '../types';

export const setColdStorageWallets = (payload: SetColdStorageWallets['payload']): SetColdStorageWallets => ({
  type: 'SET_COLD_STORAGE_WALLETS',
  payload,
});

export const removeColdStorageWallet = (payload: RemoveColdStorageWallet['payload']): RemoveColdStorageWallet => ({
  type: 'REMOVE_COLD_STORAGE_WALLET',
  payload,
});

export const setColdStorageDevice = (payload: SetColdStorageDevice['payload']): SetColdStorageDevice => ({
  type: 'SET_COLD_STORAGE_DEVICE',
  payload,
});

export const setColdStorageMode = (payload: SetColdStorageMode['payload']): SetColdStorageMode => ({
  type: 'SET_COLD_STORAGE_MODE',
  payload,
});

export const setColdStorageConnectedDeviceId = (payload: SetColdStorageConnectedDeviceId['payload']): SetColdStorageConnectedDeviceId => ({
  type: 'SET_COLD_STORAGE_DEVICE_ID',
  payload,
});

export const setColdStorageLastConnectedDeviceId = (payload: SetColdStorageLastConnectedDeviceId['payload']): SetColdStorageLastConnectedDeviceId => ({
  type: 'SET_COLD_STORAGE_LAST_CONNECTED_DEVICE_ID',
  payload,
}); // TODO maybe make as internal var (not changeable from outside)
