import { Currencies } from 'components/ColdStorage/types';

export const TREZOR_NETWORKS = [
  Currencies.BITCOIN,
  Currencies.ETHEREUM,
];

export const NETWORKS_TO_CURRENCY_AND_NETWORK_MAP = new Map([
  ['Bitcoin', { currency: 'BTC', network: 'Bitcoin' }],
  ['Ethereum', { currency: 'ETH', network: 'ERC20' }],
  ['Tron', { currency: 'TRX', network: 'TRC20' }],
  ['Litecoin', { currency: 'LTC', network: 'Litecoin' }],
  ['Dogecoin', { currency: 'DOGE', network: 'Dogecoin' }],
  ['Binance Smart Chain', { currency: 'BNB', network: 'BEP20' }],
]);
