import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IWhitelistWallet } from 'interfaces';
import { Filter } from 'types/Filter';
import { Sort } from 'types/Sort';

export type GetWhitelistParams = {
  uuid: string | undefined;
  sorting?: Sort<IWhitelistWallet>[];
  filter?: Filter<Record<string, unknown>>;
  paging?: { limit: number; offset?: number };
};

const useGetWhitelist = ({ uuid, ...params }: GetWhitelistParams) => {
  return useQuery<{ totalCount: number, nodes: IWhitelistWallet[] }>({
    enabled: Boolean(uuid),
    queryKey: ['whitelist', uuid, params],
    queryFn: async () => {
      const response = await axios.get(`${SERVICE.CLIENT}/whitelist-wallets/${uuid}`, { params });

      return {
        totalCount: response.data.totalCount || 0,
        nodes: response.data.nodes || [],
      };
    },
  });
};

export default useGetWhitelist;
