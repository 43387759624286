import axios, { AxiosError, SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { UnknownType } from 'types/Unknown';

export type OptionType = {
  onSuccess?: (response: CommisionType) => void;
  onError?: (error: AxiosError<{ message: string }>) => void;
};
export type CommissionQuery = {
  network: string;
  currency: string;
  amount: string | number;
  addressFrom: string;
  destinationAddress: string;
};
export type CommisionType = {
  feeValue: number;
  serviceFee: number;
  gasPrice?: string;
};

export const useCommission = (query: CommissionQuery | null, enabled: boolean, options?: OptionType): UnknownType => {
  const getCommission = async (context: UnknownType) => {
    try {
      const { data } = await axios.get(
        `${SERVICE.CLIENT}/balance/withdraw/service-fee`,
        {
          params: context.queryKey[1] || query,
        },
      );
      return data;
    } catch (error: UnknownType) {
      return {
        serviceFee: 0,
        error: error?.response?.data?.message,
        //   'Can not create BTC payment order, there are no suitable unspents'
      };
    }

  };

  const queryResponse = useQuery<CommisionType>({
    queryKey: ['commission', query],
    queryFn: getCommission,
    enabled,
    ...options,
  });

  const refresh = (newParams?: UnknownType) => {
    return getCommission({
      queryKey: ['commission', newParams],
    });
  };

  return {
    ...(queryResponse),
    refresh,
  };
};
