import React from 'react';
import ReactJson from 'react-json-view';
import { Divider } from 'antd';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import { INotification } from 'interfaces/INotification';
import { NotificationType } from 'components/Notifications/types';
import { useTranslate } from 'hooks';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './NotificationView.module.scss';

const NotificationView = ({ data }: { data: INotification }) => {
  const { t } = useTranslate();

  const formatDate = (date: string) => moment(date).format('DD.MM.YYYY');

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <span
          className={clsx(styles.title, {
            [styles.warning]: data.type === NotificationType.WARNING,
            [styles.error]: data.type === NotificationType.ERROR,
          })}
        >
          <span>{data.title}</span>
          {data.isAlert && (
            <ExclamationCircleOutlined className={styles.alertIcon} />
          )}
        </span>
        <span className={styles.description}>{data.description}</span>
      </div>

      <Divider className={styles.divider} />

      <div className={styles.dates}>
        <div className={styles.label}>
          {t('created')}{' '}
          <span className={styles.value}>{formatDate(data.createdAt)}</span>
        </div>
        {data.readAt && (
          <div className={styles.label}>
            {t('readed')}{' '}
            <span className={styles.value}>{formatDate(data.readAt)}</span>
          </div>
        )}
        {data.updatedAt && (
          <div className={styles.label}>
            {t('updated')}{' '}
            <span className={styles.value}>{formatDate(data.updatedAt)}</span>
          </div>
        )}
        {data.deletedAt && (
          <div className={styles.label}>
            {t('deleted')}{' '}
            <span className={styles.value}>{formatDate(data.deletedAt)}</span>
          </div>
        )}
      </div>

      <div className={styles.payload}>
        {!_.isEmpty(data?.payload) ? (
          <div className={styles.jsonViewer}>
            <ReactJson
              src={data.payload}
              name="payload"
              collapsed={true}
              quotesOnKeys={false}
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
              style={{ fontFamily: 'Proxima Nova' }}
              theme={{
                base00: 'white',
                base01: '#ddd',
                base02: '#ddd',
                base03: '#444',
                base04: 'purple',
                base05: '#444',
                base06: '#444',
                base07: '#444',
                base08: '#444',
                base09: '#64a405',
                base0A: '#777777',
                base0B: 'var(--primary-color)',
                base0C: 'var(--primary-color)',
                base0D: 'var(--primary-color)',
                base0E: '#3333c9',
                base0F: '#630a63',
              }}
            />
          </div>
        ) : (
          <span className={styles.empty}>
            {t('notifications.payloadEmpty')}
          </span>
        )}
      </div>
    </div>
  );
};

export default NotificationView;
