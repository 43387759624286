import React, { useEffect, useMemo, useRef } from 'react';
import ReactCountUp, { CountUpProps } from 'react-countup';
import { Space } from 'antd';
import { truncateNumber } from 'utils';
import { getDecimals } from 'utils/truncateNumber';
import { getCurrencyLabelJSX } from 'components/CurrencySelect/CurrencySelect';

export type CountUpPropTypes = CountUpProps & {
  currency: string;
  styles?: string;
  withIcon?: boolean;
  isRoundDown?: boolean;
};

export const CountUp = ({
  end: value,
  currency = 'BTC',
  withIcon = false,
  isRoundDown,
  ...otherProps
}: CountUpPropTypes) => {
  const decimals = useMemo(() => getDecimals({ currency }), [currency]);

  const isExponential = value.toString().includes('e');

  const newValue = isRoundDown
    ? truncateNumber({
      currency,
      number: isExponential ? value.toFixed(16) : value,
    })
    : isExponential ? value.toFixed(16) : value;

  const previousEndRef = useRef(otherProps.start ?? Number(newValue || 0));

  useEffect(() => {
    if ((otherProps.start || 0) > 0) {
      previousEndRef.current = Number(newValue || 0);
    }
  }, [otherProps.start, newValue]);

  const countUpProps = {
    separator: ' ',
    decimal: ',',
    decimals: parseFloat(String(newValue)) ? decimals : 0,
    end: Number(newValue),
    ...otherProps,
  };

  return (
    <Space>
      {countUpProps.end === 0 ? (
        <span className={countUpProps.className}>
          0,00
        </span>
      ) : (
        <ReactCountUp {...countUpProps} start={previousEndRef.current} />
      )}
      {withIcon ? getCurrencyLabelJSX(currency) : null}
    </Space>
  );
};
