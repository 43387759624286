import React, { useState } from 'react';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { notification } from 'antd';
import { StageEnum } from 'hooks/use2FA';
import useTranslate from 'hooks/useTranslate';
import { useColdStorageHardwareId } from 'components/ColdStorage/hooks';
import { useModal } from 'components/Modal/hooks/useModal';
import { useCreateWallet } from 'components/WalletOverview/hooks';
import { OTP_ERROR } from 'constants/index';
import { useAuthorisationContext } from 'contexts';
import AddSubaccountForm, { FormData } from '../AddSubaccountForm';
import { Button, With2FA } from 'components';
import { Modal } from 'components/Modal';
import { WalletOutlined } from '@ant-design/icons';
import styles from './AddSubaccountButton.module.scss';

export type AddSubaccountButtonProps = {
  unitId: number | undefined;
  disabled?: boolean;
};

const AddSubaccountButton = ({ unitId, disabled }: AddSubaccountButtonProps) => {
  const [savedData, setSavedData] = useState<FormData>();
  const hardwareId = useColdStorageHardwareId();
  const { t } = useTranslate();

  const { isOpen, onModalOpen, onModalClose } = useModal('unit-tab-subaccounts');

  const { twoFA: {
    stage,
    setStage,
    onSuccess,
    onError,
  } } = useAuthorisationContext();

  const { createWallet, isLoading } = useCreateWallet(unitId, {
    onSuccess: (res) => {
      onSuccess(res, async () => {
        await cleverRefetchQueries('subaccounts');

        onModalClose();

        notification.success({
          message: t('coldStorage.successfullyAdded'),
        });
      });
    },
    onError: (error) => {
      onError(error, () => {
        if (stage !== StageEnum.DEFAULT && error.response?.data?.message.includes(OTP_ERROR)) {
          setStage(StageEnum.DEFAULT);
        }
        notification.error({
          message: t('coldStorage.cannotAdded'),
          description: error.response?.data.message,
        });
      });
    },
  });

  const handleSubmit = (data: FormData, otpCode?: string) => {
    if (!otpCode) {
      setSavedData(data);
    }

    createWallet({
      ...data,
      otpCode,
      isSubWallet: true,
      hardwareId: hardwareId || undefined,
    });
  };

  const handleSubmitOtp = (otpCode: string) => {
    if (!savedData) {
      return;
    }
    handleSubmit(savedData, otpCode);
  };

  return (
    <>
      <Button
        type="default"
        textSize="14px"
        disabled={disabled}
        onClick={onModalOpen}
        suffixIcon={<WalletOutlined style={{ fontSize: 16 }} />}
        className={styles.button}
      >
        {t('subaccount.form.title')}
      </Button>

      <Modal
        width={450}
        destroyOnClose
        isOpen={isOpen}
        onClose={onModalClose}
        titleIcon={<WalletOutlined style={{ fontSize: 20 }} />}
        title={t('subaccount.form.title')}
      >
        <With2FA onSubmit={handleSubmitOtp}>
          <AddSubaccountForm
            onSubmit={handleSubmit}
            onClose={onModalClose}
            loading={isLoading}
          />
        </With2FA>
      </Modal>
    </>
  );
};

export default AddSubaccountButton;
