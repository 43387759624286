import React from 'react';
import { BackButton } from 'components/ColdStorage/components';
import { CloseOutlined } from '@ant-design/icons';
import styles from './StepperHeader.module.scss';

type StepperHeaderProps = {
  title?: React.ReactNode,
  subTitle?: React.ReactNode,
  onBack?: () => void,
  onClose?: () => void,
};

const StepperHeader = ({ title, subTitle, onBack, onClose }: StepperHeaderProps) => {
  return (
    <div className={styles.wrapper}>
      {onBack && <BackButton onClick={onBack} className={styles.back} />}

      <div className={styles.titleContainer}>
        {title && <span className={styles.title}>{title}</span>}
        {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
      </div>

      {onClose && <CloseOutlined className={styles.cross} onClick={onClose} />}
    </div>
  );
};

export default StepperHeader;
