import axios, { AxiosError, SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';

type Toggle2FABody = { enable: boolean, otpCode?: string };
type OptionType = {
  onSuccess?: (e: any, variables: Toggle2FABody) => void;
  onError?: (e: AxiosError<{ message: string }>, variables: Toggle2FABody) => void;
};

export const useProfileMutation = (options: OptionType) => {
  const toggle2FAMutate = async (body: Toggle2FABody) => {
    const { data } = await axios.post(`${SERVICE.ADMIN}/users/otp`, body);
    return data;
  };

  const {
    mutate: toggle2FA,
    isPending: isLoading2FA,
  } = useMutation({ mutationFn: toggle2FAMutate, ...options });

  return {
    toggle2FA,
    isLoading2FA,
  };
};
