import React from 'react';
import clsx from 'clsx';
import { RegisterDeviceRequest, RegisterDeviceStatus } from 'components/ColdStorage/ledger/hooks/useRegisterDevice';
import { Button } from 'components';
import { StepProps } from 'components/ColdStorage/ledger/actions/AddAccounts';
import Alert from 'components/ColdStorage/ledger/components/Alert';
import { renderAllowManager, renderError, renderLoading, renderSuccess } from 'components/ColdStorage/ledger/rendering/rendering';
import styles from './StepRegisterDevice.module.scss';

const StepRegisterDevice = ({
  t,
  device,
  deviceName,
  onRegisterDevice,
  registerDeviceState,
}: StepProps) => {
  return (
    <div className={clsx(styles.wrapper, {
      [styles.withFooterHeight]: [
        RegisterDeviceStatus.IDLE,
        RegisterDeviceStatus.SUCCESS,
      ].includes(registerDeviceState.status),
    })}>
      {registerDeviceState.status === RegisterDeviceStatus.IDLE && (
        <>
          <div className={styles.info}>
            <span className={styles.title}>{t('coldStorage.ledger.register.title')}</span>
            <span className={styles.subTitle}>{t('coldStorage.ledger.register.description')}</span>
          </div>

          <Alert>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <span className={styles.alertText}>{t('coldStorage.ledger.register.info.1')}</span>
              <span className={styles.alertText}>{t('coldStorage.ledger.register.info.2')}</span>
            </div>
          </Alert>
        </>
      )}

      {registerDeviceState.status === RegisterDeviceStatus.SUCCESS && device && renderSuccess({
        name: deviceName as string,
        model: device.productName,
        success: registerDeviceState.reason,
        children: (
          <Alert type="warn">
            <span className={styles.alertText}>{t('coldStorage.ledger.register.warn')}</span>
          </Alert>
        ),
      })}

      {registerDeviceState.status === RegisterDeviceStatus.PENDING && (
        <>
          {registerDeviceState.reason === RegisterDeviceRequest.RENAME_DEVICE && device &&
            renderAllowManager({
              text: t('coldStorage.ledger.approveRenamingOnDevice'),
              modelId: device.id,
              type: 'light',
            })}

          {registerDeviceState.reason === RegisterDeviceRequest.GET_DEVICE_NAME && device &&
            renderAllowManager({
              text: t('coldStorage.ledger.approveAccessToDeviceName'),
              modelId: device.id,
              type: 'light',
            })}
        </>
      )}

      {registerDeviceState.status === RegisterDeviceStatus.LOADING && renderLoading({
        children: <span className={styles.loading}>{t('coldStorage.connecting')}...</span>,
      })}

      {registerDeviceState.status === RegisterDeviceStatus.ERROR &&
        renderError({
          error: registerDeviceState.reason,
          onRetry: onRegisterDevice,
          t,
        })}
    </div>
  );
};

export default StepRegisterDevice;

export const StepRegisterDeviceFooter = ({
  t,
  deviceName,
  transitionTo,
  onRegisterDevice,
  registerDeviceState,
}: StepProps) => {
  if (
    registerDeviceState.status !== RegisterDeviceStatus.IDLE &&
    registerDeviceState.status !== RegisterDeviceStatus.SUCCESS
  ) return null;

  const handleContinue = () => {
    if (registerDeviceState.status === RegisterDeviceStatus.SUCCESS) {
      return transitionTo('chooseCurrency');
    }
    onRegisterDevice();
  };

  return (
    <div className={styles.footer}>
      <span className={styles.deviceName}>{deviceName}</span>

      <Button
        type="primary"
        withoutWaveEffect
        onClick={handleContinue}
        className={styles.button}
      >
        {t('continue')}
      </Button>
    </div>
  );
};
