import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'init/rootReducer';
import { NestedUnitType } from 'interfaces';
import { OptionsKeys } from '../types';
import { StorageType } from 'components/ColdStorage/types';
import { useUnitTotalBalances } from 'pages/Administration/Units/hooks';
import { useCurrencyRate, useTranslate } from 'hooks';
import { Wallet } from 'hooks/useCurrencyRate';
import { useColdStorageDevice, useColdStorageHardwareId } from 'components/ColdStorage/hooks';
import { calculateBalance } from '../utils';
import { ReactComponent as LedgerLogo } from 'assets/icons/LedgerLogo.svg';
import { ReactComponent as TrezorLogo } from 'assets/icons/TrezorLogo.svg';

type UseToggleOptionParams = {
  unit: NestedUnitType | undefined;
  currency: string;
};

const useToggleOption = ({ unit, currency }: UseToggleOptionParams) => {
  const [checkedOption, setCheckedOption] = useState(OptionsKeys.External);

  const isColdStorageMode = useSelector((state) => state.coldStorageReducer.isColdStorageMode);
  const { walletBalance, totalBalance, rates } = useCurrencyRate();
  const { t } = useTranslate();
  const { coldStorageDevice } = useColdStorageDevice();
  const hardwareId = useColdStorageHardwareId();

  const {
    data: totalBalances,
    isLoading: isLoadingTotalBalances,
  } = useUnitTotalBalances({
    uuid: unit?.uuid,
    hardwareId,
  });

  const { walletsBalance, groupBalance } = useMemo(() => ({
    walletsBalance: walletBalance((unit?.wallets || []) as Wallet[], currency),
    groupBalance: totalBalance((totalBalances || []) as { [key: string]: number }, currency),
  }), [walletBalance, unit?.wallets, currency, totalBalance, totalBalances]);

  const reducedBalances = useMemo(() => {
    return calculateBalance({
      group: totalBalances || {},
      unit: unit?.wallets || [],
      rates,
      viewType: checkedOption,
      viewCurrency: currency,
    });
  }, [checkedOption, currency, rates, totalBalances, unit?.wallets]);

  const toggleOption = useCallback((value?: OptionsKeys) => {
    if (value) {
      setCheckedOption(value);
    } else {
      setCheckedOption(isColdStorageMode ? OptionsKeys.System : OptionsKeys.Group);
    }
  }, [isColdStorageMode]);

  const balance = useMemo(() => {
    if (checkedOption === OptionsKeys.External) {
      return 0; // TODO: add external balance
    }

    return checkedOption === OptionsKeys.Group || checkedOption === OptionsKeys.System ? groupBalance : walletsBalance;
  }, [checkedOption, groupBalance, walletsBalance]);

  const title = useMemo(() => {
    if (coldStorageDevice && isColdStorageMode) {
      if (coldStorageDevice.type === StorageType.Ledger) {
        return <span><LedgerLogo /> Ledger</span>;
      } else if (coldStorageDevice.type === StorageType.Trezor) {
        return <span><TrezorLogo /> Trezor</span>;
      }
    }
    return t('balance');
  }, [coldStorageDevice, isColdStorageMode, t]);

  const wallets = useMemo(() => {
    if (!isColdStorageMode || checkedOption === OptionsKeys.System) {
      return unit?.wallets || [];
    }
    return null;
  }, [checkedOption, isColdStorageMode, unit?.wallets]);

  const togglerOptions = useMemo(() => {
    return [
      { value: OptionsKeys.Group, label: t('group'), isColdStorageOption: false },
      { value: OptionsKeys.Unit, label: t('users.units.one'), isColdStorageOption: false },
      { value: OptionsKeys.System, label: t('system.title'), isColdStorageOption: true },
      { value: OptionsKeys.External, label: t('processing.paymentOrders.external'), isColdStorageOption: true, disabled: !coldStorageDevice },
    ].filter((option) => option.isColdStorageOption === isColdStorageMode);
  }, [isColdStorageMode, t, coldStorageDevice]);

  useEffect(() => {
    toggleOption();
  }, [toggleOption]);

  return {
    wallets,
    balance,
    title,
    togglerOptions,
    checkedOption,
    toggleOption,
    isLoading: isLoadingTotalBalances,
    reducedBalances,
  };
};

export default useToggleOption;
