import { IStatus } from '../../interfaces/IStatus';
import { InvoiceStatusEnum } from 'types/InvoiceStatusEnum';
import { ColorEnum } from '../../types';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import {
  HourglassOutlined,
  LoadingOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import {
  ReactComponent as SmileSadOutlined,
} from 'assets/icons/SmileSadOutlined.svg';

export const getInvoiceStatuses = (t: TranslateFunc): IStatus[] => [
  {
    status: InvoiceStatusEnum.AWAITING_PAYMENT,
    title: 'Awaiting payment',
    color: ColorEnum.PENDING,
    icon: HourglassOutlined,
  },
  {
    status: InvoiceStatusEnum.PROCESSING,
    title: 'Processing',
    color: ColorEnum.PENDING,
    icon: LoadingOutlined,
  },
  {
    status: InvoiceStatusEnum.IN_FINALIZATION,
    title: 'In finalization',
    color: ColorEnum.PENDING,
    icon: LoadingOutlined,
  },
  {
    status: InvoiceStatusEnum.TRANSFERRED,
    title: 'Transferred',
    color: ColorEnum.SUCCESS,
    icon: SmileOutlined,
  },
  {
    status: InvoiceStatusEnum.TRANSFERRING,
    title: 'Transferring',
    color: ColorEnum.PENDING,
    icon: HourglassOutlined,
  },
  {
    status: InvoiceStatusEnum.PARTIALLY_PAID,
    title: 'Partially paid',
    color: ColorEnum.PENDING,
    icon: HourglassOutlined,
  },
  {
    status: InvoiceStatusEnum.COMPLETED,
    title: 'Completed',
    color: ColorEnum.SUCCESS,
    icon: SmileOutlined,
  },
  {
    status: InvoiceStatusEnum.OVERPAID,
    title: 'Overpaid',
    color: ColorEnum.SUCCESS,
    icon: SmileOutlined,
  },
  {
    status: InvoiceStatusEnum.CLOSED,
    title: 'Closed',
    color: ColorEnum.ERROR,
    icon: SmileSadOutlined,
  },
];
