import React, { useCallback, useMemo } from 'react';
import { AxiosError } from 'libs/axios';
import { Checkbox, Divider, notification, Space, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { noop } from 'lodash';
import { IPermission } from 'interfaces/IPermission';
import { UnknownType } from 'types/Unknown';
import { formatPermissions } from 'pages/Administration/AdministrationGroups/utils/formatPermissions';
import { usePermissions, useTranslate } from 'hooks';

export interface PermissionsSelectProps<T extends IPermission> {
  initialValues: T[];
  handlers?: {
    add: (callback: (state: T[]) => T[]) => void;
    remove: (callback: (state: T[]) => T[]) => void;
  };
}

export const categorySettings = [
  [
    'transactions',
    ['transaction', 'transaction_payout', 'transaction_payin'],
  ],
  ['financialManagment', ['transaction_group', 'transaction_category']],
];

export const PermissionsList = ({
  initialValues,
  handlers: { add, remove } = { add: noop, remove: noop },
}: PermissionsSelectProps<IPermission>) => {
  const { t } = useTranslate();

  const handleErrorPermissions = useCallback((e: AxiosError<{ message: string }>) => {
    notification.error({
      message: e.response?.data?.message || e.message,
    });
  }, []);

  const { data, isLoading } = usePermissions('', {
    onError: handleErrorPermissions,
  });

  const permissions = useMemo(() => data?.nodes || [], [data?.nodes]);

  const handleChange = useCallback((item: IPermission) => (event: CheckboxChangeEvent) => {
    const checked = event.target.checked;
    const initialChecked = initialValues.find((permission) => permission.id === item.id);

    add((state: IPermission[]) => {
      if (checked && !initialChecked) {
        return [...state, item];
      }
      return [...state].filter((i) => i.id !== item.id);
    });

    remove((state: IPermission[]) => {
      if (!checked && initialChecked) {
        return [...state, item];
      }
      return [...state].filter((i) => i.id !== item.id);
    });
  }, [add, initialValues, remove]);

  const groupedPermissions = formatPermissions(
    permissions || [],
    categorySettings as UnknownType,
  );

  if (isLoading) {
    return (
      <div style={{ margin: 8, display: 'flex', justifyContent: 'center' }}>
        <Spin />
      </div>
    );
  }

  return (
    <Space
      direction="vertical"
      style={{ maxHeight: 500, overflowY: 'auto', overflowX: 'hidden', paddingRight: 16, width: '100%' }}
    >
      {groupedPermissions?.map((item) => {
        return (
          <Space key={item?.group} direction="vertical" style={{ width: '100%' }}>
            <h2 style={{ fontSize: 16, fontWeight: 600, marginBottom: 0 }}>
              {t(`permissionsList.${item.group}`)}
            </h2>
            {item.category.map((category) => {
              return (
                <Space
                  key={category?.name}
                  direction="vertical"
                  style={{ marginTop: 8, overflowX: 'hidden' }}
                >
                  <h3 style={{ color: '#333333a3', fontSize: 14, fontWeight: 600 }}>
                    {t(`permissionsList.${category?.name}`)}
                  </h3>
                  {category?.permissions?.map((perm) => {
                    return (
                      <Space
                        key={category?.name + perm?.name}
                        direction="vertical"
                        style={{ marginLeft: 8 }}
                      >
                        <Checkbox
                          defaultChecked={initialValues?.some((v) => v.id === perm?.id)}
                          onChange={handleChange(perm)}
                        >
                          {t(perm?.name)}
                        </Checkbox>
                      </Space>
                    );
                  })}
                </Space>
              );
            })}
            <Divider />
          </Space>
        );
      })}
    </Space>
  );
};
