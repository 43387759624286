import { ColumnsType } from 'antd/lib/table';
import { ILog } from 'interfaces/ILog';
import { LogActionEnum } from 'types/LogAction';
import { useTranslate } from 'hooks';
import { DateTime, StatusBadge } from 'components';

export const useGetColumns = (): ColumnsType<ILog> => {
  const { t } = useTranslate();

  const columns: ColumnsType<ILog> = [
    {
      key: 'action',
      title: t('myAccount.columns.action'),
      dataIndex: 'action',
      render: (action: LogActionEnum) => (
        <StatusBadge status={action} textTransform="uppercase" />
      ),
    },
    {
      key: 'ip',
      title: t('myAccount.columns.ipAddress'),
      dataIndex: 'ip',
    },
    // TODO: THERE IS NO BROWSER INFO FROM BE
    // {
    //   key: 'browser',
    //   title: 'Browser',
    //   dataIndex: 'browser',
    // },
    {
      key: 'createdAt',
      title: t('myAccount.columns.date'),
      dataIndex: 'createdAt',
      render: (date) => <DateTime value={date} />,
    },
  ];

  return columns;
};
