import Transport, { StatusCodes } from '@ledgerhq/hw-transport';
import { APDU } from '../types/ADPU';
import parseGetDeviceNameResponse from './parseGetDeviceNameResponse';

const GET_DEVICE_NAME_APDU: APDU = [0xe0, 0xd2, 0x00, 0x00, Buffer.from([])];

const getDeviceName = async (transport: Transport): Promise<string> => {
  const res = await transport.send(...GET_DEVICE_NAME_APDU, [
    StatusCodes.OK,
    StatusCodes.DEVICE_NOT_ONBOARDED,
    StatusCodes.DEVICE_NOT_ONBOARDED_2,
  ]);

  return parseGetDeviceNameResponse(res);
};

export default getDeviceName;
