export enum StatusVariant {
  Loading = 'coldStorage.waitingToConnect',
  Connecting = 'coldStorage.connecting',
  Success = 'coldStorage.connected',
  Error = 'coldStorage.noConnect',
}

export enum Currencies {
  ETHEREUM = 'Ethereum',
  BITCOIN = 'Bitcoin',
  TRON = 'Tron',
  DOGECOIN = 'Dogecoin',
  LITECOIN = 'Litecoin',
}

export enum StorageType {
  Ledger = 'Ledger',
  Trezor = 'Trezor',
}

export type ColdStorageWallet = {
  address: string;
  publicKey: string;
  chainCode?: string;
};

export type ColdStorageConnectedState = {
  id: string;
  name: string; // TODO maybe remove name
  type: StorageType.Ledger | StorageType.Trezor;
} | null;

export type ColdStorageData = {
  id: string;
  name: string;
  type: StorageType;
  lastConnected: string;
  wallets: Record<string, ColdStorageWallet>;
};

export type ColdStorage = {
  isColdStorageMode: boolean;
  connectedDeviceId: string | null;
  lastConnectedDeviceId: string | null;
  storages: Record<string, ColdStorageData>;
};

export type SetColdStorageWallets = {
  type: 'SET_COLD_STORAGE_WALLETS';
  payload: Pick<ColdStorageData, 'id' | 'wallets'>
};

export type RemoveColdStorageWallet = {
  type: 'REMOVE_COLD_STORAGE_WALLET';
  payload: Pick<ColdStorageData, 'id'> & { currency: Currencies };
};

export type SetColdStorageDevice = {
  type: 'SET_COLD_STORAGE_DEVICE';
  payload: Pick<ColdStorageData, 'id' | 'type' | 'name'>;
};

export type SetColdStorageMode = {
  type: 'SET_COLD_STORAGE_MODE';
  payload: boolean;
};

export type SetColdStorageConnectedDeviceId = {
  type: 'SET_COLD_STORAGE_DEVICE_ID';
  payload: string | null;
};

export type SetColdStorageLastConnectedDeviceId = {
  type: 'SET_COLD_STORAGE_LAST_CONNECTED_DEVICE_ID';
  payload: string | null;
};

export type ColdStorageActionType =
  | SetColdStorageWallets
  | RemoveColdStorageWallet
  | SetColdStorageDevice
  | SetColdStorageMode
  | SetColdStorageConnectedDeviceId
  | SetColdStorageLastConnectedDeviceId;
