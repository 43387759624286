import styled from 'styled-components';

export const Box = styled.div<{
  noShrink?: boolean,
  shrink?: boolean,
  grow?: boolean | number,
  flow?: number,
  horizontal?: boolean,
  overflow?: string,
  scroll?: boolean,
  relative?: boolean,
  sticky?: boolean,
  selectable?: boolean,
}>`
  display: flex;
  flex-shrink: ${p => (p.noShrink === true ? '0' : p.shrink === true ? '1' : '')};
  flex-grow: ${p => (p.grow === true ? '1' : p.grow || '')};
  flex-direction: ${p => (p.horizontal ? 'row' : 'column')};

  overflow: ${p => p.overflow};
  overflow-y: ${p => (p.scroll === true ? 'auto' : '')};
  position: ${p => (p.relative ? 'relative' : p.sticky ? 'absolute' : '')};

  ${p => p.selectable && 'user-select: text'};
  right: auto;

  ${p => p.sticky && 'top: 0; left: 0; right: 0; bottom: 0;'};

  > * + * {
    margin-top: ${p => (!p.horizontal && p.flow ? `${p.theme.space[p.flow]}px` : '')};
    margin-left: ${p => (p.horizontal && p.flow ? `${p.theme.space[p.flow]}px` : '')};
  }
`;
