import React from 'react';
import { Tooltip } from 'antd';
import { truncateString } from 'utils';
import styles from './TruncatedTooltip.module.scss';

export type TruncatedTooltipProps = {
  text: string;
  max: number;
};

const TruncatedTooltip = ({ text, max }: TruncatedTooltipProps) => {
  if (text.length <= max) {
    return <>{text}</>;
  }

  const truncatedText = truncateString(text, { type: 'end', startLength: max });

  return (
    <Tooltip title={text} overlayClassName={styles.overlay}>
      {truncatedText}
    </Tooltip>
  );
};

export default TruncatedTooltip;
