import React, { ReactElement } from 'react';
import { useActiveSessionsTable } from './hooks/useActiveSessionsTable';
import Table from 'components/Table/Table';

type Props = {
  userId: number;
};

export const ActiveSessionsTable = ({ userId }: Props): ReactElement => {
  const { columns, isLoading, data } = useActiveSessionsTable({ userId });

  return (
    <Table
      loading={isLoading}
      dataSource={data}
      columns={columns}
      scroll={{ x: 'max-content' }}
      pagination={false}
      rowKey="sessionId"
    />
  );
};
