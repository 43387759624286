import React, { useMemo } from 'react';
import { StepProps } from '../../types';
import { StatusVariant } from 'components/ColdStorage/types';
import { TREZOR_NETWORKS } from 'components/ColdStorage/constants';
import { ContentWithButton } from '../ContentWithButton';
import { SelectWalletCurrencies } from 'components/ColdStorage/components/SelectWalletCurrencies';

const SelectTrezorWallets = ({
  onConnect,
  checkedNetworks,
  connectionStatus,
  retryConnect,
  changeCheckedNetworks,
  isCreatingWallets,
}: StepProps) => {
  const isLoading = useMemo(() => {
    return Boolean(Object.values(connectionStatus).find(
      (status) => status === StatusVariant.Connecting || status === StatusVariant.Loading,
    ));
  }, [connectionStatus]);

  return (
    <ContentWithButton
      onButtonClick={onConnect}
      isLoading={isLoading || isCreatingWallets}
      isDisabled={isLoading || isCreatingWallets}
    >
      <SelectWalletCurrencies
        changeCheckedNetworks={changeCheckedNetworks}
        networks={TREZOR_NETWORKS}
        checkedNetworks={checkedNetworks}
        connectionStatus={connectionStatus}
        onRetry={retryConnect}
      />
    </ContentWithButton>
  );
};

export default SelectTrezorWallets;
