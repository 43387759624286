import { ReactNode } from 'react';
import { filter, head, map } from 'lodash';
import { UnknownType } from 'types/Unknown';
import { ITransactionGroup } from 'pages/Administration/FinancialManagement/api/types';
import renderOption from './renderOption';

const getCategoryOptions = (
  groups: ITransactionGroup[],
  selectedGroup: ITransactionGroup['id'] | null,
): { value: number; label: ReactNode }[] => {
  const filteredGroups = filter(groups, group => group.id === selectedGroup);
  const group = head(filteredGroups);

  return map(group?.categories, category => ({
    value: category.id,
    label: renderOption(category as UnknownType),
  }));
};

export default getCategoryOptions;
