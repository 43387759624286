import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Layout, Space } from 'antd';
import { IUser } from 'interfaces/IUser';
import { useTranslate } from 'hooks';
import { AccountInformation, NotificationSettings, PersonalInfo } from './components';
import { AccountActivity } from './components/AccountActivity';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from './UserAccount.module.scss';

type UserAccountProps = {
  user: IUser;
  setUser?: (user: IUser) => void;
  onSavePersonalInfo?: (values: Partial<IUser>) => Promise<void>;
  onRefreshCounters?: () => Promise<void>;
  withBlockInfo?: boolean;
};

const UserAccount = ({
  user,
  setUser,
  onSavePersonalInfo,
  onRefreshCounters,
  withBlockInfo = false,
}: UserAccountProps) => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  return (
    <Layout className={styles.layout}>
      <div className={styles.arrowBack} onClick={() => navigate(-1)}>
        <ArrowLeftOutlined /> {t('back')}
      </div>
      <Space direction="vertical" size={[0, 20]}>
        <Card
          title={t('myAccount.personalInformation')}
          className={styles.card}
        >
          <PersonalInfo data={user} onSave={onSavePersonalInfo} />
        </Card>

        {withBlockInfo && (
          <AccountInformation
            user={user}
            onRefresh={onRefreshCounters}
            onChangeBlocked={onSavePersonalInfo}
          />
        )}

        {user && (
          <Card title={t('notifications.title')} className={styles.card}>
            <NotificationSettings user={user} setUser={setUser} />
          </Card>
        )}

        <AccountActivity userId={user.id} />
      </Space>
    </Layout>
  );
};

export default UserAccount;
