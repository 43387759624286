import React, { Dispatch, ReactElement, SetStateAction, useEffect } from 'react';
import { ITransactionGroup } from 'pages/Administration/FinancialManagement/api/types';
import useGetTransactionGroups from 'pages/Administration/FinancialManagement/api/group/useGetTransactionGroups';

export type SelectDropdownProps = {
  unitId: string;
  render: ReactElement;
  setData: Dispatch<SetStateAction<{ nodes: ITransactionGroup[]; isLoading: boolean }>>;
};

const CategorizeTransactionSelectDropdown = ({ render, unitId, setData }: SelectDropdownProps) => {
  const { data, isLoading } = useGetTransactionGroups({ unitId });

  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      nodes: data || [],
      isLoading,
    }));
  }, [data, isLoading, setData]);

  return isLoading ? null : render;
};

export default CategorizeTransactionSelectDropdown;
