import React from 'react';
import { Permission } from '@fanckler/processing-auth';
import { ColumnsType } from 'antd/es/table';
import { IWallet } from 'interfaces';
import { IExternalUser } from 'interfaces/IExternalUser';
import { CheckPermissionsFunction } from 'contexts/AuthorisationContext/AuthorisationContext';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import renderEmptyCell from 'utils/renderEmptyCell';
import { BalanceCell, Button, DateTime } from 'components';
import { BizTag } from 'components/BizTag';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import styles from '../ClientsTab.module.scss';

export type GetColumnsParams = {
  t: TranslateFunc;
  checkTotalWalletsBalance: (wallets: IWallet[]) => number;
  onWithdrawModalOpen: (wallets: IWallet[]) => void;
  checkPermissions: CheckPermissionsFunction;
};

const getColumns = ({
  t,
  checkPermissions,
  checkTotalWalletsBalance,
  onWithdrawModalOpen,
}: GetColumnsParams) => {
  const columns: ColumnsType<IExternalUser> = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      width: 80,
    },
    {
      key: 'clientId',
      title: t('processing.clientId'),
      dataIndex: 'clientId',
      width: 160,
    },
    {
      key: 'rootUnitId',
      title: t('businessId'),
      dataIndex: 'rootUnitId',
      width: 160,
      render: (rootUnidId) =>
        <div
          title={t('clickToCopy')}
          className={styles.merchantId}
          onClick={() => navigator.clipboard.writeText(rootUnidId)}
        >
          {rootUnidId}
        </div>,
    },
    {
      key: 'name',
      title: t('name'),
      dataIndex: 'name',
      width: 160,
      render: renderEmptyCell,
    },
    {
      key: 'email',
      title: t('email'),
      dataIndex: 'email',
      width: 160,
      render: renderEmptyCell,
    },
    {
      key: 'unit',
      title: t('users.units.one'),
      dataIndex: 'unit',
      width: 160,
      render: (unit: IExternalUser['unit']) => !unit ? renderEmptyCell(null) : <BizTag>{unit.name}</BizTag>,
    },
    {
      key: 'balance',
      title: t('balance'),
      dataIndex: 'wallets',
      width: 250,
      render: value => <BalanceCell wallets={value} />,
    },
    {
      key: 'createdAt',
      title: t('created'),
      dataIndex: 'createdAt',
      width: 160,
      render: (createdAt) => <DateTime value={createdAt} />,
    },
    {
      key: 'updatedAt',
      title: t('updated'),
      dataIndex: 'updatedAt',
      width: 160,
      render: (updatedAt) => <DateTime value={updatedAt} />,
    },
  ];

  if (checkPermissions([Permission.CLIENT_PAYMENT_ORDER_CREATE])) {
    columns.push({
      key: '__extra__',
      dataIndex: '',
      width: 66,
      fixed: 'right',
      align: 'center',
      render: (record: IExternalUser) => {
        const totalBalance = checkTotalWalletsBalance(record.wallets);
        const isUnitActive = Boolean(record.unit?.isActive);
        const hasUnit = Boolean(record.unitId);

        if (totalBalance && hasUnit) {
          return (
            <Button
              block
              type="link"
              withoutBgShadow
              withoutWaveEffect
              disabled={!isUnitActive}
              className={styles.withdrawButton}
              prefixIcon={<VerticalAlignTopOutlined style={{ fontSize: 18 }} />}
              onClick={e => {
                e.stopPropagation();
                onWithdrawModalOpen(record.wallets);
              }}
            />
          );
        }
      },
    });
  }

  return columns;
};

export default getColumns;
