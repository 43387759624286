import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IUser } from 'interfaces/IUser';
import { IUnitTree } from 'components/TreeUnitsSelect/types';

export type UseUnitsTreeParams = {
  rootUnitId?: IUser['rootUnitId'] | string;
  unitId?: string | number;
  nested?: boolean;
  balances?: boolean;
};

const getUnitsTree = (params: UseUnitsTreeParams) => async () => {
  const { data } = await axios.get(`${SERVICE.CLIENT}/units/root-tree`, {
    params,
  });
  return data as IUnitTree[];
};

export const useUnitsTree = (params: UseUnitsTreeParams) => {
  return useQuery({
    queryKey: ['unit-tree', params],
    queryFn: getUnitsTree(params),
    enabled: Boolean(params.rootUnitId) || Boolean(params.unitId),
    refetchOnMount: true,
  });
};
