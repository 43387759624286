import React from 'react';
import { Avatar, Image as AntdImage } from 'antd';
import clsx from 'clsx';
import useCheckImage, { ImageStatusEnum } from 'hooks/useCheckImage';
import { BASE_IMAGE_URL } from 'constants/image';
import { generateColorByString } from './utils';
import styles from './OrganizationalLogo.module.scss';

export type OrganizationalLogoProps = {
  name: string;
  logoPath: string | null;
};

const OrganizationalLogo = ({ logoPath, name }: OrganizationalLogoProps) => {
  const status = useCheckImage({ src: logoPath || '' });

  const { color, colorWithAlpha } = generateColorByString(name);

  return (
    <Avatar
      size="large"
      style={{ backgroundColor: `#${colorWithAlpha}` }}
      className={clsx(styles.avatar, {
        [styles.isLogo]: status === ImageStatusEnum.SUCCESS,
      })}
    >
      {status === ImageStatusEnum.SUCCESS && logoPath ? (
        <AntdImage
          src={`${BASE_IMAGE_URL}${logoPath}`}
          preview={false}
          className={styles.logo}
        />
      ) : (
        <span style={{ color: `#${color}` }} className={styles.letterName}>
          {name.slice(0, 1)}
        </span>
      )}
    </Avatar>
  );
};

export default OrganizationalLogo;
