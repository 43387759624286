import React, { useMemo } from 'react';
import { useCurrencyRate } from 'hooks';
import { CountUp, CurrencyBadge } from 'components';
import styles from './GroupBalanceCell.module.scss';

const currency = 'USDT'; // Currency in which the balance is displayed

export type GroupBalanceCellProps = {
  balances: { [key: string]: number };
};

const GroupBalanceCell = ({ balances }: GroupBalanceCellProps) => {
  const { totalBalance } = useCurrencyRate();

  const amount = useMemo(() => Number(totalBalance(balances, currency)), [balances, totalBalance]);

  return (
    <div className={styles.wrapper}>
      <div style={{ marginBottom: 4, marginRight: 4 }}>
        <CurrencyBadge
          inline
          withoutText
          width="18px"
          currency={currency}
        />
      </div>
      <CountUp
        end={amount}
        currency={currency}
        className={styles.amount}
        isRoundDown
      />
      <span className={styles.currency}>{currency}</span>
    </div>
  );
};

export default GroupBalanceCell;
