import React, { lazy } from 'react';
import { Permission } from '@fanckler/processing-auth';
import lazyRetry from 'setup/lazyRetry';
import { IRoute } from './interfaces/IRoute';
import { Path } from './interfaces/Path';

const Clients = lazy(() => lazyRetry(() => import('../pages/Users/Clients/Clients')));
const ClientProfile = lazy(() => lazyRetry(() => import('../pages/Users/ClientProfile/ClientProfile')));
const TransactionsTeam = lazy(() => lazyRetry(() => import('../pages/Users/Transactions/Transactions')));
const Invoices = lazy(() => lazyRetry(() => import('../pages/Users/Invoices/Invoices')));

const usersRoutes: IRoute[] = [
  {
    path: Path.USERS_CLIENTS,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_USER_READ],
    component: () => <Clients />,
  },
  {
    path: Path.USERS_CLIENTS_UUID,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_USER_READ],
    component: () => <ClientProfile />,
  },
  {
    path: Path.USERS_TRANSACTIONS,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_TRANSACTION_READ],
    component: () => <TransactionsTeam />,
  },
  {
    path: Path.USERS_INVOICES,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_TRANSACTION_READ],
    component: () => <Invoices />,
  },
  // {
  //   path: `${Path.USERS_FINANCIAL_STATEMENT}`,
  //   isPrivate: true,
  //   withHeader: true,
  //   withSideBar: true,
  //   permissions: [Permission.ADMIN_IAM_FINANCIAL_STATEMENT_READ],
  //   component: () => <FinancialStatement />,
  // },
  // {
  //   path: `${Path.VERIFICATION_OF_ACTIONS}`,
  //   isPrivate: true,
  //   withHeader: true,
  //   withSideBar: true,
  //   permissions: [Permission.ADMIN_IAM_USER_CREATE],
  //   component: () => <Verification />,
  // },
];

export default usersRoutes;
