import React, { ReactElement } from 'react';
import { Space, Typography } from 'antd';
import clsx from 'clsx';
import { useTranslate } from '../../../../hooks';
import styles from './LocaleItem.module.scss';

export type LocaleItemProps = {
  locale: string;
  image: string;
  selected?: boolean;
  onClick?: () => void;
  withPaddings?: boolean;
  withLabel?: boolean;
};

const LocaleItem = ({
  locale,
  image,
  selected,
  onClick,
  withPaddings,
  withLabel = true,
}: LocaleItemProps): ReactElement => {
  const { t } = useTranslate();

  return (
    <Space
      size={10}
      className={clsx(styles.container, {
        [styles.selected]: selected,
        [styles.withPaddings]: withPaddings,
      })}
      onClick={onClick}
    >
      <img src={image} alt={locale} height={32} width={32} />
      {withLabel && (
        <Typography.Text className={styles.title}>
          {t(`locales.${locale}`)}
        </Typography.Text>
      )}
    </Space>
  );
};

export default LocaleItem;
