import { UnknownType } from 'types/Unknown';
import { DeviceModelId } from '../types';

const nanoS = {
  plugAndPinCode: {
    light: require('./nanoS/1PlugAndPinCode/light.json'),
    dark: require('./nanoS/1PlugAndPinCode/dark.json'),
  },
  enterPinCode: {
    light: require('./nanoS/3EnterPinCode/light.json'),
    dark: require('./nanoS/3EnterPinCode/dark.json'),
  },
  quitApp: {
    light: require('./nanoS/4QuitApp/light.json'),
    dark: require('./nanoS/4QuitApp/dark.json'),
  },
  allowManager: {
    light: require('./nanoS/5AllowManager/light.json'),
    dark: require('./nanoS/5AllowManager/dark.json'),
  },
  openApp: {
    light: require('./nanoS/6OpenApp/light.json'),
    dark: require('./nanoS/6OpenApp/dark.json'),
  },
  validate: {
    light: require('./nanoS/7Validate/light.json'),
    dark: require('./nanoS/7Validate/dark.json'),
  },
  firmwareUpdating: {
    light: require('./nanoS/2FirmwareUpdating/light.json'),
    dark: require('./nanoS/2FirmwareUpdating/dark.json'),
  },
  installLoading: {
    light: require('./nanoS/8InstallLoading/light.json'),
    dark: require('./nanoS/8InstallLoading/dark.json'),
  },
};

const nanoX = {
  plugAndPinCode: {
    light: require('./nanoX/1PlugAndPinCode/light.json'),
    dark: require('./nanoX/1PlugAndPinCode/dark.json'),
  },
  enterPinCode: {
    light: require('./nanoX/3EnterPinCode/light.json'),
    dark: require('./nanoX/3EnterPinCode/dark.json'),
  },
  quitApp: {
    light: require('./nanoX/4QuitApp/light.json'),
    dark: require('./nanoX/4QuitApp/dark.json'),
  },
  allowManager: {
    light: require('./nanoX/5AllowManager/light.json'),
    dark: require('./nanoX/5AllowManager/dark.json'),
  },
  openApp: {
    light: require('./nanoX/6OpenApp/light.json'),
    dark: require('./nanoX/6OpenApp/dark.json'),
  },
  validate: {
    light: require('./nanoX/7Validate/light.json'),
    dark: require('./nanoX/7Validate/dark.json'),
  },
  firmwareUpdating: {
    light: require('./nanoX/2FirmwareUpdating/light.json'),
    dark: require('./nanoX/2FirmwareUpdating/dark.json'),
  },
  installLoading: {
    light: require('./nanoX/8InstallLoading/light.json'),
    dark: require('./nanoX/8InstallLoading/dark.json'),
  },
};

const nanoSP = {
  plugAndPinCode: {
    light: require('./nanoSP/1PlugAndPinCode/light.json'),
    dark: require('./nanoSP/1PlugAndPinCode/dark.json'),
  },
  enterPinCode: {
    light: require('./nanoSP/3EnterPinCode/light.json'),
    dark: require('./nanoSP/3EnterPinCode/dark.json'),
  },
  quitApp: {
    light: require('./nanoSP/4QuitApp/light.json'),
    dark: require('./nanoSP/4QuitApp/dark.json'),
  },
  allowManager: {
    light: require('./nanoSP/5AllowManager/light.json'),
    dark: require('./nanoSP/5AllowManager/dark.json'),
  },
  openApp: {
    light: require('./nanoSP/6OpenApp/light.json'),
    dark: require('./nanoSP/6OpenApp/dark.json'),
  },
  validate: {
    light: require('./nanoSP/7Validate/light.json'),
    dark: require('./nanoSP/7Validate/dark.json'),
  },
  firmwareUpdating: {
    light: require('./nanoSP/2FirmwareUpdating/light.json'),
    dark: require('./nanoSP/2FirmwareUpdating/dark.json'),
  },
  installLoading: {
    light: require('./nanoSP/8InstallLoading/light.json'),
    dark: require('./nanoSP/8InstallLoading/dark.json'),
  },
};

const nanoFTS = {
  plugAndPinCode: {
    light: require('./nanoS/1PlugAndPinCode/light.json'),
    dark: require('./nanoS/1PlugAndPinCode/dark.json'),
  },
  enterPinCode: {
    light: require('./nanoS/3EnterPinCode/light.json'),
    dark: require('./nanoS/3EnterPinCode/dark.json'),
  },
  quitApp: {
    light: require('./nanoS/4QuitApp/light.json'),
    dark: require('./nanoS/4QuitApp/dark.json'),
  },
  allowManager: {
    light: require('./nanoS/5AllowManager/light.json'),
    dark: require('./nanoS/5AllowManager/dark.json'),
  },
  openApp: {
    light: require('./nanoS/6OpenApp/light.json'),
    dark: require('./nanoS/6OpenApp/dark.json'),
  },
  validate: {
    light: require('./nanoS/7Validate/light.json'),
    dark: require('./nanoS/7Validate/dark.json'),
  },
  firmwareUpdating: {
    light: require('./nanoS/2FirmwareUpdating/light.json'),
    dark: require('./nanoS/2FirmwareUpdating/dark.json'),
  },
  installLoading: {
    light: require('./nanoS/8InstallLoading/light.json'),
    dark: require('./nanoS/8InstallLoading/dark.json'),
  },
};

const blue = {
  plugAndPinCode: {
    light: require('./blue/1PlugAndPinCode/data.json'),
  },
  enterPinCode: {
    light: require('./blue/3EnterPinCode/data.json'),
  },
  quitApp: {
    light: require('./blue/4QuitApp/data.json'),
  },
  allowManager: {
    light: require('./blue/5AllowManager/data.json'),
  },
  openApp: {
    light: require('./blue/6OpenApp/data.json'),
  },
  validate: {
    light: require('./blue/7Validate/data.json'),
  },
  // Nb We are dropping the assets for blue soon, this is temp
  firmwareUpdating: {
    light: require('./nanoS/2FirmwareUpdating/light.json'),
    dark: require('./nanoS/2FirmwareUpdating/dark.json'),
  },
  installLoading: {
    light: require('./nanoS/8InstallLoading/light.json'),
    dark: require('./nanoS/8InstallLoading/dark.json'),
  },
};

const animations = { nanoX, nanoS, nanoSP, nanoFTS, blue };

type InferredKeys = keyof typeof nanoS;

const getDeviceAnimation = (
  modelId: DeviceModelId,
  theme: 'light' | 'dark',
  key: InferredKeys,
) => {
  const lvl1 = (animations as UnknownType)[modelId] || animations.nanoX;
  const lvl2 = (lvl1[key] || animations.nanoX[key]) as UnknownType;
  if (theme === 'dark' && lvl2.dark) return lvl2.dark;
  return lvl2.light;
};

export default getDeviceAnimation;
