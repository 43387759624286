import { useCallback, useEffect, useMemo, useState } from 'react';
import { IWallet } from 'types/IWallet';
import { UnknownType } from 'types/Unknown';
import { WalletNetworkEnum } from 'types/WalletNetworkEnum';
import { filterExistingNetworks } from 'pages/Administration/Units/components/NestedUnit/utils';
import { CreateWalletDTO } from 'components/WalletOverview/hooks/useCreateWallet';
import { defaultNetworks, networkList } from 'constants/wallet';
import { LocalStorage, LocalStorageKey } from 'utils';
import localStorage from 'utils/localStorage';

const checkIsFavorite = (favoriteWallets: string[]) => (wallet: IWallet) => {
  return favoriteWallets.includes(`${wallet.currency}/${wallet.network}`);
};

const getFilteredWallets = (wallets: IWallet[], selectedNetworks: string[], favoriteWallets: string[]) => {
  const isFavorite = checkIsFavorite(favoriteWallets);
  const selectedWallets = wallets?.filter((wallet) => selectedNetworks.includes(wallet.network));
  const favorite = selectedWallets?.filter((wallet) => isFavorite(wallet));
  const others = selectedWallets?.filter((wallet) => !isFavorite(wallet));
  return [...favorite, ...others];
};

const getUpdatedSelection = (item: WalletNetworkEnum, selectedNetworks: WalletNetworkEnum[]) => {
  if (item === 'favorite' as WalletNetworkEnum) {
    if (selectedNetworks.includes(item)) {
      return [networkList[0]];
    } else {
      return [item];
    }
  } else {
    if (selectedNetworks.includes(item)) {
      if (selectedNetworks.length === 1) {
        return selectedNetworks;
      }
      return selectedNetworks.filter(el => el !== item && el !== 'favorite' as WalletNetworkEnum);
    } else {
      return [...selectedNetworks.filter(el => el !== 'favorite' as WalletNetworkEnum), item];
    }
  }
};

const getDefaultWallets = (wallets: IWallet[]) => {
  const networks = wallets?.map((wallet) => wallet.network);
  return defaultNetworks.filter((network) => networks?.includes(network));
};

const useWalletFilter = (wallets: IWallet[]) => {
  const [selectedNetworks, setSelectedNetworks] = useState(LocalStorage.get((LocalStorageKey.WALLETS_SELECTED) || []) as WalletNetworkEnum[]);
  const [favoriteWallets, setFavoriteWallets] = useState(LocalStorage.get(LocalStorageKey.WALLETS_FAVORITES) || []);

  const availableNetworks = useMemo(() => filterExistingNetworks(wallets), [wallets]);

  const toggleFavoriteWallet = useCallback((walletId: string) => {
    const updatedList = favoriteWallets.includes(walletId)
      ? favoriteWallets.filter((id) => id !== walletId)
      : [...favoriteWallets, walletId];

    setFavoriteWallets(updatedList);
    LocalStorage.set(LocalStorageKey.WALLETS_FAVORITES, updatedList);
  }, [favoriteWallets]);

  const toggleSelectedNetwork = useCallback((network: WalletNetworkEnum) => {
    const filteredSelectedNetworks = selectedNetworks.filter(
      (selectedNetwork) => availableNetworks.includes(selectedNetwork),
    );
    const newSelectedNetworks = getUpdatedSelection(network, filteredSelectedNetworks || []) || [];

    setSelectedNetworks(newSelectedNetworks);
    localStorage.set(LocalStorageKey.WALLETS_SELECTED, newSelectedNetworks);
  }, [availableNetworks, selectedNetworks]);

  const filteredWallets = useMemo(
    () => getFilteredWallets(wallets || [], selectedNetworks || [], favoriteWallets || []),
    [favoriteWallets, selectedNetworks, wallets],
  );

  const onWalletWillCreate = useCallback((wallet: CreateWalletDTO) => {
    const network: WalletNetworkEnum = wallet.network as UnknownType;
    if ((selectedNetworks || []).includes(network as UnknownType)) return;
    toggleSelectedNetwork(network as UnknownType);
  }, [selectedNetworks, toggleSelectedNetwork]);

  useEffect(() => {
    if (!wallets?.length) {
      return;
    }

    const localStorageWallets = LocalStorage.get(LocalStorageKey.WALLETS_SELECTED) as WalletNetworkEnum[];
    const filteredStorageWallets = (localStorageWallets || []).filter(
      (selectedNetwork) => availableNetworks.includes(selectedNetwork),
    );
    setSelectedNetworks(filteredStorageWallets.length ? filteredStorageWallets : getDefaultWallets(wallets));
  }, [availableNetworks, wallets]);

  return {
    filteredWallets,

    favoriteWallets,
    toggleFavoriteWallet,

    selectedNetworks,
    toggleSelectedNetwork,

    onWalletWillCreate,
  };
};

export default useWalletFilter;
