import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IClientTransaction } from 'interfaces/IClientTransaction';
import { Filter } from 'types/Filter';
import { UnknownType } from 'types/Unknown';
import { TransactionsSorting } from 'pages/Processing/Transactions/types';

type TransactionsParams = {
  filter?: Filter<Record<string, UnknownType>>;
  paging?: { limit: number; offset: number };
  sorting?: TransactionsSorting[];
  page?: 'unit' | 'client' | 'transaction';
  enabled?: boolean;
};

const useGetClientTransactions = ({ enabled, ...params }: TransactionsParams) => {
  const getUnitTransactions = async () => {
    const { data } = await axios.get(`${SERVICE.CLIENT}/transactions/manager`, { params });
    return data;
  };

  return useQuery<{ nodes: IClientTransaction[], totalCount: number }>({
    queryKey: ['transactions', params],
    queryFn: getUnitTransactions,
    enabled,
  });
};

export default useGetClientTransactions;
