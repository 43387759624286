import { useMemo, useState } from 'react';
import { UnknownType } from 'types/Unknown';
import AddAccounts from '../ledger/actions/AddAccounts';
import AddTrezor from '../trezor/index';
import {
  BluetoothLogo,
  CustomConnectionIcon,
  EVMWalletLogo,
  UsbIcon,
  WalletConnectLogo,
} from '../components/ChooseMethodConnection/assets';
import { ReactComponent as LedgerLogo } from 'assets/icons/LedgerLogo.svg';
import { ReactComponent as TrezorLogo } from 'assets/icons/TrezorLogo.svg';

export interface IConnectionMethod {
  key: string;
  title: string;
  icon: UnknownType;
  comingSoon?: boolean;
  sub?: IConnectionMethod[];
}

export const connectionMethods: IConnectionMethod[] = [
  {
    key: 'ledger',
    title: 'Ledger',
    icon: LedgerLogo,
    sub: [
      {
        key: 'ledger.desktopApp',
        title: 'Ledger live desktop application',
        icon: LedgerLogo,
        comingSoon: true,
      },
      {
        key: 'ledger.mobileApp',
        title: 'Ledger live mobile application',
        icon: LedgerLogo,
        comingSoon: true,
      },
      {
        key: 'ledger.usb',
        title: 'USB',
        icon: UsbIcon,
      },
      {
        key: 'ledger.bluetooth',
        title: 'Bluetooth',
        icon: BluetoothLogo,
        comingSoon: true,
      },
    ],
  },
  {
    key: 'trezor',
    title: 'Trezor',
    icon: TrezorLogo,
    sub: [
      {
        key: 'trezor.webApp',
        title: 'Trezor Suite application',
        icon: TrezorLogo,
      },
      {
        key: 'trezor.usb',
        title: 'USB',
        icon: UsbIcon,
        comingSoon: true,
      },
    ],
  },
  {
    key: 'customConnection',
    title: 'Custom connection',
    icon: CustomConnectionIcon,
    comingSoon: true,
  },
  {
    key: 'walletConnect',
    title: 'WalletConnect',
    icon: WalletConnectLogo,
    comingSoon: true,
  },
  {
    key: 'evmWallet',
    title: 'EVM wallet',
    icon: EVMWalletLogo,
    comingSoon: true,
  },
];

const connectionFlowComponents = new Map([
  ['ledger.usb', AddAccounts],
  // ['ledger.desktopApp', ConnectLedgerViaDesktopApp] as any,
  ['trezor.webApp', AddTrezor],
]);

const useChooseConnection = () => {
  const [selectedConnection, setSelectedConnection] = useState<string>();

  const startConnection = (method: string) => {
    setSelectedConnection(method);
  };

  const stopConnection = () => {
    setSelectedConnection(undefined);
  };

  const FlowComponent: UnknownType = useMemo(() => {
    if (!selectedConnection || !connectionFlowComponents.has(selectedConnection)) {
      return null;
    }
    return connectionFlowComponents.get(selectedConnection);
  }, [selectedConnection]);

  return {
    selectedConnection,
    startConnection,
    stopConnection,
    FlowComponent,
  };
};

export default useChooseConnection;
