import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { MutationOptions, UpdateUnitVariables } from './types';

const updateUnit = async ({ id, body }: UpdateUnitVariables) => {
  const { data } = await axios.put(`${SERVICE.CLIENT}/units/${id}`, body);
  return data;
};

const useUpdateUnit = (mutationOptions?: MutationOptions) => {
  return useMutation({
    mutationFn: updateUnit,
    ...mutationOptions,
  });
};

export default useUpdateUnit;
