import { useCallback } from 'react';
import { IUser } from 'interfaces/IUser';
import { useNavigateToUnit } from 'hooks';

const useAssignedUnitClick = (rootUnitId?: number | null) => {
  const navigate = useNavigateToUnit();

  return useCallback((unit: IUser['units'][0], rootUnitId_ = rootUnitId) => {
    if (rootUnitId_) {
      navigate(rootUnitId_, unit.uuid);
    }
  }, [navigate, rootUnitId]);
};

export default useAssignedUnitClick;
