import { useCallback, useMemo } from 'react';
import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IRate } from 'pages/Processing/Balance/utils/getWalletsTotalBalance';
import { unifyTotalBalancesStructure, unifyWalletsStructure } from 'utils';
import { convertCurrency } from 'utils/convertCurreny';

export interface CurrencyRate {
  addtime: number;
  input: string;
  output: string;
  rate: [number, number];
  source: string;
}

export interface Wallet {
  currency: string;
  balance?: number | string;
  all?: number;
}

const getCurrencyRate = async () => {
  const { data } = await axios.get(`${SERVICE.RATE}/`);
  return data;
};

const FIXED_RATES = [
  {
    input: 'usdt',
    output: 'usd',
    rate: [1, 1.02],
  },
  {
    input: 'usdt',
    output: 'usdc',
    rate: [1, 1], // TODO: >>>>> CHANGE TO CORRECT RATE
  },
];

export const useCurrencyRate = () => {
  const { data: rates, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ['currency-rate'],
    queryFn: getCurrencyRate,
  });

  const extendedRates: CurrencyRate[] = useMemo(() => {
    return rates ? [...rates, ...FIXED_RATES] : [];
  }, [rates]);

  const walletBalance = useCallback(
    (wallets: Wallet[], currency: string): number => {
      const unifiedWallets = unifyWalletsStructure(wallets);
      return unifiedWallets.reduce((total, wallet) => {
        const converted = convertCurrency(
          extendedRates as IRate[],
          wallet.currency,
          currency,
          Number(wallet.balance),
        );
        return total + converted;
      }, 0);
    },
    [extendedRates],
  );

  const totalBalance = useCallback(
    (totalBalances: { [key: string]: number }, currency: string): number => {
      const convertedTotalBalances = unifyTotalBalancesStructure(totalBalances);

      return convertedTotalBalances.reduce((total, b) => {
        const converted = convertCurrency(
          extendedRates as IRate[],
          b.currency,
          currency,
          Number(b.balance),
        );
        return total + converted;
      }, 0);
    },
    [extendedRates],
  );

  return {
    walletBalance,
    totalBalance,
    isLoading: isLoading || isRefetching,
    rates: extendedRates,
    refetch,
  };
};
