// eslint-disable-next-line
export const EMAIL_SCHEMA = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;// eslint-disable-next-line
export const EMAIL_USERNAME_REGEX = /(^([a-zA-Z0-9_])*$)|(^[a-zA-Z0-9_]+[a-zA-Z0-9._]+[a-zA-Z0-9_])+$/;
export const ALLOWED_EMAIL_SYMBOLS = /^[a-zA-Z0-9@.]+$/i;
export const SPECIAL_CHARACTERS_FILE_NAME_REGEX = /[^A-Za-z0-9-_ ]/;
export const POSITIVE_WHOLE_NUMBER = /(^[0-9]$)|(^[1-9][0-9]{1,}$)/;
export const NEGATIVE_WHOLE_NUMBER = /(^(-)[0-9]$)|(^(-)[1-9][0-9]{1,}$)/;
export const POSITIVE_FRACTIONAL_NUMBER = /(^[0-9]$)|(^(?!0\d)\d{1,}((\.|,)?\d{0,})$)/;
export const HTML_TAG_REGEX = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
export const USER_LABEL_REGEX = /^(?!\s+$)[a-zA-Zа-яА-ЯёЁіІїЇ0-9 ]{1,32}$/gm;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*():;{[}\]|<,>.?/+=\-_~`])[\dA-Za-z!@#$%^&*():;{[}\]|<,>.?/+=\-_~`]{16,48}$/;
