import React, { ReactElement, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout, Space } from 'antd';
import { AppState } from 'init/rootReducer';
import { useTranslate } from '../../hooks';
import { getPageTitle } from './utils';
import { TruncatedTooltip } from 'components';
import Notifications from 'components/Notifications';
import UserMenuDropdown from 'components/UserMenuDropdown';
import styles from './Header.module.scss';

const Header = (): ReactElement => {
  const { pathname } = useLocation();
  const { t } = useTranslate();
  const params = useParams();

  const { title: unitTitle } = useSelector((state: AppState) => state.unitReducer);

  const title = useMemo(() =>
    getPageTitle({
      params,
      unitTitle,
      translate: t,
      path: pathname,
    }),
  [t, pathname, params, unitTitle]);

  return (
    <Layout.Header className={styles.header}>
      <div className={styles.leftContent}>
        <h2 className={styles.title}>
          <TruncatedTooltip text={title} max={32} />
        </h2>
      </div>
      <div className={styles.rightContent}>
        <Space>
          <Notifications />
          <span className={styles.divider} />
          <UserMenuDropdown />
        </Space>
      </div>
    </Layout.Header>
  );
};

export default Header;
