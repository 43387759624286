import React, { useCallback, useMemo, useState } from 'react';
import { noop } from 'lodash';
import { AddAccountsProps, InternalStep, StepId } from './types';
import { useTranslate } from 'hooks';
import useWalletsConnect from '../hooks/useWalletsConnect';
import { useTrezorConnection } from 'components/ColdStorage/hooks';
import { createSteps } from './utils';
import { connectWallet } from 'components/ColdStorage/trezor/api/trezorAPI';
import Stepper from '../components/Stepper/Stepper';

const INITIAL_STATE = {
  stepId: 'connect' as StepId,
  // err: null as boolean | null,
};

const AddAccounts = ({ onBackToMethods, onModalClose = noop }: AddAccountsProps) => {
  const [state, setState] = useState(INITIAL_STATE);
  const { t } = useTranslate();
  useTrezorConnection();

  const steps = useMemo(() => {
    return createSteps({
      onBackToMethods,
      onModalClose,
      translate: t,
    });
  },
  [onModalClose, onBackToMethods, t]);

  const handleStepChange = useCallback((step: InternalStep) => {
    setState(prevState => ({ ...prevState, stepId: step.id }));
  }, []);

  const {
    connectionStatus,
    checkedNetworks,
    retryConnect,
    handleContinue: onConnect,
    changeCheckedNetworks,
    isCreatingWallets,
  } = useWalletsConnect({
    onContinue: () => handleStepChange(steps[2]),
    connectWallet,
  });

  const stepperProps = {
    // err,
    // setError: (status: boolean) => {
    //   setState((prevState) => ({
    //     ...prevState,
    //     err: status,
    //     scanStatus: status ? 'error' : 'idle',
    //   }));
    // },
    connectionStatus,
    checkedNetworks,
    retryConnect,
    onConnect,
    changeCheckedNetworks,
    isCreatingWallets,
  };

  return (
    <Stepper
      stepId={state.stepId}
      title={t('coldStorage.trezorConnection')}
      onStepChange={handleStepChange}
      onClose={onModalClose}
      steps={steps}
      {...stepperProps}
    />
  );
};

export default AddAccounts;
