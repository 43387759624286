import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';

type UnitTotalBalancesParams = {
  uuid: string | undefined;
  hardwareId?: string | null;
};

const getUnitTotalBalances = ({ uuid, hardwareId }: UnitTotalBalancesParams) => async () => {
  const { data } = await axios.get(`${SERVICE.CLIENT}/units/${uuid}/total-balances`, {
    params: {
      hardwareId,
    },
  });
  return data as Record<string, number>;
};

const useUnitTotalBalances = (params: UnitTotalBalancesParams) => {
  return useQuery({
    queryFn: getUnitTotalBalances(params),
    queryKey: ['unit-total-balances', params],
    enabled: !!params.uuid,
  });
};

export default useUnitTotalBalances;
