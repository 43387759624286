import { endOfDay } from 'date-fns';
import { ClientTransactionStatusEnum } from 'types/ClientTransactionStatusEnum';
import { CurrencyEnum } from 'types/Currency';
import { TransactionDirectionEnum as DirectionEnum } from 'types/TransactionDirection';
import { POSITIVE_WHOLE_NUMBER } from 'constants/validation';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import {
  BetweenComparisonType,
  ComparisonType,
  FilterField,
  FilterFieldType,
} from 'components/Filters';

const getFilterFields = (
  translate: TranslateFunc,
): Map<string, FilterField> => new Map<string, FilterField>([
  ['currency', {
    label: translate('processing.currency'),
    field: 'currency',
    alwaysVisible: true,
    type: FilterFieldType.SELECT,
    comparisonType: ComparisonType.EQUAL,
    options: [
      { label: CurrencyEnum.ETH, value: CurrencyEnum.ETH },
      { label: CurrencyEnum.TRX, value: CurrencyEnum.TRX },
      { label: CurrencyEnum.BTC, value: CurrencyEnum.BTC },
      { label: CurrencyEnum.USDC, value: CurrencyEnum.USDC },
      { label: CurrencyEnum.DAI, value: CurrencyEnum.DAI },
      // { label: CurrencyEnum.BUSD, value: CurrencyEnum.BUSD },
      { label: CurrencyEnum.BNB, value: CurrencyEnum.BNB },
      // { label: CurrencyEnum.TRC20, value: CurrencyEnum.TRC20 },
      // { label: CurrencyEnum.ERC20, value: CurrencyEnum.ERC20 },
      { label: 'USDT', value: 'USDT' },
      // { label: 'EUR', value: 'EUR' },
    ],
  }],
  ['network', {
    label: translate('transaction.network'),
    field: 'network',
    alwaysVisible: true,
    type: FilterFieldType.SELECT,
    comparisonType: ComparisonType.EQUAL,
    options: [
      { label: 'TRC20', value: 'TRC20' },
      { label: 'ERC20', value: 'ERC20' },
      { label: 'BEP20', value: 'BEP20' },
      { label: 'Bitcoin', value: 'Bitcoin' },
    ],
  }],
  ['direction', {
    label: translate('processing.direction'),
    field: 'direction',
    alwaysVisible: true,
    type: FilterFieldType.SELECT,
    comparisonType: ComparisonType.EQUAL,
    options: [
      { label: DirectionEnum.PAYOUT, value: DirectionEnum.PAYOUT },
      { label: DirectionEnum.PAYIN, value: DirectionEnum.PAYIN },
      { label: 'refund', value: 'refund' },
      // { label: DirectionEnum.AGGREGATE, value: DirectionEnum.AGGREGATE },
      // { label: DirectionEnum.ACC_ACTIVATE, value: DirectionEnum.ACC_ACTIVATE },
      // { label: DirectionEnum.FREEZE_BALANCE_ENERGY, value: DirectionEnum.FREEZE_BALANCE_ENERGY },
      // { label: DirectionEnum.UNFREEZE_BALANCE_ENERGY, value: DirectionEnum.UNFREEZE_BALANCE_ENERGY },
      // { label: DirectionEnum.FREEZE_BALANCE_BANDWIDTH, value: DirectionEnum.FREEZE_BALANCE_BANDWIDTH },
      // { label: DirectionEnum.UNFREEZE_BALANCE_BANDWIDTH, value: DirectionEnum.UNFREEZE_BALANCE_BANDWIDTH },
    ],
  }],
  ['status', {
    label: translate('processing.status'),
    field: 'status',
    type: FilterFieldType.SELECT,
    alwaysVisible: true,
    comparisonType: ComparisonType.EQUAL,
    options: [
      { label: ClientTransactionStatusEnum.CREATED, value: ClientTransactionStatusEnum.CREATED },
      { label: ClientTransactionStatusEnum.SUBMITED, value: ClientTransactionStatusEnum.SUBMITED },
      { label: ClientTransactionStatusEnum.COMPLETED, value: ClientTransactionStatusEnum.COMPLETED },
      { label: ClientTransactionStatusEnum.CANCELED, value: ClientTransactionStatusEnum.CANCELED },
      { label: ClientTransactionStatusEnum.REJECTED, value: ClientTransactionStatusEnum.REJECTED },
      { label: ClientTransactionStatusEnum.UNDER_REVIEW, value: ClientTransactionStatusEnum.UNDER_REVIEW },
      { label: ClientTransactionStatusEnum.PENDING_APPROVAL, value: ClientTransactionStatusEnum.PENDING_APPROVAL },
      { label: ClientTransactionStatusEnum.APPROVED, value: ClientTransactionStatusEnum.APPROVED },
      { label: ClientTransactionStatusEnum.SUSPENDED, value: ClientTransactionStatusEnum.SUSPENDED },
      { label: ClientTransactionStatusEnum.PENDING, value: ClientTransactionStatusEnum.PENDING },
      { label: ClientTransactionStatusEnum.IN_PROCESS, value: ClientTransactionStatusEnum.IN_PROCESS },
    ],
  }],
  ['id', {
    label: translate('id'),
    field: 'id',
    type: FilterFieldType.NUMBER,
    comparisonType: ComparisonType.EQUAL,
  }],
  ['unit.id', {
    label: translate('users.transactions.columns.unitId'),
    field: 'unit.id',
    type: FilterFieldType.NUMBER,
    comparisonType: ComparisonType.EQUAL,
  }],
  ['unit.name', {
    label: translate('users.units.unitName'),
    field: 'unit.name',
    type: FilterFieldType.TEXT,
    comparisonType: ComparisonType.I_LIKE,
    regex: /^[0-9a-zA-Zа-яА-ЯҐЄІЇ-ґєії ]+$/i,
  }],
  ['invoice.id', {
    label: translate('users.transactions.columns.invoiceId'),
    field: 'invoice.id',
    type: FilterFieldType.NUMBER,
    comparisonType: ComparisonType.EQUAL,
  }],
  ['client.clientId', {
    label: translate('processing.clientId'),
    field: 'client.clientId',
    type: FilterFieldType.TEXT,
    comparisonType: ComparisonType.EQUAL,
    regex: POSITIVE_WHOLE_NUMBER,
  }],
  ['category.id', {
    label: translate('administration.financialManagement.fields.categoryId'),
    field: 'category.id',
    type: FilterFieldType.NUMBER,
    comparisonType: ComparisonType.EQUAL,
  }],
  ['addressFrom', {
    label: translate('users.transactions.addressFrom'),
    field: 'addressFrom',
    type: FilterFieldType.TEXT,
    comparisonType: ComparisonType.I_LIKE,
  }],
  ['addressTo', {
    label: translate('users.transactions.addressTo'),
    field: 'addressTo',
    type: FilterFieldType.TEXT,
    comparisonType: ComparisonType.I_LIKE,
  }],
  ['type', {
    label: translate('type'),
    field: 'type',
    type: FilterFieldType.SELECT,
    comparisonType: ComparisonType.EQUAL,
    options: [
      { label: 'WITHDRAWALS', value: 'WITHDRAWALS' },
      { label: 'REFILL', value: 'REFILL' },
      { label: 'FEE', value: 'FEE' },
    ],
  }],
  ['origin', {
    label: translate('transaction.origin'),
    field: 'origin',
    type: FilterFieldType.SELECT,
    comparisonType: ComparisonType.EQUAL,
    options: [
      { label: 'OUTSIDE_OUR_TOPUP', value: 'outside_our_topup' },
      { label: 'LEV_TWO_OUR_TOPUP', value: 'lev_two_our_topup' },
      { label: 'OUTSIDE_TOPUP', value: 'outside_topup' },
      { label: 'LEV_TWO_TOPUP', value: 'lev_two_topup' },
      { label: 'INSIDE_TOPUP', value: 'inside_topup' },
    ],
  }],
  ['owner', {
    label: translate('transaction.owner'),
    field: 'owner',
    type: FilterFieldType.SELECT,
    comparisonType: ComparisonType.NOT_EQUAL,
    options: [
      { label: 'Invoice', value: 'invoice' },
      { label: 'Unit', value: 'unit' },
      { label: 'Client', value: 'client' },
    ],
  }],
  ['invoice.customerEmail', {
    label: translate('invoice.fields.email'),
    type: FilterFieldType.TEXT,
    field: 'invoice.customerEmail',
    comparisonType: ComparisonType.LIKE,
  }],
  ['client.email', {
    label: translate('email'),
    type: FilterFieldType.TEXT,
    field: 'client.email',
    comparisonType: ComparisonType.LIKE,
  }],
  ['txId', {
    label: 'Tx ID',
    field: 'txId',
    type: FilterFieldType.TEXT,
    comparisonType: ComparisonType.EQUAL,
  }],
  ['createdAt.from', {
    label: `${translate('created')} (${translate('dateFrom')})`,
    field: 'createdAt',
    type: FilterFieldType.DATE,
    maxDate: (filters) => (filters['createdAt.to'] ?? endOfDay(new Date())),
    betweenComparisonType: BetweenComparisonType.LOWER,
    comparisonType: ComparisonType.BETWEEN,
  }],
  ['createdAt.to', {
    label: `${translate('created')} (${translate('dateTo')})`,
    field: 'createdAt',
    type: FilterFieldType.DATE,
    maxDate: () => endOfDay(new Date()),
    minDate: (filters) => (filters['createdAt.from']),
    betweenComparisonType: BetweenComparisonType.UPPER,
    comparisonType: ComparisonType.BETWEEN,
  }],
  ['updatedAt.from', {
    label: `${translate('updated')} (${translate('dateFrom')})`,
    field: 'updatedAt',
    type: FilterFieldType.DATE,
    maxDate: (filters) => (filters['updatedAt.to'] ?? endOfDay(new Date())),
    betweenComparisonType: BetweenComparisonType.LOWER,
    comparisonType: ComparisonType.BETWEEN,
  }],
  ['updatedAt.to', {
    label: `${translate('updated')} (${translate('dateTo')})`,
    field: 'updatedAt',
    type: FilterFieldType.DATE,
    maxDate: () => endOfDay(new Date()),
    minDate: (filters) => (filters['updatedAt.from']),
    betweenComparisonType: BetweenComparisonType.UPPER,
    comparisonType: ComparisonType.BETWEEN,
  }],
]);

export default getFilterFields;
