import { TypedUseSelectorHook, useSelector as useSelectorOriginal } from 'react-redux';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { approveRequestReducer } from 'pages/Administration/ReplenishmentRequests/utils/reducer';
import { unitReducer } from 'pages/Administration/Units/reducer';
import { approvePOReducer } from 'pages/Processing/PaymentOrders/reducer';
import { coldStorageReducer } from 'components/ColdStorage/storage';
import { coldStoragePersistConfig } from 'components/ColdStorage/storage/reducer';
import { modalReducer } from 'components/Modal/reducer';
import { notificationReducer } from 'components/Notifications/redux/reducer';
import { sidebarReducer } from 'components/Sidebar/reducer';

export const rootReducer = combineReducers({
  sidebarReducer,
  modalReducer,
  approvePOReducer,
  approveRequestReducer,
  unitReducer,
  notificationReducer,
  coldStorageReducer: persistReducer(coldStoragePersistConfig, coldStorageReducer),
});

export type AppState = ReturnType<typeof rootReducer>;

export const useSelector: TypedUseSelectorHook<AppState> = useSelectorOriginal;
