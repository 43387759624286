export enum TransactionStatusEnum {
  COMPLETED = 'completed', // Completed
  CANCELED = 'canceled', // Canceled
  CREATED = 'created', // Created
  PENDING = 'pending', // Pending
  REJECTED = 'rejected', // Rejected
  APPROVED = 'approved', // Approved
  SUSPENDED = 'suspended', // Suspended
  SUBMITED = 'submitted', // Submitted
  PENDING_APPROVAL = 'pending_approval', // Pending Approval
  IN_PROCESS = 'in_process', // In Process
  IN_FINALIZATION = 'in_finalization', // In Finalization
  UNDER_REVIEW = 'under_review', // Under Review
}
