import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { batch } from 'react-redux';
import { cleverRefetchQueries } from 'libs/reactQuery';
import clsx from 'clsx';
import { IClientTransaction } from 'interfaces/IClientTransaction';
import { UnknownType } from 'types/Unknown';
import { useUpdateAnnotation } from 'pages/Users/Transactions/hooks';
import { getColumns } from 'pages/Users/Transactions/utils';
import { useGetClientTransactions, useTablePagination, useTranslate } from 'hooks';
import useTableColumns, { filterByDisplayed } from 'hooks/useTableColumns';
import { useModal } from 'components/Modal/hooks/useModal';
import { SocketContext } from 'contexts/SocketContext';
import { LocalStorageKey } from 'utils/localStorage';
import { Modal, Pagination } from 'components';
import DraggableTable from 'components/DraggableTable';
import { TransactionView } from './components';
import styles from './TransactionsTab.module.scss';

export type TransactionsTabProps = {
  unitId: number;
};

const TransactionsTab = ({ unitId }: TransactionsTabProps) => {
  const { socket } = useContext(SocketContext);
  const { t } = useTranslate();

  const { page, perPage, setPage, setPerPage } = useTablePagination({
    withoutURL: true,
  });

  const {
    isOpen,
    payload,
    onModalOpen,
    onModalClose,
    onSetModalPayload,
  } = useModal('unit-tab-transactions');

  const { data, isLoading, refetch } = useGetClientTransactions({
    paging: { limit: perPage, offset: (page - 1) * perPage },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
    filter: { and: [{ unitId: { eq: unitId } }] },
    enabled: Boolean(unitId),
  });

  const { mutateAsync: updateAnnotationAsync } = useUpdateAnnotation();

  const handleUpdateAnnotation = useCallback(async (id: number, annotation: string) => {
    try {
      await updateAnnotationAsync({ id, annotation });
      await cleverRefetchQueries('transactions');
      return { error: false };
    } catch (error) {
      return { error: true };
    }
  }, [updateAnnotationAsync]);

  const onViewTx = useCallback((transaction: IClientTransaction) => {
    batch(() => {
      onModalOpen();
      onSetModalPayload({ ...transaction });
    });
  }, [onModalOpen, onSetModalPayload]);

  useEffect(() => {
    return () => { setPage(1); };
  }, [setPage]);

  useEffect(() => {
    if (socket) {
      socket.on('unit_balance_changed', async () => refetch());
    }
  }, [socket, refetch]);

  const getRowClassName = (record: IClientTransaction) => clsx(styles.row, {
    [styles.suspiciousRow]: record.origin === 'external_payout',
    // [styles.highlighted]: record.category === null,
  });

  const transactions = data?.nodes || [];
  const totalCount = data?.totalCount || 0;

  const initialColumns = useMemo(() =>
    getColumns({
      t,
      onViewTx,
      onSaveAnnotation: handleUpdateAnnotation,
    }),
  [handleUpdateAnnotation, onViewTx, t]);

  const { columns, setOrder, displayed } = useTableColumns({
    storageKey: LocalStorageKey.USERS_TRANSACTIONS_COLUMNS,
    initialColumns,
  });

  return (
    <div className={styles.wrapper}>
      <DraggableTable
        setOrder={setOrder}
        onRefresh={refetch}
        dataSource={transactions}
        rowClassName={getRowClassName}
        rootClassName={styles.tableWrapper}
        loading={isLoading}
        columns={filterByDisplayed(columns, displayed)}
        scroll={{ x: 'max-content' }}
        pagination={false}
        rowKey="id"
      />
      <Pagination
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        totalCount={totalCount}
        onPerPageChange={setPerPage}
        disablePageChange={isLoading || !totalCount}
        disablePerPageHandler={isLoading || !totalCount}
        elementsSize="xs"
      />
      <Modal
        width={450}
        destroyOnClose
        isOpen={isOpen}
        onClose={onModalClose}
        title={t('transaction.transactionReceipt')}
      >
        <div className={styles.infoForm}>
          <TransactionView
            close={onModalClose}
            transaction={payload as UnknownType as IClientTransaction}
          />
        </div>
      </Modal>
    </div>
  );
};

export default TransactionsTab;
