import { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';

const getColumnsByKeys = <RecordType extends Record<string, unknown>>(
  keys: string[],
  columns: ColumnsType<RecordType>,
) => {
  const columnMap = _.keyBy(columns, 'key');
  return keys.map(key => columnMap[key]).filter(Boolean);
};

export default getColumnsByKeys;
