import React, { ReactElement } from 'react';
import { Space } from 'antd';
import { useTranslate } from 'hooks';
import { CommisionType } from 'hooks/useCommission';
import { Wallet } from 'hooks/useCurrencyRate';
import { CountUp, CurrencyBadge } from 'components';
import styles from './CommissionView.module.scss';

export type CommissionViewPropsType = {
  currency: string;
  amount: number;
  commission: CommisionType | undefined,
  convertLoading: boolean;
  convert: (wallets: Wallet[], currency: string) => number;
};

export const CommissionView = ({
  amount,
  convert,
  currency,
  commission,
  convertLoading,
}: CommissionViewPropsType): ReactElement => {
  const { t } = useTranslate();

  const totalAmount = commission ? amount + Number(commission.serviceFee) : 0;

  return (
    <div className={styles.container}>
      <div className={styles.field}>
        <span className={styles.label}>{t('commission.title')}</span>
        <Space.Compact style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          {commission ? (
            <CountUp
              isRoundDown
              currency={currency}
              end={commission.serviceFee}
              className={styles.commission}
            />
          ) : (
            <span className={styles.totalAmount}>00,00</span>
          )}
          <CurrencyBadge currency={currency} textSize="16px" width="18px" gap={7} />
        </Space.Compact>
        {commission?.serviceFee && !convertLoading ? (
          <CountUp
            prefix="≈ "
            isRoundDown
            suffix=" USDT"
            currency="USDT"
            end={convert([{ currency, balance: commission.serviceFee }], 'USDT')}
            className={styles.converted}
          />
        ) : (
          <span className={styles.converted}>≈ 00.00 USDT</span>
        )}
      </div>

      <div className={styles.field}>
        <span className={styles.label}>{t('commission.amountReceived')}</span>
        <Space.Compact style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          {commission ? (
            <CountUp
              isRoundDown
              end={totalAmount}
              currency={currency}
              className={styles.totalAmount}
            />
          ) : (
            <span className={styles.totalAmount}>00,00</span>
          )}
          <CurrencyBadge currency={currency} textSize="16px" width="18px" gap={7} />
        </Space.Compact>
        {totalAmount && !convertLoading ? (
          <CountUp
            prefix="≈ "
            isRoundDown
            suffix=" USDT"
            currency="USDT"
            end={convert([{ currency, balance: totalAmount }], 'USDT')}
            className={styles.converted}
          />
        ) : (
          <span className={styles.converted}>≈ 00.00 USDT</span>
        )}
      </div>
    </div>
  );
};
