import React from 'react';
import styles from './MaybeRenderEmptyValue.module.scss';

type Props = {
  children?: React.ReactNode;
  minWidth?: string;
  condition?: boolean;
};

const MaybeRenderEmptyValue = ({ children, condition = true, minWidth }: Props) => {
  return (
    <span className={children && condition ? '' : styles.nullableValue} style={{ minWidth }}>
      {children && condition ? children : 'null'}
    </span>
  );
};

export default MaybeRenderEmptyValue;
