import { IRate } from 'pages/Processing/Balance/utils/getWalletsTotalBalance';

export const convertCurrency = (
  rates: IRate[],
  input: string,
  output: string,
  amount: number,
): number => {
  input = input?.toLowerCase().split(' ')[0];
  output = output?.toLowerCase().split(' ')[0];

  if (input === output) {
    return amount;
  }

  const rate = rates.find((r) => r.input === input && r.output === output);
  const rateReverse = rates.find((r) => r.input === output && r.output === input);

  if (rate) {
    return amount * rate.rate[1];
  }

  if (rateReverse) {
    return amount / rateReverse.rate[1];
  }

  const rateToUsdt = rates.find((r) => r.input === input && r.output === 'usdt');
  const rateToUsdtReverse = rates.find((r) => r.input === 'usdt' && r.output === input);
  let doubleConvertednAmount = 0;

  if (rateToUsdt) {
    const usdt = amount * rateToUsdt.rate[1];
    const rateUsdtToOutput = rates.find((r) => r.input === 'usdt' && r.output === output);
    const rateUsdtToOutputReverse = rates.find((r) => r.input === output && r.output === 'usdt');

    if (rateUsdtToOutput) {
      return doubleConvertednAmount = usdt * rateUsdtToOutput.rate[1];
    }

    if (rateUsdtToOutputReverse) {
      return doubleConvertednAmount = usdt / rateUsdtToOutputReverse.rate[1];
    }
  }

  if (rateToUsdtReverse) {
    const usdt = amount / rateToUsdtReverse.rate[1];
    const rateUsdtToOutput = rates.find((r) => r.input === 'usdt' && r.output === output);
    const rateUsdtToOutputReverse = rates.find((r) => r.input === output && r.output === 'usdt');

    if (rateUsdtToOutput) {
      return doubleConvertednAmount = usdt * rateUsdtToOutput.rate[1];
    }

    if (rateUsdtToOutputReverse) {
      return doubleConvertednAmount = usdt / rateUsdtToOutputReverse.rate[1];
    }
  }

  return doubleConvertednAmount;
};
