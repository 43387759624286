import axios, { AxiosError, SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';

type CreatePOBodyType = {
  amount: string,
  currency: string,
  payway: string,
  feeValue: string;
  totalFee: string;
  gasPrice?: string;
  walletAddress: string,
  group: number;
  category: number;
  otpCode?: string;
  annotation?: string;
  transactionCategoryId?: number,
  hardwareId?: string;
};

type CreatePOVariablesType = { body: CreatePOBodyType, uuid: string };

export type OptionType = {
  onSuccess?: (e: { qrcode?: string }, variables?: CreatePOVariablesType) => Promise<void>;
  onError?: (e: AxiosError<{ message: string }>, variables?: CreatePOVariablesType) => Promise<void>;
};

export const useCreatePayment = (options: OptionType) => {
  const UNIT_URL = `${SERVICE.CLIENT}/balance/withdraw/unit`;

  const create = async ({ body, uuid }: CreatePOVariablesType) => {
    const { data } = await axios.post(`${UNIT_URL}/${uuid}`, body);
    return data;
  };

  const {
    isPending: isCreating,
    mutate: mutateCreatePayment,
  } = useMutation({ mutationFn: create, ...options });

  return {
    mutateCreatePayment,
    isCreating,
  };
};
