import { SortOrder } from 'antd/es/table/interface';
import { TransactionsSortField, TransactionsSorting } from '../types';
import { adaptSortOrder } from '../../../../utils';

const adaptSort = (
  sortKey: TransactionsSortField | string, sortOrder: SortOrder,
): TransactionsSorting[] => {
  const direction = adaptSortOrder(sortOrder);

  switch (sortKey) {
    default: {
      return [{ field: sortKey as TransactionsSortField, direction }];
    }
  }
};
export default adaptSort;
