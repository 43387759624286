import { useCallback, useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import TrezorConnect, { DEVICE, DEVICE_EVENT, DeviceEventMessage } from '@trezor/connect-web';
import { notification } from 'antd';
import { useSelector } from 'init/rootReducer';
import { StorageType } from 'components/ColdStorage/types';
import { setColdStorageConnectedDeviceId, setColdStorageDevice } from 'components/ColdStorage/storage';

const useTrezorConnection = () => {
  const isColdStorageMode = useSelector((state) => state.coldStorageReducer.isColdStorageMode);
  const dispatch = useDispatch();

  const toggleTrezorConnected = useCallback((payload: { id: string; name: string; } | null) => {
    if (payload) {
      batch(() => {
        dispatch(setColdStorageConnectedDeviceId(payload.id));
        dispatch(setColdStorageDevice({
          ...payload,
          type: StorageType.Trezor,
        }));
      });
    } else {
      dispatch(setColdStorageConnectedDeviceId(null));
    }

    notification.close('trezor');
    notification.success({
      message: `Trezor has been ${payload ? 'connected' : 'disconnected'}`,
      key: 'trezor',
    });
  }, [dispatch]);

  useEffect(() => {
    const callback = async (event: DeviceEventMessage) => {
      if (event.type === DEVICE.CONNECT) {
        const uuid = event.payload.id;
        if (uuid) {
          toggleTrezorConnected({ id: uuid, name: event.payload.label || '' });
        } else {
          console.log('Trezor device ID is not found', event);
          throw new Error('Trezor device ID is not found');
        }
      } else if (event.type === DEVICE.DISCONNECT) {
        toggleTrezorConnected(null);
      } else if (event.type === DEVICE.CONNECT_UNACQUIRED) {
        notification.warn({
          message: 'Authorize the Trezor device using the Trezor Suite app',
          key: 'trezor',
        });
      }
    };

    if (isColdStorageMode) {
      // console.log('start listening trezor events');
      TrezorConnect.on(DEVICE_EVENT, callback);
    }

    return () => {
      // console.log('remove listening the trezor events');
      // toggleTrezorConnected(false);
      TrezorConnect.off(DEVICE_EVENT, callback);
    };
  }, [isColdStorageMode, toggleTrezorConnected]);
};

export default useTrezorConnection;
