interface TreeNode<T> {
  id: number | string;
  children?: T[];
}

const findElementById = <T extends TreeNode<T>>(tree: T[], targetId: TreeNode<T>['id']): T | undefined => {
  for (const node of tree) {
    if (node.id === targetId) {
      return node;
    }

    if (node.children) {
      const foundInChildren = findElementById(node.children, targetId);
      if (foundInChildren) {
        return foundInChildren;
      }
    }
  }

  return undefined;
};

export default findElementById;
