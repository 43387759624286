import React, { useMemo } from 'react';
import { Spin } from 'antd';
import { StorageType } from 'components/ColdStorage/types';
import { useCurrencyRate, useTranslate } from 'hooks';
import { useColdStorageBalance, useColdStorageDevice } from 'components/ColdStorage/hooks';
import { AssetAllocationTable } from './AssetAllocationTable';
import styles from './AllocationTable.module.scss';

const titleMap = new Map([
  [StorageType.Ledger, 'Ledger'],
  [StorageType.Trezor, 'Trezor'],
]);

const AllocationTable = () => {
  const { t } = useTranslate();
  const { coldStorageDevice } = useColdStorageDevice();
  const deviceType = coldStorageDevice?.type;
  const { data, isLoading: isLoadingColdBalance } = useColdStorageBalance();
  const { rates, isLoading: isLoadingCurrencyRate } = useCurrencyRate();

  const title = useMemo(() => {
    if (!deviceType || !titleMap.has(deviceType)) {
      return null;
    }
    return titleMap.get(deviceType);
  }, [deviceType]);

  if (!deviceType) {
    return null;
  }

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        {title && (
          <span className={styles.cardTitle}>{t('coldStorage.assetAllocation', { variables: { wallet: title } })}</span>
        )}
      </div>
      <div className={styles.cardBody}>
        {(isLoadingCurrencyRate || isLoadingColdBalance || !data) ? (
          <Spin />
        ) : (
          <AssetAllocationTable data={data} rates={rates} />
        )}
      </div>
    </div>
  );
};

export default AllocationTable;
