import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { GetSubaccountsByUuidResponse, QueryOptions, QueryParams } from './types';

const useGetSubaccountsByUuid = ({ uuid, ...queryParams }: QueryParams, queryOptions?: Partial<QueryOptions>) => {
  const getSubaccounts = async () => {
    const url = `${SERVICE.CLIENT}/clients/${uuid}/sub-wallets`;

    const { data } = await axios.get(url, { params: queryParams });
    return data;
  };

  return useQuery<GetSubaccountsByUuidResponse>({
    ...queryOptions,
    queryKey: ['client-subaccounts', uuid, queryParams.filter],
    queryFn: getSubaccounts,
    staleTime: 600000, // 10 minutes
  });
};

export default useGetSubaccountsByUuid;
