import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useSelector } from 'init/rootReducer';
import { ColdStorageWallet, Currencies, StatusVariant } from '../types';
import { useNestedUnitContext } from 'pages/Administration/Units/components/NestedUnit/context';
import { CreateWalletDTO, useCreateWallet } from 'components/WalletOverview/hooks';
import { NETWORKS_TO_CURRENCY_AND_NETWORK_MAP } from '../constants';
import { removeColdStorageWallet, setColdStorageWallets } from '../storage';
import { useColdStorageDevice } from './index';

export enum ButtonAction {
  CONTINUE,
  CONNECT,
}

export type ConnectedWallets = Record<string, ColdStorageWallet>;

export type UseWalletsConnectParams = {
  onContinue: () => void;
  connectWallet: (coin: Currencies) => Promise<ColdStorageWallet>;
};

const useWalletsConnect = ({ onContinue, connectWallet }: UseWalletsConnectParams) => {
  const { unit, refetch } = useNestedUnitContext();
  const dispatch = useDispatch();
  const connectedDeviceId = useSelector((state) => state.coldStorageReducer.connectedDeviceId);
  const { coldStorageDevice } = useColdStorageDevice();
  const coldStorageWallets = coldStorageDevice?.wallets || {};

  const [isCreatingWallets, setIsCreatingWallets] = useState(false);
  const [buttonAction, setButtonAction] = useState(ButtonAction.CONNECT);
  const [checkedNetworks, setCheckedNetworks] = useState<Currencies[]>(Object.keys(coldStorageWallets) as Currencies[] || []);
  const [connectionStatus, setConnectionStatus] = useState<Record<string, StatusVariant>>(() => {
    const result: Record<string, StatusVariant> = {};
    Object.keys(coldStorageWallets).forEach((currency) => result[currency] = StatusVariant.Success);
    return result;
  });
  const [connectedWallets, setConnectedWallets] = useState<ConnectedWallets>(() => {
    const result: ConnectedWallets = {};
    Object.entries(coldStorageWallets).forEach(([currency, wallet]) => result[currency] = wallet);
    return result;
  });

  const { createWalletsManyAsync } = useCreateWallet(unit?.id, {
    onSuccess: refetch,
  });

  const changeCheckedNetworks = useCallback((currency: Currencies) => {
    const isChecked = !checkedNetworks.includes(currency);
    const updatedCheckedNetworks = !isChecked
      ? checkedNetworks.filter((el) => el !== currency)
      : [...checkedNetworks, currency];

    setCheckedNetworks(updatedCheckedNetworks);

    if (isChecked && buttonAction === ButtonAction.CONTINUE) {
      setButtonAction(ButtonAction.CONNECT);
    }
    if (!isChecked) {
      setConnectionStatus((prevState) => {
        const updatedState = { ...prevState };
        delete updatedState[currency];
        return updatedState;
      });
      setConnectedWallets((prevState) => {
        const updatedState = { ...prevState };
        delete updatedState[currency];
        return updatedState;
      });
      if (connectedDeviceId) {
        dispatch(removeColdStorageWallet({ id: connectedDeviceId, currency }));
      }
    }
  }, [buttonAction, checkedNetworks, connectedDeviceId, dispatch]);

  const connectWalletWrapper = useCallback(async (coin: Currencies) => {
    const setStatus = (status: StatusVariant) => {
      setConnectionStatus((prevState) => ({
        ...prevState,
        [coin]: status,
      }));
    };

    try {
      setStatus(StatusVariant.Connecting);
      const response = await connectWallet(coin);
      if (response) {
        setConnectedWallets((prevState) => {
          return {
            ...prevState,
            [coin]: response,
          };
        });
      }

      setStatus(StatusVariant.Success);
      return response;
    } catch (error) {
      setStatus(StatusVariant.Error);
    }
  }, [connectWallet]);

  const connectWallets = useCallback(async (coins: Currencies[]) => {
    return coins.reduce(
      async (previousPromise, coin) => {
        await previousPromise;
        const response = await connectWalletWrapper(coin);
        return Promise.resolve(Boolean(response)) || previousPromise;
      },
      Promise.resolve(false));
  },
  [connectWalletWrapper]);

  const handleContinue = useCallback(async () => {
    if (!checkedNetworks.length) {
      notification.close('coldStorage');
      notification.warn({ key: 'coldStorage', message: 'Please, select network' });
      return;
    }

    if (buttonAction === ButtonAction.CONNECT) {
      const newConnectionStatus: Record<string, StatusVariant> = {};
      checkedNetworks.forEach((network) => {
        newConnectionStatus[network] = connectionStatus[network] === StatusVariant.Success
          ? StatusVariant.Success
          : StatusVariant.Loading;
      });
      setConnectionStatus(newConnectionStatus);

      const unconnectedNetworks = checkedNetworks.filter((network) => connectionStatus[network] !== StatusVariant.Success);
      if (unconnectedNetworks.length === 0) {
        return onContinue();
      }
      const hasConnectedWallets = await connectWallets(unconnectedNetworks);

      if (!hasConnectedWallets) {
        notification.close('coldStorage');
        notification.error({ key: 'coldStorage', message: 'Failed to connect wallets' });
        return setButtonAction(ButtonAction.CONNECT);
      }

      setButtonAction(ButtonAction.CONTINUE);
    } else {
      if (!connectedDeviceId) {
        throw new Error('Connected device ID is not found');
      }
      dispatch(setColdStorageWallets({
        id: connectedDeviceId,
        wallets: connectedWallets,
      }));
      const walletsToCreate = Object.keys(connectedWallets).map((currency) => {
        const currencyAndNetwork = NETWORKS_TO_CURRENCY_AND_NETWORK_MAP.get(currency);
        if (!currencyAndNetwork) return;
        return {
          ...currencyAndNetwork,
          hardwareId: connectedDeviceId,
        };
      }) as CreateWalletDTO[];

      const filteredWallets = !unit?.wallets.length
        ? walletsToCreate
        : walletsToCreate.filter((walletToCreate) => unit?.wallets && unit.wallets.find((wallet) => {
          return !(
            wallet.currency === walletToCreate.currency
            && wallet.network === walletToCreate.network
            && wallet.hardwareId === connectedDeviceId
          );
        }));

      setIsCreatingWallets(true);
      await createWalletsManyAsync(filteredWallets);
      setIsCreatingWallets(false);
      onContinue();
    }
  }, [
    checkedNetworks,
    buttonAction,
    connectWallets,
    connectionStatus,
    onContinue,
    connectedDeviceId,
    dispatch,
    connectedWallets,
    unit,
    createWalletsManyAsync,
  ]);

  return {
    checkedNetworks,
    changeCheckedNetworks,
    handleContinue,
    connectionStatus,
    retryConnect: connectWalletWrapper,
    isCreatingWallets,
  };
};

export default useWalletsConnect;
