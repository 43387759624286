import LocalStorage, { LocalStorageKey } from 'utils/localStorage';
import syncColumns from './syncColumns';

export type StoredColumnsState = {
  orderedKeys: string[];
  visibleKeys: string[];
};

const getStoredColumnsState = ({
  storageKey,
  initialColumns,
  initiallyHiddenColumns,
}: {
  storageKey: LocalStorageKey,
  initialColumns: string[],
  initiallyHiddenColumns: string[],
}) => {
  const storedState = LocalStorage.get(storageKey) as string | null;
  const parsedState = storedState ? JSON.parse(storedState) as StoredColumnsState : null;

  if (parsedState) {
    const { orderedKeys, visibleKeys } = parsedState;
    const syncedState = syncColumns(orderedKeys, initialColumns, visibleKeys, orderedKeys);

    return {
      visibleKeys: syncedState.visibleKeys,
      orderedKeys: syncedState.orderedKeys,
    };
  }

  const initialVisibleKeys = initialColumns.filter(key => !initiallyHiddenColumns.includes(key));

  return {
    visibleKeys: initialVisibleKeys,
    orderedKeys: initialColumns,
  };
};

export default getStoredColumnsState;
