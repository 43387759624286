import React, { useCallback, useMemo, useState } from 'react';
import { Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { IUnit } from 'interfaces';
import { UnknownType } from 'types/Unknown';
import { UnitFormData } from '../../types';
import { useTranslate } from 'hooks';
import { Button, OrganizationalLogoUpload } from 'components';
import styles from './FormUnit.module.scss';

export type InitialValues = IUnit & { rootUnitId: number; unitId: number };

export interface ModalUnitFormProps {
  initialValues: InitialValues;
  loading: boolean;
  onClose: () => void;
  onSubmit: (data: FormData) => void;
}

const FormUnit = ({
  loading,
  onClose,
  onSubmit,
  initialValues,
}: ModalUnitFormProps) => {
  const { t } = useTranslate();
  const [form] = useForm();

  const [changedValues, setChangedValues] = useState<string[]>([]);

  const isLogoChanged = useMemo(() => changedValues.includes('logo'), [changedValues]);

  const handleFinish = useCallback((values: UnitFormData) => {
    const formData = new FormData();

    if (values.logo && isLogoChanged) {
      formData.set('file', values.logo);
    }
    if (!values.logo && isLogoChanged) {
      formData.set('isRemoveLogo', 'true');
    }
    if (values.name?.trim()) {
      formData.set('name', values.name.trim());
    }
    if (values.description?.trim()) {
      formData.set('description', values.description.trim());
    }
    if (values.isActive !== undefined) {
      formData.set('isActive', JSON.stringify(values.isActive));
    }
    if (values.rootUnitId) {
      formData.set('rootUnitId', values.rootUnitId.toString());
    }
    if (initialValues.rootUnitId) {
      formData.set('rootUnitId', initialValues.rootUnitId.toString());
    }
    if (initialValues.unitId) {
      formData.set('parent', initialValues.unitId.toString());
    }

    onSubmit(formData);
  }, [initialValues, isLogoChanged, onSubmit]);

  const handleValuesChange = useCallback((values: UnknownType) => {
    const [changedValueKey] = Object.keys(values);

    if (changedValues.includes(changedValueKey)) return;

    setChangedValues(prevState => [...prevState, changedValueKey]);
  }, [changedValues]);

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      onValuesChange={handleValuesChange}
      initialValues={initialValues}
      className={styles.form}
    >
      <div>
        <Form.Item name="logo">
          <OrganizationalLogoUpload type="unit" />
        </Form.Item>

        <Form.Item
          name="name"
          label={t('users.units.unitName')}
          className={styles.formItem}
          rules={[
            { min: 1, max: 100 },
            {
              required: true, whitespace: false,
              message: t('users.units.validation.enterUnitName'),
            },
          ]}
        >
          <Input
            className={styles.input}
            placeholder={t('users.units.validation.enterUnitName')}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label={t('users.units.desc')}
          className={styles.formItem}
        >
          <Input
            className={styles.input}
            placeholder={t('users.units.validation.enterDesc')}
          />
        </Form.Item>
      </div>

      <div className={styles.buttons}>
        <Button
          block
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          {t('save')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          disabled={loading}
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
      </div>
    </Form>
  );
};

export default FormUnit;
