import React, { ReactElement, useCallback } from 'react';
import clsx from 'clsx';
import { Pages, PerPageItem, PerPageSelect } from './components';
import styles from './Pagination.module.scss';

export type PerPageNumber = 5 | 10 | 15 | 25 | 50 | 100;
export type ElementsSize = 'xs' | 'small' | 'medium' | 'large';

export interface IPaginationProps {
  page: number;
  totalCount: number;
  perPage: PerPageNumber;
  disablePageChange: boolean;
  disablePerPageHandler: boolean;
  onPageChange: (page: number) => void;
  onPerPageChange: (perPage: PerPageNumber) => void;
  elementsSize?: ElementsSize;
  perPageItems?: PerPageNumber[];
}

// const perPageItems: PerPageNumber[] = [25, 50, 100];

const Pagination = ({
  page,
  perPage,
  totalCount,
  onPageChange,
  onPerPageChange,
  disablePageChange,
  disablePerPageHandler,
  elementsSize = 'large',
  perPageItems = [25, 50, 100],
}: IPaginationProps): ReactElement => {
  const onPerPageClick = useCallback((perPageCount: PerPageNumber) => () => {
    onPerPageChange(perPageCount);
  }, [onPerPageChange]);

  const pagesCount = Math.ceil(totalCount / perPage) || 1;

  const paginationCls = clsx(styles.pagination, {
    [styles.large]: elementsSize === 'large',
    [styles.medium]: elementsSize === 'medium',
    [styles.small]: elementsSize === 'small',
  });

  return (
    <section className={styles.pagination}>
      <div className={styles.perPageBlock}>
        {elementsSize === 'xs' ? (
          <PerPageSelect
            items={perPageItems}
            selected={perPage}
            disabled={disablePerPageHandler}
            onSelect={onPerPageClick}
          />
        ) : perPageItems.map((perPageCount) => (
          <PerPageItem
            key={perPageCount}
            count={perPageCount}
            disabled={disablePerPageHandler}
            selected={perPage === perPageCount}
            onClick={onPerPageClick(perPageCount)}
            size={elementsSize}
          />
        ))}
      </div>
      <Pages
        page={page}
        count={pagesCount}
        onPageSelect={onPageChange}
        disabled={disablePageChange}
        size={elementsSize}
      />
    </section>
  );
};

export default Pagination;
