import React, { useCallback, useMemo, useState } from 'react';
import { AxiosError } from 'libs/axios';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { notification } from 'antd';
import { IClientTransaction } from 'interfaces/IClientTransaction';
import { UnknownType } from 'types/Unknown';
import { ITransactionCategory, ITransactionGroup } from 'pages/Administration/FinancialManagement/api/types';
import { useTranslate } from 'hooks';
import useUpdateTransacitonCategory from 'hooks/useUpdateTransacitonCategory';
import useFocusTrack from './hooks/useFocusTrack';
import getCategoryOptions from './utils/getCategoryOptions';
import getDefaultOptions from './utils/getDefaultOptions';
import getGroupOptions from './utils/getGroupOptions';
import CategorizeTransactionSelect, { CategorizeTransactionSelectDropdown } from './CategorizeTransactionSelect';
import styles from './CategorizeTransactionCell.module.scss';

const CategorizeTransactionCell = ({
  transaction: { id, category, unit },
}: {
  transaction: IClientTransaction,
}) => {
  const [selectedGroup, setSelectedGroup] = useState<ITransactionGroup['id']>(category.group.id);
  const [selectedCategory, setSelectedCategory] = useState<ITransactionCategory['id'] | null>(category.id);
  const { t } = useTranslate();

  const resetToInitialValues = () => {
    setSelectedGroup(category.group.id);
    setSelectedCategory(category.id);
  };

  // Reset to initial values if focus moves outside select group and no selected category
  const { focusedElementRef, handleElementBlur } = useFocusTrack({
    extraCondition: !selectedCategory,
    onBlur: resetToInitialValues,
  });

  const { mutate } = useUpdateTransacitonCategory({
    onSuccess: () => {
      cleverRefetchQueries('transactions-manager');

      notification.success({
        message: t('transaction.categorySuccessfullyUpdated'),
      });
    },
    onError: (error: AxiosError<{ message: string }>) => {
      resetToInitialValues();

      notification.error({
        message: error.response?.data?.message || t('internalServerError'),
        description: error.message,
      });
    },
  });

  const [data, setData] = useState<{ nodes: ITransactionGroup[], isLoading: boolean }>({
    isLoading: false,
    nodes: [],
  });

  const groupOptions = useMemo(() => {
    const options = getGroupOptions(data.nodes);
    return options.length ? options : getDefaultOptions(category.group);
  }, [category.group, data.nodes]);

  const categoryOptions = useMemo(() => {
    const options = getCategoryOptions(data.nodes, selectedGroup);
    return options.length ? options : getDefaultOptions(category);
  }, [category, data.nodes, selectedGroup]);

  const handleChangeGroup = (newGroup: number) => {
    setSelectedGroup(newGroup);
    setSelectedCategory(null);
  };

  const handleChangeCategory = (newCategory: number) => {
    setSelectedCategory(newCategory);
    mutate({ transactionId: id, categoryId: newCategory });
  };

  const dropdownRender = useCallback((menu: React.ReactElement) => (
    <CategorizeTransactionSelectDropdown
      unitId={(unit as UnknownType).uuid}
      setData={setData}
      render={menu}
    />
  ), [unit]);

  if (!unit) return null;

  return (
    <div className={styles.wrapper} ref={focusedElementRef} onBlur={handleElementBlur}>
      <CategorizeTransactionSelect
        virtual={false}
        listHeight={200}
        value={selectedGroup}
        onChange={handleChangeGroup}
        loading={data.isLoading}
        options={groupOptions}
        dropdownRender={dropdownRender}
      />

      <CategorizeTransactionSelect
        virtual={false}
        listHeight={200}
        value={selectedCategory}
        onChange={handleChangeCategory}
        loading={data.isLoading}
        options={categoryOptions}
        placeholder={t('transaction.chooseCategory')}
        dropdownRender={dropdownRender}
      />
    </div>
  );
};

export default CategorizeTransactionCell;
