import axios, { AxiosError, SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { INotification } from 'interfaces/INotification';

type Options = {
  onSuccess?: (data: INotification | string) => void;
  onError?: (e: AxiosError<{ message: string }>) => void;
};

const useNotificationAlert = (options?: Options) => {
  const getAlert = async () => {
    const { data } = await axios.get(
      `${SERVICE.NOTIFICATION}/notifications/alert`,
    );

    return data;
  };

  return useQuery<INotification | string>({
    queryKey: ['alerting-notification'],
    queryFn: getAlert,
    ...options,
  });
};

export default useNotificationAlert;
