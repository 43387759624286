import WAValidator from 'multicoin-address-validator';

export const walletValidator = async (address: string, network: string) => {
  switch (network) {
    case 'TRX':
    case 'TRC20':
      return Boolean(WAValidator.validate(address, 'TRX'));
    case 'ETH':
    case 'ERC20':
    case 'BNB':
    case 'BEP20':
      return Boolean(WAValidator.validate(address, 'ETH', process.env.REACT_APP_NETWORK_ID));
    case 'BTC':
      return Boolean(WAValidator.validate(address, 'BTC', process.env.REACT_APP_NETWORK_ID));
    default:
      return false;
  }
};
