import axios, { SERVICE } from 'libs/axios';

type TransactionCategoryUpdateDto = {
  name?: string;
  groupId?: number;
  color?: string;
  description?: string;
  unitIds?: number[];
  rootUnitId?: number;
};

export type TransactionCategoryUpdateVariables = TransactionCategoryUpdateDto & {
  id: number,
  name: string;
};

export const transactionCategoryUpdate = async ({ id, ...body }: TransactionCategoryUpdateVariables) => {
  const { data } = await axios.patch(
    `${SERVICE.CLIENT}/transaction-categories/${id}`, body,
  );
  return data;
};
