import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';

type Props = {
  userId?: number;
};
export const useActiveSessions = (props?: Props) => {
  const getActiveSessions = async () => {
    const { data } = await axios.get(
      `${SERVICE.ADMIN}/auth/active-sessions${props?.userId ? `/${props.userId}` : ''}`,
    );
    return data;
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['active-sessions', props],
    queryFn: getActiveSessions,
  });

  return {
    data,
    refetch,
    isLoading,
  };
};
