import axios, { AxiosError, SERVICE } from 'libs/axios';
import { UseMutateFunction, useMutation } from 'libs/reactQuery';
import { UnknownType } from 'types/Unknown';
import { ICreateClient } from '../components/FormCreateClient/FormCreateClient';

type OptionType = {
  onSuccess?: (res: UnknownType) => void;
  onError?: (e: AxiosError<{ message: string }>) => void;
};

type ReturnedType = {
  mutation: UseMutateFunction<void, AxiosError<{
    message: string
  }>, ICreateClient, unknown>,
  loading: boolean
};

export const useCreateClient = (options: OptionType): ReturnedType => {
  const createClient = async (body: ICreateClient) => {
    const { data } = await axios.post(SERVICE.CLIENT + '/clients/create', body);
    return data;
  };

  const { mutate, isPending } = useMutation({ mutationFn: createClient, ...options });

  return {
    mutation: mutate,
    loading: isPending,
  };
};
