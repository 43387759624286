import React, { useCallback, useState } from 'react';
import { Input } from 'antd';
import { useTranslate } from 'hooks';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import styles from './SearchInput.module.scss';

export type SearchInputProps = {
  loading?: boolean;
  onSearch: (value: string) => void;
};

const SearchInput = ({ onSearch, loading }: SearchInputProps) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslate();

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    onSearch(event.target.value);
  }, [onSearch]);

  return (
    <Input
      value={search}
      onChange={handleChange}
      placeholder={`${t('search')}...`}
      addonBefore={loading
        ? <LoadingOutlined style={{ fontSize: 15 }} />
        : <SearchOutlined style={{ fontSize: 15 }} />}
      className={styles.input}
    />
  );
};

export default SearchInput;
