import Eth from '@ledgerhq/hw-app-eth';
import type { Resolver } from '../../types';
// import eip55 from 'eip55';

const resolver: Resolver = async (
  transport, { path, verify, askChainCode },
) => {
  const eth = new Eth(transport);

  const result = await eth.getAddress(path, verify, askChainCode || false);

  // const address = eip55.encode(r.address);

  return {
    path,
    address: result.address,
    publicKey: result.publicKey,
    chainCode: result.chainCode,
  };
};

export default resolver;
