import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import { CountUp } from 'components/CountUp';
import styles from 'components/TotalBalance/TotalBalance.module.scss';

type Segment = {
  color: string;
  value: number;
  tooltip: {
    currency: string;
    value: number;
  };
};

const sortSegments = (segments: Segment[]): Segment[] => segments.sort((a, b) => b.value - a.value);

interface ProgressBarProps {
  segments: Segment[];
}

const formatTooltipPercent = (percent: number) => {
  if (percent < 0.01) {
    return '< 0.01%';
  }
  return `${percent.toFixed(2)}%`;
};

const ProgressBar = ({ segments }: ProgressBarProps) => {
  const sortedSegments = useMemo(() => {
    return sortSegments(segments);
  }, [segments]);

  return (
    <div className={styles.progressBar}>
      {sortedSegments.map((segment, index) => (
        <Tooltip
          key={index}
          title={(
            <span>
              <CountUp
                currency={segment.tooltip.currency}
                end={segment.tooltip.value}
                isRoundDown
              />
              {' '}{segment.tooltip.currency}
              <br />
              <span style={{ color: '#FFFAF899' }}>
                {formatTooltipPercent(segment.value)}
              </span>
            </span>
          )}
        >
          <div
            className={styles.progressSegment}
            style={{
              backgroundColor: segment.color,
              width: `${segment.value}%`,
            }}
          />
        </Tooltip>
      ))}
    </div>
  );
};

export default ProgressBar;
