import { SERVICE } from 'libs/axios';
import { CurrencyEnum } from 'types/Currency';

const generateCurrencyUrl = (currency: CurrencyEnum | 'erc20' | 'trc20'): string => {
  switch (currency) {
    case 'erc20':
    case 'USDC':
    case CurrencyEnum.ETH:
    case CurrencyEnum.ERC20:
      return `${SERVICE.ETH}`;
    case 'trc20':
    case CurrencyEnum.TRX:
    case CurrencyEnum.TRC20:
      return `${SERVICE.TRON}`;
    case CurrencyEnum.BTC:
      return `${SERVICE.BTC}`;
    case CurrencyEnum.BNB:
      return `${SERVICE.BNB}`;
    default:
      return '';
  }
};

export default generateCurrencyUrl;
