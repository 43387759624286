import axios, { AxiosError, SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { Filter } from 'types/Filter';
import { UnknownType } from 'types/Unknown';
import { UsersSorting } from '../types';

type UsersParams = {
  filter: Filter<Record<string, unknown>> | UnknownType;
  paging?: { limit: number; offset?: number };
  sorting?: UsersSorting[];
};
type OptionType = {
  onSuccess?: () => Promise<void>;
  onError?: (e: AxiosError<{ message: string; }>) => void;
};

export const useUsers = (params: UsersParams, options?: OptionType) => {
  const getUsers = async () => {
    const { data } = await axios.get(SERVICE.ADMIN + '/users', { params });
    return data;
  };

  return useQuery({
    queryKey: ['managers', params.filter, params.sorting],
    queryFn: getUsers,
    ...options,
  });
};
