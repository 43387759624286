import React from 'react';
import styled from 'styled-components';
import { UnknownType } from 'types/Unknown';
import { Box } from './Box.styled';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export type Status = 'next' | 'active' | 'valid' | 'error' | 'disable';

const RADIUS = 24;

const Wrapper: UnknownType = styled(Box).attrs<{ status: Status }>(p => ({
  grow: true,
  relative: true,
  justifyContent: 'center',
  color: ['active', 'valid'].includes(p.status) ? 'blue' : p.status === 'error' ? 'red' : 'teal',
}))`
  width: ${RADIUS}px;
  flex-shrink: 0;
  text-align: center;
  align-items: center;
  font-size: 12px;
`;

const StepNumber = styled(Box)<{ status: Status }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  border: 1px solid ${p => ['active', 'valid'].includes(p.status) ? 'var(--primary-color)' : p.status === 'error' ? 'red' : '#1da1f266'};
  color: ${p => ['active', 'valid'].includes(p.status) ? 'var(--white-color)' : p.status === 'error' ? 'red' : 'var(--primary-color)'};
  background-color: ${p => ['active', 'valid'].includes(p.status) ? 'var(--primary-color)' : p.status === 'error' ? 'red' : 'var(--white-color)'};
  font-size: 14px;
  font-weight: 500;
  width: ${RADIUS}px;
  height: ${RADIUS}px;
  line-height: ${RADIUS}px;
  box-sizing: content-box;
  transition: all ease-in-out 0.1s ${p => (['active', 'valid'].includes(p.status) ? 0.4 : 0)}s;
`;

const Label = styled(Box)<{ status: Status }>`
  position: absolute;
  top: 28px;
  color: 1px solid ${p => ['active', 'valid'].includes(p.status) ? 'var(--primary-color)' : p.status === 'error' ? 'red' : '#1da1f266'};
  line-height: 1.2;
  transition: color ease-in-out 0.1s ${p => (['active', 'valid'].includes(p.status) ? 0.4 : 0)}s;
`;

type StepProps = {
  number: number;
  status: Status;
  children: React.ReactNode;
};

const Step = ({ number, status, children }: StepProps) => {
  return (
    <Wrapper status={status}>
      <StepNumber status={status}>
        {status === 'active' || status === 'next' ? (
          number
        ) : status === 'valid' ? (
          <CheckOutlined size={10} />
        ) : (
          <CloseOutlined size={10} style={{ color: 'var(--white-color)' }} />
        )}
      </StepNumber>
      <Label status={status}>{children}</Label>
    </Wrapper>
  );
};

export default Step;
