import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { IUser } from 'interfaces/IUser';
import { UnknownType } from 'types/Unknown';
import { useAuthorisationContext } from 'contexts';

export enum StatusStateEnum {
  ONLINE = 'online',
  OFFLINE = 'offline',
  AWAY = 'away',
  DO_NOT_DISTURB = 'do_not_disturb',
}

export enum StatusTypeEnum {
  AUTO = 'auto', // system update
  MANUAL = 'manual', // manual update by manager
}

export type UpdateStatusBody = {
  status: StatusStateEnum;
  type?: StatusTypeEnum;
};

const update = async (body: UpdateStatusBody) => {
  const { data } = await axios.put(`${SERVICE.ADMIN}/users/update-status`, body);
  return data;
};

const useUpdateStatus = () => {
  const { user, setUser } = useAuthorisationContext();

  return useMutation({
    mutationFn: update,
    onMutate: body => {
      setUser({
        ...user as IUser,
        status: body.status,
        isManualStatus: !body.type ? (user as IUser).isManualStatus : body.type === StatusTypeEnum.MANUAL,
      });

      return { previousState: user as IUser };
    },
    onError: (_err, _newStatus, context: UnknownType) => {
      setUser(context.previousState);
    },
  });
};

export default useUpdateStatus;
