import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { UseQueryHandlers } from 'types/UseQueryHandlers';

export type DeleteWhitelistWalletParams = {
  uuid: string; // Unit uuid
  id: number; // Wallet id
};

const useDeleteWhitelistWallet = (options?: UseQueryHandlers) => {
  return useMutation({
    mutationFn: async ({ uuid, id }: DeleteWhitelistWalletParams) => {
      const response = await axios.delete(
        `${SERVICE.CLIENT}/whitelist-wallets/${uuid}/${id}`,
      );
      return response.data;
    },
    ...options,
  });
};

export default useDeleteWhitelistWallet;
