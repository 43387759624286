import React, { useContext, useMemo } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { Locale } from 'contexts/TranslateContext/interfaces/Locale';
import { TranslateContext } from 'contexts';
import { UpOutlined } from '@ant-design/icons';
import styles from './LanguageDropdown.module.scss';

const LocaleDropdown = () => {
  const { locales, changeLocale, localeSettings } = useContext(TranslateContext);

  const items: MenuProps['items'] = useMemo(() => (
    Object.entries(locales).map(([locale, { image }]) => ({
      key: locale,
      className: styles.option,
      label: (
        <div
          className={styles.language}
          onClick={() => changeLocale(locale as Locale)}
        >
          <img
            src={image}
            alt={locale}
            className={styles.icon}
          />
          <span className={styles.locale}>{locale}</span>
        </div>
      ),
    }))
  ), [changeLocale, locales]);

  return (
    <Dropdown
      placement="bottomRight"
      menu={{ items, className: styles.menu }}
    >
      <div className={styles.wrapper}>
        <img
          src={localeSettings.image}
          alt={localeSettings.locale}
          className={styles.icon}
        />
        <div className={styles.languageWithArrow}>
          <span className={styles.language}>{localeSettings.locale}</span>
          <UpOutlined className={styles.arrow} />
        </div>
      </div>
    </Dropdown>
  );
};

export default LocaleDropdown;
