import axios, { SERVICE } from 'libs/axios';

type TransactionGroupUpdateDto = {
  name?: string;
  categoryIds?: number[]
  color?: string;
  description?: string;
  rootUnitId?: number;
};

export type TransactionGroupUpdateVariables = TransactionGroupUpdateDto & { id: number, name: string };

export const transactionGroupUpdate = async ({ id, ...body }: TransactionGroupUpdateVariables) => {
  const { data } = await axios.patch(
    `${SERVICE.CLIENT}/transaction-categories/group/${id}`, body,
  );
  return data;
};
