import React from 'react';
import { noop } from 'lodash';
import { CreateStepsOptions, InternalStep } from '../types';
import { CheckTrezorConnection, CompletedConnection, SelectTrezorWallets } from '../components';

const createSteps = ({ onBackToMethods = noop, onModalClose = noop }: CreateStepsOptions): InternalStep[] => [
  {
    id: 'connect',
    label: 'connect',
    component: CheckTrezorConnection,
    onBack: () => onBackToMethods(),
    noScroll: true,
  },
  {
    id: 'chooseCurrency',
    label: 'currency',
    component: SelectTrezorWallets,
    onBack: (props) => props?.transitionTo('connect'),
  },
  {
    id: 'finish',
    label: 'finish',
    component: () => (
      <CompletedConnection
        onComplete={() => {
          onBackToMethods(true);
          onModalClose();
        }}
      />
    ),
    onBack: (props) => props?.transitionTo('chooseCurrency'),
  },
];

export default createSteps;
