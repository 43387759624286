import { createContext, useContext } from 'react';
import { noop } from 'lodash';
import { NestedUnitType } from 'interfaces';

export type NestedUnitContextType = {
  unit: NestedUnitType | undefined;
  refetch: () => void,
};

export const NestedUnitContext = createContext<NestedUnitContextType>({
  unit: undefined,
  refetch: noop,
} as NestedUnitContextType);

export const useNestedUnitContext = () => useContext(NestedUnitContext);
