import { useCallback, useState } from 'react';
import React from 'react';
import { Checkbox, Tooltip } from 'antd';
import { AssignableUserItemType } from '../AssignableUserList';
import styles from './AssignableUserItem.module.scss';

export type AssignableUserItemProps = {
  item: AssignableUserItemType;
  checked: boolean;
  onChange: (value: boolean) => void;
};

const AssignableUserItem = React.forwardRef<HTMLDivElement, AssignableUserItemProps>(({
  item,
  checked,
  onChange,
}, ref) => {
  const [state, setState] = useState<boolean>(checked);

  const handleChange = useCallback(() => {
    setState((prevState) => {
      onChange(!prevState);
      return !prevState;
    });
  }, [onChange]);

  return (
    <div ref={ref} className={styles.item}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <div className={styles.circle}>{item.name[0]}</div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
          <div className={styles.name}>{item.name}</div>
          <div className={styles.email}>{item.email}</div>
        </div>
      </div>

      <Tooltip title={item.reason}>
        <Checkbox
          checked={state}
          onChange={handleChange}
          disabled={item.disabled}
        />
      </Tooltip>
    </div>
  );
});

export default AssignableUserItem;
