import Trx from '@ledgerhq/hw-app-trx';
import type { Resolver } from '../../types';

const resolver: Resolver = async (transport, { path, verify }) => {
  const trx = new Trx(transport);

  const result = await trx.getAddress(path, verify);

  return {
    path,
    address: result.address,
    publicKey: result.publicKey,
  };
};

export default resolver;
