import React from 'react';
import {
  LockedDeviceError,
  ManagerDeviceLockedError,
  TransportOpenUserCancelled,
  UserRefusedAddress,
  UserRefusedAllowManager,
  UserRefusedFirmwareUpdate,
  UserRefusedOnDevice,
} from '@ledgerhq/errors';
import clsx from 'clsx';
import {
  CloseOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  LockOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import styles from './ErrorIcon.module.scss';

export type ErrorIconProps = {
  error: Error,
  size?: number,
};

const ErrorIcon = ({ error, size = 44 }: ErrorIconProps) => {
  switch (true) {
    // case !error:
    //   return null;

    // case error instanceof DeviceNotOnboarded:
    //   return (
    //     <InfoCircleOutlined size={size} className={clsx(styles.icon, styles.info)} />
    //   );
    // case error instanceof UserRefusedFirmwareUpdate:
    //   return (
    //     <WarningOutlined
    //       style={{ fontSize: size }}
    //       className={clsx(styles.icon, styles.warn)}
    //     />
    //   );
    case error instanceof TransportOpenUserCancelled:
    case error instanceof UserRefusedOnDevice:
    case error instanceof UserRefusedAddress:
      // case error instanceof SwapGenericAPIError:
      // case error instanceof NoSuchAppOnProvider:
      return (
        <CloseOutlined
          style={{ fontSize: size }}
          className={clsx(styles.icon, styles.close)}
        />
      );
    case error instanceof LockedDeviceError:
    // case error instanceof ManagerDeviceLockedError:
      return (
        <LockOutlined
          style={{ fontSize: size }}
          className={clsx(styles.icon, styles.lock)}
        />
      );

    default:
      return (
        <ExclamationCircleOutlined
          style={{ fontSize: size }}
          className={clsx(styles.icon, styles.exclamation)}
        />
      );
  }
};

export default ErrorIcon;
