import axios, { SERVICE } from 'libs/axios';
import JWT from 'jsonwebtoken';
import { UnknownType } from 'types/Unknown';
import LocalStorage, { LocalStorageKey } from 'utils/localStorage';

const decodeToken = (token: unknown): UnknownType =>
  typeof token === 'string' ? JWT.decode(token) : null;

const getAccessTokenExpDate = () => {
  const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
  const decodedToken = accessToken ? decodeToken(accessToken) : null;
  const nowTime = Math.floor(new Date().getTime() / 1000);

  return decodedToken?.exp || nowTime;
};

export const isAccessTokenExpiring = () => {
  const nowTime = Math.floor(new Date().getTime() / 1000);
  const accessTokenExpDate = getAccessTokenExpDate();

  return (accessTokenExpDate - 60) <= nowTime;
};

export const isAccessTokenExpired = () => {
  const accessTokenExpDate = getAccessTokenExpDate();
  const nowTime = Math.floor(new Date().getTime() / 1000);

  return (accessTokenExpDate < nowTime);
};

let hasRequestedRefresh = false;
let resetRequestTimeout: ReturnType<typeof setTimeout> | null = null;

const THROTTLE_DELAY = 1500;

export const refreshSession = async (fingerprint: string | undefined) => {
  if (hasRequestedRefresh) {
    return; // Ignore subsequent requests
  }

  hasRequestedRefresh = true;

  try {
    const response = await axios.post(`${SERVICE.ADMIN}/auth/refresh-token`, { fingerprint });
    const { data } = response || {};
    if (data?.accessToken) {
      LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, data.accessToken);
    }
    return data || {};
  } catch (error) {
    return {};
  } finally {
    // Reset the flag to allow subsequent requests
    if (resetRequestTimeout) {
      clearTimeout(resetRequestTimeout);
    }
    resetRequestTimeout = setTimeout(() => {
      hasRequestedRefresh = false;
    }, THROTTLE_DELAY);
  }
};
