import React, { useContext } from 'react';
import { useTranslate } from 'hooks';
import { RegisterSuccessReason } from '../../hooks/useRegisterDevice';
import { TranslateContext } from 'contexts';

const TranslatedSuccess = ({
  success,
  field,
}: {
  success: { key: RegisterSuccessReason, model: string, name: string },
  field: 'title' | 'description'
}) => {
  const { t } = useTranslate();
  const { localeSettings: { locale } } = useContext(TranslateContext);

  const args = { variables: { name: success.name, model: success.model } };

  if (success.key) {
    const translation = t(`coldStorage.ledger.successes.${success.key}.${field}`, args);

    if (translation !== `${locale}.coldStorage.ledger.successes.${success.key}.${field}`) {
      return <>{translation}</>;
    } else {
      return <>{t(`coldStorage.ledger.successes.unknown.${field}`)}</>;
    }
  }

  return null;
};

export default TranslatedSuccess;
