import React, { ReactElement } from 'react';
import Favorite from './Favorite';
import { CountUp, CurrencyBadge, RiskScore, WalletAddress } from 'components';
import styles from './WalletItem.module.scss';

export type WalletItemProps = {
  currency: string;
  walletAddress: string;
  balance: string | number;
  payway: string;
  isFavorite: boolean;
  onToggleFavorite: () => void;
  riskScore: number;
};

export const WalletItem = ({
  walletAddress,
  currency,
  balance,
  payway,
  isFavorite,
  onToggleFavorite,
  riskScore,
}: WalletItemProps): ReactElement => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.topContent}>
        <div className={styles.inlineGroup}>
          <CurrencyBadge currency={currency} network={payway} width="32px" withoutText />
          <div className={styles.columnGroup}>
            <span className={styles.currency}>{currency}</span>
            <span className={styles.network}>{payway}</span>
          </div>
        </div>
        <Favorite
          isFavorite={isFavorite}
          onToggleFavorite={onToggleFavorite}
        />
      </div>
      <div className={styles.middleContent}>
        <CountUp
          isRoundDown
          end={Number(balance)}
          currency={currency}
          className={styles.balance}
        />
      </div>
      <div className={styles.bottomContent}>
        <WalletAddress
          textPlacement="left"
          walletAddress={walletAddress}
          startLength={4}
          endLength={4}
          className={styles.walletAddress}
        />
        <RiskScore riskScore={riskScore} />
      </div>
    </div>
  );
};
