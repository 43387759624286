import axios, { SERVICE } from 'libs/axios';

export type TransactionCategoryDeleteVariables = {
  id: number;
  name: string;
};

export const transactionCategoryDelete = async ({ id }: TransactionCategoryDeleteVariables) => {
  const { data } = await axios.delete(`${SERVICE.CLIENT}/transaction-categories/${id}`);
  return data;
};
