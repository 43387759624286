import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { notification } from 'antd';
import _ from 'lodash';
import { IExternalUser } from 'interfaces';
import { useClients, useTranslate } from 'hooks';
import { Button } from 'components';
import {
  AssignableUserItemType,
  AssignableUserList,
  AssignableUserValues,
  SearchInput,
} from '../../../../components';
import styles from './AssignClientForm.module.scss';

export type AssignClientFormProps = {
  loading: boolean;
  onSubmit: (data: AssignableUserValues) => void;
  onModalClose: () => void;
  initialValues: { unitId: number; rootUnitId: number };
};

const AssignClientForm = ({
  loading: isMutating,
  onSubmit,
  onModalClose,
  initialValues,
}: AssignClientFormProps) => {
  const { t } = useTranslate();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>('');
  const [list, setList] = useState<IExternalUser[]>([]);
  const [added, setAdded] = useState<AssignableUserValues['added']>([]);
  const [removed, setRemoved] = useState<AssignableUserValues['removed']>([]);

  const { data, isLoading, refetch, isRefetching } = useClients({
    unit: true,
    paging: { limit: 25, offset: (page - 1) * 25 },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
    filter: {
      and: [
        { rootUnitId: { eq: initialValues.rootUnitId } },
      ],
      or: [
        { name: { iLike: search ? `%${search}%` : null } },
        { email: { iLike: search ? `%${search}%` : null } },
      ],
    },
  });

  const nodes = useMemo(() => data?.nodes || [], [data?.nodes]);
  const total = useMemo(() => data?.totalCount || 0, [data?.totalCount]);

  useEffect(() => { refetch(); }, [refetch, page]);

  useEffect(() => {
    if (page === 1) {
      setList(nodes);
    } else {
      setList(prevList => _.uniq([...prevList, ...nodes]));
    }
  }, [nodes, page]);

  const debouncedSearch = useMemo(() => _.debounce((searchValue: string) => {
    setSearch(searchValue);
    setPage(1);
  }, 300), []);

  const handleLoadMore = useCallback(() => {
    if (list.length < total) {
      setPage(prevPage => prevPage + 1);
    }
  }, [list.length, total]);

  const handleSubmit = useCallback(() => {
    if (!added.length && !removed.length) {
      return notification.error({ message: t('noChanges') });
    }

    onSubmit({ added, removed });
  }, [added, onSubmit, removed, t]);

  const checkAssignment = useCallback((client: IExternalUser) => {
    if (client.unitId !== null && client.unitId !== initialValues.unitId) {
      return { disabled: true, reason: t('users.units.clientIsBusy') };
    }
    return { disabled: false };
  }, [initialValues.unitId, t]);

  const items: AssignableUserItemType[] = useMemo(() => {
    const removedIds = new Set(removed.map(({ id }) => id));
    const addedIds = new Set(added.map(({ id }) => id));

    return list.map((client: IExternalUser) => {
      const checked = addedIds.has(client.id) ? true : removedIds.has(client.id) ? false : client.unitId === initialValues.unitId;

      const item = {
        id: client.id,
        name: client.name,
        email: client.email,
        initialChecked: client.unitId === initialValues.unitId,
        checked,
        ...checkAssignment(client),
      };

      return item;
    });
  }, [removed, added, list, initialValues.unitId, checkAssignment]);

  return (
    <div className={styles.wrapper}>
      <SearchInput onSearch={debouncedSearch} loading={isLoading} />

      <AssignableUserList
        items={items}
        loading={isLoading}
        onLoadMore={handleLoadMore}
        moreLoading={page === 1 ? false : isRefetching}
        handlers={{ add: setAdded, remove: setRemoved }}
      />

      <div className={styles.buttonWrapper}>
        <Button block onClick={handleSubmit} loading={isMutating}>
          {t('save')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          onClick={onModalClose}
        >
          {t('cancel')}
        </Button>
      </div>
    </div>
  );
};

export default AssignClientForm;
