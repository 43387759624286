import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { MutationOptions, UpdateUnitManagersVariables } from './types';

const updateUnitManagers = async ({ id, body }: UpdateUnitManagersVariables) => {
  const { data } = await axios.put(`${SERVICE.CLIENT}/units/${id}/managers`, body);
  return data;
};

const useUpdateUnitManagers = (mutationOptions?: MutationOptions) => {
  return useMutation({
    mutationFn: updateUnitManagers,
    ...mutationOptions,
  });
};

export default useUpdateUnitManagers;
