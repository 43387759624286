import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';

type DTO = {
  id: number;
  annotation: string;
};

const updateAnnotation = async ({ id, annotation }: DTO) => {
  const { data } = await axios.patch(`${SERVICE.CLIENT}/transactions/${id}`, {
    annotation,
  });
  return data;
};

const useUpdateAnnotation = () => {
  return useMutation({
    mutationKey: ['update-annotation'],
    mutationFn: updateAnnotation,
  });
};

export default useUpdateAnnotation;
