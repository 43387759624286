import React, { ReactElement } from 'react';
import AuthCode, { AuthCodeProps } from 'react-auth-code-input';
import { Typography } from 'antd';
import clsx from 'clsx';
import styles from './Input2FA.module.scss';

export type Input2FAProps = {
  error?: string;
};

export const Input2FA = (
  props: AuthCodeProps & Input2FAProps,
): ReactElement => {
  return (
    <div className={styles.wrapper}>
      <AuthCode
        {...props}
        allowedCharacters="numeric"
        inputClassName={clsx(styles.input, { [styles.error]: !!props?.error })}
        containerClassName={styles.container}
      />
      <Typography.Text
        className={clsx(styles.errorMessage, { [styles.active]: !!props?.error })}
      >
        {props?.error}
      </Typography.Text>
    </div>
  );
};
