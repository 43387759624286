import axios, { AxiosError, SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { notification } from 'antd';
import useTranslate from './useTranslate';

export const useManualTransfer = () => {
  const { t } = useTranslate();

  const manuallyTransfer = async (uuid: string) => {
    const { data } = await axios.post(SERVICE.CLIENT + `/invoices/funds/transfer/${uuid}`);
    return data;
  };

  return useMutation({
    mutationFn: manuallyTransfer,
    onError: (e: AxiosError) => {
      notification.error({
        message: e.message,
      });
    },
    onSuccess: () => notification.success({
      message: t('invoice.successfullyDebitted'),
    }),
  });
};
