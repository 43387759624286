import React from 'react';
import { useTranslate } from 'hooks';
import { StatusStateEnum } from 'hooks/useUpdateStatus';
import StatusBadge from '../StatusBadge';
import { CheckOutlined } from '@ant-design/icons';
import styles from './StatusOption.module.scss';

export type StatusOptionProps = {
  status: StatusStateEnum;
  size?: number;
  checked?: boolean;
  absolute?: boolean;
};

const StatusOption = ({
  size,
  status,
  checked,
  absolute,
}: StatusOptionProps) => {
  const { t } = useTranslate();

  return (
    <div className={styles.status}>
      <div className={styles.space}>
        <div className={styles.statusBadgeWrapper}>
          <StatusBadge status={status} absolute={absolute} size={size} />
        </div>

        <span className={styles.label}>{t(`activityStatus.${status}`)}</span>
      </div>

      {checked && <CheckOutlined className={styles.icon} />}
    </div>
  );
};

export default StatusOption;
