import { useEffect, useState } from 'react';
import { UserRefusedAllowManager } from '@ledgerhq/errors';
import TransportWebHID from '@ledgerhq/hw-transport-webhid';
import { DeviceModel } from '../types';
import appendIdToDeviceName from '../utils/appendIdToDeviceName';
import editDeviceName from '../utils/editDeviceName';
import extractIdFromDeviceName from '../utils/extractIdFromDeviceName';
import generateDeviceId from '../utils/generateDeviceId';
import getAppAndVersion from '../utils/getAppAndVersion';
import getDeviceName from '../utils/getDeviceName';
import getDeviceNameMaxLength from '../utils/getDeviceNameMaxLength';
import isDashboardName from '../utils/isDashboardName';
import quitApp from '../utils/quitApp';
import { transport } from '../transport';

export enum RegisterSuccessReason {
  REGISTERED_PREVIOUSLY = 'registeredPreviously',
  REGISTERED_CURRENTLY = 'registeredCurrently',
  REGISTERED_AUTO_CURRENTLY = 'registeredAutoCurrently',
}

export enum RegisterDeviceRequest {
  GET_DEVICE_NAME,
  RENAME_DEVICE,
}

export enum RegisterDeviceStatus {
  PENDING,
  SUCCESS,
  LOADING,
  ERROR,
  IDLE,
}

type ErrorState = { status: RegisterDeviceStatus.ERROR, reason: Error };
type SuccessState = { status: RegisterDeviceStatus.SUCCESS, reason: RegisterSuccessReason };
type PendingState = { status: RegisterDeviceStatus.PENDING, reason: RegisterDeviceRequest };
type GeneralState = {
  status: Exclude<
  RegisterDeviceStatus,
  RegisterDeviceStatus.ERROR | RegisterDeviceStatus.PENDING | RegisterDeviceStatus.SUCCESS
  >
};

export type RegisterDeviceState = ErrorState | PendingState | SuccessState | GeneralState;

const delay = async (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const useRegisterDevice = ({
  registeredDeviceIds,
  setDeviceName,
  setError,
}: {
  registeredDeviceIds: string[];
  setError: (error: Error | undefined, stepIndex: number) => void;
  setDeviceName: (deviceName: string) => void;
}) => {
  const [device, setDevice] = useState<DeviceModel | null>(null);
  const [state, setState] = useState<RegisterDeviceState>({
    status: RegisterDeviceStatus.IDLE,
  });

  const registerDevice = async (withoutDelay?: boolean) => {
    setState({ status: RegisterDeviceStatus.LOADING });

    if (!withoutDelay) {
      await delay(1000);
    }

    return TransportWebHID.create()
      .then((currentTransport) => {
        transport.value = currentTransport;

        setDevice(currentTransport.deviceModel as DeviceModel);

        return getAppAndVersion(currentTransport)
          .then(appAndVersion => {
            if (isDashboardName(appAndVersion.name)) {
              setState({
                status: RegisterDeviceStatus.PENDING,
                reason: RegisterDeviceRequest.GET_DEVICE_NAME,
              });

              return getDeviceName(currentTransport).then(intitialDeviceName => {
                const initialDeviceId = extractIdFromDeviceName(intitialDeviceName);

                if (registeredDeviceIds.includes(`ledger-${initialDeviceId}`)) {
                  setDeviceName(intitialDeviceName);
                  return setState({
                    status: RegisterDeviceStatus.SUCCESS,
                    reason: RegisterSuccessReason.REGISTERED_PREVIOUSLY,
                  });
                } else {
                  if (initialDeviceId) {
                    setDeviceName(intitialDeviceName);
                    return setState({
                      status: RegisterDeviceStatus.SUCCESS,
                      reason: RegisterSuccessReason.REGISTERED_AUTO_CURRENTLY,
                    });
                  }

                  setState({
                    status: RegisterDeviceStatus.PENDING,
                    reason: RegisterDeviceRequest.RENAME_DEVICE,
                  });

                  const newDeviceName = appendIdToDeviceName({
                    maxLen: getDeviceNameMaxLength(currentTransport.deviceModel?.id),
                    deviceId: generateDeviceId(),
                    deviceName: intitialDeviceName,
                  });

                  return editDeviceName(currentTransport, newDeviceName)
                    .then(() => {
                      setDeviceName(newDeviceName);
                      return setState({
                        status: RegisterDeviceStatus.SUCCESS,
                        reason: RegisterSuccessReason.REGISTERED_CURRENTLY,
                      });
                    });
                }
              });
            }

            return quitApp(currentTransport).then(() => { registerDevice(); });
          });
      })
      .then(() => setError(undefined, 0)) // reset error
      .catch(error => {
        if (error.name === 'InvalidStateError' && transport) {
          return transport.value?.close().then(() => { registerDevice(); });
        }

        if (error.name === 'TransportStatusError' && error.statusCode === 0x5501) {
          throw new UserRefusedAllowManager();
        }

        throw error;
      })
      .catch(error => {
        // console.error(error);
        // console.dir(error);

        setError(error, 0); // 0 is index of current step "register"
        setState({
          status: RegisterDeviceStatus.ERROR,
          reason: error,
        });
      });
  };

  useEffect(() => {
    return () => {
      setError(undefined, 0); // reset error

      if (transport.value) {
        transport.value.close();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    device,
    registerDeviceState: state,
    onRegisterDevice: () => registerDevice(),
  };
};

export default useRegisterDevice;
