import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IWallet } from 'types/IWallet';

export const useWalletBalance = (uuid: string) => {
  const getWalletBalance = async () => {
    const { data } = await axios.get(`${SERVICE.CLIENT}/wallets?uuid=${uuid}`);
    return data;
  };

  return useQuery<IWallet[]>({
    queryKey: [`wallets-${uuid}`],
    queryFn: getWalletBalance,
    enabled: !!uuid,
  });
};
