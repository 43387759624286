const paths: Record<string, string> = {
  'bitcoin': '84\'/0\'/0\'/0/0',
  'ethereum': '44\'/60\'/0\'/0/0',
  'tron': '44\'/195\'/0\'/0/0',
  'bsc': '44\'/60\'/0\'/0/0',
  'dogecoin': '44\'/3\'/0\'/0/0', //FIXME: NOT WORKING ???
  'litecoin': '44\'/2\'/0\'/0/0',
};

const getDeriviationById = (currencyId: string) => {
  const path = paths[currencyId];

  if (!path) {
    throw new Error(`deriviation path with currencyId "${currencyId}" not found`);
  }

  return path;
};

export default getDeriviationById;
