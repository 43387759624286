import { useCallback, useRef } from 'react';
import { notification } from 'antd';
import { UnknownType } from 'types/Unknown';
import { useLogout } from 'contexts/AuthorisationContext/hooks/useAuth';

type LogoutOptions = {
  message?: string;
  withoutRequest?: boolean;
};

type UseSessionLogoutParams = {
  clearSessionFn: (options?: LogoutOptions) => void;
};

const THROTTLE_DELAY = 1500; // timeout for ignoring subsequent requests
const CLEAR_SESSION_DELAY = 350; // timeout for clearing the session

const useSessionLogout = ({
  clearSessionFn,
}: UseSessionLogoutParams) => {
  const hasRequestedLogoutRef = useRef<boolean>(false);
  const clearSessionDelayRef = useRef<NodeJS.Timeout | null>(null);
  const resetRequestTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { logoutMutate } = useLogout({
    onError: (error: UnknownType) => {
      notification.error({
        description: error.response.data.message,
        message: error.response.statusText,
      });
    },
  });

  const logout = useCallback((options?: LogoutOptions) => {
    if (hasRequestedLogoutRef.current) {
      return; // ignore subsequent requests
    }

    hasRequestedLogoutRef.current = true;

    if (!options?.withoutRequest) {
      logoutMutate();
    }

    clearSessionDelayRef.current = setTimeout(() => {
      clearSessionFn(options);
    }, CLEAR_SESSION_DELAY);

    // reset the flag to allow subsequent requests
    resetRequestTimeoutRef.current = setTimeout(() => {
      hasRequestedLogoutRef.current = false;
    }, THROTTLE_DELAY);
  }, [clearSessionFn, logoutMutate]);

  return {
    logout,
  };
};

export default useSessionLogout;
