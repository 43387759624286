import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { MutationOptions, UpdateUnitClientsVariables } from './types';

const updateUnitManagers = async ({ id, body }: UpdateUnitClientsVariables) => {
  const { data } = await axios.put(`${SERVICE.CLIENT}/units/${id}/clients`, body);
  return data;
};

const useUpdateUnitClients = (mutationOptions?: MutationOptions) => {
  return useMutation({
    mutationFn: updateUnitManagers,
    ...mutationOptions,
  });
};

export default useUpdateUnitClients;
