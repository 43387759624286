import React, { ReactElement } from 'react';
import { Image, Space, Typography } from 'antd';
import { CurrencyIcon } from 'utils/currencyIcon';

export type CurrencyBadgePropsType = {
  currency: string;
  network?: string;
  gap?: number;
  inline?: boolean;
  revers?: boolean;
  width?: string;
  textSize?: string;
  withoutText?: boolean;
  textWeight?: number;
  prettifyLabel?: boolean;
};

const prettifiedLabelMap = new Map([
  ['BTC', 'Bitcoin'],
  ['ETH', 'Ethereum'],
  ['TRX', 'Tron'],
  ['USDT', 'Tether'],
]);

export const CurrencyBadge = ({
  currency,
  network,
  textSize,
  revers,
  inline,
  gap = 10,
  width = '20px',
  withoutText,
  textWeight = 400,
  prettifyLabel,
}: CurrencyBadgePropsType): ReactElement => {
  const { Text } = Typography;

  const currencySrc = CurrencyIcon[currency];
  const networkSrc = network && CurrencyIcon[network];
  const prettifiedLabel = prettifyLabel && prettifiedLabelMap.get(currency);

  return (
    <Space.Compact style={{ display: 'flex', alignItems: 'center', gap }}>
      {currencySrc && (
        <div style={{ display: 'inline', order: revers ? '2' : '1', height: width, position: 'relative' }}>
          <Image
            src={currencySrc}
            width={width}
            preview={false}
            style={{ display: inline ? 'inline' : 'block' }}
          />
          {networkSrc && (
            <Image
              src={networkSrc}
              preview={false}
              style={{
                width: width,
                height: width,
                display: inline ? 'inline' : 'block',
                position: 'absolute',
                bottom: 0,
                right: 0,
                border: '2px solid #fff',
                borderRadius: '999px',
                transform: 'translate(25%, 25%) scale(0.6)',
              }}
            />
          )}
        </div>
      )}

      {!withoutText && (
        <Text style={{
          fontWeight: textWeight,
          fontSize: textSize,
          lineHeight: `calc(${textSize} - 1px)`,
          wordBreak: 'inherit',
          order: revers ? '1' : '2',
        }}>
          {prettifiedLabel || currency}
        </Text>
      )}
    </Space.Compact>
  );
};
