import React from 'react';
import { Button } from 'antd';
import clsx from 'clsx';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from './BackButton.module.scss';

export type BackButtonProps = {
  onClick: () => void;
  className?: string;
};

const BackButton = ({ onClick, className }: BackButtonProps) => (
  <Button
    onClick={onClick}
    className={clsx(styles.button, className)}
    icon={<ArrowLeftOutlined className={styles.icon} />}
  />
);

export default BackButton;
