import { Dispatch, SetStateAction, useEffect } from 'react';
import { Permission } from '@fanckler/processing-auth';
import { IUser } from 'interfaces/IUser';
import { Path } from 'routes/interfaces/Path';
import { CheckPermissionsFunction } from '../AuthorisationContext';

const useAuthenticatedRoute = ({
  user,
  setPath,
  checkPermissions,
}: {
  user: IUser | null,
  setPath: Dispatch<SetStateAction<string | null>>
  checkPermissions: CheckPermissionsFunction,
}) => {
  useEffect(() => {
    if (!user) return;

    const hasBusiness = !!user.rootUnitId;

    // User role identification
    const isRootAdmin = !hasBusiness && user.id === 1;
    const isRootUnitAdmin = checkPermissions([Permission.ADMIN_IAM_ROOT_UNIT_ADMIN]);
    const isUnitsAdmin = hasBusiness && user.units.length > 1;
    const isUnitAdmin = hasBusiness && user.units.length === 1;

    // Permissions checks
    const canUnitRead = checkPermissions([Permission.CLIENT_UNIT_READ]);
    const canBusinessRead = checkPermissions([Permission.ADMIN_IAM_ROOT_UNIT_READ]);

    // Root Admin
    if (isRootAdmin) {
      setPath(Path.ADMINISTRATION_BUSINESSES);
      return;
    }

    // Root Unit Admin and Read Unit permissions + have business
    if (hasBusiness && isRootUnitAdmin && canUnitRead) {
      setPath(`${Path.ADMINISTRATION_BUSINESSES}/${user.rootUnitId}/units`);
      return;
    }

    // Read Unit permission + have one or more units
    if ((isUnitAdmin || isUnitsAdmin) && canUnitRead) {
      const targetUnitPath = isUnitAdmin ? `/${user.units[0].uuid}` : '';
      setPath(`${Path.ADMINISTRATION_BUSINESSES}/${user.rootUnitId}/units${targetUnitPath}`);
      return;
    }

    // Read Unit permission + have business
    if (hasBusiness && canUnitRead) {
      setPath(`${Path.ADMINISTRATION_BUSINESSES}/${user.rootUnitId}/units`);
      return;
    }

    // Read Business permission
    if (canBusinessRead) {
      setPath(Path.ADMINISTRATION_BUSINESSES);
      return;
    }

    setPath(Path.MY_ACCOUNT);
  }, [user, checkPermissions, setPath]);
};

export default useAuthenticatedRoute;
