import { IClientTransactionCategory } from 'interfaces/IClientTransaction';
import renderOption from './renderOption';

const getDefautOptions = (option: IClientTransactionCategory | IClientTransactionCategory['group']) => {
  return [{
    value: option.id,
    label: renderOption(option),
  }];
};

export default getDefautOptions;
