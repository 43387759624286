import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IWallet } from 'interfaces';
import { Filter } from 'types/Filter';
import { Pagination } from 'types/Pagination';
import { Sort } from 'types/Sort';

export type GetSubaccountsParams = {
  uuid: string | undefined;
  sorting?: Sort<IWallet>[];
  filter?: Filter<IWallet>;
  paging?: Pagination;
};

const useGetSubaccounts = ({ uuid, ...params }: GetSubaccountsParams) => {
  return useQuery<{ totalCount: number, nodes: IWallet[] }>({
    enabled: Boolean(uuid),
    queryKey: ['subaccounts', uuid, params],
    queryFn: async () => {
      const url = `${SERVICE.CLIENT}/units/${uuid}/sub-wallets`;
      const response = await axios.get(url, { params });
      return response.data || { totalCount: 0, nodes: [] };
    },
  });
};

export default useGetSubaccounts;
