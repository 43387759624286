import React, {
  ReactElement, useCallback, useEffect, useMemo, useState,
} from 'react';
import _get from 'lodash/get';
import { Locale } from './interfaces/Locale';
import getSupportedLocalesByEnv from './utils/getSupportedLocalesByEnv';
import LocalStorage, { LocalStorageKey } from '../../utils/localStorage';
import locales from './locales';
import type { ITranslateContext, ITranslateOptions, TranslateKey } from './TranslateContext';
import { ChangeLocaleFunc, defaultLocale, TranslateContext } from './TranslateContext';
import { TranslateValueBuilder } from './TranslateValueBuilder';

export type TranslateProviderProps = {
  children: ReactElement;
};

const supportedLocales = getSupportedLocalesByEnv(locales, process.env.REACT_APP_LANG);

const TranslateProvider = ({ children }: TranslateProviderProps): ReactElement => {
  const [translates, setTranslates] = useState<Record<string, string> | null>(null);
  const [locale, setLocale] = useState<Locale>(() => {
    const storedLocale = LocalStorage.get(LocalStorageKey.LOCALE);

    if (storedLocale && supportedLocales[storedLocale]) {
      return storedLocale;
    }

    const [browserLanguage] = navigator.language.split('-');
    const localeFromBrowserLanguage = Object.keys(supportedLocales).find((item) => item === browserLanguage);

    if (localeFromBrowserLanguage) {
      return localeFromBrowserLanguage as Locale;
    }

    return supportedLocales[defaultLocale]
      ? defaultLocale
      : (Object.keys(supportedLocales)[0] as Locale || defaultLocale);
  });

  const localeSettings = useMemo(() => ({ ...locales[locale], locale }), [locale]);

  useEffect(() => {
    LocalStorage.set(LocalStorageKey.LOCALE, locale);
  }, [locale]);

  useEffect(() => {
    (async () => {
      try {
        const localeFileModule = await import(`../../../public/locales/${locale}/translation.json`);
        const permissionsLocaleFileModule = await import(`../../../public/locales/${locale}/permissions.json`);
        const faqLocaleFileModule = await import(`../../../public/locales/${locale}/faq.json`);

        setTranslates({
          ...localeFileModule,
          ...permissionsLocaleFileModule,
          ...faqLocaleFileModule,
        });
      } catch {
        setTranslates(null);
      }
    })();
  }, [locale]);

  const changeLocale: ChangeLocaleFunc = useCallback((newLocale) => {
    setLocale(newLocale);
  }, []);

  const translate = useCallback((key: TranslateKey, options?: ITranslateOptions) => {
    const translateValue = _get(translates, key);

    if (!translateValue) {
      return `${locale}.${key}`;
    }

    const translateValueBuilder = new TranslateValueBuilder(translateValue);

    if (options?.variables) {
      translateValueBuilder.injectVariables(options.variables);
    }

    if (options?.capitalize) {
      translateValueBuilder.capitalize();
    }

    return translateValueBuilder.get();
  }, [locale, translates]);

  const contextData: ITranslateContext = {
    locales: supportedLocales,
    translate,
    changeLocale,
    localeSettings,
  };

  return (
    <TranslateContext.Provider value={contextData}>
      {children}
    </TranslateContext.Provider>
  );
};

export default TranslateProvider;
