import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { ResponseError } from 'types/ResponseError';

type RemoveSessionOptionsType = {
  onSuccess: (data: unknown) => void;
  onError: (e: ResponseError) => void;
};

export const useRemoveActiveSession = (options: RemoveSessionOptionsType) => {
  const removeActiveSession = async (sessionId: string) => {
    const { data } = await axios.delete(`${SERVICE.ADMIN}/auth/active-sessions/${sessionId}`);
    return data;
  };

  const { mutate, isPending } = useMutation({ mutationFn: removeActiveSession, ...options });

  return {
    removeSession: mutate,
    isLoading: isPending,
  };
};
