import React from 'react';
import { UnknownType } from 'types/Unknown';
import styles from './StepperContent.module.scss';

type StepperContentProps = {
  children: UnknownType,
  noScroll?: boolean,
};

const StepperContent: React.ComponentType<StepperContentProps> = React.forwardRef(
  function ModalContent(
    { children, noScroll }: StepperContentProps,
    containerRef: React.ElementRef<UnknownType>,
  ) {
    // const [isScrollable, setScrollable] = useState(false);

    // const onHeightUpdate = useCallback(() => {
    // // $FlowFixMe help me
    //   const { current } = containerRef;
    //   if (!current) return;
    //   setScrollable(current.scrollHeight > current.clientHeight);
    // }, [containerRef]);

    // useLayoutEffect(() => {
    //   if (!containerRef.current) return;
    //   const ro = new ResizeObserver(onHeightUpdate);
    //   ro.observe(containerRef.current);
    //   return () => {
    //     ro.disconnect();
    //   };
    // }, [containerRef, onHeightUpdate]);
    return (
      <div className={styles.wrapper}>
        {children}
      </div>
    );
  });

export default StepperContent;
