import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { notification } from 'antd';
import { useTranslate } from 'hooks/index';
import { SMTPSettingsBodyType } from 'components';

const setSMTPMutate = async (body: SMTPSettingsBodyType) => {
  const { data } = await axios.post(`${SERVICE.ADMIN}/smtp/set-options`, body);
  return data;
};

export const useSetSMTP = () => {
  const { t } = useTranslate();

  const {
    mutateAsync: setSMTPAsync,
    isPending: isSetSMTPLoading,
  } = useMutation({
    mutationFn: setSMTPMutate,
    onSuccess: () => {
      notification.close('smtp-validation');
      notification.success({
        key: 'smtp-validation',
        message: t('SMTP.connectedSuccessfully'),
      });
    },
    onError: () => {
      notification.close('smtp-validation');
      notification.error({
        key: 'smtp-validation',
        message: t('SMTP.saveFailed'),
      });
    },
  });

  return {
    setSMTPAsync,
    isSetSMTPLoading,
  };
};
