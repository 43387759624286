import React from 'react';
import { CurrencyIcon } from 'utils/currencyIcon';
import styles from './CurrencyCell.module.scss';

const nativeCurrencyByNetwork: Record<string, string> = {
  ERC20: 'ETH',
  TRC20: 'TRX',
};

type PropsWithCurrency = { currency: string; network?: string };
type PropsWithNetwork = { currency?: string; network: string };
type CurrencyCellProps = (PropsWithCurrency | PropsWithNetwork) & {
  amount?: number | string;
  isNative?: boolean;
};

const CurrencyCell = ({ currency, network, amount, isNative }: CurrencyCellProps) => {
  const isAllPresent = Boolean(currency) && Boolean(network);

  const nativeCurrency = network && nativeCurrencyByNetwork[network] || network;

  return (
    <div className={styles.wrapper}>
      <div className={styles.currencyWrapper}>
        <img
          src={CurrencyIcon[(currency || network) as string]}
          alt={`${currency}-icon`}
          className={styles.icon}
        />

        {amount}

        <span className={styles.currencyName}>{currency || (isNative ? nativeCurrency : network)}</span>
      </div>

      {isAllPresent && <span className={styles.network}>({network})</span>}
    </div>
  );
};

export default CurrencyCell;
