export enum Path {
  HOME = '/',
  LOGIN = '/login',
  MY_ACCOUNT = '/my-account',
  HELP_CENTER = '/help-center',

  INVOICES = '/invoices',
  INVOICES_UUID = '/invoices/:uuid',

  ADMINISTRATION = '/administration',
  ADMINISTRATION_MANAGERS = '/administration/managers',
  ADMINISTRATION_MANAGERS_UUID = '/administration/managers/:uuid',
  ADMINISTRATION_ROLES = '/administration/roles',
  ADMINISTRATION_COLD_STORAGE = '/administration/cold-storage',
  ADMINISTRATION_BUSINESSES = '/administration/businesses',
  ADMINISTRATION_BUSINESSES_UNITS = '/administration/businesses/:rootUnitId?/units',
  ADMINISTRATION_BUSINESSES_UNITS_UNIT_IDS = '/administration/businesses/:rootUnitId?/units/*',
  ADMINISTRATION_STATISTICS = '/administration/statistics',
  ADMINISTRATION_STATISTICS_ROOT_UNIT_ID = '/administration/statistics/:rootUnitId',
  ADMINISTRATION_REPLENISHMENT_REQUESTS = '/administration/replenishment-requests',
  ADMINISTRATION_FIN_MANAGEMENT = '/administration/financial-management',
  ADMINISTRATION_EXCHANGE_MODULE = '/administration/exchange-module',

  USERS_INVOICES = '/users/invoices',
  USERS_TRANSACTIONS = '/users/transactions',
  USERS_PAYMENT_ORDERS = '/users/payment-orders',
  USERS_CLIENTS = '/users/clients',
  USERS_CLIENTS_UUID = '/users/clients/:uuid',
  // USERS_FINANCIAL_STATEMENT = '/users/financial-statement',
  // VERIFICATION_OF_ACTIONS = '/users/verification-actions',

  PROCESSING_BALANCE = '/processing/balance',
  PROCESSING_TRANSACTIONS = '/processing/transactions',
  PROCESSING_TRANSACTIONS_CURRENCY = '/processing/transactions/:currency?',
  PROCESSING_PAYMENT_ORDERS = '/processing/payment-orders',
  PROCESSING_PAYMENT_ORDERS_CURRENCY = '/processing/payment-orders/:currency?',
  PROCESSING_RESOURCES = '/processing/resources',

  SYSTEM_SYSTEM_CHANGES = '/system/system-changes',
  SYSTEM_FIREWALL = '/system/firewall',
  SYSTEM_MESSAGES = '/system/messages',
  SYSTEM_ACTIVITY_LOG = '/system/activity-log',
  SYSTEM_COMMISSIONS = '/system/commissions',

  ERROR_PAGE = '/error-page',
}
