import React, { useCallback, useMemo, useState } from 'react';
import { Permission } from '@fanckler/processing-auth';
import { Form, Input } from 'antd';
import { IBusiness } from 'interfaces/IBusiness';
import { UnknownType } from 'types/Unknown';
import { useTranslate } from 'hooks';
import { POSITIVE_WHOLE_NUMBER } from 'constants/validation';
import { useAuthorisationContext } from 'contexts';
import { Button, OrganizationalLogoUpload } from 'components';
import SmtpSettings from 'components/SmtpSettings/SmtpSettings';
import { ApiItem, SwitchItem } from './components';
import { NotificationOutlined, RightOutlined } from '@ant-design/icons';
import styles from './FormBusiness.module.scss';

export type BussinesFormData = {
  id?: number,
  name: string,
  logo?: string,
  postbackUrl?: string
  is2faRequired?: boolean;
  isIpRequired?: boolean;
  isActive?: boolean;
  activeSessionCount?: string;
  authFailedCount?: string;
};

type FormBusinessProps = {
  isLoading: boolean;
  initialValues: IBusiness;
  onClose: () => void;
  onSubmit: (formData: FormData) => void;
  refetchBusiness: () => void;
};

const FormBusiness = ({
  onClose,
  onSubmit,
  isLoading,
  initialValues,
  refetchBusiness,
}: FormBusinessProps) => {
  const { checkPermissions } = useAuthorisationContext();
  const [changedValues, setChangedValues] = useState<string[]>([]);
  const [isSMTPTab, setIsSMTPTab] = useState(false);

  const [form] = Form.useForm();
  const { t } = useTranslate();

  const isLogoChanged = useMemo(() => changedValues.includes('logo'), [changedValues]);

  const handleFinish = useCallback((values: BussinesFormData) => {
    const formData = new FormData();

    if (values.logo && isLogoChanged) {
      formData.set('file', values.logo);
    }
    if (!values.logo && isLogoChanged) {
      formData.set('isRemoveLogo', 'true');
    }
    if (values.name?.trim()) {
      formData.set('name', values.name.trim());
    }
    if (values.postbackUrl) {
      formData.set('postbackUrl', values.postbackUrl);
    }
    if (values.isActive !== undefined) {
      formData.set('isActive', values.isActive.toString());
    }
    if (values.isIpRequired !== undefined) {
      formData.set('isIpRequired', values.isIpRequired.toString());
    }
    if (values.is2faRequired !== undefined) {
      formData.set('is2faRequired', values.is2faRequired.toString());
    }
    if (values.activeSessionCount !== undefined) {
      formData.set('activeSessionCount', values.activeSessionCount);
    }
    if (values.authFailedCount !== undefined) {
      formData.set('authFailedCount', values.authFailedCount);
    }

    onSubmit(formData);
  }, [isLogoChanged, onSubmit]);

  const handleValuesChange = useCallback((values: UnknownType) => {
    const [changedValueKey] = Object.keys(values);

    if (changedValues.includes(changedValueKey)) return;

    setChangedValues(prevState => [...prevState, changedValueKey]);
  }, [changedValues]);

  if (isSMTPTab) {
    return (
      <SmtpSettings
        smtp={initialValues.smtp}
        rootUnitId={initialValues.id}
        refetchBusiness={refetchBusiness}
        onCloseModal={() => setIsSMTPTab(false)}
      />
    );
  }

  return (
    <Form
      name="bussiness-form"
      form={form}
      onFinish={handleFinish}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      className={styles.form}
    >
      <div>
        <Form.Item name="logo"><OrganizationalLogoUpload type="business" /></Form.Item>

        <div className={styles.formItems}>
          <Form.Item
            name="name"
            required
            label={t('business.name')}
            className={styles.formItem}
            rules={[
              { min: 1, max: 100 },
              { required: true, message: t('business.pleaseEnterName') },
            ]}
          >
            <Input placeholder={t('business.enterName')} className={styles.input} />
          </Form.Item>

          <Form.Item
            name="postbackUrl"
            label={t('postbackUrl')}
            className={styles.formItem}
            rules={[{ type: 'url' }]}
          >
            <Input placeholder={t('postbackUrlPlaceholder')} className={styles.input} />
          </Form.Item>

          {(initialValues.apiKey && initialValues.expirationAt) && (
            <ApiItem apiKey={initialValues.apiKey} expirationAt={initialValues.expirationAt} />
          )}

          {initialValues.id && (
            <Button
              block
              type="default"
              textSize="15px"
              className={styles.smtpButton}
              onClick={() => setIsSMTPTab(true)}
              prefixIcon={<NotificationOutlined style={{ fontSize: 16 }} />}
              suffixIcon={<RightOutlined style={{ fontSize: 16 }} />}
            >
              {t('SMTP.title')}
            </Button>
          )}

          <Form.Item name="is2faRequired" style={{ marginBottom: 0 }}>
            <SwitchItem label={t('business.twoFactorAuthRequired')} />
          </Form.Item>
          <Form.Item name="isIpRequired" style={{ marginBottom: 0 }}>
            <SwitchItem label={t('business.ipAddressConfirmation')} />
          </Form.Item>
          {initialValues.id && checkPermissions([Permission.ADMIN_IAM_ROOT_UNIT_ACTIVATE]) && (
            <Form.Item name="isActive" style={{ marginBottom: 0 }}>
              <SwitchItem
                label={t('business.activationStatus')}
                checkedLabel={t('active')}
                uncheckedLabel={t('inactive')}
              />
            </Form.Item>
          )}

          <div className={styles.alignItems}>
            <Form.Item
              name="activeSessionCount"
              label={t('business.activeSessions')}
              rules={[{ required: true, pattern: POSITIVE_WHOLE_NUMBER }]}
              className={styles.formItem}
            >
              <Input className={styles.input} />
            </Form.Item>

            <Form.Item
              name="authFailedCount"
              label={t('business.maximumFailedAuths')}
              rules={[{ required: true, pattern: POSITIVE_WHOLE_NUMBER }]}
              className={styles.formItem}
            >
              <Input className={styles.input} />
            </Form.Item>
          </div>
        </div>
      </div>

      <div className={styles.buttonWrapper}>
        <Button
          block
          htmlType="submit"
          loading={isLoading}
        >
          {t('save')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
      </div>
    </Form>
  );
};

export default FormBusiness;
