import { matchRoutes, Params } from 'react-router-dom';
import { Path } from 'routes/interfaces/Path';
import type { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';

export interface IGetPageTitleProps {
  translate: TranslateFunc;
  unitTitle: string | null;
  path: string;
  params?: Readonly<Params<string>>;
}

export default ({ translate, path, params, unitTitle }: IGetPageTitleProps): string => {
  const unitRoutesMatches = matchRoutes([
    { path: Path.ADMINISTRATION_BUSINESSES_UNITS },
    { path: Path.ADMINISTRATION_BUSINESSES_UNITS_UNIT_IDS },
  ], path);

  switch (path) {
    case Path.MY_ACCOUNT: {
      return translate('myAccount.title');
    }
    case Path.HELP_CENTER:
    case `${Path.HELP_CENTER}/${params?.categoryId}/${params?.topicId}`: {
      return translate('helpCenter.pageTitle');
    }
    case Path.USERS_CLIENTS: {
      return translate('users.clients.title');
    }
    case `${Path.USERS_CLIENTS}/${params?.uuid}`: {
      return `${translate('users.client.title')}`;
    }
    case Path.USERS_TRANSACTIONS: {
      return translate('users.transactions.title');
    }
    case Path.USERS_INVOICES: {
      return translate('invoice.title');
    }
    case Path.USERS_PAYMENT_ORDERS: {
      return translate('users.paymentOrders.title');
    }
    case unitRoutesMatches ? unitRoutesMatches[0].pathname : '' : {
      return unitTitle ? translate('users.units.unitWithName', {
        variables: { title: unitTitle || '' },
      }) : translate('users.units.title');
    }
    case Path.ADMINISTRATION_STATISTICS:
    case `${Path.ADMINISTRATION_STATISTICS}/${params?.rootUnitId}`: {
      return translate('statistics.title');
    }
    case Path.ADMINISTRATION_REPLENISHMENT_REQUESTS: {
      return translate('users.replenishmentRequests.title');
    }
    // case Path.VERIFICATION_OF_ACTIONS: {
    //   return translate('verificationActions');
    // }
    case Path.ADMINISTRATION: {
      return translate('administration.title');
    }
    case Path.ADMINISTRATION_ROLES: {
      return translate('administration.roles.title');
    }
    case Path.ADMINISTRATION_COLD_STORAGE: {
      return translate('coldStorage.title');
    }
    case Path.ADMINISTRATION_MANAGERS: {
      return translate('administration.managers.title');
    }
    case `${Path.ADMINISTRATION_MANAGERS}/${params?.uuid}`: {
      return translate('administration.managers.one.title');
    }
    case Path.ADMINISTRATION_BUSINESSES: {
      return translate('business.pageTitle');
    }
    case Path.SYSTEM_ACTIVITY_LOG: {
      return translate('activityLog.title');
    }
    case Path.SYSTEM_SYSTEM_CHANGES: {
      return translate('systemChanges');
    }
    case Path.SYSTEM_COMMISSIONS: {
      return translate('commissions.title');
    }
    // case `${Path.ADMINISTRATION_FIN_MANAGEMENT}/${params?.unitId}`:
    case Path.ADMINISTRATION_FIN_MANAGEMENT: {
      return translate('administration.financialManagement.title');
    }
    case Path.SYSTEM_FIREWALL: {
      return translate('system.firewall.title');
    }
    case Path.SYSTEM_MESSAGES: {
      return translate('messages');
    }
    // case Path.USERS_FINANCIAL_STATEMENT: {
    //   return translate('financialStatement');
    // }
    case Path.PROCESSING_BALANCE: {
      return translate('processing.balance.title');
    }
    case `${Path.PROCESSING_TRANSACTIONS}/${params?.currency}`:
    case Path.PROCESSING_TRANSACTIONS: {
      return translate('processing.transactions.title');
    }
    case `${Path.PROCESSING_PAYMENT_ORDERS}/${params?.currency}`:
    case Path.PROCESSING_PAYMENT_ORDERS: {
      return translate('processing.paymentOrders.title');
    }
    case Path.PROCESSING_RESOURCES: {
      return translate('processing.resources.title');
    }
    case Path.ADMINISTRATION_EXCHANGE_MODULE: {
      return translate('exchangeModule.title');
    }
    default: {
      return '';
    }
  }
};
