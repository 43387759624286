import Btc, { AddressFormat } from '@ledgerhq/hw-app-btc';
import type { Resolver } from '../../types';

const resolver: Resolver = async (transport, { path, verify, forceFormat }) => {
  const btc = new Btc({ transport });

  const result = await btc.getWalletPublicKey(path, {
    verify,
    format: forceFormat as AddressFormat,
  });

  return {
    path,
    address: result.bitcoinAddress,
    publicKey: result.publicKey,
    chainCode: result.chainCode,
  };
};

export default resolver;
