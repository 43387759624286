import { POFileType } from '../hooks/types';

export const makeKeyItemPO = (item: POFileType) => {
  if (item.key) {
    return item.key;
  }
  return (
    `${item.Username}-${item.Network}-${item.Currency}-${item.Wallet}-${item.Payout}`
  );
};
