import React, { PropsWithChildren } from 'react';
import { Tag } from 'antd';
import styles from './BizTag.module.scss';

const BizTag = ({ children }: PropsWithChildren) => {
  return (
    <Tag className={styles.tag}>
      {children}
    </Tag>
  );
};

export default BizTag;
