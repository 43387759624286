import axios from 'libs/axios';
import forge from 'node-forge';

let localPublicKey: string | null = null;

const getPublicKey = async () => {
  if (localPublicKey) {
    return localPublicKey;
  }

  try {
    const res = await axios.get('init-config');

    if (!res.data) {
      return localPublicKey;
    }

    localPublicKey = atob(res.data);
    return localPublicKey;
  } catch (error) {
    return localPublicKey;
  }
};

const encryptPassword = async (dataToEncrypt: string) => {
  const publicKey = await getPublicKey();
  if (!publicKey) {
    return dataToEncrypt;
  }

  const publicKeyObject = forge.pki.publicKeyFromPem(publicKey);
  const encrypted = publicKeyObject.encrypt(dataToEncrypt, 'RSA-OAEP', {
    md: forge.md.sha256.create(),
    mgf1: {
      md: forge.md.sha256.create(),
    },
  });

  return forge.util.encode64(encrypted);
};

export default encryptPassword;
