import React, { memo } from 'react';
import Tree from 'react-d3-tree';
import { Empty, Spin } from 'antd';
import { IBusiness } from 'interfaces/IBusiness';
import { IChildrenUnit, IUnit } from 'interfaces/IUnit';
import { useTreeOrgStructure } from './hooks';
import { TreeOrgStructureItem } from './components';
import styles from './TreeOrgStructure.module.scss';

export type TreeOrgStructureProps<RecordType extends IBusiness | IUnit | IChildrenUnit> = {
  parent: RecordType;
  width: number;
};

export const TreeOrgStructure = <RecordType extends IBusiness | IUnit | IChildrenUnit>({
  parent,
  width,
}: TreeOrgStructureProps<RecordType>) => {
  const { tree, isLoading } = useTreeOrgStructure(parent);

  const nodeSize = { x: 220, y: 52 };
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: `-${nodeSize.x / 2}px`,
    y: `-${nodeSize.y / 2}px`,
  };

  if (isLoading || !tree) {
    return (
      <div className={styles.placeholder}>
        {isLoading
          ? <Spin />
          : !tree && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </div>
    );
  }

  return (
    <Tree
      data={tree}
      pathFunc="step"
      orientation="vertical"
      translate={{ x: (width - nodeSize.x) / 2, y: nodeSize.y }}
      separation={{ siblings: 2, nonSiblings: 2 }}
      pathClassFunc={() => styles.line}
      renderCustomNodeElement={rd3tProps => (
        <TreeOrgStructureItem {...rd3tProps}
          foreignObjectProps={foreignObjectProps}
          parentNode={parent}
        />
      )}
    />
  );
};

export default memo(TreeOrgStructure) as typeof TreeOrgStructure;
