import { WalletCurrencyEnum } from 'types/WalletCurrencyEnum';
import { WalletNetworkEnum } from 'types/WalletNetworkEnum';

export const networkByTypeMap = new Map([
  [WalletNetworkEnum.ERC20, 'Ethereum'],
  [WalletNetworkEnum.BITCOIN, 'Bitcoin'],
  [WalletNetworkEnum.TRC20, 'Tron'],
  [WalletNetworkEnum.BNB, 'BNB'],
  [WalletNetworkEnum.LTC, 'LTC'],
  [WalletNetworkEnum.DOGE, 'DOGE'],
]);

export const currencyByNetworkMap = new Map([
  [WalletNetworkEnum.BITCOIN, 'BTC'],
  [WalletNetworkEnum.ERC20, 'ETH'],
  [WalletNetworkEnum.TRC20, 'TRX'],
  [WalletNetworkEnum.BNB, 'BNB'],
  [WalletNetworkEnum.LTC, 'LTC'],
  [WalletNetworkEnum.DOGE, 'DOGE'],
]);

export const disabledNetworks: WalletNetworkEnum[] = [
  WalletNetworkEnum.LTC,
  WalletNetworkEnum.DOGE,
];

export const networkList = [
  WalletNetworkEnum.ERC20,
  WalletNetworkEnum.BITCOIN,
  WalletNetworkEnum.TRC20,
  WalletNetworkEnum.BNB,
  WalletNetworkEnum.LTC,
  WalletNetworkEnum.DOGE,
];

export const defaultNetworks = [
  WalletNetworkEnum.ERC20,
  WalletNetworkEnum.BITCOIN,
  WalletNetworkEnum.TRC20,
  WalletNetworkEnum.BNB,
];

export const defaultNetworksAndCurrency = [
  { network: WalletNetworkEnum.ERC20, currency: WalletCurrencyEnum.ETH },
  { network: WalletNetworkEnum.ERC20, currency: WalletCurrencyEnum.USDT },
  { network: WalletNetworkEnum.ERC20, currency: WalletCurrencyEnum.USDC },
  { network: WalletNetworkEnum.BITCOIN, currency: WalletCurrencyEnum.BTC },
  { network: WalletNetworkEnum.TRC20, currency: WalletCurrencyEnum.TRX },
  { network: WalletNetworkEnum.TRC20, currency: WalletCurrencyEnum.USDT },
  { network: WalletNetworkEnum.BNB, currency: WalletCurrencyEnum.BNB },
  { network: WalletNetworkEnum.BNB, currency: WalletCurrencyEnum.USDT },
  { network: WalletNetworkEnum.BNB, currency: WalletCurrencyEnum.DAI },
  { network: WalletNetworkEnum.BNB, currency: WalletCurrencyEnum.USDC },
];
