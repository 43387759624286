import React, { useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { UnknownType } from 'types/Unknown';
import { DeviceModel } from '../../types';
import { CryptoCurrency } from 'components/ColdStorage/assets/crypto/types';
import { StorageType } from 'components/ColdStorage/types';
import { useNestedUnitContext } from 'pages/Administration/Units/components/NestedUnit/context';
import { useTranslate } from 'hooks';
import useRegisterDevice, { RegisterDeviceState } from 'components/ColdStorage/ledger/hooks/useRegisterDevice';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import extractIdFromDeviceName from '../../utils/extractIdFromDeviceName';
import StepChooseCurrency, { StepChooseCurrencyFooter } from './steps/StepChooseCurrency';
import StepConnectDevice from './steps/StepConnectDevice';
import StepFinish, { StepFinishFooter } from './steps/StepFinish';
import StepRegisterDevice, { StepRegisterDeviceFooter } from './steps/StepRegisterDevice';
import Stepper, { Step } from 'components/ColdStorage/components/Stepper/Stepper';
import { setColdStorageConnectedDeviceId, setColdStorageDevice } from 'components/ColdStorage/storage';

type StepId = 'chooseCurrency' | 'connectDevice' | 'registerDevice' | 'finish';

export type StepProps = {
  t: TranslateFunc;
  error?: Error;
  deviceId: string | null;
  deviceName: string | null;
  device: DeviceModel | null;
  currency: CryptoCurrency | null;
  registerDeviceState: RegisterDeviceState;
  onAddMore: () => void;
  onModalClose: () => void;
  onRegisterDevice: () => void;
  setError: (error: Error | undefined, errorStep: number) => void;
  transitionTo: (stepId: StepId) => void;
  setDeviceName: (deviceName: string) => void;
  setCurrency: (currency?: CryptoCurrency) => void;
};

type St = Step<StepId, StepProps>;

const createSteps = ({
  onBackToMethods,
}: {
  onBackToMethods: () => void;
}) => {
  const transitionTo = (stepId: StepId) => (props?: StepProps) => {
    props?.transitionTo(stepId);
  };

  const steps: St[] = [
    {
      id: 'registerDevice',
      label: 'register',
      component: StepRegisterDevice,
      footer: StepRegisterDeviceFooter,
      onBack: onBackToMethods,
    },
    {
      id: 'chooseCurrency',
      label: 'currency',
      component: StepChooseCurrency,
      footer: StepChooseCurrencyFooter,
      onBack: null,
      noScroll: true,
    },
    {
      id: 'connectDevice',
      label: 'connect',
      component: StepConnectDevice,
      onBack: transitionTo('chooseCurrency'),
    },
    {
      id: 'finish',
      label: 'finish',
      component: StepFinish,
      footer: StepFinishFooter,
      onBack: null,
    },
  ];

  return steps;
};

type State = {
  reset: number;
  stepId: StepId;
  error?: Error;
  errorStep?: number,
  deviceId: string | null,
  deviceName: string | null;
  currency: CryptoCurrency | null;
};

const INITIAL_STATE: State = {
  reset: 0,
  currency: null,
  deviceId: null,
  deviceName: null,
  error: undefined,
  stepId: 'registerDevice',
};

export type AddAccountsProps = {
  onBackToMethods: () => void;
  onModalClose: () => void;
};

const AddAccounts = ({ onModalClose, onBackToMethods }: AddAccountsProps) => {
  const [state, setState] = useState(INITIAL_STATE);
  const { unit } = useNestedUnitContext();
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const steps = useMemo(() => createSteps({ onBackToMethods }), [onBackToMethods]);

  const handleSetError = (error: Error | undefined, errorStep: number) => setState(prevState => ({
    ...prevState,
    errorStep,
    error,
  }));
  const handleStepChange = (step: St) => setState(prevState => ({ ...prevState, stepId: step.id }));
  const handleSetCurrency = (currency?: UnknownType) => setState(prevState => ({ ...prevState, currency }));
  const handleSetDeviceName = (deviceName: string) => setState(prevState => {
    const deviceId = extractIdFromDeviceName(deviceName);

    batch(() => {
      dispatch(setColdStorageConnectedDeviceId(`ledger-${deviceId}`));
      dispatch(setColdStorageDevice({
        id: `ledger-${deviceId}`,
        name: deviceName,
        type: StorageType.Ledger,
      }));
    });

    return ({
      ...prevState,
      deviceName,
      deviceId,
    });
  });

  const { device, onRegisterDevice, registerDeviceState } = useRegisterDevice({
    registeredDeviceIds: unit?.hardwareIds || [],
    setDeviceName: handleSetDeviceName,
    setError: handleSetError,
  });

  const onAddMore = () => {
    setState(prevState => ({
      ...prevState,
      stepId: 'chooseCurrency',
      reset: prevState.reset + 1,
    }));
  };

  const {
    deviceName,
    errorStep,
    deviceId,
    currency,
    stepId,
    reset,
    error,
  } = state;

  const errorSteps = useMemo(() => error && typeof errorStep === 'number'
    ? [errorStep]
    : [],
  [error, errorStep]);

  const stepperProps: Partial<StepProps> = {
    setCurrency: handleSetCurrency,
    setError: handleSetError,
    registerDeviceState,
    onRegisterDevice,
    onModalClose,
    deviceName,
    onAddMore,
    currency,
    deviceId,
    device,
    error,
  };

  return (
    <Stepper
      key={reset} // THIS IS A HACK because stepper is not controllable. FIXME
      steps={steps}
      stepId={stepId}
      onClose={onModalClose}
      errorSteps={errorSteps}
      onStepChange={handleStepChange}
      title={t('coldStorage.addAccounts.title')}
      {...stepperProps}
    />
  );
};

export default AddAccounts;
