import React, { useState } from 'react';
import { Typography } from 'antd';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import useChooseConnection, {
  connectionMethods,
  IConnectionMethod,
} from 'components/ColdStorage/hooks/useChooseConnection';
import ConnectionMethodButton from '../ConnectionMethodButton';
import StepperHeader from '../Stepper/components/StepperHeader/StepperHeader';
import { ReactComponent as EmptyImage } from 'assets/icons/TableEmpty.svg';
import styles from './ChooseMethodConnection.module.scss';

export type ChooseMethodConnectionProps = {
  firstConnection?: boolean;
  onModalClose?: () => void;
};

const { Title, Text } = Typography;

const ChooseMethodConnection = ({ firstConnection, onModalClose }: ChooseMethodConnectionProps) => {
  const [selectedMethod, setSelectedMethod] = useState<IConnectionMethod | null>(null);
  const {
    FlowComponent,
    stopConnection,
    startConnection,
    selectedConnection,
  } = useChooseConnection();
  const { t } = useTranslate();

  return (
    <div
      className={clsx(styles.wrapper, { [styles.firstConnection]: firstConnection })}>
      {selectedConnection && FlowComponent ? (
        <FlowComponent
          onModalClose={onModalClose}
          onBackToMethods={(toStart?: boolean) => {
            if (toStart) setSelectedMethod(null);
            stopConnection();
          }}
        />
      ) : (
        <>
          {(firstConnection && !selectedMethod) ? (
            <div className={styles.titleWrapper}>
              <EmptyImage className={styles.image} />
              <Title className={styles.title}>{t('oops')}</Title>
              <Text className={styles.subtitle}>{t('coldStorage.startMessage')}</Text>
            </div>
          ) : (
            <StepperHeader
              title={t(selectedMethod ? 'coldStorage.chooseConnectionMethod' : 'coldStorage.chooseYourColdStorage')}
              onBack={selectedMethod ? () => setSelectedMethod(null) : onModalClose}
              onClose={onModalClose}
            />
          )}

          <div className={clsx(styles.list, { [styles.noGap]: selectedConnection && FlowComponent })}>
            {selectedMethod
              ? (selectedMethod.sub as IConnectionMethod[]).map(subMethod => (
                <ConnectionMethodButton
                  key={subMethod.key}
                  icon={subMethod.icon}
                  title={subMethod.title}
                  comingSoon={subMethod.comingSoon}
                  action={() => startConnection(subMethod.key)}
                />
              ))
              : connectionMethods.map(method => (
                <ConnectionMethodButton
                  key={method.key}
                  icon={method.icon}
                  title={method.title}
                  comingSoon={method.comingSoon || !method.sub}
                  action={() => setSelectedMethod(method)}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ChooseMethodConnection;
