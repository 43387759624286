import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IBreadcrumb } from 'interfaces';

type UnitBreadcrumbsParams = {
  uuid: string | undefined;
};

const getUnitBreadcrumbs = ({ uuid }: UnitBreadcrumbsParams) => async () => {
  const { data } = await axios.get(`${SERVICE.CLIENT}/units/${uuid}/breadcrumbs`);
  return data as IBreadcrumb[];
};

const useUnitBreadcrumbs = (params: UnitBreadcrumbsParams) => {
  return useQuery({
    queryFn: getUnitBreadcrumbs(params),
    queryKey: ['unit-breadcrumbs', params],
    enabled: !!params.uuid,
  });
};

export default useUnitBreadcrumbs;
