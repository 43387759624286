import TrezorConnect from '@trezor/connect-web';
import { ColdStorageWallet } from 'components/ColdStorage/types';
import { manifest } from 'components/ColdStorage/trezor/api/config';

export const connectTrezor = async () => {
  try {
    await TrezorConnect.init({
      transports: ['WebUsbTransport'],
      manifest,
      env: 'web',
    });

    return {
      error: false,
      message: 'connected',
    };
  } catch (error) {
    return {
      error: true,
      message: 'cannot init',
    };
  }
};

export const disconnectTrezor = async () => {
  try {
    await TrezorConnect.dispose();
    return {
      error: false,
      message: 'connected',
    };
  } catch (error) {
    return {
      error: true,
      message: 'cannot disconnect',
    };
  }
};

type Address = {
  address: string;
  path: string;
};

type Addresses = {
  change: Address[];
  unused: Address[];
  used: Address[];
};

const getMainAddress = (descriptor: string, addresses?: Addresses) => {
  if (addresses) {
    return [...addresses.change, ...addresses.used, ...addresses.unused].find(address => {
      const numbers = address.path.split('/');
      return 0 === Number(numbers[numbers.length - 1]);
    })?.address || null;
  }

  return descriptor;
};

export const connectWallet = async (coin: string) => {
  const response = await TrezorConnect.getAccountInfo({
    coin,
    details: 'txs',
    tokens: 'derived',
  });
  if (!response.success) {
    throw new Error(response.payload.error);
  }

  return {
    address: getMainAddress(response.payload.descriptor, response.payload.addresses),
    publicKey: `mock_public_key_${coin}`,
  } as ColdStorageWallet;
};

// Get public addresses
// export async function getAddresses(coin: string) {
//   try {
//     const addresses: UnknownType[] = [];
//     let index = 0;
//
//     while (true as boolean) {
//       const path = `m/44'/0'/0'/0/${index}`;
//       const result = await TrezorConnect.getAddress({ path, coin, showOnTrezor: false });
//       console.log('result', result);
//       if (!result.success) break;
//       index++;
//     }
//
//     return addresses;
//   } catch (error) {
//     console.error('Error:', error);
//   }
// }

// Get public addresses
// async function getAddresses(coin: string) {
//   try {
//     const addresses: UnknownType[] = [];
//     let index = 0;
//
//     while (true) {
//       const path = `m/44'/60'/0'/0/${index}`;
//       const result = await TrezorConnect.getAddress({ path, coin, showOnTrezor: false });
//       if (!result.success) break;
//       index++;
//     }
//
//     return addresses;
//   } catch (error) {
//     console.error('Error:', error);
//   }
// }

// Get public addresses when we know count
// async function getTrezorAddressesBundle(coin: string, count: number) {
//   try {
//     const paths: string[] = [];
//     for (let index = 0; index < count; index++) {
//       paths.push(`m/44'/60'/0'/0/${index}`);
//     }
//
//     const result = await TrezorConnect.getAddress({
//       bundle: paths.map(path => ({ path, coin, showOnTrezor: false })),
//     });
//
//     if (!result.success) {
//       throw new Error('Error fetching addresses:', result.payload.error as UnknownType);
//     }
//
//   } catch (error) {
//     console.error('Error:', error);
//   }
// }

// Get transactions
// async function getTransactionHistory(address: string) {
//   const provider = new ethers.providers.EtherscanProvider();
//   const history = await provider.getHistory(address);
//   console.log(history);
//   return history;
// }
