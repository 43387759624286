import React, { ReactElement, Suspense, useContext } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient, QueryClientProvider } from 'libs/reactQuery';
import { ConfigProvider } from 'antd';
import routes from './routes';
import { AuthorisationProvider, TranslateContext } from './contexts';
import { PageLoader, Router } from './components';
import './App.scss';

function App(): ReactElement {
  const { localeSettings } = useContext(TranslateContext);

  return (
    <ConfigProvider locale={localeSettings.antdLocale}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
        <AuthorisationProvider>
          <Suspense fallback={<PageLoader />}>
            <Router routes={routes} />
          </Suspense>
        </AuthorisationProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
