import React, { ReactElement } from 'react';

export interface IIconProps {
  isActive?: boolean;
}

function SystemIcon({ isActive }: IIconProps): ReactElement {
  if (isActive) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path fill="#fff" d="M16 8a3 3 0 100-6 3 3 0 000 6z" />
        <path
          fill="#fff"
          d="M10.5 8a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
          opacity="0.35"
        />
        <path
          fill="#fff"
          d="M6 8a2 2 0 100-4 2 2 0 000 4zM18 22h-4a2 2 0 01-2-2v-7a4 4 0 018 0v7a2 2 0 01-2 2zM7 17H5a2 2 0 01-2-2v-3a3 3 0 116 0v3a2 2 0 01-2 2z"
        />
        <path
          fill="#fff"
          d="M10.5 9c-.906 0-1.724.353-2.346.919C8.676 10.458 9 11.19 9 12v3a2 2 0 01-2 2v1a2 2 0 002 2h3a2 2 0 002-2v-5.5A3.5 3.5 0 0010.5 9z"
          opacity="0.35"
        />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path fill="var(--primary-color)" d="M16 8a3 3 0 100-6 3 3 0 000 6z" />
      <path
        fill="var(--primary-color)"
        d="M10.5 8a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        opacity="0.35"
      />
      <path
        fill="var(--primary-color)"
        d="M6 8a2 2 0 100-4 2 2 0 000 4zM18 22h-4a2 2 0 01-2-2v-7a4 4 0 018 0v7a2 2 0 01-2 2zM7 17H5a2 2 0 01-2-2v-3a3 3 0 116 0v3a2 2 0 01-2 2z"
      />
      <path
        fill="var(--primary-color)"
        d="M10.5 9c-.906 0-1.724.353-2.346.919C8.676 10.458 9 11.19 9 12v3a2 2 0 01-2 2v1a2 2 0 002 2h3a2 2 0 002-2v-5.5A3.5 3.5 0 0010.5 9z"
        opacity="0.35"
      />
    </svg>
  );
}

export default SystemIcon;
