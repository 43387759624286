import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { DefaultError, UseQueryHandlers } from 'types/UseQueryHandlers';

type ApproveReplenishmentRequestDto = {
  unitId: number;
  hardwareId?: string;
  otpCode?: string;
};

export type ApproveReplenishmentRequestVariables = ApproveReplenishmentRequestDto & { id: number };

type Options = UseQueryHandlers<unknown, DefaultError, ApproveReplenishmentRequestVariables>;

const useApproveReplenishmentRequest = (options: Options) => {
  const mutationFn = async ({ id, ...body }: ApproveReplenishmentRequestVariables) => {
    const { data } = await axios.post(
      `${SERVICE.CLIENT}/replenishment-requests/approve/${id}`, body,
    );
    return data;
  };

  return useMutation({ mutationFn, ...options });
};

export default useApproveReplenishmentRequest;
