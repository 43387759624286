import React, { useMemo, useState } from 'react';
import { Tabs as AntdTabs, TabsProps as AntdTabsProps } from 'antd';
import styles from './Tabs.module.scss';

export type TabItem = {
  tab: string;
  label: string;
  content: React.ReactNode;
  extraButtons: React.ReactNode[] | null;
  hidden?: boolean;
};

export type TabsProps = {
  options: TabItem[];
};

const Tabs = ({ options }: TabsProps) => {
  const [activeTab, setActiveTab] = useState<string>(options[0].tab);

  const tabs: AntdTabsProps['items'] = useMemo(() => (
    options.filter(option => !option.hidden).map(({ tab, label, content }) => ({
      key: tab,
      label: <div style={{ fontSize: 15 }}>{label}</div>,
      children: content,
    }))
  ), [options]);

  const extraButtons = options.find(option => option.tab === activeTab)?.extraButtons;
  const tabBarExtraContent = extraButtons?.length && (
    <div className={styles.extra}>
      {extraButtons.map((item, i) => <div key={'extra' + i}>{item}</div>)}
    </div>
  );

  return (
    <AntdTabs
      items={tabs}
      activeKey={activeTab}
      onChange={setActiveTab}
      tabBarExtraContent={tabBarExtraContent}
      className={styles.tabs}
      tabBarGutter={20}
      tabBarStyle={{ color: 'var(--black-2-80-percent-color)' }}
    />
  );
};

export default Tabs;
