import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Permission } from '@fanckler/processing-auth';
import { Card, Form, Input, Select, Space, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { RcFile } from 'antd/lib/upload';
import _ from 'lodash';
import { IUser } from 'interfaces/IUser';
import { UnknownType } from 'types/Unknown';
import { useGroups } from 'pages/Administration/AdministrationGroups/hooks';
import { useBusinesses } from 'pages/Administration/Businesses/hooks';
import { useTranslate } from 'hooks';
import { useDeleteAvatar, useUploadAvatar } from 'hooks/useAvatar';
import { useConfirmRootAdmin } from 'hooks/useConfirmRootAdmin';
import { useAuthorisationContext } from 'contexts';
import { ButtonChangePassword } from '../ButtonChangePassword';
import { SmallButton } from '../SmallButton';
import { Button, UploadLogo, UserAvatar } from 'components';
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import styles from './PersonalInfo.module.scss';

const { Title, Text } = Typography;

export type PersonalInfoPropsType = {
  data: IUser;
  onSave?: (values: Partial<IUser>) => Promise<void>
};

const uniqueValues = <T extends Record<string, UnknownType>>(
  obj1: T,
  obj2: T,
) => {
  return _.reduce(obj2, (result, value, key) => {
    if (!_.isEqual(value, obj1[key])) {
      (result as UnknownType)[key] = value;
    }
    return result;
  }, {} as T);
};

export const PersonalInfo = ({ data, onSave }: PersonalInfoPropsType): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowButtons, setIsShowButtons] = useState(false);
  const [initialAvatar, setInitialAvatar] = useState<string | null>(data?.avatar);
  const [newAvatar, setNewAvatar] = useState<RcFile>();
  const { t } = useTranslate();
  const { checkPermissions, user } = useAuthorisationContext();
  const { isRoot } = useConfirmRootAdmin();
  const [form] = useForm();

  const isMyAccount = user?.id === data.id;
  const { mutate: uploadAvatar } = useUploadAvatar();
  const { mutate: deleteAvatar, isPending: isDeletePending } = useDeleteAvatar();
  const {
    data: businesses = { nodes: [{ id: data.rootUnitId, name: data.rootUnit?.name }] },
    isFetching: isBusinessesFetching,
  } = useBusinesses({
    enabled: !isMyAccount,
    withoutPaging: true,
  });
  const {
    groups,
    isGroupsFetching,
  } = useGroups({
    filter: {},
    withoutPaging: true,
    enabled: !isMyAccount,
  });
  const options = groups.length ? groups : [{ id: data.groupId, name: data.group?.name }];
  const canUpdate = checkPermissions([Permission.ADMIN_IAM_USER_UPDATE]) && checkPermissions([Permission.ADMIN_IAM_ROOT_UNIT_ADMIN]);

  const initialValues = useMemo(() => ({
    name: data.name,
    email: data.email,
    groupId: isMyAccount ? data.group.name : (data.groupId || 1),
    rootUnitId: isMyAccount ? data.rootUnit?.name : data.rootUnitId,
  }), [data, isMyAccount]);

  const canToggle2fa = useMemo(() => (
    (isRoot || checkPermissions([Permission.CLIENT_UNIT_CREATE]) && checkPermissions([Permission.ADMIN_IAM_ROOT_UNIT_ADMIN])) && !isMyAccount
  ), [checkPermissions, isMyAccount, isRoot]);

  const handleChanges = useCallback(() => {
    const isEqualsValues = _.isEqual(initialValues, form.getFieldsValue());
    setIsShowButtons(!isEqualsValues);
  }, [initialValues, form]);

  useEffect(() => {
    setInitialAvatar(data.avatar);
  }, [data]);

  const handleAvatarChange = (file: RcFile) => {
    setNewAvatar(file);
    uploadAvatar(file);
  };

  const handleAvatarDelete = () => {
    if (data.avatar) {
      deleteAvatar();
    }
    setInitialAvatar(null);
  };

  const handleFormSubmit = useCallback(async (values: UnknownType) => {
    try {
      if (onSave) {
        setIsLoading(true);
        await onSave(uniqueValues(initialValues, values));
      }
      setIsShowButtons(false);
    } catch (error) {
      // do nothing
    } finally {
      setIsLoading(false);
    }
  }, [initialValues, onSave]);

  const handleReset2FA = useCallback(async () => {
    await handleFormSubmit({ is2fa: false });
  }, [handleFormSubmit]);

  return (
    <div style={{ display: 'flex', gap: 24 }}>
      <Card className={styles.card} style={{ width: '100%' }} hidden={!isMyAccount}>
        <Space direction="vertical" size={[0, 20]} className={styles.content}>
          {data.avatar ? (
            <UserAvatar
              withoutText
              name={data.name}
              src={data.avatar}
              className={styles.avatar}
            />
          ) : (
            <UploadLogo
              onChange={handleAvatarChange}
              value={newAvatar || initialAvatar}
            />
          )}

          <div>
            <Title className={styles.name}>
              {data.name}
            </Title>

            <Text className={styles.email}>{data.email}</Text>
          </div>

          <div className={styles.buttons}>
            <Button
              type="link"
              color="error"
              loading={isDeletePending}
              onClick={handleAvatarDelete}
              suffixIcon={<DeleteOutlined />}
              disabled={!initialAvatar || isDeletePending}
            >
              {t('file.deleteAvatar')}
            </Button>
          </div>
        </Space>
      </Card>

      <Form
        form={form}
        onFinish={handleFormSubmit}
        onChange={handleChanges}
        className={styles.form}
        style={{ width: '100%' }}
        initialValues={initialValues}
      >
        <div style={{ display: 'flex', flexDirection: isMyAccount ? 'column' : 'row', gap: '18px', marginBottom: '18px' }}>
          <Form.Item name="name" label={t('nameAndSurname')} className={styles.formItem} style={{ width: '100%', marginBottom: 0 }}>
            <Input className={styles.input} placeholder={t('nameAndSurname')} disabled={!(isMyAccount || canUpdate)} />
          </Form.Item>

          <Form.Item name="email" label={t('email')} className={styles.formItem} style={{ width: '100%' }}>
            <Input className={styles.input} placeholder={t('email')} disabled={!canUpdate || isMyAccount} />
          </Form.Item>
        </div>

        <Space.Compact style={{ display: 'flex', gap: 20 }}>
          <Form.Item
            name="rootUnitId"
            label={t('business.title')}
            className={styles.formItem}
            rules={[{ required: true, message: t('business.pleaseSelect') }]}
            style={{ width: '100%' }}
          >
            <Select
              placeholder={t('business.title')}
              loading={isBusinessesFetching}
              disabled={isBusinessesFetching || !canUpdate || !isRoot || isMyAccount}
              className={styles.select}
              onSelect={handleChanges}
            >
              {businesses && businesses?.nodes?.map((item) => (
                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="groupId"
            label={t('users.roles.one')}
            className={styles.formItem}
            rules={[{ required: true, message: t('pleaseSelectGroup') }]}
            style={{ width: '100%' }}
          >
            <Select
              placeholder={t('selectGroup')}
              loading={isGroupsFetching}
              disabled={isGroupsFetching || !canUpdate || isMyAccount}
              className={styles.select}
              onSelect={handleChanges}
            >
              {options && options.map((item) => (
                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Space.Compact>
        {isMyAccount && (
          <ButtonChangePassword />
        )}
        {canToggle2fa && (
          <SmallButton
            onClick={handleReset2FA}
            suffixIcon={<ReloadOutlined style={{ fontSize: 16 }} />}
            disabled={isLoading}
          >
            {t('2FA.reset')}
          </SmallButton>
        )}
        {isShowButtons && (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 24 }}>
            <Button
              color="error"
              type="link"
              htmlType="reset"
              onClick={() => {
                form.resetFields();
                setIsShowButtons(false);
              }}
            >
              {t('cancel')}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!(isMyAccount || canUpdate) || isLoading}
              loading={isLoading}
            >
              {t('acceptAction')}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};
