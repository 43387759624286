import React, { useCallback, useMemo, useState } from 'react';
import { notification, Radio, RadioChangeEvent } from 'antd';
import { IUser } from 'interfaces/IUser';
import { useTranslate } from 'hooks';
import { useChangeNotification } from '../../hooks';
import { useAuthorisationContext } from 'contexts';
import styles from './NotificationSettings.module.scss';

type NotificationSettingsProps = {
  user: IUser;
  setUser?: (user: IUser) => void;
};

const notificationsVariants = [
  {
    key: 'email',
    title: 'Email',
    disabled: false,
  },
  {
    key: 'push',
    title: 'Push',
    disabled: false,
  },
  {
    key: 'telegram',
    title: 'Telegram-bot',
    disabled: true,
  },
];

const NotificationSettings = ({ user, setUser }: NotificationSettingsProps) => {
  const { t } = useTranslate();
  const [value, setValue] = useState(user.notification);
  const { user: myUser } = useAuthorisationContext();
  const isMyAccount = myUser?.id === user.id;

  const { isChangeNotificationLoading, changeNotification } = useChangeNotification({
    onSuccess: (data) => {
      if (setUser) {
        setUser({
          ...user,
          notification: data.notification || user.notification,
        });
      }

      notification.close('notification');
      notification.success({
        message: t('notifications.settings.title'),
        description: t('notifications.settings.savingSuccess'),
        key: 'notification',
      });
    },
    onError: () => {
      setValue(user.notification);

      notification.close('notification');
      notification.error({
        message: t('notifications.settings.title'),
        description: t('notifications.settings.savingError'),
        key: 'notification',
      });
    },
  });

  const notificationsList = useMemo(() => {
    return notificationsVariants.map((item) => ({
      ...item,
      disabled: (item.key === 'email' && !user.rootUnit?.smtp) ? true : item.disabled,
    }));
  }, [user.rootUnit?.smtp]);

  const handleChange = useCallback((event: RadioChangeEvent) => {
    notification.close('notification');
    notification.info({
      message: t('notifications.settings.title'),
      description: t('notifications.settings.savingInProgress'),
      key: 'notification',
    });

    setValue(event.target.value);
    changeNotification({ notification: event.target.value });
  }, [changeNotification, t]);

  return (
    <Radio.Group value={value} onChange={handleChange} disabled={isChangeNotificationLoading}>
      {notificationsList.map((item, index) => (
        <Radio
          key={index}
          disabled={item.disabled || !isMyAccount}
          className={styles.checkbox}
          value={item.key}
        >
          {item.title}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default NotificationSettings;
