import { useSelector } from 'init/rootReducer';
import { ColdStorageData } from 'components/ColdStorage/types';

const useColdStorageDevice = () => {
  const connectedDeviceId = useSelector((state) => state.coldStorageReducer.connectedDeviceId);
  const lastConnectedDeviceId = useSelector((state) => state.coldStorageReducer.lastConnectedDeviceId);
  const storages = useSelector((state) => state.coldStorageReducer.storages);

  const key = connectedDeviceId || lastConnectedDeviceId;
  const coldStorageDevice = (key && storages[key]) as ColdStorageData | null;

  return {
    key,
    coldStorageDevice,
    isConnected: Boolean(connectedDeviceId),
  };
};

export const useColdStorageHardwareId = (): string | null => {
  const isColdStorageMode = useSelector((state) => state.coldStorageReducer.isColdStorageMode);
  const connectedDeviceId = useSelector((state) => state.coldStorageReducer.connectedDeviceId);
  const lastConnectedDeviceId = useSelector((state) => state.coldStorageReducer.lastConnectedDeviceId);

  return isColdStorageMode ? connectedDeviceId || lastConnectedDeviceId || null : null;
};

export default useColdStorageDevice;
