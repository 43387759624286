import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { AxiosError } from 'libs/axios';
import { Form, Input, notification } from 'antd';
import { Rule } from 'antd/es/form';
import { FormDatatype } from 'pages/Administration/Managers/components/FormCreateUser/hooks/useCreateInternalUser/types';
import { useTranslate } from 'hooks';
import { StageEnum } from 'hooks/use2FA';
import { useCreateClient } from 'components/CreateClientButton/hooks/useCreateClient';
import { useModal } from 'components/Modal/hooks/useModal';
import { OTP_ERROR } from 'constants/index';
import { useAuthorisationContext } from 'contexts';
import { emailValidator } from 'utils/emailValidator';
import { BusinessSelect } from './BusinessSelect';
import { Button, With2FA } from 'components';
import styles from './FormCreateClient.module.scss';

type FormData = Omit<FormDatatype, 'groupId'>;
type FormCreateClientProps = {
  unitId?: number;
  rootUnitId?: number;
  onRefresh?: () => void;
};

export type ICreateClient = {
  unitId?: number | undefined;
  merchantId: string;
  name: string;
  email: string;
};

export const FormCreateClient = ({ unitId, rootUnitId, onRefresh }: FormCreateClientProps): ReactElement => {
  const [rootUnitID, setRootUnitID] = useState(rootUnitId);
  const [savedData, setSavedData] = useState<FormData>();
  const {
    twoFA: {
      stage,
      setStage,
      onError,
      onSuccess,
    },
  } = useAuthorisationContext();
  const { t } = useTranslate();

  const { onModalClose, isOpen } = useModal('createClient');

  const { mutation, loading: isCreating } = useCreateClient({
    onSuccess: (res) => {
      onSuccess(res, () => {
        onModalClose();
        notification.success({ message: t('client.createSuccess') });

        if (onRefresh) onRefresh();
      });
    },
    onError: (error: AxiosError<{ message: string }>) => {
      onError(error, () => {
        if (stage !== StageEnum.DEFAULT && error.response?.data?.message.includes(OTP_ERROR)) {
          setStage(StageEnum.DEFAULT);
        }
        notification.error({
          message: error.response?.data?.message,
        });
      });
    },
  });
  const [form] = Form.useForm<FormData>();

  useEffect(() => {
    if (isOpen) {
      return () => {
        form.resetFields();
      };
    }

    return undefined;
  }, [form, isOpen]);

  const handleSubmit = (values: FormData, otpCode?: string) => {
    setSavedData(values);
    const createdClient = {
      ...values,
      merchantId: values.name.split(' ').join(''),
      name: values.name?.trim(),
      ...(rootUnitID && { rootUnitId: rootUnitID }),
      ...(unitId && { unitId }),
      otpCode,
    };

    mutation(createdClient as ICreateClient);
  };

  const handleSubmitOtp = (otp: string) => {
    if (!savedData || !otp) return;

    handleSubmit(savedData, otp);
  };

  const inputEmailHandler = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      email: e.target.value.trim(),
    });
  };

  const inputNameHandler = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      name: e.target.value.trim(),
    });
  };

  // const inputPasswordHandler = (e: ChangeEvent<HTMLInputElement>) => {
  //   form.setFieldsValue({
  //     password: e.target.value.trim(),
  //   });
  // };

  // const onGeneratePassword = () => {
  //   const newPassword = generatePassword();
  //   form.setFieldsValue({ password: newPassword });
  // };

  const emailRules: Rule[] = [
    () => ({
      validator(_, value: string) {
        return emailValidator(t, value);
      },
    }),
  ];

  return (
    <With2FA onSubmit={handleSubmitOtp}>
      <Form
        name="createClient"
        className={styles.form}
        form={form}
        onFinish={handleSubmit}
      >
        <div>
          <Form.Item
            name="name"
            label={t('name')}
            className={styles.formItem}
            rules={[
              { max: 100 },
              { required: true },
              {
                pattern: /^(?:[\p{L}\p{N}%^&@#$^*:'\\.\-_]+(?: [\p{L}\p{N}%^&@#$^*:'\\.\-_]+)?)$/u,
                message: t('namePattern'),
              },
            ]}
          >
            <Input
              placeholder={t('name')}
              onBlur={inputNameHandler}
              className={styles.input}
            />
          </Form.Item>
          <Form.Item
            required
            name="email"
            label={t('email')}
            className={styles.formItem}
            rules={emailRules}
          >
            <Input
              placeholder={t('email')}
              onChange={inputEmailHandler}
              className={styles.input}
            />
          </Form.Item>

          {/* <div className={clsx(styles.formItem, styles.withAddonBtn)}>
            <Form.Item
              name="password"
              label={t('password')}
              rules={[
                { required: true, message: t('pleaseInputPassword') },
                { pattern: PASSWORD_REGEX, message: t('passwordRules') },
              ]}
            >
              <Input.Password
                autoComplete="new-password"
                prefix={<LockOutlined className={styles.icon} />}
                onChange={inputPasswordHandler}
                placeholder={t('password')}
                className={styles.input}
              />
            </Form.Item>

            <Button
              type="link"
              textSize="14px"
              withoutBgShadow
              withoutWaveEffect
              prefixIcon={<SyncOutlined />}
              className={styles.genereatePassBtn}
              onClick={onGeneratePassword}
            >
              {t('myAccount.generatePassword')}
            </Button>
          </div> */}

          {!unitId && !rootUnitId && (
            <Form.Item
              label={t('client.selectBusiness')}
              className={styles.formItem}
            >
              <BusinessSelect
                onChange={setRootUnitID}
                className={styles.select}
                inputPlaceholder={t('client.selectBusiness')}
              />
            </Form.Item>
          )}
        </div>

        <div className={styles.buttons}>
          <Button
            block
            htmlType="submit"
            loading={isCreating}
          >
            {t('create')}
          </Button>

          <Button
            block
            type="link"
            color="error"
            htmlType="button"
            onClick={onModalClose}
          >
            {t('cancel')}
          </Button>
        </div>
      </Form>
    </With2FA>
  );
};
