export enum DeviceModelId {
  blue = 'blue',
  stax = 'stax',
  nanoS = 'nanoS',
  nanoSP = 'nanoSP',
  nanoX = 'nanoX',
  nanoFTS = 'nanoFTS',
}

export interface DeviceModel {
  id: DeviceModelId;
  productName: string;
  productIdMM: number;
  legacyUsbProductId: number;
  usbOnly: boolean;
  memorySize: number;
  masks: number[];
  getBlockSize: (firmwareVersion: string) => number;
  bluetoothSpec?: {
    serviceUuid: string;
    writeUuid: string;
    writeCmdUuid: string;
    notifyUuid: string;
  }[];
}
