import { useRef } from 'react';

type UseFocusTrackParams = {
  onBlur: () => void;
  extraCondition: boolean;
};

const useFocusTrack = ({ onBlur, extraCondition }: UseFocusTrackParams) => {
  const focusedElementRef = useRef<HTMLDivElement>(null);

  const handleElementBlur = (event: React.FocusEvent) => {
    if (!focusedElementRef.current?.contains(event.relatedTarget as Node) && extraCondition) {
      onBlur();
    }
  };

  return { focusedElementRef, handleElementBlur };
};

export default useFocusTrack;
