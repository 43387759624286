import React from 'react';
import { Permission } from '@fanckler/processing-auth';
import { NestedUnitType } from 'interfaces';
import { CheckPermissionsFunction } from 'contexts/AuthorisationContext/AuthorisationContext';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import {
  AddClientDropdown,
  AddSubaccountButton,
  AddWhitelistWalletButton,
  AssignManagerButton,
  ClientsTab,
  CreateInvoiceButton,
  CreateUnitButton,
  InvoicesTab,
  ManagersTab,
  PendingApprovalsTab,
  RefetchTransactionsButton,
  SubaccountsTab,
  TransactionsTab,
  UnitsTab,
  WhitelistTab,
} from '../tabs';
import { TabItem } from 'components';

export type GetTabsParams = {
  unit: NestedUnitType | undefined;
  isColdStorageMode: boolean;
  translate: TranslateFunc;
  checkPermissions: CheckPermissionsFunction;
};

const getTabs = ({
  unit,
  translate,
  checkPermissions,
  isColdStorageMode,
}: GetTabsParams) => {
  const isActive = Boolean(unit?.isActive);
  const managerIds = unit?.adminIds || [];
  const rootUnitId = unit?.rootUnitId as number;
  const unitId = unit?.id as number;
  const uuid = unit?.uuid as string;

  const tabs: TabItem[] = [
    {
      tab: 'transactions',
      label: translate('transactions'),
      content: <TransactionsTab unitId={unitId} />,
      extraButtons: [<RefetchTransactionsButton disabled={!isActive} />],
    },
    {
      tab: 'invoices',
      label: translate('invoice.title'),
      content: <InvoicesTab uuid={uuid} />,
      extraButtons: [checkPermissions([Permission.CLIENT_INVOICE_CREATE]) && (
        <CreateInvoiceButton unitId={unitId} disabled={!isActive} />
      )],
      hidden: !checkPermissions([Permission.CLIENT_INVOICE_READ]),
    },
    {
      tab: 'pendingApprovals',
      label: translate('pendingApprovals'),
      content: <PendingApprovalsTab unit={unit} />,
      extraButtons: null,
      hidden: isColdStorageMode,
    },
    {
      tab: 'subaccounts',
      label: translate('subaccount.many'),
      content: <SubaccountsTab uuid={uuid} isActive={isActive} />,
      extraButtons: [checkPermissions([Permission.CLIENT_UNIT_SUB_WALLET_CREATE]) && (
        <AddSubaccountButton unitId={unitId} disabled={!isActive} />
      )],
      hidden: !checkPermissions([Permission.CLIENT_UNIT_SUB_WALLET_READ]),
    },
    {
      tab: 'whitelist',
      label: translate('whitelist.title'),
      content: <WhitelistTab uuid={uuid} isActive={isActive} />,
      extraButtons: [checkPermissions([Permission.CLIENT_WHITELIST_CREATE]) && (
        <AddWhitelistWalletButton uuid={uuid} disabled={!isActive} />
      )],
      hidden: !checkPermissions([Permission.CLIENT_WHITELIST_READ]),
    },
    {
      tab: 'managers',
      label: translate('administration.managers.title'),
      content: <ManagersTab managerIds={managerIds} />,
      extraButtons: [
        <AssignManagerButton
          unitId={unitId}
          rootUnitId={rootUnitId}
          managerIds={managerIds}
          disabled={!isActive}
        />,
      ],
    },
    {
      tab: 'clients',
      label: translate('clients'),
      content: <ClientsTab unitId={unitId} />,
      extraButtons: [<AddClientDropdown unit={unit as NestedUnitType} disabled={!isActive} />],
    },
    {
      tab: 'units',
      label: translate('unitsCount'),
      content: <UnitsTab uuid={uuid} />,
      extraButtons: [checkPermissions([Permission.CLIENT_UNIT_CREATE]) && (
        <CreateUnitButton rootUnitId={rootUnitId} unitId={unitId} disabled={!isActive} />
      )],
    },
  ];

  return tabs;
};

export default getTabs;
