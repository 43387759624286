import React, { ReactElement } from 'react';
import { UnknownType } from 'types/Unknown';
import styles from './WithCustomWrapper.module.scss';

const WithCustomWrapper = ({
  value,
  onChange,
  children,
}: {
  value?: UnknownType;
  onChange?: (value: UnknownType) => void;
  children: (props: { value?: UnknownType, onChange?: (value: UnknownType) => void }) => ReactElement;
}) => (
  <div className={styles.wrapper}>
    {children({ value, onChange })}
  </div>
);

export default WithCustomWrapper;
