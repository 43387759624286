import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { MutationOptions } from './types';

const createUnit = async (body: FormData) => {
  const { data } = await axios.post(`${SERVICE.CLIENT}/units/create`, body);
  return data;
};

const useCreateUnit = (mutationOptions?: MutationOptions) => {
  return useMutation({
    mutationFn: createUnit,
    ...mutationOptions,
  });
};

export default useCreateUnit;
