import React from 'react';
import { Image, Select } from 'antd';
import clsx from 'clsx';
import { IWallet } from 'interfaces';
import { WalletCurrencyEnum } from 'types/WalletCurrencyEnum';
import { useTranslate } from 'hooks';
import { CurrencyIcon } from 'utils';
import { CountUp } from 'components';
import styles from './WalletSelect.module.scss';

export type WalletSelectProps = {
  wallets: IWallet[];
  value?: string;
  onChange?: (value: string) => void;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  popupClassName?: string;
};

const WalletSelect = ({
  wallets,
  value,
  onChange,
  loading,
  disabled,
  className,
  popupClassName,
}: WalletSelectProps) => {
  const { t } = useTranslate();

  return (
    <Select
      value={value}
      onChange={onChange}
      loading={loading}
      disabled={disabled}
      placeholder={t('wallet')}
      className={clsx(styles.select, className)}
      popupClassName={clsx(styles.popup, popupClassName)}
    >
      {wallets.map(wallet => {
        const isSubWallet = wallet.subId !== null;

        return (
          <Select.Option key={wallet.id} value={wallet.walletAddress}>
            <div className={styles.option}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <Image
                  preview={false}
                  src={CurrencyIcon[wallet.currency]}
                  key={`option-icon-${wallet.currency}`}
                  className={styles.icon}
                />

                <span className={styles.label}>
                  {isSubWallet ? wallet.name : (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                      {wallet.currency}

                      {[WalletCurrencyEnum.USDT, WalletCurrencyEnum.USDC].includes(
                        wallet.currency as WalletCurrencyEnum,
                      ) && (
                        <span className={styles.network}>{wallet.network}</span>
                      )}
                    </div>
                  )}
                </span>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <CountUp
                  isRoundDown
                  end={+wallet.balance}
                  currency={wallet.currency}
                  className={styles.balance}
                />
                <span className={styles.chip}>
                  {isSubWallet ? t('subaccountWalletType') : t('mainWalletType')}
                </span>
              </div>
            </div>
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default WalletSelect;
