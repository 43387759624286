import React, {
  ReactElement,
  useEffect,
  useMemo, useState } from 'react';
import { Select } from 'antd';
import clsx from 'clsx';
import { IBusiness } from 'interfaces/IBusiness';
import { useBusinesses } from 'pages/Administration/Businesses/hooks/useBusinesses';
import styles from './BusinessSelect.module.scss';

export type BusinessOptionType = {
  key: number,
  label: string,
  value: number,
};

export type BusinessSelectPropsType = {
  onChange: (businessId: number) => void;
  inputPlaceholder: string;
  popupClassName?: string;
  className?: string;
};

export const BusinessSelect = ({
  onChange,
  inputPlaceholder,
  popupClassName,
  className,
}: BusinessSelectPropsType): ReactElement => {
  const [searchText, setSearchText] = useState('');

  const { data, isLoading, refetch } = useBusinesses({
    filter: {
      and: [searchText ? { name: { iLike: `%${searchText.trim()}%` } } : {}],
    },
  });

  useEffect(() => {
    if (searchText) {
      refetch();
    }
  }, [refetch, searchText]);

  const businessOptions: BusinessOptionType[] = useMemo(() =>
    (data?.nodes || []).map((business: IBusiness) => ({
      key: business.id,
      label: business.name,
      value: business.id,
    })),
  [data?.nodes]);

  return (
    <Select
      showSearch
      optionFilterProp="label"
      onSelect={onChange}
      loading={isLoading}
      searchValue={searchText}
      onSearch={setSearchText}
      className={clsx(styles.select, className)}
      placeholder={inputPlaceholder}
      popupClassName={popupClassName}
      options={businessOptions}
    />
  );
};
