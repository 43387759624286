import { DeviceModelId } from '../types';

const getDeviceNameMaxLength = (deviceModelId: DeviceModelId | undefined) => {
  switch (deviceModelId) {
    case DeviceModelId.stax:
    case DeviceModelId.nanoX: {
      return 20;
    }
    default:
      return 17;
  }
};

export default getDeviceNameMaxLength;
