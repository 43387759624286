import React from 'react';
import { useTranslate } from 'hooks';
import { ContentWithButton } from '../ContentWithButton';
import { CheckCircleOutlined } from '@ant-design/icons';
import styles from './CompletedConnection.module.scss';

type CompletedConnectionProps = {
  onComplete?: () => void;
};

const CompletedConnection = ({ onComplete }: CompletedConnectionProps) => {
  const { t } = useTranslate();

  return (
    <ContentWithButton onButtonClick={onComplete}>
      <div className={styles.content}>
        <CheckCircleOutlined className={styles.icon} />
        <span className={styles.title}>{t('coldStorage.successfullyAdded')}</span>
      </div>
    </ContentWithButton>
  );
};

export default CompletedConnection;
