import React, { ReactElement } from 'react';
import styles from './Chip.module.scss';

const Chip = ({ children }: { children: ReactElement | string }) => (
  <span className={styles.wrapper}>
    {children}
  </span>
);

export default Chip;
