import React from 'react';
import { Permission } from '@fanckler/processing-auth';
import { ColumnsType } from 'antd/es/table';
import { ICreator } from '../../../../interfaces/ICreator';
import { IGroup } from '../../../../interfaces/IGroup';
import { IPermission } from '../../../../interfaces/IPermission';
import { Path } from '../../../../routes/interfaces/Path';
import { GroupSortField } from '../types';
import { Sort } from '../../../../hooks';
import { CheckPermissionsFunction } from '../../../../contexts/AuthorisationContext/AuthorisationContext';
import { TranslateFunc } from '../../../../contexts/TranslateContext/TranslateContext';
import { StatusBadge } from 'components/StatusBadge';
import {
  DateTime,
  EditButton,
  Permissions,
  RelationLink,
} from '../../../../components';

export interface GetColumnsParams {
  translate: TranslateFunc;
  editGroup: (group: IGroup) => void;
  sort: Sort<GroupSortField> | null;
  checkPermissions: CheckPermissionsFunction;
  currentId?: number;
}

const getColumns = ({
  sort,
  editGroup,
  translate,
  checkPermissions,
  currentId,
}: GetColumnsParams): ColumnsType<IGroup> => {
  const columns: ColumnsType<IGroup> = [
    {
      key: 'updatedBy.name',
      title: translate('creator'),
      dataIndex: 'updatedBy',
      width: 160,
      sorter: true,
      sortOrder: sort && sort.field === 'updatedBy.name' ? sort.order : undefined,
      render: (creator: ICreator, { updatedBy }) => {
        const { name } = updatedBy || creator;
        return name;
      },
    },
    {
      key: 'updatedBy.id',
      title: translate('id'),
      dataIndex: 'updatedBy',
      width: 80,
      sorter: true,
      sortOrder: sort && sort.field === 'updatedBy.id' ? sort.order : undefined,
      render: (creator: ICreator, { updatedBy }) => {
        const { id } = updatedBy || creator;

        return (
          <RelationLink
            filters={{ id }}
            path={Path.ADMINISTRATION_MANAGERS}
            permissions={[Permission.ADMIN_IAM_USER_READ]}
          >
            {id}
          </RelationLink>
        );
      },
    },
    {
      key: 'updatedBy.email',
      title: translate('email'),
      dataIndex: 'updatedBy',
      width: 160,
      sorter: true,
      sortOrder: sort && sort.field === 'updatedBy.email' ? sort.order : undefined,
      render: (creator: ICreator, { updatedBy }) => {
        const { email } = updatedBy || creator;

        return (
          <a href={`mailto:${email}`}>{email}</a>
        );
      },
    },
    {
      key: 'rootUnit.name',
      title: translate('business.title'),
      dataIndex: 'rootUnit',
      width: 160,
      sorter: true,
      sortOrder: sort && sort.field === 'rootUnit.name' ? sort.order : undefined,
      render: (item) => item?.name,
    },
    {
      key: 'name',
      title: translate('name'),
      width: 160,
      dataIndex: 'name',
      sorter: true,
      sortOrder: sort && sort.field === 'name' ? sort.order : undefined,
    },
    {
      key: 'createdAt',
      title: translate('created'),
      dataIndex: 'createdAt',
      width: 160,
      sorter: true,
      sortOrder: sort && sort.field === 'createdAt' ? sort.order : undefined,
      render: (createdAt) => <DateTime value={createdAt} />,
    },
    {
      key: 'updatedAt',
      title: translate('updated'),
      dataIndex: 'updatedAt',
      width: 160,
      sorter: true,
      sortOrder: sort && sort.field === 'updatedAt' ? sort.order : undefined,
      render: (updatedAt) => <DateTime value={updatedAt} />,
    },
    {
      key: 'permissions.name',
      title: translate('permissions'),
      dataIndex: 'permissions',
      width: 160,
      render: (permissions: IPermission[]) => <Permissions permissions={permissions} />,
    },
    {
      key: 'isActive',
      title: translate('status'),
      dataIndex: 'isActive',
      width: 160,
      sorter: true,
      sortOrder: sort && sort.field === 'isActive' ? sort.order : undefined,
      render: (isActive) => <StatusBadge status={isActive ? 'activated' : 'deactivated'} />,
    },
  ];

  if (checkPermissions([Permission.ADMIN_IAM_GROUP_UPDATE])) {
    columns.push({
      key: '__extra__',
      dataIndex: '',
      fixed: 'right',
      align: 'center',
      width: 66,
      render: (_, group) => (group.id !== currentId || group.id === 1) && (
        <EditButton onClick={() => editGroup(group)} />
      ),
    });
  }

  return columns;
};

export default getColumns;
