import axios, { AxiosError, SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';

type RequestReplenBodyType = {
  currency: string;
  network: string;
  walletAddress: string;
  unitId: number;
  amount: number;
  description?: string;
  category: number;
  group: number;
};
type RequestReplenVariablesType = { body: RequestReplenBodyType };
export type OptionType = {
  onSuccess?: (e: unknown, variables?: RequestReplenVariablesType) => Promise<void>;
  onError?: (e: AxiosError<{ message: string }>, variables?: RequestReplenVariablesType) => void;
};

export const useRequestReplen = (options: OptionType) => {
  const UNIT_URL = `${SERVICE.CLIENT}/replenishment-requests/create`;

  const request = async ({ body }: RequestReplenVariablesType) => {
    const { data } = await axios.post(UNIT_URL, body);
    return data;
  };

  const {
    isPending: isRequesting,
    mutate: mutateRequestReplen,
  } = useMutation({
    mutationFn: request,
    ...options,
  });

  return {
    mutateRequestReplen,
    isRequesting,
  };
};
