import React, { useCallback } from 'react';
import { Permission } from '@fanckler/processing-auth';
import { Spin, Typography } from 'antd';
import { IWallet } from 'types/IWallet';
import { UnknownType } from 'types/Unknown';
import { useTranslate } from 'hooks';
import { CreateWalletDTO, useWalletFilter } from './hooks';
import { useAuthorisationContext } from 'contexts';
import { AddWallet } from 'components';
import { EmptyWallets, NetworkSelection, WalletItem } from './components';
import styles from './WalletOverview.module.scss';

export type WalletOverviewProps = {
  wallets: IWallet[];
  isLoading: boolean;
  isCreating: boolean;
  onCreateWallet: (args: UnknownType) => void;
  onAddColdWallet?: () => void;
};

const WalletOverview = ({
  wallets = [],
  isLoading,
  isCreating,
  onCreateWallet,
  onAddColdWallet,
}: WalletOverviewProps) => {
  const { checkPermissions } = useAuthorisationContext();
  const { t } = useTranslate();

  const {
    filteredWallets,
    onWalletWillCreate,
    selectedNetworks,
    toggleSelectedNetwork,
    favoriteWallets,
    toggleFavoriteWallet,
  } = useWalletFilter(wallets);

  const createWalletWrapper = useCallback((args: CreateWalletDTO) => {
    onCreateWallet(args);
    onWalletWillCreate(args);
  }, [onCreateWallet, onWalletWillCreate]);

  return (
    <section className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.leftContent}>
          <Typography.Title className={styles.title}>
            {t('wallets')}
          </Typography.Title>
          {!isLoading && (
            <AddWallet
              buttonType="default"
              wallets={wallets}
              isLoading={isCreating}
              disabled={!checkPermissions([Permission.CLIENT_USER_UPDATE])}
              onCreateWallet={createWalletWrapper}
              onAddColdWallet={onAddColdWallet}
              canOpenColdStorageModal={Boolean(onAddColdWallet)}
              className={styles.addWallet}
            />
          )}
        </div>

        <NetworkSelection
          isLoading={isLoading}
          wallets={wallets}
          selectedNetworks={selectedNetworks || []}
          onToggleSelectedNetwork={toggleSelectedNetwork}
        />
      </div>

      {isLoading ? (
        <Spin className={styles.loader} />
      ) : (
        <div className={styles.list}>
          {!filteredWallets.length ? (
            <EmptyWallets>
              <AddWallet
                buttonType="primary"
                wallets={wallets}
                isLoading={isCreating}
                disabled={!checkPermissions([Permission.CLIENT_USER_UPDATE])}
                onCreateWallet={createWalletWrapper}
                onAddColdWallet={onAddColdWallet}
                canOpenColdStorageModal={Boolean(onAddColdWallet)}
                className={styles.addWallet}
              />
            </EmptyWallets>
          ) : (
            (filteredWallets || []).map((wallet: IWallet) => (
              <WalletItem
                key={wallet.id}
                balance={wallet.balance}
                payway={wallet.network}
                currency={wallet.currency}
                walletAddress={wallet.walletAddress}
                isFavorite={favoriteWallets.includes(`${wallet.currency}/${wallet.network}`)}
                onToggleFavorite={() => toggleFavoriteWallet(`${wallet.currency}/${wallet.network}`)}
                riskScore={wallet.riskScore}
              />
            ))
          )}
        </div>
      )}
    </section>
  );
};

export default WalletOverview;
