import React, { useCallback, useEffect, useState } from 'react';
import useToggleUnit from 'api/unit/useToggleUnit';
import { UnknownType } from 'types/Unknown';
import { UnitFormData } from '../../types';
import { useTranslate } from 'hooks';
import { StageEnum } from 'hooks/use2FA';
import { useAuthorisationContext } from 'contexts';
import { LocalStorage, LocalStorageKey } from 'utils';
import { FormFreezeUnit } from '../FormFreezeUnit';
import { Modal, With2FA } from 'components';
import { ApartmentOutlined } from '@ant-design/icons';

export type ModalFreezeUnitProps = {
  isOpen: boolean;
  payload: UnknownType;
  onModalClose: () => void;
  onComplete: () => Promise<void>;
};

const ModalFreezeUnit = ({
  isOpen,
  onModalClose,
  onComplete,
  payload,
}: ModalFreezeUnitProps) => {
  const { t } = useTranslate();

  const [isOpenModal, setIsOpenModal] = useState({
    withoutReset: false,
    open: false,
  });

  const modalTitle = payload?.isActive ? t('users.units.deactivateUnit') : t('users.units.activateUnit');

  const { twoFA: {
    onSuccess,
    onError,
    setStage,
    setPrevStage,
  } } = useAuthorisationContext();

  const { mutate, isPending } = useToggleUnit({
    onSuccess: async (data) => {
      onSuccess(data, async () => {
        onComplete();
        onModalClose();
      });
    },
    onError: async (error) => {
      onError(
        error,
        () => {},
        () => {
          setIsOpenModal({
            open: true,
            withoutReset: true,
          });
        });
    },
  });

  const handleSubmit = useCallback((otpCode?: string) => {
    if (!payload) return;

    mutate({
      id: payload?.id as UnknownType,
      body: { isActive: !payload?.isActive, otpCode },
    });
  }, [mutate, payload]);

  useEffect(() => {
    if (isOpen) {
      const dontShowModal = LocalStorage.get(LocalStorageKey.DEACTIVATE_UNIT_DONT_SHOW_CONFIRMATION);

      if (dontShowModal === 'true') {
        return handleSubmit();
      } else {
        setIsOpenModal({
          withoutReset: false,
          open: true,
        });
      }
    } else {
      setIsOpenModal({
        withoutReset: false,
        open: false,
      });
    }
  }, [handleSubmit, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setStage(StageEnum.DEFAULT);
      setPrevStage(StageEnum.DEFAULT);
    }
  }, [isOpen, setPrevStage, setStage]);

  return (
    <Modal
      width={500}
      destroyOnClose
      isOpen={isOpenModal.open}
      onClose={onModalClose}
      title={modalTitle}
      titleIcon={<ApartmentOutlined style={{ fontSize: 20 }} />}
    >
      <With2FA onSubmit={handleSubmit} withoutReset={isOpenModal.withoutReset}>
        <FormFreezeUnit
          onSubmit={handleSubmit}
          loading={isPending}
          onClose={onModalClose}
          initialValues={payload as UnitFormData}
        />
      </With2FA>
    </Modal>
  );
};

export default ModalFreezeUnit;
