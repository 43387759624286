const generateColorByString = (string: string) => {
  const hash: number = Array
    .from(string)
    .reduce((acc: number, char: string) => char.charCodeAt(0) + ((acc << 5) - acc), 0);

  const maxBrightness = 0.4;

  const color = Math
    .floor(Math.abs((Math.sin(hash) * maxBrightness) * 16777215))
    .toString(16)
    .padStart(6, '0');

  const transparentColor = Math
    .floor(Math.abs((Math.sin(hash) * maxBrightness) * 16777215))
    .toString(16)
    .padStart(6, '0');

  const colorWithAlpha = transparentColor + '60';

  return { color, colorWithAlpha };
};

export default generateColorByString;
