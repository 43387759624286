import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { useGetColumns } from './useGetColumns';

type ActivityLogProps = {
  userId: number;
};

export const useActivityLogsTable = ({ userId }: ActivityLogProps) => {
  const getActivityLogs = async () => {
    const { data } = await axios.get(`${SERVICE.ADMIN}/logs`, {
      params: {
        paging: { limit: 5, offset: 0 },
        sorting: [{ field: 'createdAt', direction: 'DESC' }],
        filter: {
          and: [
            { userId: { eq: userId } },
          ],
        },
      },
    });
    return data;
  };
  const { data, isLoading } = useQuery({
    queryKey: ['my-account-activity-logs', { userId }],
    queryFn: getActivityLogs,
  });

  const columns = useGetColumns();

  return { columns, data, isLoading };
};
