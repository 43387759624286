import { DeviceAppVerifyNotSupported, UnresponsiveDeviceError, UserRefusedAddress } from '@ledgerhq/errors';
import Transport from '@ledgerhq/hw-transport';
import { log } from '@ledgerhq/logs';
import { UnknownType } from 'types/Unknown';
import { GetAddressOptions, Resolver } from './types';
import perFamily from './families';

const UNRESPONSIVE_TIMEOUT = 10000;

const dispatch: Resolver = (transport: Transport, opts: GetAddressOptions) => {
  const getAddress = (perFamily as UnknownType)[opts.currency.family];

  let timeoutId: NodeJS.Timeout;
  const unresponsivePromise = new Promise((resolve, reject) => {
    timeoutId = setTimeout(() => {
      reject(new UnresponsiveDeviceError());
    }, UNRESPONSIVE_TIMEOUT);
  });

  const getAddressPromise = getAddress(transport, opts)
    .then((result: UnknownType) => {
      clearTimeout(timeoutId);
      log('hw', `getAddress ${opts.currency.family} on ${opts.path}`, result);
      return result;
    })
    .catch((error: UnknownType) => {
      clearTimeout(timeoutId);
      log('hw', `getAddress ${opts.currency.family} on ${opts.path} FAILED ${String(error)}`);

      if (error && error.name === 'TransportStatusError') {
        if (error.statusCode === 0x6b00 && opts.verify) {
          throw new DeviceAppVerifyNotSupported();
        }

        if (error.statusCode === 0x6985 || error.statusCode === 0x5501) {
          throw new UserRefusedAddress();
        }
      }

      throw error;
    });

  return Promise.race([getAddressPromise, unresponsivePromise]);
};

export default dispatch;
