import React from 'react';
import { Permission } from '@fanckler/processing-auth';
import { MenuProps } from 'antd';
import { IUnit } from 'interfaces/IUnit';
import { CheckPermissionsFunction } from 'contexts/AuthorisationContext/AuthorisationContext';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import { CheckCircleOutlined, CloseCircleOutlined, ToolOutlined } from '@ant-design/icons';

export type GetMenuItemsProps<RecordType extends unknown> = {
  t: TranslateFunc;
  record: RecordType;
  checkPermissions: CheckPermissionsFunction;
  openEditModal: (record: RecordType) => void;
  openToggleModal: (record: RecordType) => void;
};

const getMenuItems = <RecordType extends IUnit>({
  t,
  record,
  openEditModal,
  openToggleModal,
  checkPermissions,
}: GetMenuItemsProps<RecordType>) => {
  const items: MenuProps['items'] = [];

  if (checkPermissions([Permission.CLIENT_UNIT_UPDATE])) {
    items.push({
      key: 'edit',
      label: t('edit'),
      icon: <ToolOutlined style={{ fontSize: 16 }} />,
      onClick: () => openEditModal(record),
    });
  }

  if (checkPermissions([Permission.ADMIN_IAM_ROOT_UNIT_ADMIN])) {
    const Icon = record?.isActive ? CloseCircleOutlined : CheckCircleOutlined;
    const iconColor = record?.isActive ? 'var(--red-status-color)' : 'var(--green-status-color)';

    items.push({
      key: 'toggle',
      label: record.isActive ? t('deactivate') : t('activate'),
      icon: <Icon style={{ fontSize: 16, color: iconColor }} />,
      onClick: () => openToggleModal(record),
    });
  }

  return items;
};

export default getMenuItems;
