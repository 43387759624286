import React from 'react';
import styled from 'styled-components';
import { UnknownType } from 'types/Unknown';
import { useTranslate } from 'hooks';
import { Box } from './Box.styled';
import Step, { type Status } from './Step';

const Container = styled(Box)`
  position: sticky;
  top: -20px;
  z-index: 2;
`;

const Wrapper = styled(Box).attrs(() => ({
  horizontal: true,
  alignItems: 'center',
  justifyContent: 'center',
  relative: true,
}))`
  margin-bottom: 25px;
  z-index: 2;
`;

const Bar = styled(Box)<{
  start: number,
  end: number,
  current: number,
  disabled?: number[] | null,
}>`
  background: #1da1f266;
  flex-grow: 1;
  height: 1px;
  left: ${p => p.start}%;
  position: absolute;
  overflow: hidden;
  right: ${p => p.end}%;
  top: 12px;
  z-index: 1;

  &:after,
  &:before {
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: auto;
    top: 0;
    transition: right ease-in-out 0.4s;
  }

  &:after {
    background: var(--primary-color);
    right: ${p => (p.current === 0 ? 0 : `${p.current}%`)};
    z-index: 1;
  }

  &:before {
    background: red;
    left: ${p => (p.disabled ? `${p.disabled[0]}%` : 0)};
    right: ${p => (p.disabled ? `${p.disabled[1]}%` : 'auto')};
    z-index: 2;
  }
`;

const indexToPercent = (index: string | number, itemsLength: number) =>
  100 - (100 / (itemsLength - 1)) * parseInt(index.toString(), 10);

type BreadcrumbProps = {
  currentStep: number | string,
  items: Array<UnknownType>,
  stepsDisabled?: Array<number>,
  stepsErrors?: Array<number>,
};

const Breadcrumb = ({
  currentStep,
  items,
  stepsDisabled = [],
  stepsErrors = [],
}: BreadcrumbProps) => {
  const { t } = useTranslate();
  const itemsLength = items.length;
  const start = 100 / itemsLength / 2;

  return (
    <Container>
      <Box>
        <Wrapper>
          {items
            .filter(i => !i.excludeFromBreadcrumb)
            .map((item, i) => {
              let status: Status = 'next';

              const stepIndex = parseInt(currentStep.toString(), 10);

              if (i === stepIndex) {
                status = 'active';
              }

              if (i < stepIndex) {
                status = 'valid';
              }

              if (stepsErrors.includes(i)) {
                status = 'error';
              }

              if (stepsDisabled.includes(i)) {
                status = 'disable';
              }

              return (
                <Step key={i} status={status} number={i + 1}>
                  {t(`coldStorage.steps.${item.label}`)}
                </Step>
              );
            })}
        </Wrapper>
        <Bar
          end={start}
          start={start}
          disabled={stepsDisabled.length > 0 ? [
            stepsDisabled[0] === 0 ? 0 : indexToPercent(stepsDisabled[0] + 1, itemsLength),
            indexToPercent(stepsDisabled[stepsDisabled.length - 1], itemsLength),
          ] : null}
          current={!currentStep ? 100 : indexToPercent(currentStep, itemsLength)}
        />
      </Box>
    </Container>
  );
};

export default Breadcrumb;
