import { UniqueIdentifier } from '@dnd-kit/core';

export type DragState = {
  active?: UniqueIdentifier | null;
  over?: UniqueIdentifier | null;
  direction?: 'right' | 'left';
};

const dragActiveStyle = (dragState: DragState, id: string) => {
  const { active } = dragState;

  let style: React.CSSProperties = {};

  if (active && active === id) {
    style = { opacity: 0.5 };
  }

  return style;
};

export default dragActiveStyle;
