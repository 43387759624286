import React, { useCallback } from 'react';
import { Dropdown, MenuProps } from 'antd';
import _ from 'lodash';
import { UnknownType } from 'types/Unknown';
import { Button, StatisticsButtonLink } from 'components';
import { DownOutlined, EllipsisOutlined, QuestionOutlined, UpOutlined } from '@ant-design/icons';
import styles from './Controls.module.scss';

type ExtendsRecordType = { id: number, rootUnitId?: number };

export type ControlsProps<RecordType extends ExtendsRecordType> = {
  record: RecordType;
  menuItems: MenuProps['items'];
  expandedRowKeys: React.Key[];
  setExpandedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
};

const Controls = <RecordType extends ExtendsRecordType>({
  record,
  menuItems,
  expandedRowKeys,
  setExpandedRowKeys,
}: ControlsProps<RecordType>) => {
  const handleExpandRow = useCallback((key: React.Key) => () => {
    setExpandedRowKeys(prevKeys =>
      _.includes(prevKeys, key)
        ? _.without(prevKeys, key)
        : [...prevKeys, key],
    );
  }, [setExpandedRowKeys]);

  const isExpanded = expandedRowKeys.includes(record.id);
  const rootUnitId = record.rootUnitId ? record.rootUnitId : record.id;
  const unitId = record.rootUnitId ? record.id : undefined;

  return (
    <div className={styles.container} onClick={e => e.stopPropagation()}>
      <StatisticsButtonLink rootUnitId={rootUnitId} unitId={unitId} />

      {menuItems?.length === 1 ? (
        <Button
          type="link"
          className={styles.button}
          prefixIcon={(menuItems[0] as UnknownType)?.icon || <QuestionOutlined />}
          onClick={(menuItems[0] as UnknownType)?.onClick}
        />
      ) : menuItems?.length !== 0 && (
        <Dropdown
          menu={{ items: menuItems }}
          placement="bottomRight"
          trigger={['click']}
          className={styles.dropdown}
          overlayClassName={styles.overlay}
        >
          <Button
            type="link"
            className={styles.button}
            prefixIcon={<EllipsisOutlined />}
          />
        </Dropdown>
      )}

      <Button
        type={isExpanded ? 'default' : 'link'}
        prefixIcon={isExpanded ? <UpOutlined /> : <DownOutlined />}
        onClick={handleExpandRow(record.id)}
        className={styles.button}
      />
    </div>
  );
};

export default Controls;
