import React, { ReactElement } from 'react';
import { IWallet } from 'types/IWallet';
import { useCurrencyRate } from 'hooks';
import { unifyWalletsStructure } from 'utils';
import { CountUp, CurrencyBadge } from 'components';
import styles from './BalanceCell.module.scss';

export type BalanceCellProps = {
  wallets: IWallet[];
};

const currency = 'USDT'; // Currency in which the balance is displayed

const BalanceCell = ({ wallets }: BalanceCellProps): ReactElement => {
  const { walletBalance } = useCurrencyRate();

  const amount = Number(
    walletBalance(unifyWalletsStructure(wallets), currency),
  );

  return (
    <div className={styles.wrapper}>
      <div style={{ marginBottom: 4, marginRight: 4 }}>
        <CurrencyBadge
          inline
          withoutText
          width="18px"
          currency={currency}
        />
      </div>
      <CountUp
        end={amount}
        currency={currency}
        className={styles.amount}
        isRoundDown
      />
      <span className={styles.currency}>{currency}</span>
    </div>
  );
};

export default BalanceCell;
