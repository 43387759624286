import { useCallback } from 'react';
import axios from 'libs/axios';
import { UnknownType } from 'types/Unknown';

const authEndpoints = ['auth/current-user', 'auth/login', 'auth/logout'];
const isAuthUrl = (url: string) => {
  return authEndpoints.some(endpoint => url.includes(endpoint));
};

const detectExpiredSession = (error: UnknownType) => {
  const { response, config } = error;
  const { status, data } = response || {};
  const { url } = config || {};

  const case1 = status === 401 && url && !isAuthUrl(url);
  const case2 = (status === 400 || status === 401)
    && url?.includes('auth/active-sessions')
    && data?.message !== 'Invalid RefreshToken';

  return case1 || case2;
};

export const useExpiredSessionInterceptor = (logout: (options?: { message?: string }) => void) => {
  const handleError = useCallback((error: UnknownType) => {
    if (detectExpiredSession(error)) {
      logout({ message: 'tokenExpired' });
    }
    return Promise.reject(error);
  }, [logout]);

  axios.interceptors.response.use(response => response, handleError);
};
