import React, { useContext, useEffect, useMemo } from 'react';
import { useUsers } from 'pages/Administration/Managers/hooks/useUsers';
import { useTablePagination, useTranslate } from 'hooks';
import useSocketStatus from 'hooks/useSocketStatus';
import useTableColumns from 'hooks/useTableColumns';
import { TranslateContext } from 'contexts';
import { LocalStorageKey } from 'utils/localStorage';
import { getColumns } from './utils/getColumns';
import { Pagination, useAssignedUnitClick } from 'components';
import DraggableTable from 'components/DraggableTable';
import styles from './ManagersTab.module.scss';

export type ManagersTabProps = {
  managerIds: number[];
};

const ManagersTab = ({ managerIds }: ManagersTabProps) => {
  const { localeSettings: { locale } } = useContext(TranslateContext);
  const { page, perPage, setPage, setPerPage } = useTablePagination({ withoutURL: true });
  const { t } = useTranslate();

  const { data, isLoading, refetch, isRefetching } = useUsers({
    filter: { id: { in: managerIds?.length ? managerIds : [0] } },
    paging: { limit: perPage, offset: (page - 1) * perPage },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
  });

  useEffect(() => {
    refetch();
  }, [page, perPage, refetch]);
  const handleUnitClick = useAssignedUnitClick();

  useSocketStatus(refetch);

  const managers = data?.nodes || [];
  const totalCount = data?.totalCount || 0;

  const initialColumns = useMemo(() => getColumns({
    t,
    locale,
    onUnitClick: handleUnitClick,
  }), [handleUnitClick, locale, t]);

  const { columns, setOrder } = useTableColumns({
    storageKey: LocalStorageKey.UNIT_MANAGERS_COLUMNS,
    initialColumns,
  });

  return (
    <div className={styles.wrapper}>
      <DraggableTable
        setOrder={setOrder}
        onRefresh={refetch}
        dataSource={managers}
        rowClassName={styles.row}
        rootClassName={styles.tableWrapper}
        loading={isLoading || isRefetching}
        columns={columns}
        scroll={{ x: 'max-content' }}
        pagination={false}
        rowKey="id"
      />
      <Pagination
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        totalCount={totalCount}
        onPerPageChange={setPerPage}
        disablePageChange={isLoading || isRefetching || !totalCount}
        disablePerPageHandler={isLoading || isRefetching || !totalCount}
        elementsSize="xs"
      />
    </div>
  );
};

export default ManagersTab;
