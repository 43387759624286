import axios, { AxiosError, SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { Filter } from 'types/Filter';
import { UnknownType } from 'types/Unknown';
import { BusinessResponseType } from '../types';
import { BusinessSorting } from '../Businesses';

type OptionType = {
  onSuccess?: (res: UnknownType) => void;
  onError?: (e: AxiosError<{ message: string }>) => void;
};

export interface UseBusinessesProps {
  sorting?: BusinessSorting[];
  filter?: Filter<Record<string, UnknownType>>;
  paging?: { limit: number; offset: number };
  withoutPaging?: boolean;
  enabled?: boolean;
  options?: OptionType;
}

export const useBusinesses = ({
  withoutPaging,
  paging,
  filter,
  sorting,
  enabled,
  options,
}: UseBusinessesProps) => {
  const getBusinesses = async () => {
    const { data } = await axios.get(`${SERVICE.ADMIN}/root-units`, {
      params: withoutPaging ? { filter, sorting } : { filter, paging, sorting },
    });

    return data;
  };

  return useQuery<BusinessResponseType>({
    queryKey: ['businesses', filter],
    queryFn: getBusinesses,
    enabled,
    ...options,
  });
};
