import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { UnknownType } from 'types/Unknown';
import { DefaultError, UseQueryHandlers } from 'types/UseQueryHandlers';

export type UseBusinessOptions = UseQueryHandlers<
'created' | 'updated' | 'deleted',
DefaultError,
CreateUnitVariablesType | UpdateUnitVariablesType | { id: number }
>;

type CreateUnitVariablesType = { body: UnknownType, type?: string };
type UpdateUnitVariablesType = {
  body: UnknownType, id: unknown, name?: string, type?: string,
};
type FreezeUnitVariablesType = {
  body: FreezeUnitDto, id: unknown, name?: string, type?: string,
};

type RemoveVariablesType = {
  id: number, name?: string, type?: string,
};

type FreezeUnitDto = {
  isActive: boolean;
  otpCode?: string;
};

const useUnitMutations = (options: UseBusinessOptions) => {
  const UNIT_URL = `${SERVICE.CLIENT}/units`;

  const create = async ({ body }: CreateUnitVariablesType) => {
    const { data } = await axios.post(`${UNIT_URL}/create`, body);
    return data;
  };

  const update = async ({ id, body }: UpdateUnitVariablesType) => {
    const { data } = await axios.put(`${UNIT_URL}/${id}`, body);
    return data;
  };

  const remove = async ({ id }: RemoveVariablesType) => {
    const { data } = await axios.delete(`${UNIT_URL}/${id}`);
    return data;
  };

  const freeze = async ({ id, body }: FreezeUnitVariablesType) => {
    const { data } = await axios.put(`${UNIT_URL}/${id}`, body);
    return data;
  };

  const removeUnitLogo = async ({ id }: { id: number }) => {
    const { data } = await axios.delete(`${UNIT_URL}/${id}/logo`);
    return data;
  };

  const { mutate: createUnit, isPending: isCreating } = useMutation({
    mutationFn: create,
    onSuccess: () => options.onSuccess('created'),
    onError: options.onError,
  });
  const { mutate: updateUnit, isPending: isUpdating } = useMutation({
    mutationFn: update,
    onSuccess: () => options.onSuccess('updated'),
    onError: options.onError,
  });
  const { mutate: removeUnit, isPending: isRemoving } = useMutation({
    mutationFn: remove,
    onSuccess: () => options.onSuccess('deleted'),
    onError: options.onError,
  });
  const { mutate: freezeUnit, isPending: isFreezing } = useMutation({
    mutationFn: freeze,
    onSuccess: () => options.onSuccess('updated'),
    onError: options.onError,
  });
  const { mutate: removeLogo, isPending: isRemovingLogo } = useMutation({
    mutationFn: removeUnitLogo,
    onSuccess: () => options.onSuccess('deleted'),
    onError: options.onError,
  });

  return {
    createUnit, isCreating,
    updateUnit, isUpdating,
    removeUnit, isRemoving,
    freezeUnit, isFreezing,
    removeLogo, isRemovingLogo,
  };
};

export default useUnitMutations;
