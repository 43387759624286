import { CryptoCurrency } from './types';

const cryptocurrenciesById: Record<string, CryptoCurrency> = {
  bitcoin: {
    type: 'CryptoCurrency',
    id: 'bitcoin',
    coinType: 0,
    name: 'Bitcoin',
    managerAppName: 'Bitcoin',
    ticker: 'BTC',
    // scheme: 'bitcoin',
    color: '#ffae35',
    symbol: 'Ƀ',
    // units: bitcoinUnits,
    supportsSegwit: true,
    supportsNativeSegwit: true,
    family: 'bitcoin',
    blockAvgTime: 15 * 60,
    bitcoinLikeInfo: {
      P2PKH: 0,
      P2SH: 5,
      XPUBVersion: 0x0488b21e,
    },
    explorerViews: [
      {
        address: 'https://blockstream.info/address/$address',
        tx: 'https://blockstream.info/tx/$hash',
      },
      {
        address: 'https://www.blockchain.com/btc/address/$address',
        tx: 'https://blockchain.info/btc/tx/$hash',
      },
    ],
  },
  ethereum: {
    type: 'CryptoCurrency',
    id: 'ethereum',
    coinType: 60,
    name: 'Ethereum',
    managerAppName: 'Ethereum',
    ticker: 'ETH',
    // scheme: 'ethereum',
    color: '#0ebdcd',
    symbol: 'Ξ',
    // units: ethereumUnits('ether', 'ETH'),
    family: 'ethereum',
    blockAvgTime: 15,
    ethereumLikeInfo: {
      baseChain: 'mainnet',
      chainId: 1,
      networkId: 1,
      hardfork: 'petersburg',
    },
    explorerViews: [
      {
        tx: 'https://etherscan.io/tx/$hash',
        address: 'https://etherscan.io/address/$address',
        token: 'https://etherscan.io/token/$contractAddress?a=$address',
      },
    ],
  },
  tron: {
    type: 'CryptoCurrency',
    id: 'tron',
    coinType: 195,
    name: 'Tron',
    managerAppName: 'Tron',
    ticker: 'TRX',
    // scheme: 'tron',
    color: '#D9012C',
    family: 'tron',
    blockAvgTime: 3,
    units: [
      {
        name: 'TRX',
        code: 'TRX',
        magnitude: 6,
      },
    ],
    explorerViews: [
      {
        tx: 'https://tronscan.org/#/transaction/$hash',
        address: 'https://tronscan.org/#/address/$address',
      },
    ],
  },
  bsc: {
    type: 'CryptoCurrency',
    id: 'bsc',
    coinType: 60,
    name: 'Binance Smart Chain',
    managerAppName: 'Binance Smart Chain',
    ticker: 'BNB',
    // scheme: 'bsc',
    color: '#F0B90A',
    family: 'ethereum',
    ethereumLikeInfo: {
      baseChain: 'mainnet',
      chainId: 56,
      networkId: 56,
      hardfork: 'muirGlacier',
    },
    units: [
      {
        name: 'BNB',
        code: 'BNB',
        magnitude: 18,
      },
    ],
    explorerViews: [
      {
        tx: 'https://bscscan.com/tx/$hash',
        address: 'https://bscscan.com/address/$address',
        token: 'https://bscscan.com/token/$contractAddress?a=$address',
      },
    ],
  },
  dogecoin: {
    type: 'CryptoCurrency',
    id: 'dogecoin',
    coinType: 3,
    name: 'Dogecoin',
    managerAppName: 'Dogecoin',
    ticker: 'DOGE',
    // scheme: 'dogecoin',
    color: '#65d196',
    family: 'bitcoin',
    blockAvgTime: 60,
    bitcoinLikeInfo: {
      P2PKH: 30,
      P2SH: 22,
      XPUBVersion: 0x02facafd,
    },
    symbol: 'Ð',
    units: [
      {
        name: 'dogecoin',
        code: 'DOGE',
        magnitude: 8,
      },
      {
        name: 'satoshi',
        code: 'sat',
        magnitude: 0,
      },
    ],
    explorerViews: [
      {
        tx: 'https://dogechain.info/tx/$hash',
        address: 'https://dogechain.info/address/$address',
      },
    ],
  },
  litecoin: {
    type: 'CryptoCurrency',
    id: 'litecoin',
    coinType: 2,
    name: 'Litecoin',
    managerAppName: 'Litecoin',
    ticker: 'LTC',
    // scheme: 'litecoin',
    color: '#cccccc',
    supportsSegwit: true,
    supportsNativeSegwit: true,
    family: 'bitcoin',
    blockAvgTime: 5 * 60,
    bitcoinLikeInfo: {
      P2PKH: 48,
      P2SH: 50,
      XPUBVersion: 0x019da462,
    },
    symbol: 'Ł',
    units: [
      {
        name: 'litecoin',
        code: 'LTC',
        magnitude: 8,
      },
      {
        name: 'mLTC',
        code: 'mLTC',
        magnitude: 5,
      },
      {
        name: 'litoshi',
        code: 'litoshi',
        magnitude: 0,
      },
    ],
    explorerViews: [
      {
        tx: 'https://live.blockcypher.com/ltc/tx/$hash',
        address: 'https://live.blockcypher.com/ltc/address/$address',
      },
    ],
  },
};

export const getCryptoCurrencyById = (id: string): CryptoCurrency => {
  const currency = cryptocurrenciesById[id];

  if (!currency) {
    throw new Error(`currency with id "${id}" not found`);
  }

  return currency;
};

export default cryptocurrenciesById;
