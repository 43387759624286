import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { batch } from 'react-redux';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { notification } from 'antd';
import useUpdateUnit from 'api/unit/useUpdateUnit';
import clsx from 'clsx';
import { IUnit } from 'interfaces';
import { UnknownType } from 'types/Unknown';
import { ActionModalType } from 'components/Modal/types/Modal';
import { useUnitChildren } from 'pages/Administration/Units/hooks';
import getColumns from 'pages/Administration/Units/utils/getColumns';
import { useNavigateToUnit, useTablePagination, useTranslate } from 'hooks';
import { StageEnum } from 'hooks/use2FA';
import { useModal } from 'components/Modal/hooks/useModal';
import { OTP_ERROR } from 'constants/index';
import { useAuthorisationContext } from 'contexts';
import { FormUnit } from '../../../FormUnit';
import { ModalFreezeUnit } from '../../../ModalFreezeUnit';
import { Modal, Pagination, With2FA } from 'components';
import Table from 'components/Table';
import TreeOrgStructure, { useDetectTreeStartPosition } from 'components/TreeOrgStructure';
import { ApartmentOutlined } from '@ant-design/icons';
import styles from './UnitsTab.module.scss';

export type UnitsTabProps = {
  uuid: string;
};

const UnitsTab = ({ uuid }: UnitsTabProps) => {
  const [savedData, setSavedData] = useState<FormData>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const {
    checkPermissions,
    twoFA: {
      onSuccess,
      onError,
      stage,
      setStage,
    },
  } = useAuthorisationContext();
  const { ref, width } = useDetectTreeStartPosition();
  const navigate = useNavigateToUnit();
  const { t } = useTranslate();

  const {
    isOpen,
    payload,
    isUpload,
    onModalOpen,
    onModalClose,
    onSetModalType,
    onSetModalPayload,
  } = useModal('unit-tab-units-update-child');

  const { page, perPage, setPage, setPerPage } = useTablePagination({
    withoutURL: true,
  });

  const { data, isLoading, refetch } = useUnitChildren({
    paging: { limit: perPage, offset: (page - 1) * perPage },
    uuid,
  });

  const openEditModal = useCallback((unit: IUnit) => {
    batch(() => {
      onSetModalPayload({ ...unit });
      onModalOpen();
    });
  }, [onModalOpen, onSetModalPayload]);

  const openToggleModal = useCallback((unit: IUnit) => {
    batch(() => {
      onSetModalType(ActionModalType.UPLOAD);
      onSetModalPayload({ ...unit });
      onModalOpen();
    });
  }, [onModalOpen, onSetModalPayload, onSetModalType]);

  const onRefresh = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const handleSuccess = useCallback((res: UnknownType) => {
    onSuccess(res, () => {
      notification.success({ message: t('users.units.updated') });
      onRefresh();
      onModalClose();
    });
  }, [onModalClose, onRefresh, onSuccess, t]);

  useEffect(() => {
    onRefresh();
  }, [onRefresh, page, perPage]);

  const { mutate: updateUnit, isPending: isUpdating } = useUpdateUnit({
    onSuccess: handleSuccess,
    onError: (error: UnknownType) => {
      onError(error, () => {
        if (stage !== StageEnum.DEFAULT && error.response?.data?.message.includes(OTP_ERROR)) {
          setStage(StageEnum.DEFAULT);
        }

        if (error?.response?.status === 413) {
          notification.error({ message: t('business.invalidFileSize') });
        } else {
          notification.error({
            description: error?.response?.data?.message,
            message: error?.response?.status,
          });
        }
      });
    },
  });

  const isMutating = isUpdating;

  const onSubmit = (formData: FormData, otpCode?: string) => {
    if (!otpCode) {
      setSavedData(formData);
    }
    updateUnit({ id: payload.id as number, body: formData });
  };

  const handleSubmitOtp = (otpCode: string) => {
    if (!savedData) {
      return;
    }
    onSubmit(savedData, otpCode);
  };

  const units = data || [];
  const totalCount = data?.length || 0;

  const columns = useMemo(() => (
    getColumns({
      t,
      openEditModal,
      openToggleModal,
      checkPermissions,
      expandedRowKeys,
      setExpandedRowKeys,
    })
  ), [
    t,
    openEditModal,
    openToggleModal,
    checkPermissions,
    expandedRowKeys,
  ]);

  return (
    <div className={styles.wrapper} ref={ref}>
      <Table
        rowKey="id"
        columns={columns}
        loading={isLoading}
        dataSource={units}
        onRefresh={onRefresh}
        pagination={false}
        scroll={{ x: 'max-content' }}
        rootClassName={styles.tableWrapper}
        rowClassName={record => clsx(styles.rowClassName, { [styles.deactivated]: !record.isActive })}
        onRow={record => ({
          onClick: () => navigate(record.rootUnitId, record.uuid),
        })}
        expandable={{
          expandedRowRender: record => <TreeOrgStructure parent={record} width={width} />,
          expandedRowClassName: () => styles.expandedRowClassName,
          showExpandColumn: false,
          expandedRowKeys,
        }}
      />
      <Pagination
        totalCount={totalCount}
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
        disablePageChange={isLoading || !totalCount}
        disablePerPageHandler={isLoading || !totalCount}
        elementsSize="xs"
      />
      {isUpload ? (
        <ModalFreezeUnit
          isOpen={isOpen}
          onModalClose={onModalClose}
          onComplete={async () => { await cleverRefetchQueries('unit-children'); }}
          payload={payload as UnknownType}
        />
      ) : (
        <Modal
          width={520}
          destroyOnClose
          isOpen={isOpen}
          title={t('users.units.updateUnit')}
          titleIcon={<ApartmentOutlined style={{ fontSize: 20 }} />}
          onClose={onModalClose}
        >
          <With2FA onSubmit={handleSubmitOtp}>
            <FormUnit
              loading={isMutating}
              onSubmit={onSubmit}
              onClose={onModalClose}
              initialValues={payload as UnknownType}
            />
          </With2FA>
        </Modal>
      )}
    </div>
  );
};

export default UnitsTab;
