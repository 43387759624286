import React from 'react';
import { Image } from 'antd';
import clsx from 'clsx';
import { CryptoCurrency } from 'components/ColdStorage/assets/crypto/types';
import { useNestedUnitContext } from 'pages/Administration/Units/components/NestedUnit/context';
import getCryptoCurrencyIcon from 'components/ColdStorage/utils/getIcon';
import { StepProps } from '../../AddAccounts';
import { Button } from 'components';
import { CheckCircleFilled } from '@ant-design/icons';
import styles from './StepFinish.module.scss';

const StepFinish = ({ t, currency }: StepProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.imageWrapper}>
        <Image
          width={50}
          preview={false}
          key={`added-icon-${((currency as CryptoCurrency).id)}`}
          src={getCryptoCurrencyIcon(currency as CryptoCurrency)}
        />

        <CheckCircleFilled className={styles.successBadge} />
      </div>

      <span className={styles.title}>{t('coldStorage.addAccounts.success.title')}</span>

      <span className={styles.description}>{t('coldStorage.addAccounts.success.desc')}</span>
    </div>
  );
};

export default StepFinish;

export const StepFinishFooter = ({ t, onAddMore, onModalClose }: StepProps) => {
  const { refetch } = useNestedUnitContext();

  const handleFinish = () => {
    onModalClose();
    refetch();
  };

  return (
    <div className={styles.footer}>
      <Button
        type="link"
        withoutBgShadow
        withoutWaveEffect
        onClick={onAddMore}
        className={clsx(styles.button, styles.addMore)}
      >
        {t('coldStorage.addAccounts.addMore')}
      </Button>

      <Button
        type="primary"
        withoutWaveEffect
        onClick={handleFinish}
        className={styles.button}
      >
        {t('continue')}
      </Button>
    </div>
  );
};
