import { useMutation } from 'libs/reactQuery';
import { notification } from 'antd';
import { UnknownType } from 'types/Unknown';
import { UseQueryHandlers } from 'types/UseQueryHandlers';
import { MutateActionEnum, MutationFunctionMap } from '../types';
import { useTranslate } from 'hooks';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import {
  transactionCategoryCreate, type TransactionCategoryCreateVariables,
  transactionCategoryDelete, type TransactionCategoryDeleteVariables,
  transactionCategoryUpdate, type TransactionCategoryUpdateVariables,
} from './mutations';

type MutationVariables = {
  [MutateActionEnum.CREATE]: TransactionCategoryCreateVariables;
  [MutateActionEnum.UPDATE]: TransactionCategoryUpdateVariables;
  [MutateActionEnum.DELETE]: TransactionCategoryDeleteVariables;
};

const notifyler = (action: MutateActionEnum, t: TranslateFunc, name: string) => {
  const notifications = {
    [MutateActionEnum.CREATE]: 'administration.financialManagement.categoryWithNameCreated',
    [MutateActionEnum.UPDATE]: 'administration.financialManagement.categoryWithNameUpdated',
    [MutateActionEnum.DELETE]: 'administration.financialManagement.categoryWithNameDeleted',
  };

  notification.success({ message: t(notifications[action], { variables: { name } }) });
};

const useTransactionCategoryMutate = (options?: UseQueryHandlers) => {
  const { t } = useTranslate();

  const handleSuccess = (action: MutateActionEnum) => (
    response: UnknownType,
    variables: UnknownType,
  ) => {
    options?.onSuccess?.(response, variables);
    notifyler(action, t, variables.name);
  };

  const handleError = (error: UnknownType, variables: UnknownType) => {
    options?.onError?.(error, variables);
    notification.error({
      message: error.message || t('somethingWrong'),
    });
  };

  const createMutation = useMutation({
    mutationFn: transactionCategoryCreate,
    onSuccess: handleSuccess(MutateActionEnum.CREATE),
    onError: handleError,
  });

  const updateMutation = useMutation({
    mutationFn: transactionCategoryUpdate,
    onSuccess: handleSuccess(MutateActionEnum.UPDATE),
    onError: handleError,
  });

  const deleteMutation = useMutation({
    mutationFn: transactionCategoryDelete,
    onSuccess: handleSuccess(MutateActionEnum.DELETE),
    onError: handleError,
  });

  const mutations: MutationFunctionMap<MutationVariables> = {
    [MutateActionEnum.CREATE]: createMutation.mutate,
    [MutateActionEnum.UPDATE]: updateMutation.mutate,
    [MutateActionEnum.DELETE]: deleteMutation.mutate,
  };

  return {
    isPending: createMutation.isPending || updateMutation.isPending || deleteMutation.isPending,
    mutate: <M extends MutateActionEnum>(action: M) => mutations[action],
  };
};

export default useTransactionCategoryMutate;
