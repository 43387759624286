import { StatusCodes, TransportStatusError } from '@ledgerhq/hw-transport';

const parseGetDeviceNameResponse = (response: Buffer): string => {
  const status = response.readUInt16BE(response.length - 2);

  switch (status) {
    case StatusCodes.OK:
      return response.slice(0, response.length - 2).toString('utf-8');
    case StatusCodes.DEVICE_NOT_ONBOARDED:
    case StatusCodes.DEVICE_NOT_ONBOARDED_2:
      return '';
  }

  throw new TransportStatusError(status);
};

export default parseGetDeviceNameResponse;
