import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './Loading.module.scss';

type LoadingProps = {
  size?: number;
  color?: string;
};

export const Loading = ({ size = 54, color }: LoadingProps) => {
  return (
    <div className={styles.loading}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: size, color }} spin />} />
    </div>
  );
};
