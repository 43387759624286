import React, { useId, useState } from 'react';
import { Input, InputProps, Select } from 'antd';
import clsx from 'clsx';
import { IWhitelistWallet } from 'interfaces';
import styles from './WalletInputWithWhitelist.module.scss';

type WalletInputWithWhitelistProps = Omit<InputProps, 'onChange' | 'onBlur'> & {
  whitelistedWallets: IWhitelistWallet[];
  value: string;
  onBlur: () => void;
  onChange: (value: string) => void;
  selectClassName?: string;
  selectPopupClassName?: string;
};

const WalletInputWithWhitelist = ({
  whitelistedWallets,
  onBlur,
  onChange,
  selectClassName,
  selectPopupClassName,
  ...props
}: WalletInputWithWhitelistProps) => {
  const [open, setOpen] = useState(false);
  const wrapperId = useId();

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const handleSelect = (selectedValue: string) => {
    onChange(selectedValue);
    setOpen(false);
  };

  const handleOpen = () => {
    if (whitelistedWallets.length) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    onBlur();
  };

  return (
    <div id={wrapperId} className={styles.wrapper}>
      <Input
        autoComplete="off"
        onChange={handleInput}
        onFocus={handleOpen}
        onBlur={handleClose}
        {...props}
      />

      <Select
        open={open}
        value={props.value}
        onSelect={handleSelect}
        className={clsx(styles.select, selectClassName)}
        popupClassName={clsx(styles.popup, selectPopupClassName)}
        getPopupContainer={() => document.getElementById(wrapperId) as HTMLElement}
      >
        {whitelistedWallets.map(wallet => (
          <Select.Option
            key={wallet.id}
            value={wallet.walletAddress}
            className={styles.option}
          >
            <div className={styles.vertical}>
              <span className={styles.name}>{wallet.name}</span>
              <span className={styles.address}>{wallet.walletAddress}</span>
            </div>

            <div className={styles.vertical} style={{ alignItems: 'flex-end' }}>
              <span className={styles.currency}>{wallet.currency}</span>
              <span className={styles.network}>{wallet.network}</span>
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default WalletInputWithWhitelist;
