import { ColumnsType } from 'antd/lib/table';
import { UnknownType } from 'types/Unknown';

const filterByDisplayed = <RecordType extends Record<string, UnknownType>>(
  columns: ColumnsType<RecordType>,
  displayedColumns: string[],
) => {
  return columns.filter(col => displayedColumns.includes(col.key as string));
};

export default filterByDisplayed;
