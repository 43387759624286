import axios, { AxiosError, SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { INotification } from 'interfaces/INotification';
import { Pagination } from 'types/Pagination';
import { NotificationsSorting } from 'components/Notifications/types';

export type NotificationParams = {
  paging?: Pagination,
  sorting?: NotificationsSorting[],
  filter?: Record<string, unknown>,
};

export type Response = {
  nodes: INotification[];
  totalCount: number;
  totalReadCount: number;
  totalUnreadCount: number;
  totalArchiveCount: number;
};

type Options = {
  onSuccess?: (data: Response) => void;
  onError?: (e: AxiosError<{ message: string }>) => void;
  enabled?: boolean;
};

const useNotifications = (params?: NotificationParams, options?: Options) => {
  const getNotifications = async () => {
    const { data } = await axios.get(
      `${SERVICE.NOTIFICATION}/notifications`, { params },
    );

    return data;
  };

  return useQuery<Response>({
    queryKey: ['notifications', params],
    queryFn: getNotifications,
    ...options,
  });
};

export default useNotifications;
