import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { MassWithdrawVariablesType, OptionType } from './types';

export const useMassWithdraw = (options?: OptionType) => {
  const massWithdraw = async ({ uuid, body }: MassWithdrawVariablesType) => {
    const { data } = await axios.post(`${SERVICE.CLIENT}/balance/mass-withdraw/unit/${uuid}`, body);
    return data;
  };

  const {
    mutate: massWithdrawMutate,
    isPending: isMassWithdrawLoading,
  } = useMutation({ mutationFn: massWithdraw, ...options });

  return {
    massWithdrawMutate,
    isMassWithdrawLoading,
  };
};
