import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { ChangeNotificationBodyType, ChangeNotificationOptionsType } from './types';

const changeNotificationMutate = async (body: ChangeNotificationBodyType) => {
  const { data } = await axios.patch(`${SERVICE.ADMIN}/users/update-notification`, body);
  return data;
};

export const useChangeNotification = (options: ChangeNotificationOptionsType) => {
  const {
    mutate: changeNotification,
    isPending: isChangeNotificationLoading,
  } = useMutation({ mutationFn: changeNotificationMutate, ...options });

  return {
    changeNotification,
    isChangeNotificationLoading,
  };
};
