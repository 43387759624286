import { UnknownType } from 'types/Unknown';
import { TreeNodeUnitType } from 'components/TreeUnitsSelect/types';

const flattenArray = (data: (TreeNodeUnitType | UnknownType) []): TreeNodeUnitType[] => {
  return data.reduce((acc, item) => {
    const { children = [], ...rest } = item;
    return acc.concat(rest, flattenArray(children));
  }, []) as TreeNodeUnitType[];
};

export default flattenArray;
