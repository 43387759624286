import React, { ReactElement, useCallback, useMemo } from 'react';
import { Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import { IStatus } from './interfaces/IStatus';
import { ColorEnum } from './types';
import { useTranslate } from 'hooks';
import { getStatuses } from './utils';
import { QuestionCircleOutlined } from '@ant-design/icons';
import styles from './StatusBadge.module.scss';

type TextTransformType = 'uppercase' | 'lowercase' | 'capitalize' | 'none';

export type StatusBadgePropsType = {
  status: string;
  withoutIcon?: boolean,
  withoutText?: boolean,
  withBackground?: boolean,
  withTooltip?: boolean;
  textTransform?: TextTransformType,
  tooltipText?: string | null;
};

export const StatusBadge = ({
  status,
  withoutIcon,
  withoutText,
  withBackground,
  withTooltip,
  textTransform = 'none',
  tooltipText,
}: StatusBadgePropsType): ReactElement => {
  const { t } = useTranslate();

  const statuses = useMemo(() => getStatuses(t), [t]);

  const transformText = useCallback(
    (text: string) => {
      switch (textTransform) {
        case 'uppercase':
          return text.toUpperCase();
        case 'lowercase':
          return text.toLowerCase();
        case 'capitalize':
          return text.charAt(0).toUpperCase() + text.slice(1);
        default:
          return text;
      }
    }, [textTransform],
  );

  const statusOption = useMemo(
    () => {
      const hexAlpha = 30;

      const defaultStatus: IStatus = {
        status: `UNKNOWN (${status})`,
        color: ColorEnum.ERROR,
        icon: QuestionCircleOutlined,
      };

      const currentStatus = statuses.find(item => item.status.toLowerCase() === status.toLowerCase()) || defaultStatus;

      return {
        ...currentStatus,
        title: transformText(currentStatus.title || currentStatus.status),
        bgColor: currentStatus.bgColor || `${currentStatus.color}${hexAlpha}`,
      };
    }, [transformText, status, statuses],
  );

  const StatusIcon = !withoutIcon && statusOption.icon;

  return (
    <div
      className={clsx(styles.container, { [styles.withBackground]: withBackground })}
      style={{
        color: statusOption.color,
        backgroundColor: withBackground ? statusOption.bgColor : undefined,
      }}
    >
      <Tooltip title={withTooltip && (tooltipText || statusOption.title)}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {StatusIcon && <StatusIcon className={styles.icon} />}
          {!withoutText && (
            <Typography.Text className={styles.text}>{statusOption.title}</Typography.Text>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
