import React, { ReactElement } from 'react';
import { Form, FormInstance, Input } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/lib/form/interface';
import clsx from 'clsx';
import { ChangePasswordBodyType } from '../../hooks/types';
import { useTranslate } from 'hooks';
import { PASSWORD_REGEX } from 'constants/validation';
import { generatePassword } from 'utils';
import { Button } from 'components';
import { LockOutlined, SyncOutlined } from '@ant-design/icons';
import styles from './FormChangePassword.module.scss';

export type FormChangePassDataType = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  otpCode: string;
};

export type FormChangePassPropsType = {
  onSubmit: (body: ChangePasswordBodyType) => void;
  onClose: () => void;
  form: FormInstance;
  loading: boolean;
};

export const FormChangePassword = ({
  onSubmit,
  onClose,
  loading,
  form,
}: FormChangePassPropsType): ReactElement => {
  const { t } = useTranslate();

  const onGeneratePassword = () => {
    const newPassword = generatePassword();
    form.setFieldsValue({ newPassword, confirmPassword: newPassword });
  };

  const onFinish = (values: FormChangePassDataType) => {
    onSubmit({
      oldPassword: values.oldPassword,
      password: values.newPassword,
      otpCode: values.otpCode,
    });
  };

  const passwordRules = { pattern: PASSWORD_REGEX, message: t('passwordRules') };

  const confirmPasswordValidator = (_: RuleObject, value: StoreValue) => {
    if (!value || form.getFieldValue('newPassword') === value) {
      return Promise.resolve();
    }
    return Promise.reject(t('myAccount.validation.passwordMismatch'));
  };

  return (
    <Form
      form={form}
      name="password_change"
      onFinish={onFinish}
      className={styles.form}
    >
      <div>
        <Form.Item
          name="oldPassword"
          label={t('myAccount.fields.oldPassword')}
          className={styles.formItem}
          rules={[{ required: true, message: t('myAccount.validation.pleaseInputOldPassword') }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder={t('myAccount.fields.oldPassword')}
            className={styles.input}
          />
        </Form.Item>

        <div className={clsx(styles.formItem, styles.withAddonBtn)}>
          <Form.Item
            name="newPassword"
            label={t('myAccount.fields.newPassword')}
            rules={[{ required: true, message: t('myAccount.validation.pleaseInputNewPassword') }, passwordRules]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder={t('myAccount.fields.newPassword')}
              className={styles.input}
            />
          </Form.Item>

          <Button
            type="link"
            textSize="14px"
            withoutBgShadow
            withoutWaveEffect
            prefixIcon={<SyncOutlined />}
            className={styles.genereatePassBtn}
            onClick={onGeneratePassword}
          >
            {t('myAccount.generatePassword')}
          </Button>
        </div>

        <Form.Item
          name="confirmPassword"
          className={styles.formItem}
          label={t('myAccount.fields.confirmPassword')}
          rules={[
            { required: true, message: t('myAccount.validation.pleaseConfirmPassword') },
            { validator: confirmPasswordValidator }, passwordRules,
          ]}
        >
          <Input.Password
            placeholder={t('myAccount.fields.confirmPassword')}
            prefix={<LockOutlined />}
            className={styles.input}
          />
        </Form.Item>
      </div>

      <div className={styles.buttons}>
        <Button
          block
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          {t('save')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          disabled={loading}
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
      </div>
    </Form>
  );
};
