import _ from 'lodash';
import { IWhitelistWallet } from 'interfaces';

const filterWhitelistWallets = ({
  wallets,
  currency,
  network,
}: {
  wallets: IWhitelistWallet[];
  currency: string | undefined;
  network: string | undefined;
}) => {
  return _.filter(wallets, wallet => {
    const currencyMatch = currency ? wallet.currency === currency : true;
    const networkMatch = network ? wallet.network === network : true;
    return currencyMatch && networkMatch;
  });
};

export default filterWhitelistWallets;
