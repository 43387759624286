export enum TransactionDirectionEnum {
  FEE = 'fee', // Fee
  PAYOUT = 'payout', // Payout
  PAYIN = 'payin', // Payin
  REFUND = 'refund', // Refund
  REFILL = 'refill', // Refill
  UNREFILL = 'unrefill', // Unrefill
  APPROVE = 'approve', // Approve
  AGGREGATE = 'aggregate', // Aggregate
  ACC_ACTIVATE = 'acc_activate', // Account Activate
  DELEGATE_BALANCE_BANDWIDTH = 'delegateBalance_bandwidth', // Delegate Balance Bandwidth
  UNDELEGATE_BALANCE_BANDWIDTH = 'undelegateBalance_bandwidth', // Undelegate Balance Bandwidth
  DELEGATE_BALANCE_ENERGY = 'delegateBalance_energy', // Delegate Balance Energy
  UNDELEGATE_BALANCE_ENERGY = 'undelegateBalance_energy', // Undelegate Balance Energy
  FREEZE_BALANCE_BANDWIDTH = 'freezeBalance_bandwidth', // Freeze Balance Bandwidth
  UNFREEZE_BALANCE_BANDWIDTH = 'unfreezeBalance_bandwidth', // Unfreeze Balance Bandwidth
  FREEZE_BALANCE_ENERGY = 'freezeBalance_energy', // Freeze Balance Energy
  UNFREEZE_BALANCE_ENERGY = 'unfreezeBalance_energy', // Unfreeze Balance Energy
}
