import React, { PropsWithChildren, useEffect } from 'react';
import { StageEnum } from 'hooks/use2FA';
import { useAuthorisationContext } from 'contexts';
import { Form2FA } from '../Form2FA';
import { FormScanQR } from '../FormScanQR';

type With2FAProps = PropsWithChildren<{
  onSubmit: (code: string) => void;
  style?: React.CSSProperties;
  className?: string;
  isLoading?: boolean;
  withoutReset?: boolean;
}>;

const With2FA = ({
  onSubmit,
  children,
  className,
  style,
  isLoading,
  withoutReset,
}: With2FAProps) => {
  const {
    twoFA: {
      qrcode,
      stage,
      setStage,
      prevStage,
      setPrevStage,
    },
    loading,
  } = useAuthorisationContext();

  useEffect(() => {
    if (!withoutReset) {
      setStage(StageEnum.DEFAULT);
      setPrevStage(StageEnum.DEFAULT);
    }
  }, [setPrevStage, setStage, withoutReset]);

  return (
    <>
      {stage === StageEnum.DEFAULT && children}

      {(stage === StageEnum.QR_CODE && !!qrcode) && (
        <div className={className} style={style}>
          <FormScanQR
            qrCode={qrcode}
            onBack={() => setStage(StageEnum.DEFAULT)}
            onContinue={() => {
              setPrevStage(StageEnum.QR_CODE);
              setStage(StageEnum.OTP_CODE);
            }}
          />
        </div>
      )}

      {stage === StageEnum.OTP_CODE && (
        <div className={className} style={style}>

          <Form2FA
            isLoading={Boolean(loading || isLoading)}
            onSubmit={onSubmit}
            onBack={() => setStage(prevStage)}
          />
        </div>
      )}
    </>
  );
};

export default With2FA;
