import React, { memo, useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import dragActiveStyle from './utils/dragActiveStyle';

export type DraggableHeaderCellProps = React.HTMLAttributes<HTMLTableCellElement> & {
  style: React.CSSProperties;
  id: string;
};

export const DraggableHeaderCell = memo((props: DraggableHeaderCellProps) => {
  const {
    over,
    active,
    overIndex,
    activeIndex,
    listeners,
    transform,
    transition,
    setNodeRef,
    attributes,
  } = useSortable({ id: props.id });

  const dragActiveStyles: React.CSSProperties = useMemo(() => (
    dragActiveStyle(({
      over: over?.id,
      active: active?.id,
      direction: overIndex > activeIndex ? 'right' : 'left',
    }), props.id)
  ), [active?.id, activeIndex, over?.id, overIndex, props]);

  const style: React.CSSProperties = {
    ...props.style,
    ...dragActiveStyles,
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move',
  };

  return (
    <th {...props} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.children}
    </th>
  );
});
