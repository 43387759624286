import React from 'react';
import { UnknownType } from 'types/Unknown';
import styles from './StepperFooter.module.scss';

type StepperFooterProps = {
  children: UnknownType,
};

const StepperFooter = ({ children }: StepperFooterProps) =>{
  return (
    <div className={styles.wrapper}>
      {children}
    </div>
  );
};

export default StepperFooter;
