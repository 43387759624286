import React, { lazy } from 'react';
import { Permission } from '@fanckler/processing-auth';
import lazyRetry from 'setup/lazyRetry';
import { IRoute } from './interfaces/IRoute';
import { Path } from './interfaces/Path';

const ActivityLog = lazy(() => lazyRetry(() => import('../pages/System/ActivityLog/ActivityLog')));
const Firewall = lazy(() => lazyRetry(() => import('../pages/System/Firewall/Firewall')));
const Commissions = lazy(() => lazyRetry(() => import('pages/System/Commissions/Commissions')));

const systemRoutes: IRoute[] = [
  {
    path: `${Path.SYSTEM_ACTIVITY_LOG}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.ADMIN_IAM_LOGS_READ],
    component: () => <ActivityLog />,
  },
  {
    path: `${Path.SYSTEM_FIREWALL}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.ADMIN_IAM_ALLOWED_IP_READ],
    component: () => <Firewall />,
  },
  {
    path: `${Path.SYSTEM_COMMISSIONS}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_COMMISSION_READ],
    component: () => <Commissions />,
  },
];

export default systemRoutes;
