import React, { useMemo } from 'react';
import { Progress, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import clsx from 'clsx';
import { UnknownType } from 'types/Unknown';
import { useTranslate } from 'hooks';
import { convertCurrency } from 'utils/convertCurreny';
import { CurrencyBadge } from 'components';
import styles from './AllocationTable.module.scss';

export const AmountJsx = (amount: number) => {
  return new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(
    amount,
  );
};

type DataSource = {
  currency: string;
  amount: number;
  price: number;
};

type AssetAllocationTableProps = {
  data: Record<string, Record<string, string | number>>;
  rates: UnknownType;
};

const SKIP_CURRENCIES = ['USDC'];

export const AssetAllocationTable = ({ data, rates }: AssetAllocationTableProps) => {
  const { t } = useTranslate();

  const formattedData = useMemo(() => {
    const result: DataSource[] = [];
    for (const value of Object.values(data)) {
      for (const [currency, amount] of Object.entries(value)) {
        if (SKIP_CURRENCIES.includes(currency)) {
          continue;
        }
        const price = convertCurrency(rates, currency, 'USD', 1);

        result.push({
          currency: currency,
          price: price,
          amount: Number(amount),
        });
      }
    }
    return result;
  }, [data, rates]);

  const totalValue = useMemo(() => {
    return formattedData.reduce((total, el) => total + el.amount * el.price, 0);
  }, [formattedData]);

  const columns: ColumnsType<(typeof formattedData)[0]> = [
    {
      title: t('coldStorage.assetType'),
      dataIndex: 'assetType',
      key: 'assetType',
      render: (_, { currency }) => <CurrencyBadge currency={currency} prettifyLabel />,
    },
    {
      title: t('price'),
      dataIndex: 'price',
      key: 'price',
      render: AmountJsx,
    },
    {
      title: t('coldStorage.allocation'),
      dataIndex: 'allocation',
      key: 'allocation',
      render: (_: UnknownType, { amount, price }: { amount: number, price: number }) => {
        const allocation = (((amount * price) / totalValue) * 100) || 0;
        const percent = Number(allocation.toFixed(2));

        return (
          <Progress
            className={styles.progress}
            percent={percent}
            size="small"
            format={() => (
              <span
                className={clsx(styles.percent, {
                  [styles.little]: percent !== undefined && percent < 50,
                  [styles.middle]: percent !== undefined && percent >= 50 && percent < 80,
                  [styles.full]: percent !== undefined && percent >= 80,
                })}
              >
                {Number(percent !== undefined && percent.toFixed())}%
              </span>
            )}
          />
        );
      },
    },
    {
      title: t('coldStorage.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number, { currency }) => (
        <div className={styles.tdAmount}>
          <CurrencyBadge currency={currency} withoutText />
          <span>{amount} {currency}</span>
        </div>
      ),
    },
    {
      title: t('coldStorage.value'),
      dataIndex: 'value',
      key: 'value',
      render: (_: UnknownType, { amount, price }: { amount: number, price: number }) => {
        return `${Number((amount * price).toFixed(2))}$`;
      },
    },
  ];

  return (
    <Table
      dataSource={formattedData}
      columns={columns}
      rowKey="key"
      className={styles.table}
      pagination={false}
    />
  );
};
