import { useContext } from 'react';
import { Permission } from '@fanckler/processing-auth';
import { IUser } from 'interfaces/IUser';
import { Path } from 'routes/interfaces/Path';
import { AuthorisationContext } from 'contexts';

export const useGenerateStatisticsPath = () => {
  const { user, checkPermissions } = useContext(AuthorisationContext);

  const isRootUnitAdmin = !!(user as IUser).rootUnitId && checkPermissions([Permission.ADMIN_IAM_ROOT_UNIT_ADMIN]);
  const isUnitAdmin = !!(user as IUser).rootUnitId && !!(user as IUser).units.length;

  if (isRootUnitAdmin) {
    return `${Path.ADMINISTRATION_STATISTICS}/${(user as IUser).rootUnitId}`;
  } else if (isUnitAdmin) {
    if ((user as IUser).units.length > 1) {
      return `${Path.ADMINISTRATION_STATISTICS}/${(user as IUser).rootUnitId}`;
    } else {
      const unitId = (user as IUser).units[0].id;
      return `${Path.ADMINISTRATION_STATISTICS}/${(user as IUser).rootUnitId}?unitId=${unitId}`;
    }
  } else {
    return `${Path.ADMINISTRATION_STATISTICS}/${(user as IUser).rootUnitId}`;
  }
};
