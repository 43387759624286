import React from 'react';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { useTranslate } from 'hooks';
import { Button } from 'components';
import { ReloadOutlined } from '@ant-design/icons';
import styles from './RefetchTransactionsButton.module.scss';

export type RefetchTransactionsButtonProps = {
  disabled?: boolean;
};

const RefetchTransactionsButton = ({ disabled }: RefetchTransactionsButtonProps) => {
  const { t } = useTranslate();

  const handleRefetchTransactions = async () => {
    await cleverRefetchQueries('transactions');
  };

  return (
    <Button
      type="default"
      textSize="14px"
      disabled={disabled}
      onClick={handleRefetchTransactions}
      suffixIcon={<ReloadOutlined style={{ fontSize: 16 }} />}
      className={styles.button}
    >
      {t('users.transactions.refresh')}
    </Button>
  );
};

export default RefetchTransactionsButton;
