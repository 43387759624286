import React from 'react';
import { useDispatch } from 'react-redux';
import { CryptoCurrency } from 'components/ColdStorage/assets/crypto/types';
import { ColdStorageWallet } from 'components/ColdStorage/types';
import { useNestedUnitContext } from 'pages/Administration/Units/components/NestedUnit/context';
import useConnect, {
  ConnectionRequest,
  ConnectionStatus,
} from 'components/ColdStorage/ledger/hooks/useConnect';
import { createWallet, CreateWalletDTO } from 'components/WalletOverview/hooks/useCreateWallet';
import { NETWORKS_TO_CURRENCY_AND_NETWORK_MAP } from 'components/ColdStorage/constants';
import { StepProps } from 'components/ColdStorage/ledger/actions/AddAccounts';
import {
  renderError,
  renderLoading,
  renderRequestOpenApp,
  renderVerifyAddress,
} from 'components/ColdStorage/ledger/rendering/rendering';
import { setColdStorageWallets } from 'components/ColdStorage/storage';
import styles from './StepConnectDevice.module.scss';

const StepConnectDevice = ({
  t,
  setError,
  currency,
  deviceId,
  deviceName,
  transitionTo,
}: StepProps) => {
  const { unit } = useNestedUnitContext();
  const dispatch = useDispatch();

  const onFinish = async (data: ColdStorageWallet) => {
    dispatch(setColdStorageWallets({
      id: `ledger-${deviceId}`,
      wallets: { [(currency as CryptoCurrency).name]: data },
    }));

    const currencyAndNetwork = NETWORKS_TO_CURRENCY_AND_NETWORK_MAP.get((currency as CryptoCurrency).name);

    if (!currencyAndNetwork) {
      return Promise.reject({
        response: {
          data: {
            message: 'Invalid currency or network',
          },
        },
      });
    }

    const dto: CreateWalletDTO = {
      ...currencyAndNetwork,
      hardwareId: `ledger-${deviceId}`,
      name: deviceName as string,
    };

    return createWallet(unit?.id)(dto).then(() => transitionTo('finish'));
  };

  const { device, state, onRetry } = useConnect(currency as CryptoCurrency, {
    onFinish,
    setError,
  });

  return (
    <div className={styles.wrapper}>
      {state.status === ConnectionStatus.LOADING && renderLoading({
        children: <span className={styles.loading}>{t('coldStorage.connecting')}...</span>,
      })}

      {state.status === ConnectionStatus.ERROR &&
        renderError({
          error: state.reason,
          onRetry,
          t,
        })}

      {state.status === ConnectionStatus.PENDING_CONFIRMATION && (
        <>
          {state.reason === ConnectionRequest.OPEN_APP && device && renderRequestOpenApp({
            modelId: device,
            type: 'light',
            currency: currency as CryptoCurrency,
            t,
          })}

          {state.reason === ConnectionRequest.VERIFY && device && renderVerifyAddress({
            modelId: device,
            type: 'light',
            currency: currency as CryptoCurrency,
            t,
          })}
        </>
      )}
    </div>
  );
};

export default StepConnectDevice;
