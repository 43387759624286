import { notification } from 'antd';
import Axios, { InternalAxiosRequestConfig } from 'axios';
import { isEmpty } from 'lodash';
import { UnknownType } from 'types/Unknown';
import { getFingerprint } from 'contexts/AuthorisationContext/hooks/useFingerprint/utils/getFingerprint';
import {
  isAccessTokenExpired,
  isAccessTokenExpiring,
  refreshSession,
} from 'contexts/AuthorisationContext/hooks/useRefreshToken/utils/refreshToken';
import LocalStorage, { LocalStorageKey } from 'utils/localStorage';

export { AxiosError } from 'axios';

export const enum SERVICE {
  CLIENT = 'client',
  ADMIN = 'admin',
  NOTIFICATION = 'notification',
  TRON = 'tron',
  BTC = 'btc',
  ETH = 'eth',
  BNB = 'bnb',
  RATE = 'rate',
}

const PROCESSING_URLS = [
  `${SERVICE.BTC}`,
  `${SERVICE.TRON}`,
  `${SERVICE.ETH}`,
  `${SERVICE.BNB}`,
];

export const axiosExcludedUrls: string[] = [
  `${SERVICE.ADMIN}/auth/active-sessions`,
  `${SERVICE.ADMIN}/auth/refresh-token`,
  `${SERVICE.ADMIN}/auth/logout`,
  `${SERVICE.ADMIN}/auth/login`,
  'init-config',
  ...PROCESSING_URLS,
];

const axios = Axios.create({
  headers: {
    Authorization: `Bearer ${LocalStorage.get(LocalStorageKey.ACCESS_TOKEN)}`,
  },
  withCredentials: true,
  baseURL: process.env.REACT_APP_GATEWAY,
});

const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

const getBase64Timestamp = () => btoa(Date.now().toString());

const maybeRefreshSession = async (config: InternalAxiosRequestConfig<UnknownType>) => {
  const isExpiring = isAccessTokenExpiring();
  const isExpired = isAccessTokenExpired();

  if ((isExpiring && !isExpired) || isExpiring || isExpired) {
    const isAuthURL = axiosExcludedUrls.some((address) => (config.url as string).startsWith(address));
    const isProcessingURL = PROCESSING_URLS.some((address) => (config.url || '').startsWith(address));

    if ((!isAuthURL && !isExpired) || isProcessingURL) {
      try {
        const fingerprint = await getFingerprint();
        await refreshSession(fingerprint);
        await sleep(500);
      } catch (e) {
        notification.warn({ message: 'Something went wrong. Please try again' });
      }
    }
  }
};

const checkIsInternalRequest = (url: string, baseUrl: string) => {
  if (url.includes('http')) {
    return url.includes(process.env.REACT_APP_GATEWAY || '');
  }
  return baseUrl.includes(process.env.REACT_APP_GATEWAY || '');
};

axios.interceptors.request.use(async (config) => {
  await maybeRefreshSession(config);
  const isInternalUrl = checkIsInternalRequest(config.url || '', config.baseURL || '');

  if (isInternalUrl) {
    const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : null;
    config.headers.nonce = getBase64Timestamp();
  } else {
    config.headers.Authorization = null;
    config.withCredentials = false;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  return response;
}, async (error) => {
  const hasErrorResponse = error.response;
  if (!hasErrorResponse) return Promise.reject(error);

  const originalConfig = error.config || {};
  const isAuthURL = axiosExcludedUrls.some((address) => (originalConfig.url as string)?.startsWith(address));
  const isRefreshedRequest = originalConfig._isRefreshedRequest; // this is a flag to know if a token refresh was attempted
  const isTokenExpiryError = error.response.status === 401;

  if (isTokenExpiryError && !isRefreshedRequest && !isAuthURL) {
    originalConfig._isRefreshedRequest = true; // set the flag so that we don't end up in an infinite loop
    try {
      const fingerprint = await getFingerprint();
      const refreshSessionResponse = await refreshSession(fingerprint);
      if (isEmpty(refreshSessionResponse)) {
        return await Promise.reject({ message: 401 });
      }
    } catch (e) {
      LocalStorage.remove(LocalStorageKey.ACCESS_TOKEN);
      return Promise.reject(error);
    }

    return axios(originalConfig); // retry the original request
  }

  // TODO: Implement notification system here (using blacklist or whitelist for error codes and messages)
  // notification.error({
  //   message: get(error, ['response', 'data', 'message'], 'Oops! Something went wrong.'),
  //   description: get(error, ['message'], 'Please try again later.'),
  // });

  return Promise.reject(error);
});

export default axios;
